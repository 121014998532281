import { useSocket } from "@/contexts/socketContext";
import React, { useContext } from "react";
import { contentDivText, headings, buttonTitle } from "@/constants/constants";
import useDivHeight from "@/hooks/useDivHeight";
import { MonitorVivaProps } from "@/types/componentTypes";
import { useSelector } from "react-redux";
import { RootState } from "@/store";
import ButtonComponent from "./buttonComponent";
import VideoConferencing from "./videoConferencing";
import { ItemStateContext } from "@/contexts/questionContext";
import classNames from "classnames";
import { MdOutlineCallEnd } from "react-icons/md";

const MonitorViva: React.FC<MonitorVivaProps> = ({ candidateDetails }) => {
  const { candidates }: any = useSocket();
  const containerIdName = "container";
  const divHeight = useDivHeight(containerIdName);
  const userId = useSelector((state: RootState) => state.candidateId);
  // const [isConnected, setIsConnected] = useState(false);
  const { setVivaStarted, isConnected, setIsConnected }: any =
    useContext(ItemStateContext);

  const handleConnectToViva = () => {
    setIsConnected(true);
  };

  const handleDisconnect = () => {
    setIsConnected(false);
  };
  return (
    <div
      className="w-[85%] h-[91%] bg-[#DEE1E6] px-[10px] py-[20px]"
      id={containerIdName}>
      <div className="pb-[10px]">
        {contentDivText?.candidateId}
        <span>{candidateDetails?.candidate_id}</span>
      </div>
      <div
        className="bg-[white] pl-[10px] pr-[10px] pt-[20px] w-[100%]"
        style={{ height: divHeight - 60, overflowY: "hidden" }}>
        {candidates[userId] === headings?.vivaOngoing ? (
          <div className="h-[95%] flex flex-col justify-center items-center mx-[30px]">
            {isConnected ? (
              <div
                className={classNames(
                  "h-[90%] w-[100%] text-[20px] flex flex-col space-y-[10px] items-center justify-center ",
                )}>
                <div className="bg-[lightGray] ">
                  <VideoConferencing
                    setVivaStarted={setVivaStarted}
                    isMonitorTab={true}
                  />
                </div>
                <div
                  className="bg-primary rounded-[50%] p-[8px]"
                  onClick={handleDisconnect}>
                  <MdOutlineCallEnd size={25} style={{ color: "white" }} />
                </div>
              </div>
            ) : (
              <div
                className={classNames(
                  "h-[95%] w-full border-[2px] border-[lightgray] border-solid rounded-[2px] text-[20px] flex items-center justify-center bg-[lightGray]",
                )}>
                <ButtonComponent
                  text={buttonTitle?.connect}
                  className="bg-primary px-[15px] py-[10px] text-[14px] border-none rounded-md font-semibold text-[white]"
                  onClick={handleConnectToViva}
                />
              </div>
            )}
          </div>
        ) : candidates[userId] === headings?.waiting ||
          candidates[userId] === headings?.online ? (
          <div
            className={classNames(
              "h-[95%] mx-[30px] border-[2px] border-[lightgray] border-solid rounded-[2px] text-[20px] flex items-center justify-center bg-[lightGray]",
            )}>
            {headings?.vivaNotStarted}
          </div>
        ) : (
          candidates[userId] === headings?.completed && (
            <div
              className={classNames(
                "h-[95%] mx-[30px] border-[2px] border-[lightgray] border-solid rounded-[2px] text-[20px] flex items-center justify-center bg-[lightGray]",
              )}>
              {headings?.vivaCompleted}
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default MonitorViva;
