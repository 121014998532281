/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useRef, useState } from "react";
import { ZIM } from "zego-zim-web";
import { ConfigProvider, Input } from "antd";
import classNames from "classnames";
import { FiSend } from "react-icons/fi";
import { ItemStateContext } from "@/contexts/questionContext";
import { ChatContext } from "@/contexts/chatContext";
import { localStorageKeys, messageEnum } from "@/constants/constants";
import { ChatProps } from "@/types/componentTypes";
import { authenticatedAxios } from "@/utils/axiosInterceptor";
import { CustomAxiosRequestConfig } from "@/types/functionTypes";
import { getIntlErrorMessage, handleMessage } from "@/utils/functions";
import { URL } from "@/utils/service-urls";
import { useSocket } from "@/contexts/socketContext";

const Chat: React.FC<ChatProps> = ({
  height = 0,
  isDrawerChat = false,
  isGroupChat = false,
}) => {
  const [message, setMessage] = useState("");
  const {
    sentMessages,
    setSentMessages,
    receivedMessages,
    setReceivedMessages,
    zimInstance,
    selectedName,
    userId,
    userName,
    zegoToken,
  }: any = useContext(ChatContext);
  const { candidates }: any = useSocket();
  const [currentTime, setCurrentTime] = useState(Date.now());
  const orgId = localStorage.getItem(localStorageKeys.orgId);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(Date.now());
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  const queryHistoryMessage = (zim: any, conversationID: any) => {
    const config = {
      nextMessage: null,
      count: 30,
      reverse: true,
    };
    zim
      .queryHistoryMessage(conversationID, 0, config)
      .then((result: any) => {
        setReceivedMessages((prev) => [...result.messageList, ...prev]);
      })
      .catch((err) => {
        console.error("Failed to query history messages:", err);
      });
  };

  useEffect(() => {
    queryHistoryMessage(
      zimInstance,
      stakeholderDetails?.is_stakeholder
        ? `org-${orgId}-stakeholder-user-${selectedName?.id}`
        : `org-${orgId}-user-${selectedName?.id}`,
    );
  }, [selectedName]);

  const sendMessage = () => {
    if (message.trim()) {
      setMessage("");
    }
    if (!zimInstance) return;

    const stakeholderKeys = Object.keys(candidates).filter((key) =>
      key.startsWith("stakeholder"),
    );

    const selected = JSON.parse(localStorage.getItem("selectedName") ?? "{}");
    // Select a random key from the filtered keys
    const stakeholder =
      stakeholderKeys[Math.floor(Math.random() * stakeholderKeys.length)];

    let toConversationID: string;

    if (!stakeholderDetails?.is_stakeholder) {
      toConversationID = receivedMessages?.length
        ? receivedMessages[receivedMessages?.length - 1].senderUserID
        : `org-${orgId}-stakeholder-user-${stakeholder.replace("stakeholder-", "")}`;
      toConversationID = `org-${orgId}-stakeholder-user-${stakeholder.replace("stakeholder-", "")}`;
    } else {
      toConversationID =
        selected.config === "one-to-one"
          ? selected.type == "candidate"
            ? `org-${orgId}-user-${selected?.id}`
            : `org-${orgId}-stakeholder-user-${selected?.id}`
          : "130";
    }
    const conversationType = !stakeholderDetails?.is_stakeholder
      ? 0
      : selected.config === "one-to-one"
        ? 0
        : 1;
    const config = {
      priority: 1,
    };

    const messageTextObj = {
      type: 1,
      message: message,
      extendedData: userName,
      timestamp: Date.now(),
    };

    const notification = {
      onMessageAttached: function (message) {
        console.log("Message:", message);
      },
    };

    zimInstance
      .sendMessage(
        messageTextObj,
        toConversationID,
        conversationType,
        config,
        notification,
      )
      .then(({ message }) => {
        setSentMessages((prev) => [...prev, message]);
        setMessage("");
      })
      .catch((err) => {
        console.error("Failed to send message:", err);
      });
  };

  const allMessages = [...sentMessages, ...receivedMessages].sort(
    (a, b) => a.timestamp - b.timestamp,
  );

  const formatTimestamp = (timestamp) => {
    const now = currentTime;
    const diffInSeconds = Math.floor((now - timestamp) / 1000);

    if (diffInSeconds < 60) {
      return "just now";
    }

    const diffInMinutes = Math.floor(diffInSeconds / 60);
    if (diffInMinutes < 60) {
      return diffInMinutes === 1 ? "1 min ago" : `${diffInMinutes} mins ago`;
    }

    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) {
      return diffInHours === 1 ? "1 hr ago" : `${diffInHours} hrs ago`;
    }

    const date = new Date(timestamp);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${hours}:${minutes}`;
  };
  const extractFirstLetter = (name: any) => {
    return name?.charAt(0).toUpperCase();
  };
  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      sendMessage();
    }
  };
  const assessmentDetails: any = JSON.parse(
    localStorage.getItem(localStorageKeys?.assessmentDetails) ?? "{}",
  );
  const stakeholderDetails: any = JSON.parse(
    localStorage.getItem(localStorageKeys?.stakeholderDetails) ?? "{}",
  );
  const messageListRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = () => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [allMessages]);
  return (
    <div
      className={classNames(
        "p-[20px] relative w-[100%]",
        isDrawerChat ? "" : "border-[#BCC1CA] border-[2px] border-solid",
      )}
      style={{ height }}>
      <div
        ref={messageListRef}
        className={classNames(
          "flex h-[93%] flex-1 flex-col overflow-scroll mb-3",
        )}>
        {allMessages?.map((msg, index) => {
          const showAvatar =
            index === 0 ||
            allMessages[index - 1]?.senderUserID !== msg?.senderUserID;
          const isSentByCurrentUser = msg?.senderUserID === userId;
          const previousMessage = index > 0 ? allMessages[index - 1] : null;
          const shouldApplyPadding =
            previousMessage &&
            previousMessage.senderUserID === msg.senderUserID;

          return (
            <div
              key={index}
              className={`flex ${msg?.senderUserID === userId ? "justify-end" : "justify-start"} mb-2 mr-2`}>
              {msg?.senderUserID !== userId && showAvatar && (
                <div className="bg-[#22c95cfa] rounded-full w-8 h-8 flex items-center justify-center text-[white] mr-2">
                  {extractFirstLetter(msg?.extendedData)}
                </div>
              )}
              <div
                className={`p-2 rounded-lg flex flex-col text-[14px] ${msg?.senderUserID === userId ? "bg-[#F2FCF5] text-primaryText border-none rounded-[5px] " : "bg-[#F3F4F6] border-none rounded-[5px] text-primaryText"} ${isSentByCurrentUser && shouldApplyPadding ? "mr-[40px]" : ""} ${!isSentByCurrentUser && shouldApplyPadding ? "ml-[40px]" : ""}
    `}
                style={{ maxWidth: "70%", overflowWrap: "break-word" }}>
                <div>{msg?.message}</div>
                <div className="text-[8px] text-gray-500 text-right self-end">
                  {formatTimestamp(msg.timestamp)}
                </div>
              </div>
              {msg?.senderUserID === userId && showAvatar && (
                <div className="bg-[#22c95cfa] rounded-full w-8 h-8 flex items-center justify-center text-[white] ml-2">
                  {extractFirstLetter(stakeholderDetails?.full_name)}
                </div>
              )}
            </div>
          );
        })}
      </div>
      <div className="absolute bottom-[20px] w-[93%] ">
        <ConfigProvider
          theme={{
            components: {
              Input: {
                activeBorderColor: "#27CA5F",
                hoverBorderColor: "#27CA5F",
                inputFontSize: 12,
              },
            },
          }}>
          <Input
            placeholder="Type your message here"
            className="text-[14px] h-[50px]"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={handleKeyDown}
            suffix={
              <FiSend
                size={25}
                onClick={sendMessage}
                style={{ cursor: "pointer" }}
              />
            }
          />
        </ConfigProvider>
      </div>
    </div>
  );
};

export default Chat;
