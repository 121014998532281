import {
  CustomAxiosRequestConfig,
  StakeholdersLogEventParams,
} from "@/types/functionTypes";
import { authenticatedAxios } from "@/utils/axiosInterceptor";
import { getIntlErrorMessage, handleMessage } from "@/utils/functions";
import { messageEnum, localStorageKeys } from "@/constants/constants";
import { URL } from "@/utils/service-urls";
const useStakeholderEventLogging = () => {
  const assessmentId = localStorage.getItem(localStorageKeys.assessmentId);
  const stakeholderLogEvent = async ({
    candidateId,
    setId,
    eventType,
    eventName,
    eventData,
    additionalDetails,
  }: StakeholdersLogEventParams) => {
    const event = {
      candidate_id: candidateId ?? "",
      set_id: setId ?? "",
      event_type: eventType || "",
      event_name: eventName ?? "",
      event_data: eventData || {},
      additional_details: additionalDetails ?? "",
    };
    const logs = {
      requestBody: {
        ...event,
      },
    };
    await handleUploadLogsToDB(logs);
  };
  const handleUploadLogsToDB = async (logs: any) => {
    try {
      const url = `${URL?.ASSESSMENT?.STAKEHOLDER_ASSESSMENT}/${assessmentId}${URL?.ASSESSMENT?.LOGS}`;

      authenticatedAxios
        .post(url, logs, {
          authenticated: true,
        } as CustomAxiosRequestConfig)
        .then(async (response) => {
          console.log(response, url);
        })
        .catch(() => {
          const errorMsg = getIntlErrorMessage(
            "STAKEHOLDER_LOGS_MESSAGE",
            "STAKEHOLDER_MESSAGE",
          );
          handleMessage(messageEnum.error, errorMsg);
        });
    } catch (error) {
      const errorMsg = getIntlErrorMessage(
        "STAKEHOLDER_LOGS_MESSAGE",
        "STAKEHOLDER_MESSAGE",
      );
      handleMessage(messageEnum.error, errorMsg);
    }
  };
  return {
    stakeholderLogEvent,
  };
};

export default useStakeholderEventLogging;
