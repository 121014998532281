import React, { useEffect, useState } from "react";
import { authenticatedAxios } from "@/utils/axiosInterceptor";
import { CustomAxiosRequestConfig } from "@/types/functionTypes";
import { getIntlErrorMessage, handleMessage } from "@/utils/functions";
import { messageEnum, localStorageKeys } from "@/constants/constants";
import { URL } from "@/utils/service-urls";
import * as XLSX from "xlsx";
function CandidateResults() {
  const assessment_id = localStorage.getItem(localStorageKeys?.assessmentId);
  const candidate_Id = localStorage.getItem("candidate_id");
  const [examResults, setExamResults] = useState<any>(null); // State for exam results
  const fetchData = async (endpoint: string, successCallback: (data: any) => void) => {
    try {
      const response = await authenticatedAxios.get(endpoint, { authenticated: true } as CustomAxiosRequestConfig);
      if (response.status === 200 || response.data.responseHead.statusDesc === "Technical Error:No answer to evaluate") {
        successCallback(response.data.responseBody);
      } else {
        const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
        handleMessage(messageEnum.error, errorMsg);
      }
    } catch (error) {
      const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
      handleMessage(messageEnum.error, errorMsg);
    }
  };
  useEffect(() => {
    fetchData(
      `${URL.ASSESSMENT.CANDIDATES_ASSESSMENT}/${assessment_id}/candidate/${candidate_Id}/exam_result`,
      setExamResults
    );
  }, [assessment_id, candidate_Id]);
  const downloadExcel = () => {
    if (!examResults) {
      handleMessage(messageEnum.error, "No results to download.");
      return;
    }
    // Prepare data for Excel
    const dataForExcel = [
      { Category: "Theory", Score: examResults.theory || "N/A" },
      { Category: "Practical", Score: examResults.practical || "N/A" },
      { Category: "Viva", Score: examResults.viva || "N/A" },
      { Category: "Total", Score: examResults.total || "N/A" },
    ];
    const worksheet = XLSX.utils.json_to_sheet(dataForExcel);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Results");
    // Generate and trigger file download
    XLSX.writeFile(workbook, `Candidate_Results_${candidate_Id}.xlsx`);
  };
  return (
    <div style={{ position: "relative" }}>
      {/* Download Button */}
      <button
        className="flex items-center bg-green-500 text-black py-2 px-4 rounded hover:bg-green-600"
        style={{
          backgroundColor: "rgba(34, 201, 92, 0.98)",
          padding: "10px",
          position: "fixed", // Ensures the button stays fixed
          top: "212px", // Adjust vertical position
          right: "62px", // Adjust horizontal position
          zIndex: 1000, // Ensures it stays above other elements
        }}
        onClick={downloadExcel}
      >
        <span>Download Results</span>
      </button>
      {/* Content */}
      <p style={{ padding: "10px", marginTop: "50px" }}>
        We are pleased to inform you that you have scored <strong>A</strong> in the assessment. Your performance has been
        evaluated thoroughly, and the results reflect your dedication and hard work.
      </p>
      {/* Table */}
      <table
        style={{
          width: "50%",
          margin: "20px 0",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th
              style={{
                border: "1px solid black",
                padding: "8px",
                textAlign: "center",
              }}
            >
              Category
            </th>
            <th
              style={{
                border: "1px solid black",
                padding: "8px",
                textAlign: "center",
              }}
            >
              Score
            </th>
          </tr>
        </thead>
        <tbody>
          {examResults ? (
            ["Theory", "Practical", "Viva", "Total"].map((category, index) => (
              <tr key={index}>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "20px",
                    textAlign: "center",
                  }}
                >
                  {category}
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "20px",
                    textAlign: "center",
                    width: "60%",
                  }}
                >
                  {examResults[category.toLowerCase()] || "N/A"}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td
                style={{
                  border: "1px solid black",
                  padding: "20px",
                  textAlign: "center",
                }}
                colSpan={2}
              >
                Loading results...
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
export default CandidateResults;