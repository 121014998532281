/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useContext, useEffect, useRef } from "react";
import { ItemStateContext } from "@/contexts/questionContext";
import { ItemStateContextValues } from "@/types/contextTypes";

export const useFullscreenToggle = () => {
  const { setFullScreen }: ItemStateContextValues = (useContext(
    ItemStateContext,
  ) || {}) as ItemStateContextValues;

  const isTogglingManually = useRef(false);

  useEffect(() => {
    function handleFullscreenChange() {
      if (!isTogglingManually.current) {
        setFullScreen(Boolean(document.fullscreenElement));
      }
      isTogglingManually.current = false;
    }

    document.addEventListener("fullscreenchange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, [setFullScreen]);

  const toggleFullscreen = (isFullscreen = true) => {
    isTogglingManually.current = true;

    if (!isFullscreen) {
      setFullScreen(true);
      const element = document.documentElement;
      //@ts-ignore
      const requestFullscreenFn =
        element.requestFullscreen ||
        //@ts-ignore
        element.mozRequestFullScreen ||
        //@ts-ignore
        element.webkitRequestFullscreen ||
        //@ts-ignore
        element.msRequestFullscreen;

      if (requestFullscreenFn) {
        requestFullscreenFn.call(element).catch((err) => {
          return err;
        });
      }
    } else {
      setFullScreen(false);

      const exitFullscreenFn =
        document.exitFullscreen ||
        //@ts-ignore
        document.mozCancelFullScreen ||
        //@ts-ignore
        document.webkitExitFullscreen ||
        //@ts-ignore
        document.msExitFullscreen;

      if (exitFullscreenFn) {
        exitFullscreenFn.call(document).catch((err) => {
          return err;
        });
      }
    }
  };

  return toggleFullscreen;
};
