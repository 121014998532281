export const messages = {
  FEEDBACK_MESSAGES: {
    FEEDBACK_SUCCESS_SUBMIT: {
      en: "Feedback submitted successfully!",
    },
  },
  ASSESSMENT_SUBMITTED_MESSAGE: {
    SUBMIT_SUCCESS: {
      en: "Assessment submitted successfully!",
    },
  },
  OUT_OFF_TIME_ASSESSMENT_SUBMITTED_MESSAGE: {
    SUBMIT_SUCCESS: {
      en: "Reached out off time. Assesment submitted successfully!",
    },
  },
  IMAGE_POSTED_MESSAGE: {
    POSTED_SUCCESS: {
      en: "Image posted successfully!",
    },
  },
  FILE_POSTED_MESSAGE: {
    POSTED_SUCCESS: {
      en: "File posted successfully!",
    },
  },
  FIREBASE_UPDATE_MESSAGE: {
    ADD_SUCCESS: {
      en: "Data successfully added.",
    },
    UPDATE_SUCCESS: {
      en: "Data successfully updated.",
    },
    DELETE_SUCCESS: {
      en: "Data successfully deleted.",
    },
  },
  VIDEOCALL_END_MESSAGE: {
    VIVA_COMPLETE: {
      en: "Video is ending",
    },
  },
  FILE_DELETED_MESSAGE: {
    DELETE_MESSAGE: {
      en: "File deleted successsfully",
    },
  },
  ADJUSTED_ASSESSMENT_TIME_MESSAGE: {
    ADJUSTED_TIME: {
      en: "Time updated successfully",
    },
  },
  BLOCKING_THE_CANDIDATE: {
    BLOCKING_MESSAGE: {
      en: "candidate has blocked successfully!",
    },
  },
  MARKS_UPDATE: {
    MARKS_UPDATE_MESSAGE: {
      en: "Marks updated successfully!",
    },
  },
  KPI_SCORE: {
    KPI_SCORE_MESSAGE: {
      en: "Score updated successfully!",
    },
  },
  FEEDBACK_SUBMITTED_MESSAGE: {
    SUBMIT_SUCCESS: {
      en: "Feedback submitted successfully!",
    },
  },
  RESULTS_PUBLISHED_MESSAGE: {
    SUBMIT_SUCCESS: {
      en: "Results published successfully!",
    },
  },
};
