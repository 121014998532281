import React, { useContext } from "react";
import LanguageSelector from "@/components/languageSelector";
import { ItemStateContext } from "@/contexts/questionContext";
import { questionStatus, languageDropdownOptions } from "@/constants/constants";
import RightComponentCollapse from "@/components/rightComponentCollapse";
import { RightComponentProps } from "@/types/componentTypes";

const RightComponent: React.FC<RightComponentProps> = ({
  questionsResponse,
  selectedLanguage,
  response,
}) => {
  const { setCurrentQuestionIndex, setSelectedLanguage, setQuestions }: any =
    useContext(ItemStateContext);

  const handleLanguageChange = (newLanguage: string) => {
    setSelectedLanguage(newLanguage);
    setQuestions(response[newLanguage]);
    setCurrentQuestionIndex(0);
  };

  return (
    <div className="ml-[10px] h-full overflow-y-hidden">
      <div className="h-[30%]">
        <LanguageSelector
          onChange={(e: any) => handleLanguageChange(e.target.value)}
          languageOptions={languageDropdownOptions}
          className="border-[2px] border-[lightgray] rounded w-[80%] h-[30px] mb-[20px] pl-[10px]"
          selectedLanguage={selectedLanguage}
        />
        <div className="flex flex-col gap-[10px] mb-[5px]">
          {questionStatus?.map((item: any) => (
            <div key={item?.divStyle} className="flex items-center gap-[10px]">
              <div className={item?.divStyle}></div>
              <div className="font-bold">{item?.status}</div>
            </div>
          ))}
        </div>
      </div>
      <RightComponentCollapse questionsResponse={questionsResponse} />
    </div>
  );
};

export default RightComponent;
