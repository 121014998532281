import { ImageWithBasePathProps } from "@/types/componentTypes";

const ImageWithBasePath: React.FC<ImageWithBasePathProps> = ({
  isUrl = false,
  src,
  height,
  width,
  className,
  style,
  alt,
}) => {
  const url = isUrl ? src : `/${src}.png`;
  return (
    <img
      src={url}
      alt={alt}
      className={className}
      style={style}
      width={width}
      height={height}
    />
  );
};

export default ImageWithBasePath;
