import { useContext } from "react";
import { ItemStateContext } from "@/contexts/questionContext";
import { handleFirebaseOperation } from "@/firebase";
import { useSelector } from "react-redux";
import { localStorageKeys, firebaseMethodKeys } from "@/constants/constants";
import moment from "moment";
import { getFirebaseUrl } from "@/utils/functions";
import { FIREBASE_URL } from "@/utils/service-urls";
import { RootState } from "@/store";
import { LogEventParams } from "@/types/functionTypes";

const useEventLogging = () => {
  const { setLoggedEvents, previousTimeRef }: any =
    useContext(ItemStateContext);
  const candidateId = useSelector((state: RootState) => state.candidateId);
  const assessmentId = useSelector((state: RootState) => state.assessmentId);
  const setId = useSelector((state: RootState) => state.setId);

  function getTimeString() {
    return moment().format("HH:mm:ss");
  }

  function calculateTimeDifference() {
    const currentTime = moment();
    const previousTime = moment(previousTimeRef.current, "HH:mm:ss");
    previousTimeRef.current = getTimeString();
    const diffInSeconds = currentTime.diff(previousTime, "seconds");
    return `${diffInSeconds}s`;
  }

  const logEvent = async ({
    section,
    questionIndex = -1,
    eventName,
    answer,
    eventType,
    questionType,
    imageUrl,
  }: LogEventParams) => {
    const timeElapsed = calculateTimeDifference();
    const event = {
      section: section ?? "",
      question: questionIndex > -1 ? `Q${questionIndex + 1}` : "",
      event_name: eventName || "",
      answer: answer ?? "",
      event_type: eventType || "",
      question_type: questionType ?? "",
      image: imageUrl ?? "",
      time_elapsed: timeElapsed,
      time_stamp: moment?.now(),
    };

    const options = {
      assessmentId: assessmentId,
      setId: setId,
      candidateId: candidateId,
      endpoint: `${FIREBASE_URL?.ENDPOINT?.LOGS}/${moment.now()}`,
    };

    const path = getFirebaseUrl(options);
    const localStorageKey = localStorageKeys.failedEvents;
    const storedEvents =
      JSON.parse(localStorage.getItem(localStorageKey) as string) || [];

    try {
      setLoggedEvents((prevEvents: any) => [...prevEvents, event]);
      if (storedEvents.length > 0) {
        const eventsToProcess = [...storedEvents, event];
        await Promise.all(
          eventsToProcess.map(async (storedEvent) => {
            try {
              await handleFirebaseOperation(
                path,
                firebaseMethodKeys.PUT,
                storedEvent,
              );
              localStorage.setItem(localStorageKey, JSON.stringify([]));
            } catch (error) {
              console.error("Failed to send stored event to Firebase:", error);
            }
          }),
        );
      } else {
        await handleFirebaseOperation(path, firebaseMethodKeys.PUT, event);
      }
    } catch (error) {
      console.error("Firebase PUT operation failed:", error);
      storedEvents.push(event);
      localStorage.setItem(localStorageKey, JSON.stringify(storedEvents));
    }
  };

  return {
    logEvent,
  };
};

export default useEventLogging;
