import React from "react";
import { ConfigProvider, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { SearchInputComponentProps } from "@/types/componentTypes";

const SearchInputComponent: React.FC<SearchInputComponentProps> = ({
  handleSearchChange,
  searchQuery,
  className,
}) => {
  return (
    <div>
      <ConfigProvider
        theme={{
          components: {
            Input: {
              activeBorderColor: "#22c95cfa",
              hoverBorderColor: "#22c95cfa",
            },
          },
        }}>
        <Input
          placeholder="Search"
          prefix={<SearchOutlined />}
          value={searchQuery}
          onChange={handleSearchChange}
          className={className}
        />
      </ConfigProvider>
    </div>
  );
};

export default SearchInputComponent;
