import React, { useState, useEffect } from "react";
import { handleFirebaseOperation } from "@/firebase";
import { FIREBASE_URL } from "@/utils/service-urls";
import { useSelector } from "react-redux";
import { RootState } from "@/types/componentTypes";
import { getFirebaseUrl, getIntlErrorMessage } from "@/utils/functions";
import moment from "moment";
import { firebaseMethodKeys } from "@/constants/constants";

const indexedDBOpenRequest: IDBOpenDBRequest = indexedDB.open(
  "videoRecorderDB",
  1,
);

indexedDBOpenRequest.onupgradeneeded = function () {
  const db = indexedDBOpenRequest.result;
  if (!db.objectStoreNames.contains("chunks")) {
    db.createObjectStore("chunks", { autoIncrement: true });
  }
};

const saveChunkToDB = async (chunk: Blob) => {
  const db = indexedDBOpenRequest.result;
  return new Promise<void>((resolve) => {
    const transaction = db.transaction("chunks", "readwrite");
    const store = transaction.objectStore("chunks");

    transaction.oncomplete = () => {
      resolve();
    };

    transaction.onerror = () => {
      console.error(getIntlErrorMessage("INDEX_DB_MESSAGE", "SAVE_ERROR"));
    };

    store.add(chunk);
  });
};

const fetchVideoFromDB = async (): Promise<Blob> => {
  return new Promise((resolve) => {
    const db: IDBDatabase | null = indexedDBOpenRequest.result;

    if (!db) {
      console.error(getIntlErrorMessage("INDEX_DB_MESSAGE", "DB_UNAVAILABLE"));
      return;
    }

    const transaction = db.transaction("chunks", "readonly");
    const store = transaction.objectStore("chunks");
    const request = store.getAll();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    request.onerror = (event: any) => {
      console.error(
        getIntlErrorMessage("INDEX_DB_MESSAGE", "FETCH_ERROR").replace(
          "$msg",
          request.error?.message ?? "Unknown error",
        ),
      );
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    request.onsuccess = (event: any) => {
      const result = request.result;

      if (result && Array.isArray(result)) {
        const blob = new Blob(result, { type: "video/mp4" });
        resolve(blob);
      } else {
        console.error(
          getIntlErrorMessage("INDEX_DB_MESSAGE", "NO_CHUNK_FOUND"),
        );
      }
    };
  });
};

const clearIndexedDB = () => {
  const request = window.indexedDB.open("videoRecorderDB");

  request.onsuccess = (event: any) => {
    const db = event.target.result;
    const transaction = db.transaction(["chunks"], "readwrite");
    const objectStore = transaction.objectStore("chunks");
    const clearRequest = objectStore.clear();
    clearRequest.onerror = (event: any) => {
      console.error(
        getIntlErrorMessage("INDEX_DB_MESSAGE", "DELETE_ERROR").replace(
          "$msg",
          event.target?.error,
        ),
      );
    };
  };
  request.onerror = (event: any) => {
    console.error(
      getIntlErrorMessage("INDEX_DB_MESSAGE", "DELETE_ERROR").replace(
        "$msg",
        event.target?.error,
      ),
    );
  };
};

const VideoCapturing: React.FC<{ feedBackModal: boolean }> = ({
  feedBackModal,
}) => {
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(
    null,
  );
  const [isRecording, setIsRecording] = useState(false);
  const candidateId = useSelector((state: RootState) => state.candidateId);
  const assessmentId = useSelector((state: RootState) => state.assessmentId);
  const setId = useSelector((state: RootState) => state.setId);

  const convertFileToBase64 = (file: File): Promise<string> => {
    if (!file) {
      throw new Error("No file provided");
    }

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        const result = reader.result;

        if (result && typeof result === "string") {
          let base64String = result;
          base64String = base64String?.replace(
            "data:video/webm",
            "data:video/mp4",
          );
          resolve(base64String);
        } else {
          reject(new Error("Failed to convert file to base64"));
        }
      };

      reader.onerror = (error) => {
        console.error(
          getIntlErrorMessage("INDEX_DB_MESSAGE", "CONVERT_ERROR"),
          error,
        );
        reject(reader.error);
      };
    });
  };

  const handleUploadTODB = async (videoFile: File) => {
    try {
      const base64String = await convertFileToBase64(videoFile);

      if (base64String) {
        await handleUploadToFirebase(base64String);
        clearIndexedDB();
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleUploadToFirebase = async (data: any) => {
    try {
      const options = {
        assessmentId: assessmentId,
        setId: setId,
        candidateId: candidateId,
        endpoint: `${FIREBASE_URL?.ENDPOINT?.EVIDENCES}/${FIREBASE_URL?.ENDPOINT?.VIDEO}/${moment.now()}`,
      };
      const firebaseUrl = getFirebaseUrl(options);
      await handleFirebaseOperation(firebaseUrl, firebaseMethodKeys.PUT, data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    async function setupMedia() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: false,
        });
        const recorder = new MediaRecorder(stream);

        recorder.ondataavailable = async (event) => {
          if (event.data.size > 0) {
            await saveChunkToDB(event.data);
          }
        };

        setMediaRecorder(recorder);
      } catch (error) {
        console.error(getIntlErrorMessage("INDEX_DB_MESSAGE", "ACCESS_ERROR"));
      }
    }

    setupMedia();

    return () => {
      if (mediaRecorder) {
        mediaRecorder.stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, []);

  useEffect(() => {
    if (!feedBackModal && mediaRecorder && !isRecording) {
      mediaRecorder.start(30000);
      setIsRecording(true);
    }
  }, [feedBackModal, mediaRecorder]);

  const playBackVideo = async () => {
    try {
      const blob = await fetchVideoFromDB();
      const file = new File([blob], "recorded_video.mp4", { type: blob.type });

      await handleUploadTODB(file);
    } catch (error) {
      console.error(getIntlErrorMessage("INDEX_DB_MESSAGE", "ACCESS_ERROR"));
    }
  };

  useEffect(() => {
    if (!feedBackModal) {
      const intervalId = setInterval(playBackVideo, 30000);
      return () => clearInterval(intervalId);
    }
  }, [feedBackModal]);

  return <div className="flex gap-5"></div>;
};

export default VideoCapturing;
