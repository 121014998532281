import { useContext, useEffect } from "react";
import Header from "@/components/header";
import LeftComponent from "@/components/leftComponent";
import { analyticKeys, eventType } from "@/constants/constants";
import { ItemStateContext } from "@/contexts/questionContext";
import useEventLogging from "@/hooks/useLogs";
function AssessmentResponse() {
  const {
    currentQuestionIndex,
    selectedLanguage,
    setShowAllQuestions,
    questions,
    setQuestions,
  }: any = useContext(ItemStateContext);
  const { logEvent } = useEventLogging();
  const response: any = JSON.parse(
    localStorage.getItem("question_paper_theory") ?? "{}",
  );

  useEffect(() => {
    setQuestions(response?.[selectedLanguage] || []);
    setShowAllQuestions(true);
    logEvent({
      eventName: analyticKeys?.VIEW_RESPONSES,
      eventType: eventType?.POST_ASSESSMENT_ACTION,
    });
  }, []);
  const currentQuestion = questions?.[currentQuestionIndex];

  return (
    <div>
      <div className="sticky top-0 z-10">
        <Header heroHeader={true} />
      </div>
      <div>
        <div className="flex h-full bg-[#ededed] items-center justify-center py-[20px]">
          <div className="bg-[white] md:w-[800px] rounded-sm p-[32px]">
            <LeftComponent
              currentQuestion={currentQuestion}
              reviewButton={false}
              selectedLanguage={selectedLanguage}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AssessmentResponse;
