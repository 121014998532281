import React, { useContext, useEffect, useState } from "react";
import Header from "@/components/header";
import { ItemStateContext } from "@/contexts/questionContext";
import { Checkbox } from "antd";
import ButtonComponent from "@/components/buttonComponent";
import HtmlEditor from "@/components/htmlEditor";
import Tutorials from "@/components/tutorials";
import {
  instructionsResponse,
  buttonTitle,
  messageEnum,
  languageOptions,
  localStorageKeys,
  analyticKeys,
  eventType,
  firebaseMethodKeys,
  assessmentStatus,
} from "@/constants/constants";
import {
  handleMessage,
  getIntlErrorMessage,
  updateReduxStateFromLocalStorage,
  getFirebaseUrl,
} from "@/utils/functions";
import useEventLogging from "@/hooks/useLogs";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/types/componentTypes";
import LanguageSelector from "@/components/languageSelector";
import InstructionsBodyComponent from "@/components/instructionsComponent";
import { handleFirebaseOperation } from "@/firebase";
import moment from "moment";

function InstructionsScreen() {
  const [isAgreed, setIsAgreed] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState("English");
  const {
    setIsStarted,
    steps,
    setSteps,
    setFromSampleAssessment,
    setCurrentQuestionIndex,
  }: any = useContext(ItemStateContext);
  const [isTutorialBtnClicked, setIsTutorialBtnClicked] = useState(false);

  const { logEvent } = useEventLogging();
  const buttonCommonStyle =
    "bg-primary py-[10px] px-[20px] border-none rounded-[10px] text-[white]";

  const dispatch = useDispatch();
  const assessmentId = useSelector((state: RootState) => state.assessmentId);
  const setId = useSelector((state: RootState) => state.setId);
  const candidateId = useSelector((state: RootState) => state.candidateId);
  const assessmentDetails = JSON.parse(
    localStorage.getItem("assessment_details") ?? "{}",
  );
  useEffect(() => {
    localStorage.setItem(
      localStorageKeys.isIntsructionScreenVisited,
      String(true),
    );
    setFromSampleAssessment(false);
    setCurrentQuestionIndex(0);
    logEvent({
      eventName: analyticKeys?.READ_INSTRUCTIONS,
      eventType: eventType?.PRE_ASSESSMENT_ACTION,
    });
  }, []);
  const onChange = (e: any) => {
    if (e.target.checked) {
      setIsAgreed(true);
    } else if (e.target.checked === false) {
      setIsAgreed(false);
    }
  };

  const navigateToQuestions = () => {
    if (isAgreed) {
      setIsStarted(true);
      setSteps(steps + 1);
      const options = {
        assessmentId: assessmentId,
        setId: setId,
        candidateId: candidateId,
        endpoint: ``,
      };
      const firebaseUrl = getFirebaseUrl(options);
      const data = {
        exam_status: assessmentStatus.IN_PROGRESS,
        start_time: moment?.now(),
      };
      handleFirebaseOperation(firebaseUrl, firebaseMethodKeys.UPDATE, data);
    } else {
      const errorMsg = getIntlErrorMessage("PERMISSIONS", "CHECK_I_AGREE");
      handleMessage(messageEnum.error, errorMsg);
    }
  };
  const navigateToTutorials = () => {
    setIsTutorialBtnClicked(true);
    logEvent({
      eventName: analyticKeys?.WATCH_TUTORIALS,
      eventType: eventType?.PRE_ASSESSMENT_ACTION,
    });
  };
  const navigateToSampleAssessment = () => {
    setSteps(2);
    setFromSampleAssessment(true);
    logEvent({
      eventName: analyticKeys?.SAMPLE_ASSESSMENT_START,
      eventType: eventType?.PRE_ASSESSMENT_ACTION,
    });
  };

  const handleSelectedLanguage = (e: any) => {
    setCurrentLanguage(e.target.value);
  };

  const buttonsContent = [
    {
      title: buttonTitle.watchTutorials,
      onClick: navigateToTutorials,
      display: assessmentDetails.rules.enableTutorials,
    },
    {
      title: buttonTitle.trySampleAssessment,
      onClick: navigateToSampleAssessment,
      display: assessmentDetails.rules.enablePracticeAssessments,
    },
    {
      title: buttonTitle.startAssessment,
      onClick: navigateToQuestions,
      display: true,
    },
  ];

  useEffect(() => {
    if (!assessmentId) {
      updateReduxStateFromLocalStorage(dispatch);
    }
  }, []);

  return (
    <div className="text-primaryText relative h-[100%] overflow-y-hidden">
      <div className="fixed top-0 w-full z-10 bg-white">
        <Header heroHeader={true} />

        <InstructionsBodyComponent assessmentDetails={assessmentDetails} />

        <div className="text-end pt-[10px] pr-[50px]">
          <LanguageSelector
            onChange={(e) => handleSelectedLanguage(e)}
            selectedLanguage={currentLanguage}
            languageOptions={languageOptions}
            className="border-[2px] border-[lightgray] rounded w-[100px] h-[30px] p-[2px]"
          />
        </div>
      </div>

      <div className="px-[50px] pb-[20px] mt-[200px] h-[100%]">
        <div className="p-[15px]  rounded-md mt-[10px] mb-[50px]">
          <HtmlEditor initialValue={assessmentDetails?.instructions} />
        </div>
      </div>
      <div className="flex justify-between items-center fixed bottom-0 w-full bg-white pl-[65px] pr-[50px] py-[16px] border-t-[1px] border-solid border-gray mt-[10px]">
        <Checkbox onChange={onChange} className="font-rubik">
          {instructionsResponse?.checkInstructions}
        </Checkbox>
        <div className="flex space-x-[10px]">
          {buttonsContent.map((button) =>
            button.display ? (
              <ButtonComponent
                key={button.title}
                text={button.title}
                onClick={button?.onClick}
                className={buttonCommonStyle}
              />
            ) : null,
          )}
        </div>
      </div>
      <Tutorials
        isTutorialBtnClicked={isTutorialBtnClicked}
        setTutorialBtnClicked={setIsTutorialBtnClicked}
      />
    </div>
  );
}

export default InstructionsScreen;
