import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const HorizontalBarChart = ({ id, dashboardData,value }) => {
  // Store the chart instance using useRef
  const chartRef = useRef(null);
  // Extract labels and data 
  const labels = dashboardData.map(item => item.name);
  const data = dashboardData.map(item => parseInt(item.description, 10));

  useEffect(() => {
    // Make sure the canvas element is available before initializing the chart
    const ctx = document.getElementById(id);

    if (ctx) {
      // If there's an existing chart instance, destroy it
      if (chartRef.current) {
        chartRef.current.destroy();
      }

      // Create a new chart instance and store it in chartRef
      chartRef.current = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: labels,
          datasets: [{
            label: value,
            data: data,
            backgroundColor: '#22c95cfa',
            borderWidth: 1
          }]
        },
        options: {
          indexAxis: 'y',
          scales: {
            x: {
              beginAtZero: true,
            },
            y: {
              beginAtZero: true,
            }
          }
        }
      });
    }

    // Cleanup the chart instance when the component unmounts
    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };

  }, [id, labels, data]); // Re-run the effect when props change

  return (
    <div style={{ width: "600px", height: "200px" }}>
      <canvas id={id} width="400px" height="200px"></canvas>
    </div>
  );
};

export default HorizontalBarChart;
