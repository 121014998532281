/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext } from "react";
import { ItemStateContext } from "@/contexts/questionContext";
import classNames from "classnames";
import { responseText } from "@/constants/constants";
import { DataItem } from "@/types/contextTypes";

interface MatchingResponseProps {
  matchingResponse: DataItem;
  ourResponse: any;
  optionsArray: any[];
}

const getClass = (ourResponse: boolean, isResponseCorrect: boolean) => {
  if (ourResponse) {
    return "border-[gray] bg-lightGray";
  } else if (isResponseCorrect) {
    return "bg-lightGreen border-green";
  }
  return "bg-lightRed border-[red]";
};

const SequenceRendererMatching: React.FC<{ data: DataItem }> = ({ data }) => {
  const renderedSequences = () => {
    const combined: { [key: string]: string[] } = {};

    Object.entries(data).forEach(([, sequence]) => {
      Object.entries(sequence).forEach(([key, value]) => {
        if (!combined[key]) {
          combined[key] = [];
        }
        combined[key].push(value);
      });
    });

    const renderedSequences = Object.entries(combined).map(
      ([key, values], index, array) => {
        const sequenceText = `${values.join(" -> ")}`;
        return index === array.length - 1 ? (
          <span key={key}>{sequenceText}</span>
        ) : (
          <span key={key}>{`${sequenceText}, `}</span>
        );
      },
    );

    return renderedSequences;
  };

  const sequences = renderedSequences();

  return <div className="flex gap-2">{sequences}</div>;
};

const MatchingResponse: React.FC<MatchingResponseProps> = ({
  matchingResponse,
  ourResponse,
  optionsArray,
}) => {
  //const { setMatchingResponse }: any = useContext(ItemStateContext);

  if (Object.values(matchingResponse).length > 0) {
    const extractedListValues = {};

    optionsArray
      ?.filter((item) => item?.list_order_id !== 1)
      ?.forEach((item, index) => {
        const convertedValues: { [key: string]: number } = {};
        for (const key in item.list_correct_sequence) {
          convertedValues[key] = parseInt(item.list_correct_sequence[key], 10);
        }
        extractedListValues[index] = convertedValues;
      });

    const isResponseCorrect =
      JSON.stringify(extractedListValues) === JSON.stringify(matchingResponse);

    //setMatchingResponse(isResponseCorrect);

    return (
      <div
        className={classNames(
          "mx-[32px] my-[5px] relative p-[10px] mt-[10px] border-[2px] rounded-md",
          getClass(ourResponse, isResponseCorrect),
        )}>
        <SequenceRendererMatching data={matchingResponse} />
        {ourResponse && (
          <div className="absolute text-[12px] text-[white] rounded-tl-sm rounded-br-sm bg-[gray] right-0 bottom-[0px] px-[8px] py-[1px] ">
            {responseText?.yourAnswer}
          </div>
        )}
        {!ourResponse && !isResponseCorrect && (
          <div className="absolute text-[12px] text-[white] rounded-tl-sm rounded-br-sm bg-[red] right-0 bottom-[0px] px-[8px] py-[1px] ">
            {responseText?.yourAnswer}
          </div>
        )}
      </div>
    );
  } else {
    return <></>;
  }
};

export default MatchingResponse;
