import React from "react";
import { headings } from "@/constants/constants";
const CandidateBlockedScreen = () => {
  return (
    <div className="w-[100%] h-[100%] flex items-center justify-center min-h-screen bg-gray-400">
      <div className="bg-lightRed text-gray-900 p-8 rounded shadow-sm">
        {headings?.blockedMessage}
      </div>
    </div>
  );
};

export default CandidateBlockedScreen;
