import {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  useMemo,
} from "react";
import { auth } from "@/firebase";
import { signOut, signInAnonymously, User } from "firebase/auth";
import { handleMessage, getIntlErrorMessage } from "@/utils/functions";

interface AuthContextType {
  currentUser: User | null;
  login: () => Promise<any>;
  logout: () => Promise<void>;
  setLoggedIn: () => void;
  setAssessmentIdCxt: any;
  isLoggedIn: boolean;
  assessmentId: any;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { readonly children: ReactNode }) {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [assessmentId, setAssessmentId] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });

    return unsubscribe;
  }, []);

  const login = async () => {
    try {
      const data = await signInAnonymously(auth);
      return data;
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      const errorMsg = getIntlErrorMessage("LOGOUT", "LOGOUT_ERROR");
      handleMessage("error", errorMsg);
    }
  };

  const setLoggedIn = () => {
    setIsLoggedIn(true);
  };

  const setAssessmentIdCxt = (id) => {
    console.log("=======setting context=======", id);
    setAssessmentId(id);
  };

  return (
    <AuthContext.Provider
      value={useMemo(() => {
        return {
          currentUser,
          login,
          logout,
          setLoggedIn,
          isLoggedIn,
          setAssessmentIdCxt,
          assessmentId,
        };
      }, [currentUser, login, logout])}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}
