import { BottomComponentProps } from "@/types/componentTypes";
import ButtonComponent from "@/components/buttonComponent";
import { buttonTitle } from "@/constants/constants";

const Bottom: React.FC<BottomComponentProps> = ({
  handleSubmit,
  fromSampleAssessment,
  goToNextPart,
}: any) => {
  const buttonStyle =
    "border-none bg-white py-[5px] px-[10px] rounded-md font-medium";
  const hasPracticals = JSON.parse(
    localStorage.getItem("has_practicals") ?? "{}",
  );
  const hasViva = JSON.parse(localStorage.getItem("has_viva") ?? "{}");

  return (
    <div className="flex justify-end fixed bottom-0 items-center bg-primary p-[16px] pr-[30px] w-full">
      <div className="flex gap-[30px]">
        {!fromSampleAssessment && (hasViva || hasPracticals) && (
          <ButtonComponent
            className={buttonStyle}
            text={buttonTitle.finishAndGoToNextPart}
            onClick={goToNextPart}
          />
        )}
        <ButtonComponent
          className={buttonStyle}
          text={buttonTitle.finishAssessment}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default Bottom;
