import React, { useEffect, useState } from "react";
import Logo from "./logo";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { authenticatedAxios } from "@/utils/axiosInterceptor";
import { URL } from "@/utils/service-urls";
import { CustomAxiosRequestConfig } from "@/types/functionTypes";
import { localStorageKeys, messageEnum } from "@/constants/constants";
import { useNavigate } from "react-router-dom";
import { NAVIGATION } from "@/utils/internalNavigations";
import { getIntlErrorMessage, handleMessage } from "@/utils/functions";

const DashboardHeader = () => {
  const navigate = useNavigate();
  const [stakeHolderDetails, setStakeHolderDetails] = useState<any>({});
  const logoutHandler = () => {
    const data = {
      requestBody: {
        sessionId: sessionStorage.getItem(localStorageKeys.jwt),
        userId: parseInt(stakeHolderDetails.id),
      },
    };
    authenticatedAxios
      .post(URL.ACCOUNT.LOGOUT, data, {
        authenticated: true,
      } as CustomAxiosRequestConfig)
      .then((res) => {
        if (res.status == 200) {
          sessionStorage.clear();
          localStorage.clear();
          window.close();
        } else {
          const errorMsg = getIntlErrorMessage("LOGOUT", "LOGOUT_ERROR");
          handleMessage(messageEnum.error, errorMsg);
        }
      });
  };

  useEffect(() => {
    const details: any = JSON.parse(
      localStorage.getItem(localStorageKeys.stakeholderDetails) ?? "{}",
    );
    setStakeHolderDetails(details);
  }, []);
  return (
    <div className="sticky top-0 w-full z-50">
      <div className="w-full bg-primary flex items-center space-x-10 text-[white]">
        <div className="w-[200px] h-[80px] flex items-center">
          <Logo
            heroheader={true}
            logo={stakeHolderDetails.orgaization?.logo_file_name}
          />
        </div>
        <div className="flex items-center justify-between w-full pr-[20px]">
          <div
            role="button"
            onClick={() => navigate(NAVIGATION.STAKEHOLDERS_HOME)}
            style={{ cursor: "pointer" }}>
            Home
          </div>
          <div className="flex gap-5 items-center">
            <div className="flex text-right flex-col">
              <div>{stakeHolderDetails.full_name}</div>
              <div className="text-[12px]">
                {stakeHolderDetails.category?.name}
              </div>
            </div>
            <div className=" flex items-center  bg-white rounded-full p-2 cursor-pointer">
              <RiLogoutCircleRLine
                size={25}
                color="black"
                onClick={logoutHandler}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardHeader;
