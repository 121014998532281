import useDivHeight from "@/hooks/useDivHeight";
import { ViolationsProps } from "@/types/componentTypes";
import React, { useEffect, useState } from "react";
import {
  contentDivText,
  messageEnum,
  localStorageKeys,
} from "@/constants/constants";
import { Table } from "antd";
import { URL } from "@/utils/service-urls";
import { authenticatedAxios } from "@/utils/axiosInterceptor";
import { CustomAxiosRequestConfig } from "@/types/functionTypes";
import { getIntlErrorMessage, handleMessage } from "@/utils/functions";

const Violations: React.FC<ViolationsProps> = ({ candidateDetails }) => {
  const containerIdName = "container";
  const divHeight = useDivHeight(containerIdName);
  const [violationsResponse, setViolationsResponse] = useState([]);
  const assessmentId = localStorage.getItem(localStorageKeys.assessmentId);
  const candidateId = localStorage.getItem(localStorageKeys.candidateId);

  const getAllViolations = () => {
    const url = `${URL.ASSESSMENT.CANDIDATES_ASSESSMENT}/${assessmentId}/${URL.ASSESSMENT.CANDIDATE}/${candidateId}/${URL.ASSESSMENT.VIOLATIONS}`;
    try {
      authenticatedAxios
        .get(url, { authenticated: true } as CustomAxiosRequestConfig)
        .then((response) => {
          if (response?.status == 200) {
            setViolationsResponse(response?.data?.responseBody);
          } else {
            const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
            handleMessage(messageEnum.error, errorMsg);
          }
        })
        .catch(() => {
          const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
          handleMessage(messageEnum.error, errorMsg);
        });
    } catch (error) {
      const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
      handleMessage(messageEnum.error, errorMsg);
    }
  };

  useEffect(() => {
    getAllViolations();
  }, []);

  const columns = [
    {
      title: "S No.",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Description",
      dataIndex: "descripton",
      key: "descripton",
    },
    {
      title: "Action Taken",
      dataIndex: "action",
      key: "action",
    },
    {
      title: "Evidence",
      dataIndex: "evidence",
      key: "evidence",
    },
  ];

  return (
    <div
      className="w-[85%] h-[91%] bg-[#DEE1E6] px-[10px] py-[20px]"
      id={containerIdName}>
      <div className="pb-[10px]">
        {contentDivText?.candidateId}
        <span>{candidateDetails?.candidate_id}</span>
      </div>
      <div
        className="bg-[white] pl-[10px] pr-[10px] pt-[20px]"
        style={{
          height: divHeight - 60,
          overflowY: "hidden",
          backgroundColor: "white",
        }}>
        <Table
          dataSource={violationsResponse}
          columns={columns}
          pagination={false}
          className=""
          scroll={{ y: divHeight - 120 }}
        />
      </div>
    </div>
  );
};

export default Violations;
