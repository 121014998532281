import { SegmentedComponentProps } from "@/types/componentTypes";
import { ConfigProvider, Segmented } from "antd";
import React from "react";

const SegmentedComponent: React.FC<SegmentedComponentProps> = ({
  handleSegmentedChange,
  options,
}) => {
  return (
    <div>
      <ConfigProvider
        theme={{
          components: {
            Segmented: {
              itemActiveBg: "#00b96b",
              trackBg: "#22c95cfa",
              itemSelectedColor: "#22c95cfa",
              itemColor: "white",
              itemHoverColor: "#3a3a3a",
            },
          },
        }}>
        <Segmented
          size="large"
          options={options}
          onChange={handleSegmentedChange}
          className="custom-segmented"
        />
      </ConfigProvider>
    </div>
  );
};

export default SegmentedComponent;
