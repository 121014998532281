import React, { useEffect, useState } from "react";
import ModalComponent from "@/components/customModal";
import {
  buttonTitle,
  recordVideoButtons,
  recordVideoKeys,
  messageEnum,
  localStorageKeys,
} from "@/constants/constants";
import moment from "moment";
import { RecordVideoModalPros } from "@/types/componentTypes";
import { ZegoExpressEngine } from "zego-express-engine-webrtc";
import classNames from "classnames";
import { authenticatedAxios } from "@/utils/axiosInterceptor";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { CustomAxiosRequestConfig } from "@/types/functionTypes";
import { URL } from "@/utils/service-urls";
import { getIntlErrorMessage, handleMessage } from "@/utils/functions";

const RecordVideoModal: React.FC<RecordVideoModalPros> = ({
  setRecordVideo,
  recordVideo,
}) => {
  const date = new Date();
  const formattedDate = moment(date).format("MMMM D, YYYY");
  const [localStream, setLocalStream] = useState<any | null>(null);
  const assessmentId = localStorage.getItem(localStorageKeys.assessmentId);
  const [streamId, setStreamId] = useState("");
  const [zegoInstance, setZegoInstance] = useState<any>(null);
  const [permissions, setPermissions] = useState({
    videoStopped: false,
    audioStopped: false,
    shareStopped: false,
    recordingStarted: false,
  });
  const [taskId, setTaskId] = useState<any>();
  const streamIdNum = "170";
  const SignatureNonce = "15215528852396";
  const Signature = "7a2c0f11145fb760d607a07b54825013";
  const AppId = process.env.REACT_APP_ZEGO_APP_ID;
  const headers = { "Content-type": "application/json;charset=utf-8" };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [screenStreamId, setScreenStreamId] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [screenStream, setScreenStream] = useState<any>(null);
  const generateToken = async () => {
    try {
      const response = await authenticatedAxios.get(
        URL.ACCOUNT.GET_ZEGO_TOKEN + `/${assessmentId}` + URL.ACCOUNT.TOKEN,
        {
          authenticated: true,
        } as CustomAxiosRequestConfig,
      );
      return response;
    } catch (error) {
      const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
      handleMessage(messageEnum?.error, errorMsg);
    }
  };
  useEffect(() => {
    const initializeApp = async () => {
      const response = await generateToken();
      const zegoAppId = process.env.REACT_APP_ZEGO_APP_ID;
      const zegoServerId = process.env.REACT_APP_ZEGO_SERVER_ID;
      const zegoToken = response?.data?.responseBody?.data?.token;

      if (
        typeof zegoAppId !== "string" ||
        typeof zegoServerId !== "string" ||
        typeof zegoToken !== "string"
      ) {
        console.error(
          "Zego environment variables are not defined or not of type string.",
        );
        return;
      }

      const zg = new ZegoExpressEngine(parseInt(zegoAppId), zegoServerId);
      try {
        await zg.loginRoom(
          streamIdNum,
          zegoToken,
          {
            userID: response?.data?.responseBody?.data?.user_id,
            userName: "Prasad",
          },
          { userUpdate: true },
        );
        setZegoInstance(zg);
      } catch (error) {
        console.error("Error during login:", error);
      }
      try {
        const stream = await zg.createZegoStream({
          camera: { audio: true, video: true },
        });
        setLocalStream(stream);
        const streamID = streamIdNum + Date.now();
        setStreamId(streamID);
        zg.startPublishingStream(streamID, stream);
      } catch (error) {
        console.error("Error occurred while creating local stream:", error);
      }
    };

    initializeApp();
  }, []);

  const handleStart = () => {
    const bodyData = {
      RoomId: streamIdNum,
      RecordInputParams: {
        RecordMode: 2,
        StreamType: 3,
        MaxIdleTime: 60,
        MixConfig: {
          MixMode: 2,
          MixOutputStreamId: "mix",
          MixOutputVideoConfig: {
            Width: 1280,
            Height: 720,
            Fps: 15,
            Bitrate: 1130000,
          },
        },
      },
      RecordOutputParams: {
        OutputFileFormat: "mp4",
        OutputFolder: "record/",
      },
      StorageParams: {
        Vendor: 1,
        Region: "ap-south-1",
        Bucket: "certiplate-v3-dev-private",
        AccessKeyId: "AKIA6FC7BVS2DOIHWUWC",
        AccessKeySecret: "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",
      },
    };
    const url = `https://cloudrecord-api.zego.im/?Action=StartRecord&AppId=${AppId}&SignatureNonce=${SignatureNonce}&Timestamp=1234567890&Signature=${Signature}&SignatureVersion=2.0&IsTest=false`;
    try {
      authenticatedAxios
        .post(url, bodyData, {
          headers,
        })
        .then(async (response) => {
          const responseData = response.data;
          setTaskId(responseData?.Data?.TaskId);
          setPermissions((prev) => ({ ...prev, recordingStarted: true }));
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handlePause = () => {
    const bodyData = {
      TaskId: taskId,
    };

    const url = `https://cloudrecord-api.zego.im/?Action=PauseRecord&AppId=${AppId}&SignatureNonce=${SignatureNonce}&Timestamp=1234567890&Signature=${Signature}&SignatureVersion=2.0&IsTest=false`;
    try {
      authenticatedAxios
        .post(url, bodyData, {
          headers,
        })
        .then(async (response) => {
          console.log("Video paused", response.data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleStopVideo = async () => {
    if (zegoInstance) {
      try {
        if (!permissions.videoStopped) {
          setPermissions((prev) => ({ ...prev, videoStopped: true }));
          await zegoInstance.mutePublishStreamVideo(localStream, true);
        } else {
          setPermissions((prev) => ({ ...prev, videoStopped: false }));
          await zegoInstance.mutePublishStreamVideo(localStream, false);
        }
      } catch (error) {
        console.error("Error occurred while toggling video:", error);
      }
    }
  };

  const handleStopAudio = async () => {
    if (zegoInstance) {
      try {
        if (permissions && permissions.audioStopped) {
          await zegoInstance.mutePublishStreamAudio(localStream, false);
          setPermissions((prev) => ({ ...prev, audioStopped: false }));
        } else {
          setPermissions((prev) => ({ ...prev, audioStopped: true }));
          await zegoInstance.mutePublishStreamAudio(localStream, true);
        }
      } catch (error) {
        console.error("Error occurred while toggling audio:", error);
      }
    }
  };

  const handleShare = async () => {
    if (!permissions.shareStopped) {
      const screenStream = await zegoInstance.createZegoStream({
        screen: {
          videoBitrate: 1500,
          audio: true,
          video: {
            quality: 2,
            frameRate: 15,
          },
        },
      });
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const screenStreamId = streamIdNum + Date.now();
      setPermissions((prev) => ({ ...prev, shareStopped: true }));
      setScreenStreamId(String(screenStreamId));
      setScreenStream(screenStream);
      zegoInstance.startPublishingStream(String(screenStreamId), localStream);
      zegoInstance.on("screenSharingEnded", () => {
        zegoInstance.stopPublishingStream(String(screenStreamId));
        zegoInstance.destroyStream(localStream);
        setPermissions((prev) => ({ ...prev, shareStopped: false }));
      });
    } else {
      const screenStreamId = streamIdNum + Date.now();
      zegoInstance.stopPublishingStream(String(screenStreamId));
      zegoInstance.destroyStream(localStream);
      setPermissions((prev) => ({ ...prev, shareStopped: false }));
    }
  };

  const StreamSrcObject = (stream: any, videoElement: any) => {
    if (videoElement && stream instanceof MediaStream) {
      try {
        videoElement.srcObject = stream;
      } catch (error) {
        console.error("Error setting stream:", error);
      }
    }
  };

  const handleOnCancel = () => {
    setRecordVideo(false);
    if (localStream && zegoInstance && streamId) {
      zegoInstance.stopPublishingStream(streamId);
      zegoInstance.destroyStream(localStream);
    }
  };

  const handleUpload = (text: string) => {
    if (text == recordVideoKeys?.start) {
      permissions?.recordingStarted ? handleStart() : handlePause();
    } else if (text == recordVideoKeys?.cam) {
      handleStopVideo();
    } else if (text == recordVideoKeys?.mic) {
      handleStopAudio();
    } else if (text == recordVideoKeys?.share) {
      handleShare();
    } else {
      return;
    }
  };

  const getColor = (text: string) => {
    if (text == recordVideoKeys?.start && permissions?.recordingStarted) {
      return "bg-[red]";
    } else if (text == recordVideoKeys?.cam && permissions?.videoStopped) {
      return "bg-[red]";
    } else if (text == recordVideoKeys?.mic && permissions?.audioStopped) {
      return "bg-[red]";
    } else if (text == recordVideoKeys?.share && permissions?.shareStopped) {
      return "bg-[red]";
    } else {
      return;
    }
  };

  const handleChangeIcon = (text: string, icon: any, icon1: any) => {
    if (text == recordVideoKeys?.start) {
      return permissions?.recordingStarted ? icon : icon1;
    } else if (text == recordVideoKeys?.cam) {
      return !permissions?.videoStopped ? icon : icon1;
    } else if (text == recordVideoKeys?.mic) {
      return !permissions?.audioStopped ? icon : icon1;
    } else if (text == recordVideoKeys?.share) {
      return !permissions?.shareStopped ? icon : icon1;
    } else {
      return;
    }
  };

  return (
    <div>
      {recordVideo && (
        <ModalComponent
          title={`Record - ${formattedDate}`}
          handleOnCancel={() => {
            handleOnCancel();
          }}
          className="uploadModal"
          titleClass={"text-primaryText text-[20px] font-semibold"}
          closeIcon={true}
          isAssessment={false}
          questionModal={true}
          width={650}
          isModalOpen={recordVideo}
          uploadButtonName1={buttonTitle?.cancel}
          uploadButtonName2={buttonTitle?.upload}
          // modalButtonClick={() => {}}
          content={
            <div className="mt-[20px]">
              {localStream ? (
                <video
                  id="local-video"
                  className="h-[377px] w-[600px]"
                  autoPlay={true}
                  muted={true}
                  playsInline={true}
                  ref={(video) => StreamSrcObject(localStream.stream, video)}
                />
              ) : (
                <div className="h-[377px] w-[600px] flex justify-center items-center">
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{
                          fontSize: 50,
                          color: "#22c95cfa",
                        }}
                        spin
                      />
                    }
                  />
                </div>
              )}

              <div className="flex gap-3 mt-3 justify-center font-semibold">
                {recordVideoButtons.map((button: any, index: number) => (
                  <div
                    key={index}
                    onClick={() => {
                      handleUpload(button.label);
                    }}
                    className="flex flex-col items-center">
                    <div
                      className={classNames(
                        getColor(button.label),
                        "bg-[gray] w-[40px] h-[40px] flex justify-center items-center rounded-md",
                      )}>
                      {handleChangeIcon(
                        button.label,
                        button?.icon,
                        button?.icon2,
                      )}
                    </div>
                    <div>{button.label}</div>
                  </div>
                ))}
              </div>
            </div>
          }
        />
      )}
    </div>
  );
};

export default RecordVideoModal;
