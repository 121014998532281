import React, { useState } from "react";
import ModalComponent from "./customModal";
import Dragger from "antd/es/upload/Dragger";
import { BsCloudUpload } from "react-icons/bs";
import {
  buttonTitle,
  messageEnum,
  allowedTypeVideoUpload,
  allowedTypesFileUpload,
  modalContent,
  firebaseMethodKeys,
} from "@/constants/constants";
import {
  getBase64,
  getFirebaseUrl,
  getIntlErrorMessage,
  getIntlSuccessMessage,
  getIntlWarningMessage,
  handleMessage,
} from "@/utils/functions";
import { URL, FIREBASE_URL } from "@/utils/service-urls";
import { authenticatedAxios } from "@/utils/axiosInterceptor";
import { CustomAxiosRequestConfig } from "@/types/functionTypes";
import { handleFirebaseOperation } from "../firebase";
import type { UploadFile } from "antd";
import { UploadModalComponentProps } from "@/types/componentTypes";
import { useFullscreenToggle } from "@/hooks/useFullScreenMode";
import { Button, Progress } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { RootState } from "@/store";
import { useSelector } from "react-redux";
import { FileType } from "@/types/contextTypes";

const UploadModalComponent: React.FC<UploadModalComponentProps> = ({
  setUploadModalOpen,
  uploadModalOpen,
  currentQuestion,
}) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [progressList, setProgressList] = useState<Record<string, number>>({});
  const [uploadCompletedList, setUploadCompletedList] = useState<
    Record<string, boolean>
  >({});

  const [uploading, setUploading] = useState<boolean>(false);

  const candidateId = useSelector((state: RootState) => state.candidateId);
  const assessmentId = useSelector((state: RootState) => state.assessmentId);
  const setId = useSelector((state: RootState) => state.setId);
  const toggleFullscreen = useFullscreenToggle();
  const uploadNames = {
    uploadingFiles: "uploading File",
    uploadedFiles: "uploaded Files",
    uploadingVideos: "uploading Video",
    uploadedVideos: "uploaded Videos",
  };

  const handleFileChange = (file: UploadFile) => {
    const maxSize = 2 * 1024 * 1024;
    let allowedTypes: string[] = [];

    if (uploadModalOpen.fileModal) {
      allowedTypes = allowedTypesFileUpload;
    } else if (uploadModalOpen.videoModal) {
      allowedTypes = allowedTypeVideoUpload;
    }

    const isValidType = allowedTypes.includes(file?.type ?? "");
    const isValidSize = (file?.size ?? 0) <= maxSize;
    const isDuplicate = fileList.some(
      (existingFile) => existingFile.name === file.name,
    );

    if (isDuplicate) {
      const errorMsg = getIntlErrorMessage(
        "UPLOAD_FILE_VIDEO_MESSAGES",
        "DUPLICATE_FILE_MESSAGE",
      );
      handleMessage(messageEnum.error, errorMsg);
      return false;
    }
    if (isValidType && isValidSize && !isDuplicate) {
      setFileList((prev) => [...prev, file]);
      setProgressList((prev) => ({ ...prev, [file.uid]: 0 }));
      setUploadCompletedList((prev) => ({ ...prev, [file.uid]: false }));
      simulateProgress(file);
    } else {
      if (!isValidType && file) {
        const errorMsg = getIntlErrorMessage(
          "UPLOAD_FILE_VIDEO_MESSAGES",
          "FILE_TYPE_MESSAGES",
        );
        const errorMsgNew = getIntlErrorMessage(
          "UPLOAD_FILE_VIDEO_MESSAGES",
          "FILE_TYPE_NOT_SUPPORT",
        );
        handleMessage(
          messageEnum.error,
          `${errorMsg} ${file.type} ${errorMsgNew}`,
        );
      }
      if (!isValidSize) {
        const errorMsg = getIntlErrorMessage(
          "UPLOAD_FILE_VIDEO_MESSAGES",
          "FILE_SIZE_LIMIT_MESSAGE",
        );
        handleMessage(messageEnum.error, errorMsg);
      }
    }
    return isValidType && isValidSize;
  };

  const simulateProgress = (file: UploadFile) => {
    let progressValue = 0;
    const interval = setInterval(() => {
      progressValue += 10;
      setProgressList((prev) => ({ ...prev, [file.uid]: progressValue }));

      if (progressValue >= 100) {
        clearInterval(interval);
        setUploadCompletedList((prev) => ({ ...prev, [file.uid]: true }));
      }
    }, 200);
  };

  const handleUploadToDB = async () => {
    // let files: any;
    const files = [...fileList];
    // const localStorageUploadedKey = localStorageKeys.activityUploadFiles;
    // const storedEvents =
    //   JSON.parse(localStorage.getItem(localStorageUploadedKey) as any) || [];
    // if (storedEvents.length > 0) {
    //   files = [...storedEvents, ...files];
    // }

    if (files?.length <= 0) {
      // setUploading(false);
      const warningMsg = getIntlWarningMessage(
        "EMPTY_FILE_UPLOAD",
        "EMPTY_UPLOADING",
      );
      handleMessage(messageEnum.warning, warningMsg);
      return;
    }
    if (hasUploadingFiles) {
      return;
    }
    setUploading(true);

    files.forEach(async (file: any) => {
      const fileUploaded = await getBase64(file as FileType);

      const imageData = {
        requestBody: {
          folder:
            (uploadModalOpen?.fileModal && "images") ||
            (uploadModalOpen?.videoModal && "videos"),
          file: fileUploaded,
        },
      };
      try {
        const url = URL.SERVICES.UPLOAD;
        authenticatedAxios
          .post(url, imageData, {
            authenticated: true,
          } as CustomAxiosRequestConfig)
          .then(async (response) => {
            if (response?.status == 200) {
              await handleUploadToFirebase(
                { key: response.data.responseBody, fileName: file?.name },
                file?.uid,
              );
              const successMsg = getIntlSuccessMessage(
                "FILE_POSTED_MESSAGE",
                "POSTED_SUCCESS",
              );
              handleMessage(messageEnum.success, successMsg);
              setUploading(false);
              setUploadModalOpen({ fileModal: false, videoModal: false });
              toggleFullscreen(false);
              // localStorage.setItem(localStorageUploadedKey, JSON.stringify([]));
            } else {
              const errorMsg = getIntlErrorMessage(
                "FILE_POSTED_MESSAGE",
                "POSTED_FAILED_MESSAGE",
              );
              // storedEvents.push(file);
              // localStorage.setItem(
              //   localStorageUploadedKey,
              //   JSON.stringify(storedEvents),
              // );
              handleMessage(messageEnum.error, errorMsg);
              setUploading(false);
            }
          })
          .catch((error) => {
            console.error("Error:", error);
            setUploading(false);
          });
      } catch (error) {
        const errorMsg = getIntlErrorMessage(
          "FILE_POSTED_MESSAGE",
          "POSTED_FAILED_MESSAGE",
        );

        handleMessage(messageEnum.error, errorMsg);
        setUploading(false);
        console.error("Error:", error);
      }
    });
  };

  const handleUploadToFirebase = async (data: any, uid: any) => {
    try {
      const options = {
        assessmentId: assessmentId,
        setId: setId,
        candidateId: candidateId,
        endpoint: `${FIREBASE_URL?.ENDPOINT?.PRACTICALS}/${currentQuestion?.id}/${uid}`,
      };
      const firebaseUrl = getFirebaseUrl(options);
      await handleFirebaseOperation(firebaseUrl, firebaseMethodKeys.PUT, data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const props = {
    name: "file",
    multiple: true,
    fileList,
    beforeUpload: (file: UploadFile) => {
      handleFileChange(file);
      return false;
    },
    onRemove: (file: UploadFile) => {
      setFileList((prevFileList) =>
        prevFileList.filter((item) => item.uid !== file.uid),
      );
      setProgressList((prev) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [file.uid]: _, ...rest } = prev;
        return rest;
      });
      setUploadCompletedList((prev) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [file.uid]: _, ...rest } = prev;
        return rest;
      });
    },
  };
  const hasUploadingFiles = fileList.some(
    (file) => !uploadCompletedList[file.uid],
  );

  const hasUploadedFiles = fileList.some(
    (file) => uploadCompletedList[file.uid],
  );
  return (
    <div>
      {(uploadModalOpen.fileModal || uploadModalOpen.videoModal) && (
        <ModalComponent
          title={
            (uploadModalOpen.fileModal && modalContent.uploadFiles) ||
            (uploadModalOpen.videoModal && modalContent.uploadVideo)
          }
          width={650}
          className="uploadModal"
          titleClass="text-primaryText text-[20px] font-semibold"
          closeIcon={true}
          isModalOpen={uploadModalOpen.fileModal || uploadModalOpen.videoModal}
          isAssessment={false}
          questionModal={true}
          uploadButtonName1={buttonTitle.cancel}
          uploadButtonName2={
            !uploading ? buttonTitle.upload : buttonTitle.uploading
          }
          // disabled={fileList.length === 0 || hasUploadingFiles}
          handleOnCancel={() => {
            setUploadModalOpen({ fileModal: false, videoModal: false });
          }}
          modalButtonClick={handleUploadToDB}
          content={
            <div className="pt-[20px]">
              <Dragger {...props}>
                <p className="flex justify-center">
                  <BsCloudUpload fontSize={50} color="gray" />
                </p>
                <p className="font-semibold text-[20px] text-primaryText pt-2">
                  {modalContent.dropFiles}
                </p>
                <p className="pt-2 text-[13px] text-primaryText">
                  {(uploadModalOpen.fileModal &&
                    modalContent.supportedFormatesFile) ||
                    (uploadModalOpen.videoModal &&
                      modalContent.supportedFormatesVideos)}
                </p>
                <div className="pt-2 text-primaryText">{modalContent.or}</div>
                <div className="pt-2 font-semibold text-primary">
                  {modalContent.browseFiles}
                </div>
              </Dragger>
              {hasUploadingFiles && (
                <div>
                  <div className="font-semibold text-[16px]">
                    {uploadModalOpen.fileModal
                      ? uploadNames.uploadingFiles
                      : uploadNames.uploadingVideos}
                  </div>
                  <div className="max-h-[100px] overflow-y-auto">
                    {fileList.map((file) =>
                      !uploadCompletedList[file.uid] ? (
                        <div key={file.uid} style={{ marginTop: 16 }}>
                          <Progress percent={progressList[file.uid]} />
                          <div className="ml-[2px]">{file.name}</div>
                        </div>
                      ) : null,
                    )}
                  </div>
                </div>
              )}

              {hasUploadedFiles && (
                <div className="font-semibold text-[16px] mt-[16px]">
                  {uploadModalOpen.fileModal
                    ? uploadNames.uploadedFiles
                    : uploadNames.uploadedVideos}
                </div>
              )}
              <div className="max-h-[100px] overflow-y-auto">
                {fileList.map((file) =>
                  uploadCompletedList[file.uid] ? (
                    <div
                      key={file.uid}
                      className="flex flex-col mt-[10px] ml-[10px]">
                      <div className="flex justify-between  ml-[2px]">
                        <span>{file.name}</span>
                        <Button
                          type="text"
                          icon={<DeleteOutlined />}
                          onClick={() => {
                            setFileList((prevFileList) =>
                              prevFileList.filter(
                                (item) => item.uid !== file.uid,
                              ),
                            );
                            setProgressList((prev) => {
                              // eslint-disable-next-line @typescript-eslint/no-unused-vars
                              const { [file.uid]: _, ...rest } = prev;
                              return rest;
                            });
                            setUploadCompletedList((prev) => {
                              // eslint-disable-next-line @typescript-eslint/no-unused-vars
                              const { [file.uid]: _, ...rest } = prev;
                              return rest;
                            });
                          }}
                        />
                      </div>
                    </div>
                  ) : null,
                )}
              </div>
            </div>
          }
        />
      )}
    </div>
  );
};

export default UploadModalComponent;
