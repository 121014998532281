import React, { useContext } from "react";
import HtmlEditor from "@/components/htmlEditor";
import LanguageSelector from "@/components/languageSelector";
import { languageOptions } from "@/constants/constants";
import { ItemStateContext } from "@/contexts/questionContext";
import { AssessmentInstructionsProps } from "@/types/componentTypes";
const AssessmentInstructions: React.FC<AssessmentInstructionsProps> = ({
  assessmentDetailsResponse,
}) => {
  const { currentLanguage }: any = useContext(ItemStateContext);
  const handleSelectedLanguage = (e: any) => {
    console.log(e?.target?.value);
  };
  return (
    <div className="p-[15px] mb-[50px]">
      <div className="text-end pt-[10px] pr-[50px]">
        <LanguageSelector
          onChange={(e) => handleSelectedLanguage(e)}
          selectedLanguage={currentLanguage}
          languageOptions={languageOptions}
          className="border-[2px] border-[lightgray] rounded w-[100px] h-[30px] p-[2px]"
        />
      </div>
      <div className="flex-1 flex overflow-y-auto">
        <HtmlEditor initialValue={assessmentDetailsResponse?.instructions} />
      </div>
    </div>
  );
};

export default AssessmentInstructions;
