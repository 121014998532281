import React, { FC, useContext, useEffect, useState } from "react";
import SearchInputComponent from "./searchComponent";
import classNames from "classnames";
import {
  ConfigProvider,
  Drawer,
  Empty,
  List,
  Popover,
  Table,
  Tabs,
  TabsProps,
} from "antd";
import {
  languageOptions,
  buttonTitle,
  contentDivText,
  assessmentPopOverContent,
  localStorageKeys,
  messageEnum,
} from "@/constants/constants";
import VideoConferencing from "./videoConferencing";
import { ItemStateContext } from "@/contexts/questionContext";
import LanguageSelector from "./languageSelector";
import CustomNumberInput from "./customInputNumber";
import ButtonComponent from "./buttonComponent";
import Chat from "./chat";
import { useSocket } from "@/contexts/socketContext";
import HtmlEditor from "./htmlEditor";
import { ConductVivaProps } from "@/types/contextTypes";
import {
  getIntlErrorMessage,
  handleMessage,
  questionPaperMapping,
} from "@/utils/functions";
import { authenticatedAxios } from "@/utils/axiosInterceptor";
import { URL } from "@/utils/service-urls";
import { CustomAxiosRequestConfig } from "@/types/functionTypes";
import { useChatContext } from "@/contexts/chatContext";

const ConductViva: FC<ConductVivaProps> = ({
  candidatesDetails,
  assessmentDetailsResponse,
}) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { candidates }: any = useSocket();
  // const [ischatRoomOpen, setIsChatRoomOpen] = useState<boolean>(false);
  const [selectedPerson, setSelectedPerson] = useState<string>("");
  const [chatOpen, setChatOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [kpis, setKpis] = useState<{ [key: string]: any }>({});
  const [observations, setObservations] = useState<{ [key: string]: any }>({});
  const [vivaScores, setVivaScores] = useState<{ [key: string]: any }>({});
  const [vivaQuestions, setVivaQuestions] = useState<any>();
  const [kpiRemarks, setKpiRemarks] = useState<any>();
  const [divHeight, setDivHeight] = useState<any>(0);
  const {
    setVivaStarted,
    currentLanguage,
    ischatRoomOpen,
    setIsChatRoomOpen,
  }: any = useContext(ItemStateContext);
  const { setSelectedName }: any = useChatContext();
  useEffect(() => {
    const elHeight = document?.getElementById?.("container")?.clientHeight;
    setDivHeight(elHeight);
  }, [loading, chatOpen]);

  const selectedSetId = assessmentDetailsResponse?.set_ids[0];
  const assessmentId = localStorage.getItem(localStorageKeys?.assessmentId);

  const fetchData = async (url: string, onSuccess: (data: any) => void) => {
    try {
      const response = await authenticatedAxios.get(url, {
        authenticated: true,
      } as CustomAxiosRequestConfig);
      if (response?.status === 200) {
        onSuccess(response.data.responseBody);
      } else {
        const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
        handleMessage(messageEnum.error, errorMsg);
      }
    } catch (error) {
      const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
      handleMessage(messageEnum.error, errorMsg);
    }
  };

  useEffect(() => {
    const vivaQuestionsUrl = `${URL.ASSESSMENT.CANDIDATE_ASSESSMENT}/${assessmentId}/${URL.ASSESSMENT.CANDIDATE_SET}/${selectedSetId}`;
    const kpiRemarksUrl = `${URL.ASSESSMENT.CANDIDATE_ASSESSMENT}/${assessmentId}/${URL.ASSESSMENT.CANDIDATE_SET}/${selectedSetId}?answer=true`;

    fetchData(vivaQuestionsUrl, (data) => {
      questionPaperMapping(data);
      const vivaQuestions: any = JSON.parse(
        localStorage.getItem(localStorageKeys.vivaPaper) ?? "{}",
      );
      setVivaQuestions(vivaQuestions);
    });

    fetchData(kpiRemarksUrl, (data) => {
      const KPIRemarks = JSON.parse(data);
      setKpiRemarks(KPIRemarks);
    });
  }, []);
  const showLoading = () => {
    setChatOpen(true);
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const getKPIData = (vivaQuestions: any, KPIRemarks: any) => {
    const result: any = [];
    vivaQuestions?.forEach((question: any) => {
      const kpiData = KPIRemarks?.En[question?.id];
      if (kpiData) {
        result.push(kpiData);
      }
    });
    return result;
  };

  const kpiDataAssessment = getKPIData(vivaQuestions?.viva1, kpiRemarks);

  const KPIData = kpiDataAssessment.flatMap((item: any) =>
    item.options.flat().map((kpi: any) => ({
      kpi_id: kpi.kpi_id,
      kpi_name: kpi.kpi_name,
      kpi_score: kpi.kpi_score,
      observation_score: kpi.observation_score,
      viva_score: kpi.viva_score,
    })),
  );
  const questionsList = (vivaQuestions?.viva1 || []).map(
    (item: any, index: number) => ({
      key: index + 1,
      question_text: item?.additional_information || "",
      question_remarks: kpiDataAssessment?.[index]?.question_remarks,
    }),
  );

  const Remarkscolumns = [
    {
      title: "Question",
      dataIndex: "question_text",
      render: (text: string, record: any) => (
        <HtmlEditor initialValue={record?.question_text} />
      ),
    },
    {
      title: "Remarks",
      dataIndex: "question_remarks",
    },
  ];

  const handleKPIChange = (
    person: string | null,
    kpi: string,
    value: number,
  ) => {
    if (person) {
      setKpis((prevState) => ({
        ...prevState,
        [person]: {
          ...prevState[person],
          [kpi]: value,
        },
      }));
    }
  };

  const handleObservationChange = (
    person: string | null,
    kpi: string,
    value: number,
  ) => {
    if (person) {
      setObservations((prevState) => ({
        ...prevState,
        [person]: {
          ...prevState[person],
          [kpi]: value,
        },
      }));
    }
  };

  const handleVivaScoreChange = (
    person: string | null,
    kpi: string,
    value: number,
  ) => {
    if (person) {
      setVivaScores((prevState) => ({
        ...prevState,
        [person]: {
          ...prevState[person],
          [kpi]: value,
        },
      }));
    }
  };

  const saveData = () => {
    const savedData: { [key: string]: any } = {};
    for (const person in kpis) {
      if (Object.prototype.hasOwnProperty.call(kpis, person)) {
        savedData[person] = {};
        for (const content in kpis[person]) {
          if (Object.prototype.hasOwnProperty.call(kpis[person], content)) {
            savedData[person][content] = {
              kpiscore: kpis[person][content],
              observScore: observations[person]?.[content],
              VivaScore: vivaScores[person]?.[content],
            };
          }
        }
      }
    }
  };

  const KPIColumns = [
    {
      title: "KPI Name",
      dataIndex: "name",
      render: (text: string, record: any) => record.kpi_name,
    },
    {
      title: "KPI Score",
      dataIndex: "kpi_score",
      render: (text: string, record: any) => {
        const value = kpis[selectedPerson]?.[record.kpi_name];
        return (
          <div className="flex gap-2 items-center">
            <CustomNumberInput
              key={record.kpi_id}
              min={0}
              max={10}
              value={value}
              onChange={(value: number) =>
                handleKPIChange(selectedPerson, record.kpi_name, value)
              }
            />
            <div>/ {record.kpi_score}</div>
          </div>
        );
      },
    },
    {
      title: "Observation Score",
      dataIndex: "observation_score",
      render: (text: string, record: any) => {
        const value = observations[selectedPerson]?.[record.kpi_name];
        return (
          <div className="flex gap-2 items-center">
            <CustomNumberInput
              key={record.kpi_id}
              min={0}
              max={10}
              value={value}
              onChange={(value: number) =>
                handleObservationChange(selectedPerson, record.kpi_name, value)
              }
            />
            <div>/ {record.observation_score}</div>
          </div>
        );
      },
    },
    {
      title: "Viva Score",
      dataIndex: "viva_score",
      render: (text: string, record: any) => {
        const value = vivaScores[selectedPerson]?.[record.kpi_name];
        return (
          <div className="flex gap-2 items-center">
            <CustomNumberInput
              key={record.kpi_id}
              min={0}
              max={10}
              value={value}
              onChange={(value: number) =>
                handleVivaScoreChange(selectedPerson, record.kpi_name, value)
              }
            />
            <div>/ {record.viva_score}</div>
          </div>
        );
      },
    },
  ];

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const filteredCandidates =
    (Array.isArray(candidatesDetails)
      ? candidatesDetails?.filter((candidate: any) =>
          candidate?.full_name.toLowerCase().includes(searchQuery),
        )
      : []) || [];
  const handleSelectedLanguage = (e: any) => {
    console.log(e?.target?.value);
  };

  const tagColor = (status: string) => {
    if (
      status === assessmentPopOverContent?.waiting ||
      status === assessmentPopOverContent?.ongoing ||
      status === assessmentPopOverContent?.completed
    ) {
      return "text-[#379AE6] bg-[#F1F8FD]";
    } else if (status === assessmentPopOverContent?.online) {
      return "text-primary bg-[#e8fff2]";
    } else if (
      status === assessmentPopOverContent?.offline ||
      status === assessmentPopOverContent?.blocked
    ) {
      return "text-[#D32F2F] bg-[#FDF2F2]";
    }
  };

  const getPopoverContent = (status: string, name: string, id: number) => {
    if (
      status === assessmentPopOverContent?.waiting ||
      status === assessmentPopOverContent?.online
    ) {
      return (
        <div className="cursor-pointer text-[white] ">
          {status === assessmentPopOverContent?.waiting && (
            <div
              className="px-4 py-2 border-b-[1px] border-[white]"
              onClick={() => {
                if (selectedPerson !== name) {
                  setActiveTab("1");
                }
                setIsChatRoomOpen(true);
                setSelectedPerson(name);
                setSelectedName({
                  name,
                  id,
                  type: "candidate",
                  config: "one-to-one",
                });
              }}>
              {contentDivText?.connectRoom}
            </div>
          )}
          <div
            className="px-4 py-2"
            onClick={() => {
              setSelectedPerson(name);
              showLoading();
              setSelectedName({
                name,
                id,
                type: "candidate",
                config: "one-to-one",
              });
              localStorage.setItem(
                "selectedName",
                JSON.stringify({
                  name,
                  id,
                  type: "candidate",
                  config: "one-to-one",
                }),
              );
            }}>
            {contentDivText?.chat}
          </div>
        </div>
      );
    }
    return null;
  };
  const handleTabChange = (key) => {
    setSearchQuery("");
    setActiveTab(key);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Viva Questions",
      children: (
        <div>
          <div className=" mx-[20px] mb-[20px] bg-[white]">
            <div className="flex justify-end">
              <LanguageSelector
                onChange={(e) => handleSelectedLanguage(e)}
                selectedLanguage={currentLanguage}
                languageOptions={languageOptions}
                className="border-[2px] border-[lightgray] rounded w-[100px] h-[30px] p-[2px] m-2"
              />
            </div>
            <div className="overflow-y-auto h-[26vh]">
              <List
                size="large"
                dataSource={vivaQuestions?.viva1}
                renderItem={(item: any, index: number) => (
                  <List.Item>
                    <div className="flex gap-1">
                      <div>{index + 1}.</div>
                      <div>
                        <HtmlEditor initialValue={item?.question_text} />
                      </div>
                    </div>
                  </List.Item>
                )}
              />
            </div>
          </div>
        </div>
      ),
    },
    {
      key: "2",
      label: "KPI",
      children: (
        <div>
          <div className=" mx-[20px] mb-[20px] bg-[white]">
            <div className="p-[10px]">
              <Table
                columns={KPIColumns}
                className="remarks-table"
                dataSource={KPIData}
                bordered
                pagination={false}
                scroll={{ y: 160 }}
              />
              <div className="flex justify-end pt-2 pr-4">
                <ButtonComponent
                  text={buttonTitle?.save}
                  className="bg-primary px-[20px] py-[10px] border-none rounded-md font-semibold text-[white]"
                  onClick={saveData}
                />
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      key: "3",
      label: "Question Remarks",
      children: (
        <div>
          <div className=" mx-[20px] mb-[20px] bg-[white]">
            <div className="flex justify-end">
              <LanguageSelector
                onChange={(e) => handleSelectedLanguage(e)}
                selectedLanguage={currentLanguage}
                languageOptions={languageOptions}
                className="border-[2px] border-[lightgray] rounded w-[100px] h-[30px] p-[2px] m-2"
              />
            </div>
            <div className="px-[10px] pb-[10px]">
              <Table
                columns={Remarkscolumns}
                className="remarks-table"
                dataSource={questionsList}
                bordered
                pagination={false}
                scroll={{ y: 180 }}
              />
            </div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="flex h-[100%]">
      <div className="bg-bgGray pt-0 w-[30%] box-border">
        <div className="p-2 sticky top-0 bg-bgGray">
          <SearchInputComponent
            className="h-[40px]"
            handleSearchChange={handleSearchChange}
            searchQuery={searchQuery}
          />
        </div>
        <div className="flex flex-col justify-between h-[100%]">
          <div className="overflow-auto">
            {filteredCandidates?.length > 0 ? (
              <div className="pt-2 px-2">
                {filteredCandidates?.map((item: any, index: number) => {
                  // const isSelected = selectedPerson === item.name;
                  return (
                    <ConfigProvider
                      key={index}
                      theme={{
                        components: {
                          Popover: {
                            borderRadiusLG: 2,
                            fontSize: 18,
                            colorBgElevated: "#22c95cfa",
                            sizePopupArrow: 20,
                          },
                        },
                      }}>
                      <Popover
                        placement="rightTop"
                        className="assessmentConduct"
                        content={getPopoverContent(
                          candidates[item.candidate_id],
                          item?.name,
                          item?.candidate_id,
                        )}>
                        <div
                          className={classNames(
                            tagColor(
                              item.status == "Blocked"
                                ? "blocked"
                                : candidates[item.candidate_id] || "offline",
                            ),
                            "p-[6px] rounded-2xl text-[14px] flex items-center justify-center",
                          )}>
                          {item.status == "Blocked"
                            ? "blocked"
                            : candidates[item.candidate_id] || "offline"}
                        </div>
                      </Popover>
                    </ConfigProvider>
                  );
                })}
              </div>
            ) : (
              <div className="mt-[70%]">
                <Empty />
              </div>
            )}
          </div>
          <div
            className="bg-primary px-3 py-4 text-[white] flex items-center justify-center sticky bottom-0"
            onClick={() => {
              setSelectedPerson("");
              showLoading();
            }}>
            {contentDivText?.chatOnlineCandidates}
          </div>
        </div>
      </div>
      <div className="w-full m-[20px] flex flex-col overflow-y-auto">
        {ischatRoomOpen && (
          <>
            <VideoConferencing
              setVivaStarted={setVivaStarted}
              isAssessmentDetailsPage={true}
            />
            <div className="mt-[10px] overflow-auto">
              <ConfigProvider
                theme={{
                  components: {
                    Tabs: {
                      inkBarColor: "#22c95cfa",
                      itemActiveColor: "#22c95cfa",
                      itemSelectedColor: "#22c95cfa",
                      itemHoverColor: "#22c95cfa",
                      titleFontSizeLG: 18,
                    },
                  },
                  token: {
                    colorBgContainer: "red",
                  },
                }}>
                <Tabs
                  defaultActiveKey="1"
                  items={items}
                  size={"large"}
                  indicator={{ size: 250 }}
                  activeKey={activeTab}
                  onChange={(key) => handleTabChange(key)}
                />
              </ConfigProvider>
            </div>
          </>
        )}
      </div>

      <ConfigProvider
        theme={{
          token: {
            colorBgElevated: "white",
          },
        }}>
        <Drawer
          closable={false}
          destroyOnClose
          placement="right"
          open={chatOpen}
          loading={loading}
          id="container"
          onClose={() => setChatOpen(false)}>
          <div id="testx">
            <Chat
              isDrawerChat={true}
              height={divHeight}
              isGroupChat={!selectedPerson && true}
            />
          </div>
        </Drawer>
      </ConfigProvider>
    </div>
  );
};

export default ConductViva;
