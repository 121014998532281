import ConditionalClassName from "@/components/responsesClassComponent";
import { responseText } from "@/constants/constants";
import classNames from "classnames";

const ResponseItem = ({
  optionIndex,
  optionText,
  ourResponse,
  selectedOption,
  correctOption,
  isSelectedOptionPresent,
}) => (
  <ConditionalClassName
    ourResponse={ourResponse}
    selectedOption={selectedOption}
    correctOption={correctOption}
    isSelectedOptionPresent={isSelectedOptionPresent}
    defaultClassName="px-[10px] mt-[10px]">
    <div className="flex gap-1">
      <div>{optionIndex + 1}.</div>
      <div>
        <span dangerouslySetInnerHTML={{ __html: optionText }} />
      </div>
    </div>
    {ourResponse && selectedOption && (
      <div className="absolute text-[12px] text-[white] w-[98.28px] flex items-center justify-center rounded-tl-sm rounded-br-sm bg-[gray] right-0 bottom-[0px] px-[8px] py-[1px] ">
        {responseText?.yourAnswer}
      </div>
    )}
    {!ourResponse && selectedOption && (
      <div
        className={classNames(
          correctOption && correctOption === selectedOption ? "bg-primary" : "",
          selectedOption && correctOption !== selectedOption && "bg-[red]",
          "absolute text-[12px] text-[white] w-[98.28px] flex items-center justify-center rounded-tl-sm rounded-br-sm bg-[gray] right-0 bottom-[0px] px-[8px] py-[1px] ",
        )}>
        {responseText?.yourAnswer}
      </div>
    )}
    {!ourResponse && correctOption && (
      <div
        className={classNames(
          "absolute text-[12px] text-[white] w-[105px] flex items-center justify-center rounded-tl-sm rounded-br-sm bg-primary right-0 bottom-[0px] px-[8px] py-[1px] ",
        )}>
        {responseText?.correctAnswer}
      </div>
    )}
  </ConditionalClassName>
);

export default ResponseItem;
