import React, { useEffect, useContext, useState } from "react";
import Logo from "@/components/logo";
import { ItemStateContext } from "@/contexts/questionContext";
import { HeaderProps, RootState } from "@/types/componentTypes";
import {
  headerText,
  localStorageKeys,
  messageEnum,
} from "@/constants/constants";
import {
  convertMinutesToHHMMSS,
  getIntlSuccessMessage,
  handleMessage,
  parseTime,
} from "@/utils/functions";
import { useSelector } from "react-redux";
import { useSocket } from "@/contexts/socketContext";

const Header: React.FC<HeaderProps> = ({ heroHeader }) => {
  const assessmentDetails: any = JSON.parse(
    localStorage.getItem("assessment_details") ?? "{}",
  );
  const imageCapturingRequiredAtEnd =
    assessmentDetails?.rules?.captureCandidatesImage;

  const candidateId: any = useSelector((state: RootState) => state.candidateId);

  const { isStarted, setFeedBackModal, setSubmitClicked }: any =
    useContext(ItemStateContext);
  const [countdown, setCountdown] = useState(
    parseTime(
      convertMinutesToHHMMSS(
        assessmentDetails?.question_paper_details?.time_limit,
      ),
    ),
  );
  const { timerCountdown }: any = useSocket();
  const updatedTime = timerCountdown[candidateId];

  useEffect(() => {
    const interval = setInterval(() => {
      if (countdown > 0) {
        setCountdown(countdown - 1);
      } else {
        clearInterval(interval);
        const successMsg = getIntlSuccessMessage(
          "OUT_OFF_TIME_ASSESSMENT_SUBMITTED_MESSAGE",
          "SUBMIT_SUCCESS",
        );
        handleMessage(messageEnum.success, successMsg);
        if (imageCapturingRequiredAtEnd) {
          setSubmitClicked(true);
        } else {
          setFeedBackModal(true);
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [countdown, history]);

  const updateTimer = (action: string, time: number) => {
    if (action == "increment") {
      setCountdown((prev) => prev + parseTime(convertMinutesToHHMMSS(time)));
    } else if (action == "decrement") {
      setCountdown((prev) => prev + parseTime(convertMinutesToHHMMSS(time)));
    }
  };

  useEffect(() => {
    updateTimer(updatedTime?.action, updatedTime?.time);
  }, [timerCountdown]);

  useEffect(() => {
    const updatedTimeBy = localStorage.getItem(localStorageKeys?.adjustTime);
    if (updatedTimeBy) {
      setCountdown(
        (prev) => prev + parseTime(convertMinutesToHHMMSS(updatedTimeBy)),
      );
    }
  }, []);

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  return (
    <div>
      {heroHeader ? (
        <div className="w-full bg-primary">
          <div className="w-[200px] h-[80px] ">
            <Logo
              heroheader={heroHeader}
              logo={assessmentDetails?.question_paper_details?.logo_file_name}
            />
          </div>
        </div>
      ) : (
        <div className="flex justify-between items-center bg-primary pr-[30px]">
          <div>
            <div className="w-[200px] h-[80px] ">
              <Logo
                heroheader={heroHeader}
                logo={assessmentDetails?.question_paper_details?.logo_file_name}
              />
            </div>
          </div>
          <div>
            <div>
              <span className="text-[white]">{headerText.studentName}</span> :{" "}
              <span className="text-[white]">
                {assessmentDetails?.full_name}
              </span>
            </div>
            <div>
              <span className="text-[white]">{headerText.studentId} </span> :{" "}
              <span className="text-[white]">{candidateId}</span>
            </div>
          </div>
          <div>
            <div>
              <span className="text-[white]">{headerText.duration}</span> :{" "}
              <span className="text-[white]">
                {convertMinutesToHHMMSS(
                  assessmentDetails?.question_paper_details?.time_limit,
                )}
              </span>
            </div>
            <div>
              <span className="text-[white]">{headerText.testDate} </span>:
              <span className="text-[white]">
                {new Date().toLocaleDateString(undefined, {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })}
              </span>
            </div>
          </div>
          <div className="w-[150px]">
            {isStarted ? (
              <div className="bg-white px-[10px] text-[30px] font-bold rounded-sm">
                {formatTime(countdown)}
              </div>
            ) : (
              <div className="bg-white px-[10px] text-[30px] font-bold  rounded-sm">
                {formatTime(countdown)}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
