import { SequenceRendererProps } from "@/types/contextTypes";
import React from "react";

const SequenceRenderer: React.FC<SequenceRendererProps> = ({ data }) => {
  const combined: { [key: string]: string[] } = {};

  data?.forEach((item) => {
    Object.entries(item.list_correct_sequence).forEach(([key, value]) => {
      if (!combined[key]) {
        combined[key] = [];
      }
      combined[key].push(value);
    });
  });

  const renderedSequences = Object.entries(combined).map(
    ([key, values], index, array) => {
      const sequence = `${values.join(" -> ")}`;
      return index === array.length - 1 ? (
        sequence
      ) : (
        <div key={key}>{`${sequence},`}</div>
      );
    },
  );

  return <div className="flex gap-1">{renderedSequences}</div>;
};

export default SequenceRenderer;
