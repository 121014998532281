import { componentText } from "@/constants/constants";

function StakeholderLogout() {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-400">
      <div className="bg-lightRed text-gray-900 p-8 rounded shadow-sm">
        {componentText.stakeholderLogout}
      </div>
    </div>
  );
}

export default StakeholderLogout;
