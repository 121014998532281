/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import SearchInputComponent from "./searchComponent";
import {
  contentDivText,
  invigilationTitles,
  localStorageKeys,
  messageEnum,
} from "@/constants/constants";
import { ConfigProvider, Pagination } from "antd";
import { IoArrowBack } from "react-icons/io5";
import EventsLogsTable from "./eventsLogsTableComponent";
import classNames from "classnames";
import AssessmentIncrementDecrement from "./assessmentIncrementDecrement";
import RaiseWarning from "./raiseWarning";
import FinishTest from "./finishTest";
import { useSocket } from "@/contexts/socketContext";
import Violations from "./violations";
import { InvigilationProps } from "@/types/componentTypes";
import { ItemStateContext } from "@/contexts/questionContext";
import CandidateProofs from "./assessmentIdCandidateProof";
import MonitorViva from "./monitorViva";
import FlagViolation from "./flagViolation";
import { authenticatedAxios } from "@/utils/axiosInterceptor";
const ITEMS_PER_PAGE = 6;
const STATUS_ONLINE = "online";
import { ZegoExpressEngine } from "zego-express-engine-webrtc";
import { URL } from "@/utils/service-urls";
import { CustomAxiosRequestConfig } from "@/types/functionTypes";
import { getIntlErrorMessage, handleMessage } from "@/utils/functions";
import CandidateVideo from "./candidateVideo";

const Invigilation: React.FC<InvigilationProps> = ({
  candidatesDetails,
  assessmentDetailsResponse,
  isMonitorTab = false,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [candidateDetails, setCandidateDetails] = useState<any>({
    details: {},
    openLogs: false,
  });
  const [remoteStreams, setRemoteStreams] = useState<any>({});
  const [screenRemoteStreams, setScreenRemoteStreams] = useState<any>({});
  const [zegoInstance, setZegoInstance] = useState<any>(null);
  const [selectedMenu, setSelectedMenu] = useState<string>(
    invigilationTitles?.candidateVideo,
  );
  const { selectedOption }: any = useContext(ItemStateContext);
  const { candidates }: any = useSocket();
  const candidateOnlineStatus: any =
    candidates[candidateDetails?.details?.candidate_id];
  const assessmentId = localStorage.getItem(localStorageKeys.assessmentId);
  useEffect(() => {
    setCandidateDetails({ details: {}, openLogs: false });
    setSelectedMenu(invigilationTitles?.candidateVideo);
  }, [selectedOption]);
  const handlePageChange = (page: number) => setCurrentPage(page);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value.toLowerCase());
    setCurrentPage(1);
  };

  const filteredCandidates =
    (Array.isArray(candidatesDetails)
      ? candidatesDetails?.filter((candidate) =>
          candidate?.full_name.toLowerCase().includes(searchQuery),
        )
      : []) || [];

  const currentData = filteredCandidates.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE,
  );

 

  const handleGetLogsOfCandidate = (candidateDetails) => {
    console.log(candidateDetails, "inside--id");
    setCandidateDetails({ details: candidateDetails, openLogs: true });
    localStorage.setItem(
      localStorageKeys?.candidateId,
      candidateDetails?.candidate_id,
    );
    localStorage.setItem(localStorageKeys?.setId, candidateDetails?.set_id);
  };
  const handleBack = () => {
    setCandidateDetails({ details: {}, openLogs: false });
    setSelectedMenu(invigilationTitles?.viewLogs);
  };

  const invigilate = {
    roomId: `candidate-invigilate-${candidatesDetails.candidate_id}`,
    userName: "",
  };
  const updateTypes = {
    delete: "DELETE",
    add: "ADD",
  };
  const zegoMethods = {
    roomStreamUpdate: "roomStreamUpdate",
    screenEnd: "screenSharingEnded",
  };
  const generateToken = async () => {
    try {
      const response = await authenticatedAxios.get(
        URL.VIVA.ZEGO_CLOUD_TOKEN + `/${assessmentId}` + URL.VIVA.TOKEN,
        {
          authenticated: true,
        } as CustomAxiosRequestConfig,
      );
      return response;
    } catch (error) {
      const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
      handleMessage(messageEnum?.error, errorMsg);
    }
  };
  console.log(remoteStreams);
  useEffect(() => {
    const initializeApp = async () => {
      const response = await generateToken();
      const zegoAppId = process.env.REACT_APP_ZEGO_APP_ID;
      const zegoServerId = process.env.REACT_APP_ZEGO_SERVER_ID;
      const zegoToken = response?.data?.responseBody?.data?.token;
      if (
        typeof zegoAppId !== "string" ||
        typeof zegoServerId !== "string" ||
        typeof zegoToken !== "string"
      ) {
        console.error(
          "Zego environment variables are not defined or not of type string.",
        );
        return;
      }
      const zg = new ZegoExpressEngine(parseInt(zegoAppId), zegoServerId);
   
      //zg.enableMultiRoom(true);
      await renderVideoStreams(zg);
      await joinRoom(
        zg,
        zegoToken,
        response?.data?.responseBody?.data?.user_id,
      );
      // setVivaResponse((prevResponse) => {
      //   const updatedResponse = { ...prevResponse };
      //   if (updatedResponse[currentQuestionIndex]) {
      //     updatedResponse[currentQuestionIndex].visited = true;
      //   }
      //   return updatedResponse;
      // });
    };

    initializeApp();
  }, []);
  const joinRoom = async (zg, zegoToken, user_id) => {
    try {
     
      candidatesDetails.forEach(async (candidate: any) => {
        await zg.loginRoom(
          `candidate-invigilate-${candidate.candidate_id}`,
          zegoToken,
          {
            userID: user_id,
            userName: "Aruna",
          },
          { userUpdate: true },
        );
      });
      console.log("after login");
      setZegoInstance(zg);
    } catch (error) {
      console.error("Error during login:", error);
    }
  };
  const renderVideoStreams = (zg) => {
    zg.on(
      zegoMethods?.roomStreamUpdate,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      async (roomID, updateType, streamList, extendedData) => {
        
        if (updateType === updateTypes?.add) {
          streamList.forEach(async (stream) => {
            try {
              const playingStream = await zg.startPlayingStream(
                stream.streamID,
                {
                  audio: true,
                  video: true,
                },
              );
              
              // if (remoteStreams?.[roomID]) {
              //   setScreenRemoteStreams((prevStreams) => ({
              //     ...prevStreams,
              //     [roomID]: [playingStream],
              //   }));
              // } else {
              //   setRemoteStreams((prevStreams) => ({
              //     ...prevStreams,
              //     [roomID]: [playingStream],
              //   }));
              // }
              setRemoteStreams((prevStreams) => {
                if (prevStreams?.[roomID]) {
                  setScreenRemoteStreams((prevStreams) => ({
                    ...prevStreams,
                    [roomID]: [playingStream],
                  }));
                  return { ...prevStreams };
                } else {
                  return {
                    ...prevStreams,
                    [roomID]: [playingStream],
                  };
                }
              });
            } catch (error) {
              console.error(
                "Error occurred while starting to play stream:",
                error,
              );
            }
          });
        } else if (updateType === updateTypes?.delete) {
          zg.stopPublishingStream(streamList[0].streamID);
          zg.logoutRoom(invigilate?.roomId);
        }
      },
    );
  };

  useEffect(() => {
   
  }, [remoteStreams?.length]);

  useEffect(() => {
    
  }, [screenRemoteStreams?.length]);

  const invigilationItems = [
    { key: 0, title: invigilationTitles?.candidateVideo },
    { key: 1, title: invigilationTitles?.viewLogs },
    { key: 2, title: invigilationTitles?.viewSnapShots },
    ...(!isMonitorTab
      ? [{ key: 3, title: invigilationTitles?.incrementDecrement }]
      : []),
    ...(candidateOnlineStatus === STATUS_ONLINE && !isMonitorTab
      ? [{ key: 4, title: invigilationTitles?.raiseWarning }]
      : isMonitorTab
        ? [{ key: 4, title: invigilationTitles?.monitorViva }]
        : []),
    ...(candidateOnlineStatus === STATUS_ONLINE && !isMonitorTab
      ? [{ key: 5, title: invigilationTitles?.finishTest }]
      : isMonitorTab
        ? [{ key: 5, title: invigilationTitles?.flagViolation }]
        : []),
    { key: 6, title: invigilationTitles?.violations },
    { key: 7, title: invigilationTitles?.idProof },
    { key: 8, title: invigilationTitles?.candidateImage },
    ...(assessmentDetailsResponse.rules.candidatesVideoRecording
      ? [{ key: 9, title: invigilationTitles?.videoRecordings }]
      : []),
  ];
  
  const invigilationScreens: { [key: string]: JSX.Element } = {
    "Candidate Video": (
      <CandidateVideo
        className="w-[85%] h-[91%]"
        candidateDetails={candidateDetails.details}
        viewContent={true}
        isMonitorTab={true}
        remoteStream={
          remoteStreams?.[
            `candidate-invigilate-${candidateDetails.details.candidate_id}`
          ]
        }
        screenStream={
          screenRemoteStreams?.[
            `candidate-invigilate-${candidateDetails.details.candidate_id}`
          ]
        }
      />
    ),
    "View Logs": (
      <EventsLogsTable
        className="w-[85%] h-[91%]"
        candidateDetails={candidateDetails.details}
        viewContent={true}
        isMonitorTab={isMonitorTab}
      />
    ),
    "View Snapshots": (
      <EventsLogsTable
        className="w-[85%] h-[91%]"
        candidateDetails={candidateDetails.details}
        viewContent={false}
        isMonitorTab={isMonitorTab}
      />
    ),
    "Increment / Decrement Time": !isMonitorTab ? (
      <AssessmentIncrementDecrement
        candidateDetails={candidateDetails.details}
        assessmentDetailsResponse={assessmentDetailsResponse}
      />
    ) : (
      <></>
    ),
    "Raise Warning":
      candidateOnlineStatus === STATUS_ONLINE &&
      candidateDetails.details &&
      !isMonitorTab ? (
        <RaiseWarning candidateDetails={candidateDetails.details} />
      ) : (
        <></>
      ),
    "Finish Test":
      candidateOnlineStatus === STATUS_ONLINE &&
      candidateDetails.details &&
      !isMonitorTab ? (
        <FinishTest candidateDetails={candidateDetails.details} />
      ) : (
        <></>
      ),
    Violations: <Violations candidateDetails={candidateDetails.details} />,
    "ID Proof": (
      <CandidateProofs
        candidateDetails={candidateDetails.details}
        type="id"
        isMonitorTab={true}
      />
    ),
    "Candidate Image": (
      <CandidateProofs
        candidateDetails={candidateDetails.details}
        type="candidate"
        isMonitorTab={true}
      />
    ),
    "Video Recordings": (
      <CandidateProofs
        candidateDetails={candidateDetails.details}
        type="recording"
        isMonitorTab={true}
      />
    ),
    "Monitor Viva": isMonitorTab ? (
      <MonitorViva candidateDetails={candidateDetails.details} />
    ) : (
      <></>
    ),
    "Flag Violation":
      isMonitorTab &&
      candidateOnlineStatus === STATUS_ONLINE &&
      candidateDetails.details ? (
        <FlagViolation candidateDetails={candidateDetails.details} />
      ) : (
        <></>
      ),
  };

  const handleInvigilationMenuClick = (title: string) => {
    setSelectedMenu(title);
  };

  const baseClasses = "px-2 py-3 bg-[white] text-primary cursor-pointer";
  const activeClasses = "font-semibold";
  const inactiveClasses = "font-normal";

  return (
    <div className="my-[20px] mx-[10px] h-[100%]">
      <div className="flex justify-between items-center">
        {!candidateDetails?.openLogs ? (
          <SearchInputComponent
            className="h-[40px] w-[400px]"
            handleSearchChange={handleSearchChange}
            searchQuery={searchQuery}
          />
        ) : (
          <div
            className="cursor-pointer flex items-center text-[18px] gap-1 font-normal text-primaryText"
            onClick={handleBack}>
            <IoArrowBack size={22} />
            <div>{contentDivText?.back}</div>
          </div>
        )}
        {!isMonitorTab && (
          <div className="bg-primary px-3 py-3 rounded text-[white] flex items-center justify-center sticky bottom-0">
            {contentDivText?.chatOnlineCandidates}
          </div>
        )}
      </div>
      {!candidateDetails?.openLogs ? (
        <div className="h-[55vh] overflow-y-auto">
          <div className="grid grid-cols-3">
            {currentData?.map((item) => (
              <div
                key={item.candidate_id}
                onClick={() => handleGetLogsOfCandidate(item)}
                className="border-[#BCC1CA] my-[10px] border-[1px] border-solid p-2 text-primaryText cursor-pointer">
                <div
                  className={classNames(
                    "flex justify-between bg-[#DEE1E6] p-1",
                  )}>
                  <div>
                    {contentDivText.candidateId}
                    <span>{item.candidate_id}</span>
                  </div>
                  <div>{item.full_name}</div>
                </div>
                {remoteStreams[`candidate-invigilate-${item.candidate_id}`] ? (
                  <div id="remote-video">
                    {remoteStreams[
                      `candidate-invigilate-${item.candidate_id}`
                    ]?.map((stream, index) => {
                      
                      return (
                        <video
                          key={index}
                          className={classNames(
                            "w-[100%] bg-[white] ",
                            "h-[200px]",
                          )}
                          autoPlay
                          playsInline
                          ref={(video) => {
                            if (video && stream instanceof MediaStream) {
                              try {
                                video.srcObject = stream;
                              } catch (error) {
                                console.error(
                                  "Error setting remoteStream:",
                                  error,
                                );
                              }
                            }
                          }}
                        />
                      );
                    })}
                  </div>
                ) : (
                  <div className="h-[147px] text-center bg-[#DEE1E6] pt-[50px] font-bold">
                    Candidate is{" "}
                    {item.status == "Blocked"
                      ? "blocked"
                      : candidates[item.candidate_id] == "online"
                        ? "online. But the video is not available"
                        : "offline"}
                  </div>
                )}
              </div>
            ))}
          </div>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: "white",
                colorBgTextActive: "white",
                colorText: "#9095A0",
              },
            }}>
            <Pagination
              current={currentPage}
              pageSize={ITEMS_PER_PAGE}
              total={filteredCandidates.length}
              onChange={handlePageChange}
              className="mt-4 flex justify-center items-center pagination"
            />
          </ConfigProvider>
        </div>
      ) : (
        <div className="border-gray flex gap-2 border-t-[2px] mt-[20px] pt-2 w-full h-[91%]">
          {invigilationScreens[selectedMenu]}
          <div className="w-[15%] bg-[#DEE1E6] p-1 h-[91%] flex flex-col flex-1 overflow-y-auto">
            {invigilationItems.map((item) => (
              <div
                key={item.key}
                className={classNames(
                  baseClasses,
                  selectedMenu === item.title ? activeClasses : inactiveClasses,
                )}
                onClick={() => handleInvigilationMenuClick(item.title)}>
                {item.title}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Invigilation;
