/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { createContext, useContext, useEffect, useState } from "react";
import { ZIM } from "zego-zim-web";
import { authenticatedAxios } from "@/utils/axiosInterceptor";
import { CustomAxiosRequestConfig } from "@/types/functionTypes";
import { getIntlErrorMessage, handleMessage } from "@/utils/functions";
import { URL } from "@/utils/service-urls";
import { messageEnum, localStorageKeys } from "@/constants/constants";
import { ZegoExpressEngine } from "zego-express-engine-webrtc";
import { useSocket } from "./socketContext";

export const ChatContext = createContext<any>(null);

export const ChatProvider = ({ children }) => {
  const [userId, setUserId] = useState("");
  const [zegoToken, setZegoToken] = useState("");
  const [zimInstance, setZimInstance] = useState(null);
  const [sentMessages, setSentMessages] = useState<any>([]);
  const [zegoInstance, setZegoInstance] = useState<any>(null);
  const [receivedMessages, setReceivedMessages] = useState<any>([]);
  const [selectedName, setSelectedName] = useState<any>({});
  const stakeholderDetails: any = JSON.parse(
    localStorage.getItem(localStorageKeys?.stakeholderDetails) ?? "{}",
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [screenStreamId, setScreenStreamId] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [screenStream, setScreenStream] = useState<any>(null);
  const { shareScreenCandidatesList }: any = useSocket();
  const [localStream, setLocalStream] = useState<any | null>(null);
  const [streamId, setStreamId] = useState("");
  const [zgInstance, setZgInstance] = useState<any>(null);
  const candidateId = localStorage.getItem(localStorageKeys.candidateId);
  const assessmentId = localStorage.getItem(localStorageKeys.assessmentId);
  const [newMessages, setNewMessages] = useState<any>({});
  const assessmentDetails: any = JSON.parse(
    localStorage.getItem(localStorageKeys?.assessmentDetails) ?? "{}",
  );
  const invigilation = {
    roomId: `candidate-invigilate-${candidateId}`,
    userName: "",
  };

  const updateTypes = {
    delete: "DELETE",
    add: "ADD",
  };
  const orgId = localStorage.getItem(localStorageKeys.orgId);
  const appID = Number(process.env.REACT_APP_ZEGO_APP_ID);
  const serverId = process.env.REACT_APP_ZEGO_SERVER_ID;
  // eslint-disable-next-line no-extra-boolean-cast
  const userName = !!stakeholderDetails?.is_stakeholder
    ? stakeholderDetails.full_name
    : assessmentDetails?.full_name;
  const generateToken = async () => {
    try {
      const response = await authenticatedAxios.get(
        URL.ACCOUNT.GET_ZEGO_TOKEN + `/${assessmentId}` + URL.ACCOUNT.TOKEN,
        {
          authenticated: true,
        } as CustomAxiosRequestConfig,
      );
      setZegoToken(response.data.responseBody.data.token);
      setUserId(response.data.responseBody.data.user_id);
      return response.data.responseBody.data;
    } catch (error) {
      const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
      handleMessage(messageEnum.error, errorMsg);
    }
  };

  const zegoMethods = {
    roomStreamUpdate: "roomStreamUpdate",
    screenEnd: "screenSharingEnded",
  };

  const initializeZIM = async () => {
    if (!appID) {
      console.error("appID is not defined or not a number");
      return;
    }

    let tokenRes: any;
    if (!zegoToken) {
      tokenRes = await generateToken();
    }

    const zim: any = ZIM.create({ appID });
    if (!zim) {
      console.error("Failed to create ZIM instance");
      return;
    }

    zim.on("error", (zim, errorInfo) => {
      console.error("ZIM error", errorInfo.code, errorInfo.message);
    });

    setZimInstance(zim);

    console.log(!stakeholderDetails.is_stakeholder, "here");
    if (!stakeholderDetails.is_stakeholder) {
      console.log("inside");
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const zg = new ZegoExpressEngine(appID, serverId);
      await renderVideoStreams(zg);
      await joinRoom(zg, tokenRes);
    }
    await loginToRoom(zim, tokenRes);

    zim.on("receivePeerMessage", (zim, { messageList, fromConversationID }) => {
      const selected = JSON.parse(localStorage.getItem("selectedName") ?? "{}");
      if (
        !stakeholderDetails?.is_stakeholder ||
        messageList[0].senderUserID ===
          (stakeholderDetails?.is_stakeholder
            ? `org-${orgId}-stakeholder-user-${selected?.id}`
            : `org-${orgId}-user-${selected?.id}`)
      ) {
        setReceivedMessages((prev) => [...prev, ...messageList]);
      } else {
        const id = stakeholderDetails?.is_stakeholder
          ? `stakeholder-${selected?.id}`
          : `${selected?.id}`;
        setNewMessages((prev) => {
          if (prev.id) {
            return { ...prev, [id]: prev[id] + 1 };
          } else {
            return { ...prev, [id]: 1 };
          }
        });
      }
    });
    zim.on("receiveRoomMessage", (zim, { messageList, fromConversationID }) => {
      setReceivedMessages((prev) => [...prev, ...messageList]);
    });
  };

  const joinRoom = async (zg, tokenRes) => {
    try {
      await zg.loginRoom(
        invigilation?.roomId,
        tokenRes?.token ?? zegoToken,
        {
          userID: tokenRes?.user_id ?? userId,
          userName: invigilation?.userName,
        },
        { userUpdate: true },
      );
      setZegoInstance(zg);
      await localStreamSetup(zg);
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  const localStreamSetup = async (zg) => {
    try {
      const stream = await zg.createZegoStream({
        camera: { audio: true, video: true },
      });
      console.log(stream, "====stream====");
      setLocalStream(stream);
      const streamID = invigilation?.roomId + Date.now();
      setStreamId(streamID);
      zg.startPublishingStream(streamID, stream);
      console.log("====started publishing stream====");
    } catch (error) {
      console.error("Error occurred while creating local stream:", error);
    }
  };

  const renderVideoStreams = (zg) => {
    zg.on(
      zegoMethods?.roomStreamUpdate,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      async (roomID, updateType, streamList, extendedData) => {
        if (updateType === updateTypes?.delete) {
          zg.stopPublishingStream(streamList[0].streamID);
          zg.logoutRoom(invigilation?.roomId);
        }
      },
    );
  };

  const loginToRoom = async (zim, tokenRes) => {
    const userInfo = { userID: tokenRes?.user_id ?? userId, userName };
    await zim.login(userInfo, tokenRes?.token ?? zegoToken);

    const roomInfo = { roomID: "190", roomName: "assessment" };
    await zim.enterRoom(roomInfo);
  };

  const handleShareScreen = async () => {
    console.log(localStream, "before error");
    // localStream.startCaptureScreen();
    const screenStream = await zegoInstance.createZegoStream({
      screen: {
        videoBitrate: 1500,
        audio: false,
        video: {
          quality: 2,
          frameRate: 15,
        },
      },
    });
    console.log(screenStream, "=====screenStreem");
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const screenStreamIdGenerate =
      `candidate-invigilate-${candidateId}` + Date.now();
    setScreenStreamId(String(screenStreamIdGenerate));
    setScreenStream(screenStream);
    zegoInstance.startPublishingStream(
      String(screenStreamIdGenerate),
      screenStream,
    );
    console.log("===== after start screenStreem", localStream);
    zegoInstance.on("screenSharingEnded", () => {
      zegoInstance.stopPublishingStream(String(screenStreamId));
      zegoInstance.destroyStream(localStream);
    });
  };

  useEffect(() => {
    console.log(
      shareScreenCandidatesList,
      "===shareScreenCandidatesList",
      userId,
    );
    if (
      !stakeholderDetails?.is_stakeholder &&
      candidateId &&
      shareScreenCandidatesList?.[candidateId] == true
    ) {
      handleShareScreen();
    }
  }, [shareScreenCandidatesList]);

  return (
    <ChatContext.Provider
      value={{
        sentMessages,
        setSentMessages,
        receivedMessages,
        setReceivedMessages,
        zimInstance,
        selectedName,
        setSelectedName,
        userId,
        userName,
        initializeZIM,
        newMessages,
      }}>
      {children}
    </ChatContext.Provider>
  );
};

export const useChatContext = () => useContext(ChatContext);
