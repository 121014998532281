import React from "react";
import classNames from "classnames";
import ImageWithBasePath from "@/components/imageComponent";
import { dosDonts } from "@/constants/constants";
import { DosAndDontsProps } from "@/types/componentTypes";

const DosAndDonts: React.FC<DosAndDontsProps> = ({ idVerifyStatus }) => {
  return (
    <div className="w-[25%] flex flex-col space-y-[50px] mt-[50px]">
      {dosDonts?.map((item: any) => {
        return (
          <div
            key={item?.title}
            className="flex flex-col bg-lightGray border-none rounded-[5px] justify-center items-center space-y-[10px] mx-[30px] p-[20px]">
            <div className="flex space-x-[5px]">
              <div>{item?.icon}</div>
              <span className={classNames(`font-bold ${item?.titleColor}`)}>
                {item?.title}
              </span>
            </div>
            <ImageWithBasePath
              src={!idVerifyStatus ? item?.img1 : item?.img2}
              width={!idVerifyStatus ? item?.faceImageWidth : item?.aadharWidth}
              height={
                !idVerifyStatus ? item?.faceImageHeight : item?.aadharHeight
              }
            />
            <div className=" text-[13px] text-center text-primaryText">
              {!idVerifyStatus ? item?.text1 : item?.text2}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default DosAndDonts;
