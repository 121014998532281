import React from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import * as XLSX from 'xlsx';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
} from 'chart.js';

// Register chart components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement);

export default function AssessmentCandidatePerformanceDashboard({ averages, individualScores }) {
  // Assuming individualScores and averages are structured as follows
  console.log(averages); // { name: 'Average Score', description: 3, etc. }
  console.log(individualScores[0].theory); // { theory: '6', practical: '0', viva: '0', total: '6', etc. }

  // Sum of candidate values (for simplicity assume it's given as 100)
  const candidateTotal = individualScores[0].total;  // individual score total
  const totalAverage = averages.find(average => average.name === 'Average Score')?.description || 0; // Sum of all averages
  console.log(totalAverage);

  // Pie chart data: total performance breakdown (candidate vs average)
  const pieData = {
    labels: ['Candidate', 'Average'],
    datasets: [{
      data: [candidateTotal, totalAverage],
      backgroundColor: ['#36A2EB', '#FF6384'], // Blue & Red
    }],
  };

  // Bar chart data: individual scores vs averages
  const barData = {
    labels: ['Theory', 'Practicals', 'Viva'],
    datasets: [
      {
        label: 'Candidate',
        data: [individualScores[0].theory, individualScores[0].practical, individualScores[0].viva],
        backgroundColor: '#36A2EB',
      },
      {
        label: 'Average',
        data: [
          averages.find(item => item.name === 'Theory Average')?.description || 0,
          averages.find(item => item.name === 'Practicals Average')?.description || 0,
          averages.find(item => item.name === 'Viva Average')?.description || 0
        ],
        backgroundColor: '#FF6384',
      },
    ],
  };

  // Function to generate the Excel report
  const generateExcelReport = () => {
    // Prepare the data to be written into the Excel file
    const worksheetData = [
      ['Category', 'Candidate Score', 'Average'],
      ['Theory', individualScores[0].theory, averages.find(item => item.name === 'Theory Average')?.description || 0],
      ['Practicals', individualScores[0].practical, averages.find(item => item.name === 'Practicals Average')?.description || 0],
      ['Viva', individualScores[0].viva, averages.find(item => item.name === 'Viva Average')?.description || 0],
      ['Total', individualScores[0].total, totalAverage]
    ];

    // Create a worksheet
    const ws = XLSX.utils.aoa_to_sheet(worksheetData);

    // Create a new workbook and append the worksheet to it
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Performance Report');

    // Generate Excel file and trigger download
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const file = new Blob([excelBuffer], { type: 'application/octet-stream' });

    // Create a download link for the Blob and trigger the download
    const link = document.createElement('a');
    link.href = URL.createObjectURL(file);
    link.download = 'Performance_Report.xlsx';
    link.click();
  };

  return (
    <div>
      <div className="flex justify-end items-center mr-[16px] mb-[8px]">
        <button onClick={generateExcelReport} className="bg-primary px-[12px] py-[5px] rounded-md font-semibold text-[white]">
          Download Report
        </button>
      </div>

      <div className="flex justify-between">
        {/* Bar Chart */}
        <div style={{ width: '60%' }}>
          <h3>Average vs Candidate Performance</h3>
          <Bar
            data={barData}
            options={{
              responsive: true,
              plugins: {
                title: {
                  display: true,
                  text: 'Candidate vs Average Performance'
                },
              },
              scales: {
                x: {
                  title: {
                    display: true,
                    text: 'Categories',
                  },
                },
                y: {
                  title: {
                    display: true,
                    text: 'Scores',
                  },
                },
              },
            }}
          />
        </div>

        {/* Pie Chart */}
        <div style={{ width: '35%' }}>
          <h3>Performance Breakdown</h3>
          <Pie
            data={pieData}
            options={{
              responsive: true,
              plugins: {
                title: {
                  display: true,
                  text: 'Total Performance Breakdown',
                },
                legend: {
                  position: 'top',
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  );
}
