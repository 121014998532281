/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useContext, useEffect, useState } from "react";
import RightComponent from "@/components/rightComponent";
import Bottom from "@/components/bottomComponent";
import LeftComponent from "@/components/leftComponent";
import Header from "@/components/header";
import LeftComponentButtons from "@/components/leftComponentButtons";
import { ItemStateContext } from "@/contexts/questionContext";
import { useFullscreenToggle } from "@/hooks/useFullScreenMode";
import { useNavigate } from "react-router-dom";
import { DisableSpecialKeys } from "@/components/disableSpecialKeys";
import useSystemCheck from "@/hooks/useSystemCheck";
import { PiWarningCircleFill } from "react-icons/pi";
import ScreenSharingCheck from "@/components/screenShareStatus";
import BackHandlingModals from "@/components/backHandlingModals";
import ModalComponent from "@/components/customModal";
import { useSelector } from "react-redux";
import {
  buttonTitle,
  disableSpecialKeys,
  messageEnum,
  modalContent,
  questionType,
  analyticKeys,
  eventType,
  localStorageKeys,
  firebaseMethodKeys,
  environmentVariables,
  totalParts,
  subjectOptions,
  headings,
} from "@/constants/constants";
import { URL, FIREBASE_URL } from "@/utils/service-urls";
import {
  handleMessage,
  getIntlErrorMessage,
  getIntlSuccessMessage,
  getFirebaseUrl,
  getMarginTopClass,
  vpnCheck,
} from "@/utils/functions";
import { handleFirebaseOperation } from "@/firebase";
import { authenticatedAxios } from "@/utils/axiosInterceptor";
import useEventLogging from "@/hooks/useLogs";
import { QuestionsProps } from "@/types/componentTypes";
import { RootState } from "@/store";
import { CustomAxiosRequestConfig } from "@/types/functionTypes";
import useWindowSize from "@/hooks/useWindowSize";
import classNames from "classnames";
import VerficationScreen from "@/components/verficationScreen";
import FeedbackModalComponent from "@/components/feedbackModalComponent";
import ImageCapturing from "@/services/imageService";
import VideoCapturing from "@/services/videoService";
import { ConfigProvider, FloatButton, Popover, message } from "antd";
import { BsChatText } from "react-icons/bs";
import Chat from "./chat";
import { useAuth } from "@/contexts/authContext";
import { useChatContext } from "@/contexts/chatContext";
import { useSocket } from "@/contexts/socketContext";
import { NAVIGATION } from "@/utils/internalNavigations";
import { signOut } from "firebase/auth";
import { auth } from "@/firebase";

const Questions: React.FC<QuestionsProps> = ({
  isEnabledSpecialKeys = true,
  response,
}) => {
  const activityTypeQuestions: any = JSON.parse(
    localStorage.getItem("question_paper_practicals") ?? "{}",
  );
  const vivaQuestions: any = JSON.parse(
    localStorage.getItem("question_paper_viva") ?? "{}",
  );
  const {
    currentQuestionIndex,
    selectedLanguage,
    responses,
    setResponses,
    showAllQuestions,
    setShowAllQuestions,
    questions,
    setQuestions,
    fullScreen,
    setFullScreen,
    allPermissions,
    fromSampleAssessment,
    setFromSampleAssessment,
    setSteps,
    sectionNumber,
    setSingleQuestionType,
    streamRef,
    violationChecks,
    selectedPart,
    setSelectedPart,
    setCurrentQuestionIndex,
    uploadModalOpen,
    currentQuestion,
    setCurrentQuestion,
    setCurrentSection,
    currentSection,
    feedBackModal,
    setFeedBackModal,
    submitClicked,
    setSubmitClicked,
    allQuestionsInPage,
    setAllQuestionsInPage,
    activityResponse,
  }: any = useContext(ItemStateContext);
  const systemCheckDone = useSystemCheck(allPermissions);
  const toggleFullscreen = useFullscreenToggle();
  const userId = useSelector((state: RootState) => state.candidateId);
  const assessmentId = useSelector((state: RootState) => state.assessmentId);
  const assessmentDetails: any = JSON.parse(
    localStorage.getItem("assessment_details") ?? "{}",
  );
  const setId = useSelector((state: RootState) => state.setId);
  const navigate = useNavigate();
  const [timer, setTimer] = useState<number>(10);
  const [screenShareComponentCalled, setScreenShareComponentCalled] =
    useState<boolean>(false);
  const [assessmentSubmitted, setAssessmentSubmitted] =
    useState<boolean>(false);
  // const [feedBackModal, setFeedBackModal] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [apiResponse, setApiResponse] = useState<any>({});
  const { logEvent } = useEventLogging();
  const windowSize: any = useWindowSize();
  const [questionPageChecks, setQuestionPageChecks] = useState({
    ip: violationChecks?.ip,
    city: violationChecks?.city,
    region: violationChecks?.region,
    vpn: violationChecks?.vpn,
  });
  // const [submitClicked, setSubmitClicked] = useState<boolean>(false);
  const [isfloatingChatOpen, setIsFloatingChatOpen] = useState<boolean>(false);
  const imageCapturingRequiredAtEnd =
    assessmentDetails?.rules?.captureCandidatesImage;
  const { initializeZIM } = useChatContext();
  const { violations, socket, candidates }: any = useSocket();
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (!fromSampleAssessment) {
      toggleFullscreen(false);
      setTimer(10);
      setFullScreen(true);
      setTimer(10);
      if (isEnabledSpecialKeys) {
        DisableSpecialKeys();
      }
      (navigator as any).keyboard?.lock([disableSpecialKeys.ESCAPE_LOCK]);
    }
  }, []);

  const checkVPN = async () => {
    try {
      const data = await vpnCheck();
      const isVpnEnabled =
        process.env.NODE_ENV === environmentVariables?.dev
          ? data?.threat?.is_threat
          : data?.threat?.is_vpn;

      setQuestionPageChecks({
        ip: data?.ip,
        city: data?.city,
        region: data?.region,
        vpn: isVpnEnabled,
      });
    } catch (error) {
      return error;
    }
  };

  const handleOpenChange = (newOpen: boolean) => {
    setIsFloatingChatOpen(newOpen);
  };

  useEffect(() => {
    const intervalId = setInterval(
      () => {
        checkVPN();
        if (
          (violationChecks?.city !== questionPageChecks?.city &&
            violationChecks?.region !== questionPageChecks?.region) ||
          violationChecks?.ip !== questionPageChecks?.ip ||
          violationChecks?.vpn !== questionPageChecks?.vpn
        ) {
          const errorMsg = getIntlErrorMessage(
            "GEO_FENCING_MESSAGE",
            "LOCATION_MISMATCH_ERROR",
          );
          handleMessage(messageEnum?.error, errorMsg);
        }
      },
      15 * 60 * 1000,
    );

    return () => clearInterval(intervalId);
  }, [questionPageChecks, violationChecks]);

  useEffect(() => {
    if ((!systemCheckDone || !fullScreen) && !fromSampleAssessment) {
      setIsModalOpen(true);
    }
  }, [systemCheckDone, fromSampleAssessment]);

  useEffect(() => {
    if (fromSampleAssessment) {
      const url =
        URL.ASSESSMENT.CANDIDATE_ASSESSMENT + URL.ASSESSMENT.CANDIDATE_SAMPLE;
      try {
        authenticatedAxios
          .get(url, { authenticated: true } as CustomAxiosRequestConfig)
          .then((response) => {
            const fetchedQuestions =
              response?.data?.responseBody?.assessment?.[selectedLanguage] ||
              [];
            setQuestions(fetchedQuestions);
            setApiResponse(response?.data?.responseBody?.assessment);
          })
          .catch(() => {
            const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
            handleMessage(messageEnum.error, errorMsg);
          });
      } catch (error) {
        const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
        handleMessage(messageEnum.error, errorMsg);
      }
    } else {
      if (response?.selectedLanguage) {
        if (selectedPart === totalParts[0]) {
          setQuestions(response?.[selectedLanguage] || []);
        } else if (selectedPart === totalParts[1]) {
          setQuestions(activityTypeQuestions[subjectOptions[2].value] || []);
        } else if (selectedPart === totalParts[2]) {
          setQuestions(vivaQuestions[subjectOptions[3].value] || []);
        }
      }
    }
    setShowAllQuestions(false);
  }, [response, selectedLanguage, fromSampleAssessment]);
  const initializeResponses = () => {
    const initialResponses = {};
    const responseObj = fromSampleAssessment ? apiResponse : response;

    Object?.keys(response)?.forEach?.((language) => {
      initialResponses[language] = responseObj?.[selectedLanguage]?.map(
        (question: any) => {
          if (
            question?.question_type === questionType.trueOrfalseType ||
            question?.question_type === questionType.multiSelectType
          ) {
            return { radioResponse: "", reviewed: false, questionId: null };
          } else if (
            question?.question_type === questionType.multipleChoiceType
          ) {
            return { checkboxResponse: [], reviewed: false, questionId: null };
          } else if (question?.question_type === questionType.matchingType) {
            return { matchingResponse: {}, reviewed: false, questionId: null };
          } else if (question?.question_type === questionType.filltype) {
            return {
              fillInTheBlanksResponse: {},
              reviewed: false,
              questionId: null,
            };
          } else if (question?.question_type === questionType.subJectiveType) {
            return { textAreaResponse: "", reviewed: false, questionId: null };
          } else if (question?.question_type === questionType.scenarioType) {
            return { scenarioResponse: {}, reviewed: false, questionId: null };
          } else {
            return { inputResponse: "", reviewed: false, questionId: null };
          }
        },
      );
    });
    setResponses(initialResponses);
  };

  useEffect(() => {
    initializeResponses();
  }, [questions]);

  useEffect(() => {
    if (response && response?.[selectedLanguage]) {
      if (selectedPart === totalParts[0]) {
        setCurrentSection(response?.[selectedLanguage] || []);
        setCurrentQuestion(
          response?.[selectedLanguage][currentQuestionIndex] || {},
        );
      } else if (selectedPart === totalParts[1]) {
        setCurrentSection(activityTypeQuestions[subjectOptions[2].value] || []);
        setCurrentQuestion(
          activityTypeQuestions[subjectOptions[2].value][
            currentQuestionIndex
          ] || {},
        );
      } else if (selectedPart === totalParts[2]) {
        setCurrentSection(vivaQuestions[subjectOptions[3].value] || []);
        setCurrentQuestion(
          vivaQuestions[subjectOptions[3].value][currentQuestionIndex] || {},
        );
      } else {
        return;
      }
    } else {
      return;
    }
  }, [selectedPart, selectedLanguage, currentQuestionIndex]);

  const handleSubmit = useCallback(async () => {
    setAllQuestionsInPage(false);
    if (!fromSampleAssessment) {
      toggleFullscreen(true);
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => {
          track.stop();
        });
      }
      logEvent({
        eventName: analyticKeys?.SUBMIT_ASSESSMENT,
        eventType: eventType?.ASSESSMENT_ACTION,
      });
      if (!imageCapturingRequiredAtEnd) {
        setFeedBackModal(true);
      } else {
        setSubmitClicked(true);
      }
    } else {
      setFromSampleAssessment(false);
      setSteps(0);
      logEvent({
        eventName: analyticKeys?.SAMPLE_ASSESSMENT_CLOSE,
        eventType: eventType?.PRE_ASSESSMENT_ACTION,
      });
    }
    await handleUploadToFirebase();
  }, [
    assessmentId,
    fromSampleAssessment,
    logEvent,
    responses,
    setFromSampleAssessment,
    setSteps,
    toggleFullscreen,
    userId,
  ]);

  const restructureResponses = (responses) => {
    const answers = {};
    Object?.values(responses.En).forEach((response: any) => {
      const { questionId, reviewed }: any = response;

      if (questionId !== null)
        if (response.fillInTheBlanksResponse !== undefined) {
          answers[questionId] = {
            question_reviewed: reviewed,
            answer: response.fillInTheBlanksResponse,
          };
        } else if (response.matchingResponse !== undefined) {
          answers[questionId] = {
            question_reviewed: reviewed,
            answer: response.matchingResponse,
          };
        } else if (response.checkboxResponse !== undefined) {
          answers[questionId] = {
            question_reviewed: reviewed,
            answer: response.checkboxResponse,
          };
        } else if (response.radioResponse !== undefined) {
          answers[questionId] = {
            question_reviewed: reviewed,
            answer: response.radioResponse,
          };
        } else if (response.textAreaResponse !== undefined) {
          answers[questionId] = {
            question_reviewed: reviewed,
            answer: response.textAreaResponse,
          };
        } else if (response.scenarioResponse !== undefined) {
          answers[questionId] = {
            question_reviewed: reviewed,
            answer: response.scenarioResponse,
          };
        }
    });

    return answers;
  };

  const restructureActivityAnswers = (data) => {
    const result = {};

    Object.keys(data).forEach((key) => {
      const questionData = data[key];
      const uploadedData = questionData.uploadedData;
      const keys = Object?.keys(uploadedData);

      if (keys.length > 0) {
        result[questionData?.questionId] = {
          question_reviewed: questionData.reviewed,
          answer: keys
            .map((fileKey) => uploadedData[fileKey].key.key)
            .join(","),
        };
      }
    });

    return result;
  };

  const firebaseAnswerFormate = (data) => {
    const answer = {};
    Object.keys(data).forEach((key) => {
      const questionData = data[key];
      let result = questionData.answer;
      if (Array.isArray(result)) {
        result = result.join(",");
      } else if (typeof result === "object" && result !== null) {
        result = Object.values(result)[0];
        if (typeof result === "object") {
          result = Object.values(result).join(",");
        }
      }
      answer[key] = {
        question_reviewed: questionData.question_reviewed,
        answer: result,
      };
    });

    return { answer };
  };

  const handleUploadToFirebase = async () => {
    const data = {
      ...restructureResponses(responses),
      ...restructureActivityAnswers(activityResponse),
    };
    const formattedData = firebaseAnswerFormate(data);

    if (!fromSampleAssessment) {
      try {
        const options = {
          assessmentId: assessmentId,
          setId: setId,
          candidateId: userId,
          endpoint: FIREBASE_URL?.ENDPOINT?.ANSWERS,
        };
        const firebaseUrl = getFirebaseUrl(options);
        await handleFirebaseOperation(
          firebaseUrl,
          firebaseMethodKeys.PUT,
          formattedData,
        );
        const successMsg = getIntlSuccessMessage(
          "ASSESSMENT_SUBMITTED_MESSAGE",
          "SUBMIT_SUCCESS",
        );
        handleMessage(messageEnum.success, successMsg);
      } catch (error) {
        const errorMsg = getIntlErrorMessage(
          "ASSESSMENT_SUBMITTED_MESSAGE",
          "SUBMIT_ERROR",
        );
        handleMessage(messageEnum.error, errorMsg);
      }
    }
  };
  const scenarioBasedQuestions = currentQuestion?.options?.map((option) => {
    return {
      question_text: option?.question_text,
      question_type: option?.question_type,
      options: option?.options,
      id: option?.scenario_question_id,
    };
  });

  const handleScreenShare = async () => {
    setIsModalOpen(false);
    setTimer(10);
    setScreenShareComponentCalled(false);
    const storedData = localStorage.getItem(
      localStorageKeys?.storedScreenShareStatus,
    );
    const storedScreenShareStatus = storedData ? JSON.parse(storedData) : null;
    if (!storedScreenShareStatus) {
      setIsModalOpen(false);
      setAssessmentSubmitted(true);
      localStorage.removeItem(localStorageKeys?.storedScreenShareStatus);
    }
  };

  const handleSubmitAssessment = () => {
    setIsModalOpen(false);
    setAssessmentSubmitted(true);
  };
  const handleCloseAssessment = () => {
    toggleFullscreen(true);
    navigate(URL.AUXILIARYPATHS.DASHBOARD);
  };

  useEffect(() => {
    setSingleQuestionType(currentQuestion?.question_type);
    if (currentQuestion && !fromSampleAssessment) {
      logEvent({
        section: sectionNumber,
        questionIndex: currentQuestionIndex,
        eventName: analyticKeys?.VIEW_QUESTION,
        eventType: eventType?.ASSESSMENT_ACTION,
        questionType: currentQuestion?.question_type,
      });
    }
  }, [currentQuestion]);
  const goToNextPart = () => {
    const currentIndex = totalParts.indexOf(selectedPart);
    setCurrentQuestionIndex(0);
    if (currentIndex !== -1 && currentIndex < totalParts.length - 1) {
      setSelectedPart(totalParts[currentIndex + 1]);
    }
    setCurrentQuestion(activityTypeQuestions?.[subjectOptions[2].value]);
  };

  const checkUploadModalValues = Object.values(uploadModalOpen).some(
    (value) => value === true,
  );

  useEffect(() => {
    if (assessmentDetails.rules.enableChatSupport) {
      initializeZIM();
    }
  }, [assessmentDetails?.rules?.enableChatSupport]);

  useEffect(() => {
    if (Object.keys(violations)?.length) {
      if (violations[userId] && !violations[userId].read) {
        messageApi.open({
          type: "warning",
          content: `Warning from Invigilator: ${violations[userId]?.warningMessage}`,
          duration: 10,
        });
        const payload = {
          candidateId: userId,
          assessmentId: assessmentId,
        };
        socket.emit("mark-warning-as-read", payload);
      }
    }
  }, [violations]);
  useEffect(() => {
    if (candidates[userId] && candidates[userId] === headings?.blocked) {
      toggleFullscreen(true);
      navigate(NAVIGATION?.BLOCK);
      logEvent({
        eventName: candidates[userId],
      });
    }
  }, [candidates]);
  const show = false;
  useEffect(() => {
    if (show) {
      setAllQuestionsInPage(true);
    }
  }, []);
  return (
    <div>
      {!submitClicked && (
        <div>
          <div className="h-[100vh] flex flex-col text-[16px]">
            <Header />
            <div className="grid grid-cols-[2fr,0.7fr] ml-[50px] pt-[20px] h-[80%] pr-[50px] flex-wrap">
              <div className="flex flex-2 flex-col relative pl-[30px] pt-[30px] border-[2px] border-[lightgray] overflow-y-auto rounded ">
                {!showAllQuestions && !allQuestionsInPage && (
                  <strong className="pb-[10px]">
                    Question {currentQuestionIndex + 1}
                  </strong>
                )}
                <LeftComponent
                  currentQuestion={currentQuestion}
                  selectedLanguage={selectedLanguage}
                />
                <div
                  className={classNames(getMarginTopClass(windowSize?.width))}>
                  {!showAllQuestions &&
                    currentQuestion?.question_type ===
                      questionType.scenarioType &&
                    scenarioBasedQuestions.map(
                      (scenarioQuestion: any, index: any) => {
                        return (
                          <div
                            className={classNames(
                              "flex",
                              index == 0 ? "mt-[130px]" : "mt-[20px]",
                            )}
                            key={`${index}-${scenarioQuestion}`}>
                            <LeftComponent
                              currentQuestion={scenarioQuestion}
                              selectedLanguage={selectedLanguage}
                            />
                          </div>
                        );
                      },
                    )}
                </div>
                {!showAllQuestions && !allQuestionsInPage && (
                  <LeftComponentButtons selectedLanguage={selectedLanguage} />
                )}
              </div>
              <RightComponent
                questionsResponse={
                  fromSampleAssessment ? apiResponse : response
                }
                selectedLanguage={selectedLanguage}
                response={fromSampleAssessment ? apiResponse : response}
              />
            </div>
            <div className="flex justify-end">
              <Bottom
                handleSubmit={handleSubmit}
                fromSampleAssessment={fromSampleAssessment}
                goToNextPart={goToNextPart}
              />
            </div>
          </div>
          {feedBackModal && (
            <FeedbackModalComponent
              feedBackModal={feedBackModal}
              setFeedBackModal={setFeedBackModal}
            />
          )}
        </div>
      )}

      {screenShareComponentCalled && (
        <ScreenSharingCheck onAllow={handleScreenShare} />
      )}

      {/* {!feedBackModal && !submitClicked && !checkUploadModalValues && (
        <BackHandlingModals
          setScreenShareComponentCalled={setScreenShareComponentCalled}
          systemCheckDone={systemCheckDone}
          fullScreen={fullScreen}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          timer={timer}
          handleSubmitAssessment={handleSubmitAssessment}
          setTimer={setTimer}
          setAssessmentSubmitted={setAssessmentSubmitted}
          screenShareComponentCalled={screenShareComponentCalled}
          onAllow={() => null}
        />
      )} */}
      {assessmentSubmitted && !feedBackModal && !submitClicked && (
        <ModalComponent
          title={modalContent.testStop}
          icon={
            <PiWarningCircleFill
              style={{
                border: "none",
                color: "red",
                fontSize: "60px",
              }}
            />
          }
          width={380}
          iconClassName="text-[30px] flex justify-center items-center mb-[10px]"
          titleClass={
            "text-primaryText text-[20px] font-semibold pb-[30px] text-center"
          }
          buttonName={buttonTitle.cancel}
          buttonClass={"flex justify-center items-center"}
          isErrorModals={true}
          modalButtonClick={handleCloseAssessment}
          isModalOpen={assessmentSubmitted}
          setIsModalOpen={setAssessmentSubmitted}
        />
      )}
      {submitClicked && (
        <VerficationScreen
          key="verfication"
          feedBackModal={feedBackModal}
          setFeedBackModal={setFeedBackModal}
          isFromQuestions={true}
        />
      )}

      {assessmentDetails?.rules?.captureImageAtRegularIntervals && (
        <ImageCapturing
          capturedImageInterval={
            assessmentDetails?.rules?.captureImageAtRegularIntervalsInterval
          }
        />
      )}
      {assessmentDetails?.rules?.candidatesVideoRecording && (
        <VideoCapturing feedBackModal={feedBackModal} />
      )}

      {assessmentDetails?.rules?.enableChatSupport && (
        <ConfigProvider
          theme={{
            token: {
              fontSizeIcon: 20,
              colorFillContent: "#22c95cfa",
              colorBgElevated: "#DEE1E6",
            },
          }}>
          <Popover
            content={
              <div className="w-[600px] h-[400px] bg-[white]">
                <Chat height={400} isDrawerChat={true} />
              </div>
            }
            className="floatingChatClass"
            trigger="click"
            placement="topRight"
            open={isfloatingChatOpen}
            onOpenChange={handleOpenChange}>
            <FloatButton
              icon={<BsChatText />}
              style={{ right: 24 + 50, bottom: 24 + 65 }}
            />
          </Popover>
        </ConfigProvider>
      )}
      {contextHolder}
    </div>
  );
};

export default Questions;
