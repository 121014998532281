import { Collapse, ConfigProvider } from "antd";
import React from "react";

const DashboardCollapse = ({ items }) => {
  return (
    <div>
      <ConfigProvider
        theme={{
          components: {
            Collapse: {
              headerBg: "#DEE1E6",
            },
          },
        }}>
        <Collapse bordered={true} defaultActiveKey={1} items={items} />
      </ConfigProvider>
    </div>
  );
};

export default DashboardCollapse;
