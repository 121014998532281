import React, { useState } from "react";
import { Carousel } from "antd";
import ReactPlayer from "react-player";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { TutorialVideoProps } from "@/types/componentTypes";

const TutorialVideo: React.FC<TutorialVideoProps> = ({ videoUrls }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [videoPlaying, setVideoPlaying] = useState<boolean>(false);

  const handlePrevious = () => {
    setCurrentSlide((prevSlide) => (prevSlide > 0 ? prevSlide - 1 : 0));
  };

  const handleNext = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide < videoUrls.length - 1 ? prevSlide + 1 : prevSlide,
    );
  };

  const handleVideoPlay = () => {
    setVideoPlaying(true);
  };

  const handleVideoPause = () => {
    setVideoPlaying(false);
  };

  return (
    <div>
      <Carousel
        className="w-[500px] mb-[32px] carouselClass"
        autoplay={!videoPlaying}
        initialSlide={currentSlide}>
        {videoUrls.map((url) => (
          <div key={url} className="relative">
            <div className="relative w-[500px] h-[280px] overflow-hidden">
              <ReactPlayer
                url={url}
                width={500}
                height={280}
                controls={true}
                className="absolute top-0 left-0 w-full h-full bg-[gray]"
                onPause={handleVideoPause}
                onPlay={handleVideoPlay}
              />
              {videoUrls.length > 1 && (
                <div className="absolute top-[50%] px-[10px] transform -translate-y-1/2 flex justify-between w-full">
                  <button
                    className="bg-[#22c95c93] border-none rounded-full p-[10px] cursor-pointer z-10"
                    onClick={handlePrevious}>
                    <LeftOutlined className="text-[20px] text-[white]" />
                  </button>
                  <button
                    className="bg-[#22c95c93] border-none rounded-full p-[10px] cursor-pointer z-10"
                    onClick={handleNext}>
                    <RightOutlined className="text-[20px] text-[white]" />
                  </button>
                </div>
              )}
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default TutorialVideo;
