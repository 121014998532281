import { handleFirebaseOperation } from "@/firebase";
import { useSelector } from "react-redux";
import { FIREBASE_URL } from "@/utils/service-urls";
import { getFirebaseUrl } from "@/utils/functions";
import { firebaseMethodKeys } from "@/constants/constants";
import { RootState } from "@/store";
import { Answer } from "@/types/hookTypes";

export const useStateHandling = () => {
  const candidateId = useSelector((state: RootState) => state.candidateId);
  const assessmentId = useSelector((state: RootState) => state.assessmentId);
  const setId = useSelector((state: RootState) => state.setId);
  const handleState = async (answer: Answer) => {
    await handleUploadToFirebase(answer);
  };
  const handleUploadToFirebase = async (data: Answer) => {
    try {
      const options = {
        assessmentId: assessmentId,
        setId: setId,
        candidateId: candidateId,
        endpoint: `${FIREBASE_URL?.ENDPOINT?.STATE}/${Object.keys(data)[0]}`,
      };
      const firebaseUrl = getFirebaseUrl(options);
      await handleFirebaseOperation(
        firebaseUrl,
        firebaseMethodKeys.PUT,
        Object.values(data)[0],
      );
    } catch (error) {
      console.error("error", error);
    }
  };

  return handleState;
};
