// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import {
  getDatabase,
  ref,
  push,
  set,
  remove,
  get,
  update,
} from "firebase/database";
import { firebaseMethodKeys } from "@/constants/constants";
import { getIntlErrorMessage, getIntlSuccessMessage } from "@/utils/functions";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DB_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export const db = getFirestore(app);
export const database = getDatabase(app);
export default app;

// Import the functions you need from the SDKs you need

export const handleFirebaseOperation = async (path, method, data) => {
  console.log("inside--firebase--file");
  const dbRef = ref(database, path);
  try {
    console.log("inside-firebase0---try");
    switch (method) {
      case firebaseMethodKeys.GET: {
        console.log("inside--get");
        const snapshot = await get(dbRef);
        if (snapshot.exists()) {
          return snapshot.val();
        }
        break;
      }
      case firebaseMethodKeys.POST:
        console.log("inside--post");

        await push(dbRef, data);
        return getIntlSuccessMessage("FIREBASE_UPDATE_MESSAGE", "ADD_SUCCESS");
      case firebaseMethodKeys.PUT:
        console.log("inside--put", data, dbRef, set(dbRef, data));

        await set(dbRef, data);
        console.log("put---case", "FIREBASE_UPDATE_MESSAGE");
        return getIntlSuccessMessage(
          "FIREBASE_UPDATE_MESSAGE",
          "UPDATE_SUCCESS",
        );
      case firebaseMethodKeys.DELETE:
        await remove(dbRef);
        return getIntlSuccessMessage(
          "FIREBASE_UPDATE_MESSAGE",
          "DELETE_SUCCESS",
        );
      case firebaseMethodKeys.UPDATE:
        await update(dbRef, data);
        return getIntlSuccessMessage(
          "FIREBASE_UPDATE_MESSAGE",
          "UPDATE_SUCCESS",
        );
      default:
        return getIntlErrorMessage("FIREBASE_UPDATE_MESSAGE", "METHOD_ERROR");
    }
  } catch (error) {
    const errorMsg = getIntlErrorMessage(
      "FIREBASE_UPDATE_MESSAGE",
      "UPDATE_ERROR",
    );
    console.error(errorMsg.replace("$error", error));
    throw error;
  }
};
