import { useEffect, useState } from "react";

const useDivHeight = (containerIdName: string) => {
  const [divHeight, setDivHeight] = useState(0);

  useEffect(() => {
    const updateHeight = () => {
      const elHeight =
        document?.getElementById?.(containerIdName)?.clientHeight || 0;
      setDivHeight(elHeight);
    };

    updateHeight();

    window.addEventListener("resize", updateHeight);

    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, [containerIdName]);

  return divHeight;
};

export default useDivHeight;
