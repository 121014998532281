/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { URL } from "@/utils/service-urls";
import { useAuth } from "@/contexts/authContext";

function ProtectedRoute({ component: Component, ...rest }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useAuth();
  const user = JSON.parse(sessionStorage.getItem("firebaseAuth") ?? "{}");
  // @ts-ignore
  const isLoggedIn = currentUser?.accessToken || user?.uid;
  useEffect(() => {
    if (!isLoggedIn) {
      const fullPath = location.pathname + location.search;
      sessionStorage.setItem("redirectPath", fullPath);
      navigate(URL.ACCOUNT.LOGIN);
    }
  }, [isLoggedIn, navigate, location]);

  return isLoggedIn ? <Component {...rest} /> : null;
}

export default ProtectedRoute;
