import { useState, useEffect } from "react";
import { WindowSize } from "@/types/componentTypes";
import { eventListeners } from "@/constants/constants";

const useWindowSize = (): WindowSize => {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: window.innerWidth,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
      });
    };

    window.addEventListener(eventListeners?.resize, handleResize);

    return () => {
      window.removeEventListener(eventListeners?.resize, handleResize);
    };
  }, []);

  return windowSize;
};

export default useWindowSize;
