/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  EventsLogsTableProps,
  ViewSnapshotDataType,
} from "@/types/componentTypes";
import { Checkbox, Spin, Table } from "antd";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import ImageWithBasePath from "./imageComponent";
import { LoadingOutlined } from "@ant-design/icons";
import {
  contentDivText,
  localStorageKeys,
  messageEnum,
  firebaseMethodKeys,
  analyticValues,
  eventTypeValues,
  assessmentStatuses,
  socketMessages,
} from "@/constants/constants";
import { authenticatedAxios } from "@/utils/axiosInterceptor";
import { CustomAxiosRequestConfig } from "@/types/functionTypes";
import { URL, FIREBASE_URL } from "@/utils/service-urls";
import { getIntlErrorMessage, handleMessage } from "@/utils/functions";
import { useAuth } from "@/contexts/authContext";
import { handleFirebaseOperation } from "@/firebase";
import { useSocket } from "@/contexts/socketContext";
import moment from "moment";
import useDivHeight from "@/hooks/useDivHeight";

const EventsLogsTable: React.FC<EventsLogsTableProps> = ({
  candidateDetails,
  className,
  viewContent,
  isMonitorTab = false,
}) => {
  const [viewData, setViewData] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const { candidates }: any = useSocket();
  const { login } = useAuth();
  const [imageData, setImageData] = useState<any>({});
  const containerIdName = "container";
  const divHeight = useDivHeight(containerIdName);
  const setId = localStorage.getItem(localStorageKeys.setId);
  const assessmentId = localStorage.getItem(localStorageKeys.assessmentId);
  const candidateId = localStorage.getItem(localStorageKeys.candidateId);

  const transformLogsResponse = (data: any) => {
    const transformedData: any = [];
    Object.keys(data).forEach((key, index) => {
      transformedData.push({
        key: index + 1,
        event_time:
          moment(data[key].time_stamp).format("DD-MMM-YYYY, h:mm a") || "",
        event_name: analyticValues[data[key]?.event_name] || "",
        event_type: eventTypeValues[data[key]?.event_type] || "",
        question: data[key]?.question || "",
        answer: data[key]?.answer || "",
        image: data[key]?.image || "",
        time_elapsed: data[key]?.time_elapsed || "",
      });
    });
    return transformedData;
  };

  const transformSnapshotResponse = (data: any) => {
    const transformedData: any = [];
    Object.keys(data).forEach((key, index) => {
      transformedData.push({
        key: index + 1,
        event_time:
          moment(data[key].time_stamp).format("DD-MMM-YYYY, h:mm a") || "",
        image: data[key]?.key || "",
      });
    });
    return transformedData;
  };

  const getDataFromFirebase = async (
    assessmentId: any,
    candidateId: any,
    setId: any,
  ) => {
    try {
      const endpoint = viewContent
        ? FIREBASE_URL.ENDPOINT.LOGS
        : `${FIREBASE_URL.ENDPOINT.EVIDENCES}/${FIREBASE_URL.ENDPOINT.IMAGES}`;
      const url = `${FIREBASE_URL?.ITEM_URL?.ASSESSMENT}/${assessmentId}/${FIREBASE_URL?.ITEM_URL?.SET}/${setId}/${FIREBASE_URL?.ITEM_URL?.CANDIDATE}/${candidateId}/${endpoint}`;
      const data = await handleFirebaseOperation(url, firebaseMethodKeys.GET);
      if (data) {
        try {
          const transformedData = viewContent
            ? transformLogsResponse(data)
            : transformSnapshotResponse(data);
          setViewData(transformedData);
          setLoading(false);
        } catch (error) {
          setViewData([]);
          return;
        }
      } else {
        setViewData([]);
      }
    } catch (error) {
      setViewData([]);
      console.error("Error fetching data from Firebase:", error);
    }
  };
  const getLogsFromDB = async (assessmentId: any, candidateId: any) => {
    const endpoint = viewContent
      ? FIREBASE_URL.ENDPOINT.LOGS
      : `${FIREBASE_URL.ENDPOINT.EVIDENCES}/?type=${FIREBASE_URL.ENDPOINT.IMAGES}`;
    const url = viewContent
      ? `${URL?.ASSESSMENT?.CANDIDATES_ASSESSMENT}/${assessmentId}/${URL?.ASSESSMENT?.CANDIDATE}/${candidateId}/${endpoint}`
      : `${URL?.ASSESSMENT?.CANDIDATE_ASSESSMENT}/${assessmentId}/${URL?.ASSESSMENT?.CANDIDATE}/${candidateId}/${endpoint}`;
    try {
      authenticatedAxios
        .get(url, {
          authenticated: true,
        } as CustomAxiosRequestConfig)
        .then((response) => {
          if (response?.status == 200) {
            setLoading(false);
            const data = viewContent
              ? response.data.responseBody
              : response?.data?.responseBody[0]?.images;
            const transformedData = viewContent
              ? transformLogsResponse(data)
              : transformSnapshotResponse(data);

            setViewData(transformedData);
          } else {
            const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
            handleMessage(messageEnum.error, errorMsg);
          }
        })
        .catch(() => {
          const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
          handleMessage(messageEnum.error, errorMsg);
        });
    } catch (error) {
      const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
      handleMessage(messageEnum.error, errorMsg);
    }
  };
  const getData = async () => {
    const assessmentId = localStorage.getItem(localStorageKeys?.assessmentId);
    const setId = candidateDetails.set_id;
    const assessmentStatus = localStorage.getItem(
      localStorageKeys?.assessmentStatus,
    );
    const candidateOnlineStatus = candidates[candidateDetails.candidate_id];
    if (
      assessmentStatus === assessmentStatuses?.ongoing &&
      candidateOnlineStatus === assessmentStatuses?.online
    ) {
      await getDataFromFirebase(
        assessmentId,
        candidateDetails.candidate_id,
        candidateDetails.set_id,
      );
    } else if (assessmentStatus === assessmentStatuses?.completed) {
      getLogsFromDB(assessmentId, candidateDetails.candidate_id);
    } else {
      setLoading(false);
      setViewData([]);
    }
  };

  useEffect(() => {
    getData();
  }, [viewContent]);
  const postViolationsToDb = async (body) => {
    const url = `${URL.ASSESSMENT.CANDIDATES_ASSESSMENT}/${assessmentId}/${URL.ASSESSMENT.CANDIDATE}/${candidateId}/${URL.ASSESSMENT.VIOLATIONS}`;
    try {
      authenticatedAxios
        .post(url, body, { authenticated: true } as CustomAxiosRequestConfig)
        .then((response) => {
          if (response?.status == 200) {
            console.log(response, "res");
          } else {
            const errorMsg = getIntlErrorMessage(
              "VIOLATION_MESSAGE",
              "VIOLATION",
            );
            handleMessage(messageEnum.error, errorMsg);
          }
        })
        .catch(() => {
          const errorMsg = getIntlErrorMessage(
            "VIOLATION_MESSAGE",
            "VIOLATION",
          );
          handleMessage(messageEnum.error, errorMsg);
        });
    } catch (error) {
      const errorMsg = getIntlErrorMessage("VIOLATION_MESSAGE", "VIOLATION");
      handleMessage(messageEnum.error, errorMsg);
    }
  };
  const handleMarkAsViolationClicked = (image: any) => {
    const body = {
      requestBody: {
        set_id: setId,
        type: socketMessages?.snapshotVioaltion,
        evidence: { key: image },
        description: socketMessages?.violationDetected,
      },
    };
    postViolationsToDb(body);
  };

  const viewLogsColumns = [
    {
      title: "S.No",
      dataIndex: "key",
    },
    {
      title: "Event Timestamp",
      dataIndex: "event_time",
    },
    {
      title: "Event Name",
      dataIndex: "event_name",
    },
    {
      title: "EventType",
      dataIndex: "event_type",
    },
    {
      title: "Question",
      dataIndex: "question",
    },
    {
      title: "Answer",
      dataIndex: "answer",
    },
    {
      title: "Image",
      dataIndex: "image",
    },
    {
      title: "Time Elapsed",
      dataIndex: "time_elapsed",
    },
  ];
  const extractImages = async (src: any) => {
    const imageData = {
      requestBody: {
        key: src,
      },
    };
    try {
      const response = await authenticatedAxios.post(
        URL.SERVICES.DOWNLOAD,
        imageData,
        {
          authenticated: true,
        } as CustomAxiosRequestConfig,
      );
      if (response?.status === 200) {
        setImageData((prevState) => ({
          ...prevState,
          [src]: response?.data?.responseBody?.url,
        }));
      } else {
        // const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
        // handleMessage(messageEnum.error, errorMsg);
      }
    } catch (error) {
      console.error("Error fetching image for:", src, error);
      // const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
      // handleMessage(messageEnum.error, errorMsg);
    }
  };
  const viewShapshotColumns = [
    {
      title: "S.No",
      dataIndex: "key",
    },
    {
      title: "TimeStamp",
      dataIndex: "event_time",
    },
    {
      title: "Snapshot",
      dataIndex: "image",
      render: (_: any, record: any) => {
        const src = record.image;
        if (!imageData[src] && !viewContent) {
          extractImages(src);
        }
        return (
          <div className="flex gap-2 items-center justify-center">
            <ImageWithBasePath
              isUrl={true}
              src={imageData[src] || src}
              width={100}
              height={100}
            />
          </div>
        );
      },
    },
  ];

  if (!isMonitorTab) {
    viewShapshotColumns.push({
      title: "Mark as a violation",
      dataIndex: "markAsViolation",
      render: (_, record: any) => (
        <div
          className="flex justify-center"
          onClick={() => handleMarkAsViolationClicked(record.image)}>
          <Checkbox className="checkBoxEventLogs" />
        </div>
      ),
    });
  }

  return (
    <div className={classNames("bg-[#DEE1E6] px-[10px] py-[20px]", className)}>
      <div className="pb-[10px] ">
        {contentDivText?.candidateId}
        <span>{candidateDetails?.candidate_id}</span>
      </div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "300px",
          }}>
          <Spin
            indicator={
              <LoadingOutlined
                style={{ fontSize: 24, backgroundColor: "green" }}
                spin
              />
            }
          />
        </div>
      ) : (
        <div
          className="bg-[white] pl-[10px] pr-[10px] pt-[20px] h-full border-[1px] border-[#f0f0f0]"
          style={{
            overflowY: "auto",
            maxHeight: divHeight - 120,
          }}>
          <Table
            columns={viewContent ? viewLogsColumns : viewShapshotColumns}
            dataSource={viewData}
            bordered
            pagination={false}
            scroll={{ y: viewContent ? divHeight - 120 : divHeight - 200 }}
          />
        </div>
      )}
    </div>
  );
};

export default EventsLogsTable;
