import React from "react";
import classNames from "classnames";
import { getClassName } from "@/utils/functions";
import { ConditionalClassNameProps } from "@/types/componentTypes";

const ConditionalClassName: React.FC<ConditionalClassNameProps> = ({
  ourResponse,
  selectedOption,
  correctOption,
  isSelectedOptionPresent = 0,
  defaultClassName,
  children,
}) => {
  return (
    <div
      className={classNames(
        getClassName({
          ourResponse,
          selectedOption,
          correctOption,
          isSelectedOptionPresent,
          defaultClassName,
        }),
      )}>
      {children}
    </div>
  );
};

export default ConditionalClassName;
