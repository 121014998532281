import axios, { AxiosRequestConfig, InternalAxiosRequestConfig } from "axios";
import { localStorageKeys } from "@/constants/constants";

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  authenticated?: boolean;
}

const unauthenticatedAxios = axios.create({
  baseURL: process.env.REACT_APP_CANDIDATE_BASE_URL,
});
const authenticatedAxios = axios.create({
  baseURL: process.env.REACT_APP_CANDIDATE_BASE_URL,
});

const adminAxios = axios.create({
  baseURL: process.env.REACT_APP_ADMIN_URL,
});

const requestInterceptor = (
  config: CustomAxiosRequestConfig,
): CustomAxiosRequestConfig => {
  if (!config.headers) {
    config.headers = {};
  }
  if (config.data) {
      config.data = {
        ...config.data,
        requestHead: {
          appName: "Certiplate UI",
          platform: "web",
        },
      };
  }
  if (config.authenticated) {
    const token = sessionStorage.getItem(localStorageKeys.jwt);
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  config.headers["Content-Type"] = "application/json";
  config.headers["ngrok-skip-browser-warning"] = "69420";
  return config;
};

const responseInterceptor = (response) => {
  return response;
};

const responseErrorInterceptor = (error) => {
  return error;
};

authenticatedAxios.interceptors.request.use(
  requestInterceptor as (
    value: InternalAxiosRequestConfig<any>,
  ) =>
    | InternalAxiosRequestConfig<any>
    | Promise<InternalAxiosRequestConfig<any>>,
  responseErrorInterceptor,
);
authenticatedAxios.interceptors.response.use(
  responseInterceptor,
  responseErrorInterceptor,
);

unauthenticatedAxios.interceptors.request.use(
  requestInterceptor as (
    value: InternalAxiosRequestConfig<any>,
  ) =>
    | InternalAxiosRequestConfig<any>
    | Promise<InternalAxiosRequestConfig<any>>,
  responseErrorInterceptor,
);
unauthenticatedAxios.interceptors.response.use(
  responseInterceptor,
  responseErrorInterceptor,
);

adminAxios.interceptors.request.use(
  requestInterceptor as (
    value: InternalAxiosRequestConfig<any>,
  ) =>
    | InternalAxiosRequestConfig<any>
    | Promise<InternalAxiosRequestConfig<any>>,
  responseErrorInterceptor,
);
adminAxios.interceptors.response.use(
  responseInterceptor,
  responseErrorInterceptor,
);

export { axios, unauthenticatedAxios, authenticatedAxios, adminAxios };
