import Webcam from "react-webcam";
import { useCallback, useEffect, useRef, useState } from "react";
import { handleFirebaseOperation } from "@/firebase";
import { authenticatedAxios } from "@/utils/axiosInterceptor";
import { URL, FIREBASE_URL } from "@/utils/service-urls";
import {
  getFirebaseUrl,
  getIntlErrorMessage,
  handleMessage,
} from "@/utils/functions";
import { useSelector } from "react-redux";
import { RootState } from "@/store";
import {
  firebaseMethodKeys,
  folderTypes,
  messageEnum,
  delayTiming,
} from "@/constants/constants";
import { CustomAxiosRequestConfig } from "@/types/functionTypes";
import moment from "moment";
const ImageCapturing = ({ captureImageInterval }: any) => {
  const webcamRef = useRef<any>(null);
  const candidateId = useSelector((state: RootState) => state.candidateId);
  const assessmentId = useSelector((state: RootState) => state.assessmentId);
  const setId = useSelector((state: RootState) => state.setId);
  const [imgSrc, setImgSrc] = useState([]);
  const capture = useCallback(async () => {
    if (webcamRef.current) {
      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleString();
      const imageName = `capturedImage_${assessmentId}_${candidateId}_${formattedDate}`;
      const imageSrc = webcamRef.current?.getScreenshot();

      if (!imageSrc) {
        const errorMsg = getIntlErrorMessage(
          "GEO_FENCING_MESSAGE",
          "LOCATION_MISMATCH_ERROR",
        );
        handleMessage(messageEnum?.error, errorMsg);
      }

      setImgSrc((prev) => ({
        ...prev,
        [imageName]: imageSrc,
      }));
      try {
        await handleUploadToDB(imageSrc);
      } catch (error) {
        localStorage.setItem(imageName, JSON.stringify(imageSrc));
      }
    }
  }, [webcamRef]);

  const handleUploadToDB = async (imageSrc: any) => {
    const imgs = [...imgSrc, imageSrc];
    imgs.forEach((img) => {
      const imageData = {
        requestBody: {
          folder: folderTypes?.imageType,
          file: img,
        },
      };
      try {
        const url = URL.SERVICES.UPLOAD;

        authenticatedAxios
          .post(url, imageData, {
            authenticated: true,
          } as CustomAxiosRequestConfig)
          .then(async (response) => {
            setImgSrc([]);
            await handleUploadToFirebase(response.data.responseBody);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } catch (error) {
        console.error("Error:", error);
      }
    });
  };
  const handleUploadToFirebase = async (data: any) => {
    try {
      const options = {
        assessmentId: assessmentId,
        setId: setId,
        candidateId: candidateId,
        endpoint: `${FIREBASE_URL?.ENDPOINT?.EVIDENCES}/${FIREBASE_URL?.ENDPOINT?.IMAGES}/${moment.now()}`,
      };
      const firebaseUrl = getFirebaseUrl(options);
      await handleFirebaseOperation(firebaseUrl, firebaseMethodKeys.PUT, data);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      capture();
    }, captureImageInterval ?? delayTiming?.thirtyThousand);

    return () => {
      clearInterval(intervalId);
    };
  }, [capture]);

  return (
    <div style={{ position: "fixed", top: "-1000px", left: "-1000px" }}>
      <Webcam height={600} width={600} ref={webcamRef} muted={false} />
    </div>
  );
};
export default ImageCapturing;
