/* eslint-disable @typescript-eslint/no-unused-vars */
import { CandidateVideoProps } from "@/types/componentTypes";
import classNames from "classnames";
import React, { useEffect, useRef } from "react";
import {
  contentDivText,
  invigilationTitles,
  localStorageKeys,
  messageEnum,
} from "@/constants/constants";
import ButtonComponent from "./buttonComponent";
import { useSocket } from "@/contexts/socketContext";

const CandidateVideo: React.FC<CandidateVideoProps> = ({
  candidateDetails,
  className,
  viewContent,
  isMonitorTab = false,
  remoteStream,
  screenStream,
}) => {
  const { socket, candidates }: any = useSocket();
  const forceShareScreen = () => {
    socket.emit("share-candidate-screen", candidateDetails?.candidate_id);
  };
  console.log(
    screenStream,
    "====screen stream inside of candidate video component====",
  );
  return (
    <>
      <div
        className={classNames("bg-[#DEE1E6] px-[10px] py-[20px]", className)}>
        <div className="flex justify-between pb-[10px] items-center">
          <div className="">
            {contentDivText?.candidateId}
            <span>{candidateDetails?.candidate_id}</span>
          </div>
          {remoteStream && (
            <ButtonComponent
              text="Force candidate to share screen"
              className={
                "bg-primary px-[20px] py-[10px] border-none rounded-md font-semibold text-[white]"
              }
              onClick={forceShareScreen}
            />
          )}
        </div>
        {remoteStream ? (
          <div id="remote-video" className="relative">
            {remoteStream?.map((stream, index) => {
              return (
                <>
                  <video
                    key={index}
                    className={classNames("w-[100%] bg-[white] ", "h-full")}
                    autoPlay
                    playsInline
                    ref={(video) => {
                      if (video && stream instanceof MediaStream) {
                        try {
                          video.srcObject = stream;
                        } catch (error) {
                          console.error("Error setting remoteStream:", error);
                        }
                      }
                    }}
                  />
                </>
              );
            })}
            {screenStream && (
              <>
                {screenStream?.map((stream, index) => {
                  return (
                    <>
                      <video
                        key={`screen-stream-${index}`}
                        className={classNames(
                          "absolute top-0 right-0 w-[200px] h-[150px] bg-[white] border border-gray-500",
                        )}
                        autoPlay
                        playsInline
                        ref={(video) => {
                          if (video && stream instanceof MediaStream) {
                            try {
                              video.srcObject = stream;
                            } catch (error) {
                              console.error(
                                "Error setting remoteStream:",
                                error,
                              );
                            }
                          }
                        }}
                      />
                    </>
                  );
                })}
              </>
            )}
          </div>
        ) : (
          <div className="h-[147px] text-center bg-[#DEE1E6] pt-[150px] font-bold">
            Candidate is{" "}
            {candidateDetails.status == "Blocked"
              ? "blocked"
              : candidates[candidateDetails.candidate_id] == "online"
                ? "online. But the video is not available"
                : "offline"}
          </div>
        )}
      </div>
    </>
  );
};

export default CandidateVideo;
