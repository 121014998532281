import { LanguageSelectorProps } from "@/types/componentTypes";
import React from "react";

const LanguageSelector: React.FC<LanguageSelectorProps> = ({
  selectedLanguage,
  onChange,
  languageOptions,
  className,
}) => {
  return (
    <form>
      <select
        value={selectedLanguage}
        className={className}
        onChange={onChange}>
        {languageOptions?.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </form>
  );
};

export default LanguageSelector;
