import React, { useState } from "react";
import {
  contentDivText,
  placeHolders,
  buttonTitle,
  headings,
  stakeholderLogValues,
  localStorageKeys,
  socketMessages,
} from "@/constants/constants";
import useDivHeight from "@/hooks/useDivHeight";
import classNames from "classnames";
import ButtonComponent from "@/components/buttonComponent";
import { useSocket } from "@/contexts/socketContext";
import moment from "moment";
import { RaiseWarningProps } from "@/types/componentTypes";
// import { message } from "antd";
import useStakeholderEventLogging from "@/hooks/useStakeholderLogs";

const RaiseWarning: React.FC<RaiseWarningProps> = ({ candidateDetails }) => {
  const { socket }: any = useSocket();
  const [warningMessage, setWarningMessage] = useState("");
  const containerIdName = "container";
  const divHeight = useDivHeight(containerIdName);
  const { stakeholderLogEvent } = useStakeholderEventLogging();
  const candidateId = localStorage.getItem(localStorageKeys.candidateId);
  const setId = localStorage.getItem(localStorageKeys.setId);
  const handleTextAreaChange = (e: any) => {
    const { value } = e.target;
    setWarningMessage(value);
  };
  const handleSendData = () => {
    const payload = {
      candidateId: candidateDetails?.candidate_id,
      warningMessage: warningMessage,
      eventTimestamp: moment.now(),
      read: false,
    };
    socket.emit(socketMessages?.raiseWarning, payload);
    const logEventData = {
      "Warning from Invigilator": warningMessage,
    };
    stakeholderLogEvent({
      candidateId: candidateId,
      setId: setId,
      eventType: stakeholderLogValues?.INVIGILATE,
      eventName: stakeholderLogValues?.RAISE_WARNING,
      eventData: { ...logEventData },
    });
    setWarningMessage("");
  };
  return (
    <div
      className="w-[85%] h-[91%] bg-[#DEE1E6] px-[10px] py-[20px] "
      id={containerIdName}>
      <div className="pb-[10px] ">
        {contentDivText?.candidateId}
        <span>{candidateDetails?.candidate_id}</span>
      </div>
      <div
        className={classNames("bg-[white] pl-[20px] pr-[40px] pt-[20px]")}
        style={{ height: divHeight - 60 }}>
        <div className="font-semibold">{headings?.warningText}</div>
        <textarea
          rows={5}
          className="w-[100%] border-[1px] text-[14px] border-solid border-gray text-primaryText p-[10px] mt-[10px] rounded-md mb-[20px]"
          placeholder={placeHolders.warningText}
          style={{ resize: "none" }}
          value={warningMessage}
          onChange={handleTextAreaChange}></textarea>
        <div className="flex justify-end">
          <ButtonComponent
            text={buttonTitle?.send}
            className={classNames(
              "px-[10px] py-[5px] text-[14px] border-none rounded-md font-semibold",
              warningMessage.length > 0
                ? "bg-primary  text-[white]"
                : "bg-[lightGray] text-primaryText",
            )}
            onClick={handleSendData}
          />
        </div>
      </div>
    </div>
  );
};

export default RaiseWarning;
