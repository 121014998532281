import { Radio, RadioChangeEvent, Space } from "antd";
import React, { useContext } from "react";
import ButtonComponent from "./buttonComponent";
import classNames from "classnames";
import { buttonTitle } from "@/constants/constants";
import UploadTestFiles from "./uploadTestFiles";
import { ItemStateContext } from "@/contexts/questionContext";
const UploadResponses = () => {
  const {
    selectedUploadType,
    setSelectedUploadType,
    uploadClicked,
    setUploadClicked,
  }: any = useContext(ItemStateContext);
  const uploadTypes = [
    { key: 1, value: "Upload Responses" },
    { key: 2, value: "Upload Evidences" },
  ];
  const handleOnChangeRadio = (e: RadioChangeEvent) => {
    setSelectedUploadType(e.target.value);
  };
  const handleUploadFile = () => {
    setUploadClicked(true);
  };
  return (
    <div className="pl-[10px] pt-[10px]">
      <Radio.Group
        onChange={handleOnChangeRadio}
        value={selectedUploadType}
        className="mt-[5px] ml-[10px]">
        <Space direction="vertical">
          {uploadTypes.map((item) => {
            return (
              <Radio className="mt-[5px]" key={item?.key} value={item?.key}>
                {item?.value}
              </Radio>
            );
          })}
        </Space>
      </Radio.Group>
      <div className="mt-[20px] ml-[10px]">
        <ButtonComponent
          text={buttonTitle?.uploadFile}
          className={classNames(
            "px-[10px] py-[5px] text-[14px] border-none rounded-md font-semibold",
            selectedUploadType > 0
              ? "bg-primary  text-[white]"
              : "bg-[lightGray] text-primaryText",
          )}
          onClick={handleUploadFile}
        />
      </div>
      {uploadClicked && (
        <UploadTestFiles
          uploadClicked={uploadClicked}
          setUploadClicked={setUploadClicked}
          selectedOption={selectedUploadType}
          setSelectedUploadType={setSelectedUploadType}
        />
      )}
    </div>
  );
};

export default UploadResponses;
