import React, { useEffect, useState } from "react";
import FooterLogoComponent from "@/components/footerLogoComponent";
import DashboardHeader from "@/components/dashboardHeader";
import type { CollapseProps } from "antd";
import { DatePicker, Space } from "antd";
import {
  messageEnum,
  segmentValues,
  dashBoardContent,
  DATE_FORMAT,
  assessmentStatuses,
} from "@/constants/constants";
import SegmentedComponent from "@/components/segmentedComponent";
import { Moment } from "moment";
import DashboardCollapse from "@/components/dashboardCollapse";
import { authenticatedAxios } from "@/utils/axiosInterceptor";
import { CustomAxiosRequestConfig } from "@/types/functionTypes";
import { getIntlErrorMessage, handleMessage } from "@/utils/functions";
import { URL } from "@/utils/service-urls";
import moment from "moment";
import AssessmentCollapseComponent from "@/components/assessmentCollapseComponent";

const Dashboard = () => {
  const [value, setValue] = useState<string | number>(segmentValues?.today);
  const [dates, setDates] = useState<[Moment, Moment] | null>(null);
  const [custom, setCustom] = useState(false);
  const { RangePicker } = DatePicker;
  const [ongoingAssessments, setOngoingAssessments] = useState<any>([]);
  const [completedAssessments, setCompletedAssessments] = useState<any>([]);
  const [futureAssessments, setFutureAssessments] = useState<any>([]);

  useEffect(() => {
    let startDate = moment().format(DATE_FORMAT);
    let endDate = moment().format(DATE_FORMAT);

    if (value === segmentValues?.today) {
      endDate = moment().format(DATE_FORMAT);
    } else if (value === segmentValues?.sevenDays) {
      endDate = moment().add(7, "days").format(DATE_FORMAT);
    } else if (value === segmentValues?.twoWeeks) {
      endDate = moment().add(14, "days").format(DATE_FORMAT);
    } else if (value === segmentValues?.oneMonth) {
      endDate = moment().add(1, "month").format(DATE_FORMAT);
    } else if (value === segmentValues?.custom && dates) {
      startDate = dates[0].format(DATE_FORMAT);
      endDate = dates[1].format(DATE_FORMAT);
    }

    const url = `${URL.ASSESSMENT.CANDIDATE_STAKEHOLDER_ASSESSMENT}?start_date=${startDate}&end_date=${endDate}`;
    try {
      authenticatedAxios
        .get(url, { authenticated: true } as CustomAxiosRequestConfig)
        .then((response) => {
          const assessmentsData = response?.data?.responseBody;

          const today = moment();
          const ongoingAssessments: any = [];
          const futureAssessments: any = [];
          const completedAssessments: any = [];
           console.log(assessmentsData);
             const a=assessmentsData.filter((obj,index,self)=>
          
            index==self.findIndex((o)=>o.id ===obj.id)
          ) //  filtering the redundnacy of assessments
          
          a.forEach((assessments: any) => {
            const startDate = moment(assessments.start_datetime).startOf("day");
            const endDate = moment(assessments.end_datetime).endOf("day");

            if (today.isBetween(startDate, endDate, null, "[]")) {
              if (assessments?.status === assessmentStatuses?.completed) {
                completedAssessments.push(assessments);
              } else {
                ongoingAssessments.push(assessments);
              }
            } else if (today.isBefore(startDate)) {
              futureAssessments.push(assessments);
            } else if (today.isAfter(endDate)) {
              completedAssessments.push(assessments);
            }
          });

          setOngoingAssessments(ongoingAssessments);
          setFutureAssessments(futureAssessments);
          setCompletedAssessments(completedAssessments);
        })
        .catch(() => {
          const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
          handleMessage(messageEnum.error, errorMsg);
        });
    } catch (error) {
      const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
      handleMessage(messageEnum.error, errorMsg);
    }
  }, [value, dates]);

  const handleSegmentedChange = (val: string | number) => {
    setValue(val);
    if (val !== segmentValues?.custom) {
      setDates(null);
      setCustom(false);
    } else {
      setCustom(true);
    }
  };

  const handleDateChange = (dates: [Moment, Moment] | null) => {
    setDates(dates);
  };
  const getItems: CollapseProps["items"] = [
    {
      key: "1",
      label: dashBoardContent?.ongoingAsssessments,
      children: (
        <AssessmentCollapseComponent
          assessment={ongoingAssessments}
          emptyContent={dashBoardContent?.Noongoingfound}
          assessmentStatus={dashBoardContent?.ongoingAsssessments}
        />
      ),
    },
    {
      key: "2",
      label: dashBoardContent?.completedAssessments,
      children: (
        <AssessmentCollapseComponent
          assessment={completedAssessments}
          emptyContent={dashBoardContent?.noCompletedFound}
          assessmentStatus={dashBoardContent?.ongoingAsssessments}
        />
      ),
    },
  ];

  if (value !== dashBoardContent?.today) {
    getItems?.push({
      key: "3",
      label: dashBoardContent?.futureAssessments,
      children: (
        <AssessmentCollapseComponent
          assessment={futureAssessments}
          emptyContent={dashBoardContent?.nofutureFound}
          assessmentStatus={dashBoardContent?.ongoingAsssessments}
        />
      ),
    });
  }

  return (
    <div>
      <DashboardHeader />
      <div className="mx-[70px]">
        <div className="flex justify-between items-center py-4 border-b-[1px] border-solid border-[#BCC1CA]">
          <div className="font-bold text-[24px]">
            {dashBoardContent?.dashboard}
          </div>
          <div className="text-[gray] text-[16px]">
            {dashBoardContent?.home}
          </div>
        </div>
        <div className="overflow-y-auto">
          <div className="py-4 flex justify-end ">
            <SegmentedComponent
              handleSegmentedChange={handleSegmentedChange}
              options={[
                segmentValues?.today,
                segmentValues?.sevenDays,
                segmentValues?.twoWeeks,
                segmentValues?.oneMonth,
                {
                  label: (
                    <div>
                      {custom ? (
                        <Space direction="vertical" size={12}>
                          <RangePicker
                            value={dates as any}
                            onChange={(dates) =>
                              handleDateChange(dates as [Moment, Moment] | null)
                            }
                            format={DATE_FORMAT}
                          />
                        </Space>
                      ) : (
                        <div
                          onClick={() => {
                            setCustom(true);
                          }}>
                          {segmentValues?.custom}
                        </div>
                      )}
                    </div>
                  ),
                  value: segmentValues?.custom,
                },
              ]}
            />
          </div>
          <div className="mb-[80px]">
            <DashboardCollapse items={getItems} />
          </div>
        </div>
      </div>
      <div className="py-[20px] fixed bottom-0 w-full bg-primary">
        <FooterLogoComponent />
      </div>
    </div>
  );
};

export default Dashboard;
