import React, { useContext, useEffect, useState } from "react";
import StakeHoldersList from "@/components/stakeHoldersList";
import Chat from "@/components/chat";
import { CustomAxiosRequestConfig } from "@/types/functionTypes";
import { authenticatedAxios } from "@/utils/axiosInterceptor";
import { getIntlErrorMessage, handleMessage } from "@/utils/functions";
import { URL } from "@/utils/service-urls";
import { messageEnum, localStorageKeys } from "@/constants/constants";
import { Spin } from "antd";
import useDivHeight from "@/hooks/useDivHeight";
import { ChatContext } from "@/contexts/chatContext";

const StakeHolders = () => {
  const [stakeHoldersData, setStakeHoldersData] = useState<any>([]);
  const { selectedName }: any = useContext(ChatContext);
  const [loader, setLoader] = useState<boolean>(false);

  const containerIdName = "container";
  const divHeight = useDivHeight(containerIdName);

  useEffect(() => {
    setLoader(true);
    const assessment_id = localStorage.getItem("assessment_id");
    const url =
      URL.ASSESSMENT.ASSESSMENT_CANDIDATE +
      `/${assessment_id}` +
      URL.ASSESSMENT.STAKE_HOLDERS;
    authenticatedAxios
      .get(url, { authenticated: true } as CustomAxiosRequestConfig)
      .then((response) => {
        if (response?.status == 200) {
          const stakeholderDetails = JSON.parse(
            localStorage.getItem(localStorageKeys.stakeholderDetails) ?? "{}",
          );
          const categoryIndex = response?.data?.responseBody.findIndex(
            (item: any) => {
              return item.id == stakeholderDetails.category.id;
            },
          );
          const userIndex = response?.data?.responseBody[
            categoryIndex
          ]?.users.findIndex((item: any) => {
            return item.id == stakeholderDetails.id;
          });
          response?.data?.responseBody[categoryIndex].users.splice(
            userIndex,
            1,
          );
          setStakeHoldersData(response?.data?.responseBody);
          setLoader(false);
        } else {
          const errorMsg = getIntlErrorMessage(
            "ASSESSMENT_DETAILS_MESSAGE",
            "STAKE_HOLDER_MESSAGE",
          );
          handleMessage(messageEnum.error, errorMsg);
        }
      })
      .catch(() => {
        const errorMsg = getIntlErrorMessage(
          "ASSESSMENT_DETAILS_MESSAGE",
          "STAKE_HOLDER_MESSAGE",
        );
        handleMessage(messageEnum.error, errorMsg);
        setLoader(false);
      });
  }, []);

  return (
    <div className="h-[99%]">
      {!loader ? (
        <div className="flex space-x-[30px] h-[99%]">
          <div className="w-[40%]">
            <StakeHoldersList stakeHoldersData={stakeHoldersData} />
          </div>
          <div
            className="w-[60%] h-[100%] px-[30px] py-[20px] overflow-hidden"
            id={containerIdName}>
            {Object?.keys(selectedName)?.length > 0 && (
              <Chat height={divHeight - 30} />
            )}
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center h-full">
          <Spin />
        </div>
      )}
    </div>
  );
};

export default StakeHolders;
