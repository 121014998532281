import React from "react";
import TutorialVideo from "@/components/tutorialVideo";
import ModalComponent from "@/components/customModal";
import {
  buttonTitle,
  contentDivText,
  videoUrls,
  analyticKeys,
  eventType,
} from "@/constants/constants";
import useEventLogging from "@/hooks/useLogs";
import { TutorialsProps } from "@/types/componentTypes";

const Tutorials: React.FC<TutorialsProps> = ({
  isTutorialBtnClicked,
  setTutorialBtnClicked,
}) => {
  const { logEvent } = useEventLogging();

  const handleTutorialClose = () => {
    setTutorialBtnClicked(false);
    logEvent({
      eventName: analyticKeys?.CLOSE_TUTORIALS,
      eventType: eventType?.PRE_ASSESSMENT_ACTION,
    });
  };

  return (
    <div>
      {isTutorialBtnClicked && (
        <ModalComponent
          buttonName={buttonTitle.close}
          modalButtonClick={() => {
            setTutorialBtnClicked(false);
            logEvent({
              eventName: analyticKeys?.CLOSE_TUTORIALS,
              eventType: eventType?.PRE_ASSESSMENT_ACTION,
            });
          }}
          width={700}
          handleOnCancel={handleTutorialClose}
          closeIcon={true}
          buttonClass={"flex justify-end items-center text-[white]"}
          title={
            <div>
              <div className="font-bold text-[24px] text-primaryText">
                {contentDivText.tutorials}
              </div>
              <div className="mb-[20px] flex justify-center items-center"></div>
            </div>
          }
          content={
            <div>
              <TutorialVideo videoUrls={videoUrls} />
            </div>
          }
          isModalOpen={isTutorialBtnClicked}
          setIsModalOpen={setTutorialBtnClicked}
        />
      )}
    </div>
  );
};

export default Tutorials;
