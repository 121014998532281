import { CustomNumberInputProps } from "@/types/componentTypes";
import React from "react";

const CustomNumberInput: React.FC<CustomNumberInputProps> = ({
  value,
  min,
  max,
  onChange,
  readOnly = false,
}) => {
  const handleInput = (event) => {
    const inputValue = event.target.value;
    const newValue = inputValue === "" ? null : parseInt(inputValue, 10);

    if (
      newValue === null ||
      (!isNaN(newValue) && newValue >= min && newValue <= max)
    ) {
      onChange(newValue);
    }
  };

  const handleKeyDown = (event) => {
    const { key } = event;

    if (
      key === "Backspace" ||
      key === "Delete" ||
      key === "Tab" ||
      key === "Enter" ||
      key === "ArrowLeft" ||
      key === "ArrowRight"
    ) {
      return;
    }

    if (!/^\d$/.test(key)) {
      event.preventDefault();
      return;
    }

    const inputValue = event.target.value;
    const newValue =
      inputValue === "" ? parseInt(key, 10) : parseInt(inputValue + key, 10);

    if (newValue > max || newValue < min) {
      event.preventDefault();
    }
  };

  return (
    <div style={{ display: "inline-block" }}>
      <input
        type="number"
        value={value ?? ""}
        min={min}
        max={max}
        onInput={handleInput}
        onKeyDown={handleKeyDown}
        readOnly={readOnly}
        className="border-[2px] border-solid border-[#DEE1E6] rounded p-1"
        style={{ width: "50px", textAlign: "center" }}
      />
    </div>
  );
};

export default CustomNumberInput;
