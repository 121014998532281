import { useMemo } from "react";
import { filteredPermissions } from "@/constants/constants";
import { PermissionsState } from "@/types/hookTypes";

const useSystemCheck = (permissions: PermissionsState) => {
  const systemCheckDone = useMemo(() => {
    const keysToCheck = Object.keys(permissions).filter(
      (key) =>
        key !== filteredPermissions?.networkSpeedCheck &&
        key !== filteredPermissions?.browserNameCheck &&
        key !== filteredPermissions?.osNameCheck,
    );
    const result = keysToCheck.every((key) => permissions[key] === true);
    return result;
  }, [permissions]);

  return systemCheckDone;
};

export default useSystemCheck;
