import React, { useEffect, useState } from 'react'
import { authenticatedAxios } from "@/utils/axiosInterceptor";
import { CustomAxiosRequestConfig } from "@/types/functionTypes";
import { URL } from "@/utils/service-urls";
import { messageEnum} from "@/constants/constants";
import {
    getIntlErrorMessage,
    handleMessage,
  } from "@/utils/functions";
import "../styles/candidateFeedback.css"

export default function candidateFeedback({assessmentId, candidateId}) {
   const [feedbackData, setFeedbackData] = useState({
        '1': 0,
        '2': 0,
        '3': 0,
        '4': 0,
        '5': 0,
        '6': 0,
        feedback: ''
    });

   useEffect(() => {
    getFeedbackResponse();
   }, []);

    //Function to fetch candidates individual feedback responses
    const getFeedbackResponse = () => {
        const url = `${URL.ASSESSMENT.CANDIDATES_ASSESSMENT}/${assessmentId}${URL.ASSESSMENT.CANDIDATE_FEEDBACK_RESPONSE}/${candidateId}`;
        try {
        authenticatedAxios
            .get(url, { authenticated: true } as CustomAxiosRequestConfig)
            .then((response) => {
            if (response?.status == 200) {
                setFeedbackData(response?.data?.responseBody[0].response)
            } else {
                const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
                handleMessage(messageEnum.error, errorMsg);
            }
            })
            .catch(() => {
            const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
            handleMessage(messageEnum.error, errorMsg);
            });
        } catch (error) {
            const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
            handleMessage(messageEnum.error, errorMsg);
        }
    };

  return (
    // Candidate feedback response UI
    <div className="feedback-table-container">
        <table className="feedback-table">
            <thead>
            <tr>
                <th>Question</th>
                <th>Score</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>Clarity of questions</td>
                <td>{feedbackData['1']}</td>
            </tr>
            <tr>
                <td>Usability of test interface</td>
                <td>{feedbackData['2']}</td>
            </tr>
            <tr>
                <td>Usability of code editor</td>
                <td>{feedbackData['3']}</td>
            </tr>
            <tr>
                <td>How would you rate the quality?</td>
                <td>{feedbackData['4']}</td>
            </tr>
            <tr>
                <td>How would you rate the service?</td>
                <td>{feedbackData['5']}</td>
            </tr>
            <tr>
                <td>How would you rate the overall experience</td>
                <td>{feedbackData['6']}</td>
            </tr>
            <tr>
                <td>Feedback</td>
                <td>{feedbackData.feedback}</td>
            </tr>
            </tbody>
        </table>
        </div>

  )
}
