import React, { useContext, useState } from "react";
import AssessmentQuestionsHeader from "@/components/assessmentQuestionsHeader";
import QuestionCategoryAndSets from "@/components/questionCategoryAndSets";
import Sets from "@/components/sets";
import { IoArrowBackSharp } from "react-icons/io5";
import { Input, Space } from "antd";
import LanguageSelector from "@/components/languageSelector";
import { languageDropdownOptions } from "@/constants/constants";
import { ItemStateContext } from "@/contexts/questionContext";
import { QuestionPaperProps } from "@/types/componentTypes";
const QuestionPaper: React.FC<QuestionPaperProps> = ({
  assessmentDetailsResponse,
}) => {
  const [selectedSetId, setSelectedSetId] = useState(null);
  const { selectedLanguage }: any = useContext(ItemStateContext);
  const [section, setSection] = useState<any>(null);
  const [question, setQuestion] = useState<any>(null);
  const sets = assessmentDetailsResponse?.set_ids?.map(
    (set: any, index: any) => {
      return {
        text: `Set ${index + 1}`,
        setId: set,
      };
    },
  );
  const handleSetClicked = (id: any) => {
    setSelectedSetId(id);
  };
  const handleBackClicked = () => {
    setSelectedSetId(null);
  };
  const { Search } = Input;
  const back = "Back";

  const jumpToQuestion = () => {
    const id = `section-${section ?? 1}-${question}`;
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className="mx-[50px]">
      <AssessmentQuestionsHeader
        assessmentDetailsResponse={assessmentDetailsResponse}
      />
      {selectedSetId !== null && (
        <div className="flex justify-between items-center my-[10px]">
          <div
            onClick={handleBackClicked}
            className="cursor-pointer flex space-x-[5px] items-center">
            <IoArrowBackSharp />
            <div>{back}</div>
          </div>
          <div className="flex space-x-[5px] items-center justify-center">
            <div>
              <Space.Compact>
                <Input
                  placeholder="Section"
                  onChange={(e) => setSection(e.target.value)}
                  style={{ width: "90px" }}
                />
                <Search
                  placeholder="Jump to question"
                  onChange={(e) => setQuestion(e.target.value)}
                  onSearch={jumpToQuestion}
                  style={{ width: "170px" }}
                />
              </Space.Compact>
            </div>
            <div>
              <LanguageSelector
                languageOptions={languageDropdownOptions}
                className="border-[2px] border-[lightgray] rounded w-[100%] h-[30px]"
                selectedLanguage={selectedLanguage}
              />
            </div>
          </div>
        </div>
      )}
      <div className="border-gray border-[1px] border-solid mb-[10px]"></div>

      {selectedSetId === null ? (
        <QuestionCategoryAndSets
          sets={sets}
          handleSetClicked={handleSetClicked}
          assessmentDetailsResponse={assessmentDetailsResponse}
        />
      ) : (
        <div className="h-[60%] flex-1 flex overflow-y-auto">
          <Sets selectedSetId={selectedSetId} />
        </div>
      )}
    </div>
  );
};

export default QuestionPaper;
