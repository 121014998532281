import React, { FC } from "react";
import ImageWithBasePath from "./imageComponent";
import { DashboardEmptyContentProps } from "@/types/componentTypes";

const DashboardEmptyContent: FC<DashboardEmptyContentProps> = ({ content }) => {
  return (
    <div>
      <div className="flex flex-col items-center py-2">
        <div className="font-medium ">{content}</div>
        <div className="pt-4">
          <ImageWithBasePath
            isUrl={true}
            src="/noDataCollapse.png"
            height={60}
            width={60}
            alt="empty"
          />
        </div>
      </div>
    </div>
  );
};

export default DashboardEmptyContent;
