import { ItemStateContext } from "@/contexts/questionContext";
import classNames from "classnames";
import { FC, useContext, useEffect } from "react";
import { StakeHoldersListProps } from "@/types/componentTypes";
import { ChatContext } from "@/contexts/chatContext";
import { useSocket } from "@/contexts/socketContext";
import { componentText } from "@/constants/constants";

const StakeHoldersList: FC<StakeHoldersListProps> = ({ stakeHoldersData }) => {
  const { selectedCategory, setSelectedCategory }: any =
    useContext(ItemStateContext);

  const {
    setSelectedName,
    selectedName,
    setSentMessages,
    setReceivedMessages,
    newMessages,
  } = useContext(ChatContext);
  const { candidates }: any = useSocket();
  const noData = "No data available";

  const categories = stakeHoldersData
    ?.filter((item: any) => item.users.length)
    .map((item: any) => item.category_name);

  useEffect(() => {
    setSelectedCategory(categories[0]);
  }, [stakeHoldersData]);

  const selectedCategoryData =
    stakeHoldersData &&
    stakeHoldersData?.find(
      (item: any) => item.category_name === selectedCategory,
    );

  const handleCategoryClick = (category: string) => {
    setSentMessages([]);
    setReceivedMessages([]);
    setSelectedCategory(category);
  };

  const handleNameClicked = (item: object) => {
    setSelectedName({ ...item, type: "stakeholder", config: "one-to-one" });
    localStorage.setItem(
      "selectedName",
      JSON.stringify({ ...item, type: "stakeholder", config: "one-to-one" }),
    );
    setSentMessages([]);
    setReceivedMessages([]);
  };

  return (
    <>
      {categories?.length == 0 ? (
        <div className="m-[5px]">
          <p className="text-center">{componentText.stakeholderEmptyState}</p>
        </div>
      ) : (
        <div className="flex mt-[5px] h-full">
          <div className="border-gray border-[2px] border-solid py-[5px] px-[2px] bg-[#F8F9FA] flex flex-col space-y-[5px] w-[50%] flex-1">
            {categories?.map((category: any) => (
              <div
                key={category}
                className={classNames(
                  "py-[10px] px-[10px] cursor-pointer break-words text-[15px]",
                  selectedCategory === category ? "bg-[#DEE1E6]" : "bg-[white]",
                )}
                onClick={() => handleCategoryClick(category)}>
                {category}
              </div>
            ))}
          </div>
          <div className="bg-[#F8F9FA] py-[7px] overflow-auto w-[50%]">
            {selectedCategory && (
              <div className="flex flex-col flex-1 space-y-[5px]">
                {selectedCategoryData &&
                selectedCategoryData?.users?.length > 0 ? (
                  selectedCategoryData?.users?.map((item: any) => (
                    <div
                      key={item?.id}
                      className={classNames(
                        "py-[10px] flex justify-between items-center pr-[20px] pl-[20px] cursor-pointer break-words text-[14px]",
                        item?.id === selectedName?.id
                          ? "bg-[#DEE1E6]"
                          : "bg-[white]",
                      )}
                      onClick={() => handleNameClicked(item)}>
                      <div>{item?.full_name}</div>
                      {newMessages?.[`stakeholder-${item?.id}`]
                        ? `${newMessages?.[`stakeholder-${item?.id}`]} new`
                        : candidates[`stakeholder-${item?.id}`] == "online" && (
                            <div className="rounded-full p-1 bg-primary w-1 h-1"></div>
                          )}
                    </div>
                  ))
                ) : (
                  <div>{noData}</div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default StakeHoldersList;
