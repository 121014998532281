import { AssessmentRulesProps } from "@/types/componentTypes";
import { Table, Tag } from "antd";
import React from "react";

const AssessmentRules: React.FC<AssessmentRulesProps> = ({
  assessmentDetailsResponse,
}) => {
  const rulesData = [
    {
      displayName: "Partial submission",
      name: "partialSubmission",
      description:
        "This option lets a candidate submit and end the exam without attempting all the questions",
    },
    {
      displayName: "Show results to candidates",
      name: "showResultsToCandidates",
      description:
        "This option will let the candidates see their auto-evaluated results at the end of the online exam. This is not very useful wherever any part of the assessment requires a manual or offline evaluation. It is kept as disabled by default.",
    },
    {
      displayName: "Show responses to candidates",
      name: "showResponsesToCandidates",
      description:
        "This option will let the candidates see their responses at the end of the online exam. It is kept as disabled by default.",
    },
    {
      displayName: "Notify candidates for results",
      name: "notifyCandidatesForResults",
      description:
        "This option will notify the candidates whenever the results are published for the assessment. The notification can be done through an email or a SMS only if the details are provided during the planning of the assessment.",
    },
    {
      displayName: "Enable tutorials",
      name: "enableTutorials",
      description:
        "This option will allow the candidates to view video-based tutorials presented at the beginning of the online exam. This is kept as disabled by default. This option cannot be used unless a pre-defined tutorial videos are available for the assessment.",
    },
    {
      displayName: "Enable practice assessments",
      name: "enablePracticeAssessments",
      description:
        "This option will allow the candidates to attempt an online exam through some dummy questions. This helps the candidates in getting familiar with the online examination module. This is kept as disabled by default.",
    },
    {
      displayName: "Generate hall ticket/admit card",
      name: "generateHallTicketAdmitCard",
      description:
        "This option will automatically generate a hall ticket or an admit card in a pre-defined format. This is kept as disabled by default.",
    },
    {
      displayName: "Generate warnings for candidates",
      name: "generateWarningsForCandidates",
      description:
        "This option allows for the capture of candidate’s images at a regular interval. The interval needs to be provided in seconds. This is advised to be used whenever the AI-based capabilities cannot be used due to the internet bandwidth issues. In those instances, the images captured at shorter duration allows for the post-assessment evaluation & analysis of the online exam’s integrity.",
    },
    {
      displayName: "Enable chat support",
      name: "enableChatSupport",
      description:
        "This option allows the candidates to reach out to the support staff(s) during the conduct of the online exam, as and when needed, for online chat-based conversation.",
    },
    {
      displayName: "Access",
      name: "access",
      description: "N/A",
    },
    {
      displayName: "Access Notification",
      name: "accessNotification",
      description: "N/A",
    },
    {
      displayName: "Mode",
      name: "mode",
      description: "N/A",
    },
    {
      displayName: "Available on mobile",
      name: "availableOnMobile",
      description:
        "If the candidates can attempt the assessment on the mobile devices using the native application, then this option will be selected. If this is not selected, the candidates won’t be able to attempt the assessment through the mobile application.",
    },
    {
      displayName: "Display of questions",
      name: "displayOfQuestions",
      description: "N/A",
    },
    {
      displayName: "Jump sections",
      name: "jumpSections",
      description:
        "This option allows the candidates to toggle between the sections presented in the question paper. Not allowing this will restrict the navigation of the candidates through the sections in a fixed, pre-defined sequence.",
    },
    {
      displayName: "Jump questions",
      name: "jumpQuestions",
      description:
        "This option allows the candidates to toggle between the questions presented in the question paper. Not allowing this will restrict the navigation of the candidates through the questions in a fixed, pre-defined sequence.",
    },
    {
      displayName: "Single timer",
      name: "singleTimer",
      description:
        "This option will ensure that the candidates don’t get a category-wise (ex. Theory, Practical, etc.) timer and only a single timer monitoring the overall assessment duration is displayed.",
    },
    {
      displayName: "Show mark per question",
      name: "showMarkPerQuestion",
      description:
        "This option will allow the candidates to see the mark assigned to each of the questions presented to them during the assessment.",
    },
    {
      displayName: "Show difficulty level",
      name: "showDifficultyLevel",
      description:
        "This option will let the candidates be aware of the difficulty level of each of the questions presented to them during the assessment.",
    },
    {
      displayName: "Show feedback questions",
      name: "showFeedbackQuestions",
      description:
        "This option will allow for the addition (by selecting an existing or adding a new one) of survey question(s) to the assessment. The survey questions could be rating-based, MCQs or subjective in nature. By default, this option is disabled for the assessments.",
    },
    {
      displayName: "Capture image at regular intervals interval",
      name: "captureImageAtRegularIntervalsInterval",
      description:
        "This option allows for the capture of candidate’s images at a regular interval. The interval needs to be provided in seconds. This is advised to be used whenever the AI-based capabilities cannot be used due to the internet bandwidth issues. In those instances, the images captured at shorter duration allows for the post-assessment evaluation & analysis of the online exam’s integrity.",
    },
    {
      displayName: "Capture image at regular intervals",
      name: "captureImageAtRegularIntervals",
      description:
        "This option allows for the capture of candidate’s images at a regular interval. The interval needs to be provided in seconds. This is advised to be used whenever the AI-based capabilities cannot be used due to the internet bandwidth issues. In those instances, the images captured at shorter duration allows for the post-assessment evaluation & analysis of the online exam’s integrity.",
    },
    {
      displayName: "Assessment logs",
      name: "assessmentLogs",
      description:
        "This option will ensure that the assessment logs are captured for the entire duration of the online examination and is available for download at the end of the assessment for further distribution. Disabling this option will skip this step during the assessment.",
    },
    {
      displayName: "ID card verification",
      name: "IDCardVerification",
      description:
        "This option will require an image of the candidate’s ID card to be captured at the beginning of the assessment for each of the categories (ex. Theory, Practical, etc.).",
    },
    {
      displayName: "Enable state tracking",
      name: "enableStateTracking",
      description:
        "This option allows the candidates to restore the assessment in the case of disruptions or outages like power outages, internet disruptions, system crashes, etc. The system deploys a checkpoint-based mechanism to restore the state of the assessments in the cases of disruptions. However, state tacking across multiple devices will be available only if the internet connectivity is at a decent level (i.e. more than 2 MBPS). Else a local, single device-based state tracking capability is provided by default, whenever this option is enabled.",
    },
    {
      displayName: "Screen recording",
      name: "screenRecording",
      description:
        "This option when the candidate’s activities on the device screen need to be recorded for the entire duration of the online exam. This option will require the recording file to be uploaded at the end of the online exam. So the internet connectivity should be good enough to support the upload of such recording files. It is recommended to be avoided whenever the internet connectivity is not very good.",
    },
    {
      displayName: "Candidate’s video recording",
      name: "candidatesVideoRecording",
      description:
        "This option when the candidate’s video should be recorded for the entire duration of the online exam. The recording is done through the device’s camera. So the webcam or the device camera must be present for this option. This option will require the recording file to be uploaded at the end of the online exam. So the internet connectivity should be very good to support the upload of such recording files. It is recommended to be avoided whenever the internet connectivity is not very good.",
    },
    {
      displayName: "Capture candidate's image (beginning & end)",
      name: "captureCandidatesImage",
      description:
        "This option will ensure that the candidate’s image is captured both at the time of beginning as well as at the end of the online examination for each of the categories (ex. Theory, Practical, etc.). Disabling this option will skip this step during the assessment.",
    },
    {
      displayName: "Generate warnings for candidates",
      name: "generateWarningsForCandidatesInterval",
      description:
        "This option allows for the capture of candidate’s images at a regular interval. The interval needs to be provided in seconds. This is advised to be used whenever the AI-based capabilities cannot be used due to the internet bandwidth issues. In those instances, the images captured at shorter duration allows for the post-assessment evaluation & analysis of the online exam’s integrity.",
    },
    {
      displayName: "Enable browser tab switching",
      name: "enableBrowserTabSwitching",
      description:
        "This option will allow the candidates to switch between different tabs during the online exam. Whenever the proctoring needs to be enabled, it is recommended to be disabled (i.e. the checkbox should not be selected) for the assessment.",
    },
    {
      displayName: "Enable app switching",
      name: "enableAppSwitching",
      description:
        "This option will allow the candidates to switch between different window or screen during the online exam. Whenever the proctoring needs to be enabled, it is recommended to be disabled (i.e. the checkbox should not be selected) for the assessment.",
    },
    {
      displayName: "Enable multiple logins",
      name: "enableMultipleLogins",
      description:
        "This option will allow the candidates to login for the assessment multiple times even without the administrator’s interventions. Whenever the proctoring needs to be enabled, it is recommended to be disabled (i.e. the checkbox should not be selected) for the assessment.",
    },
    {
      displayName: "Enable geo-fencing",
      name: "enableGeoFencing",
      description:
        "This option will allow the candidates to login from a predefined set of IPs that correspond to the defined location as per the Google Maps. This is disabled by default.",
    },
    {
      displayName: "Enable special keys",
      name: "enableSpecialKeys",
      description:
        "This option will allow the candidates to use the special key sequences (ex. ctrl, copy, cut, paste, select, alt+tab, windows+tab, right-click, print screen, full screen, etc.) from the keyboard. Whenever the proctoring needs to be enabled, it is recommended to be disabled (i.e. the checkbox should not be selected) for the assessment.",
    },
    {
      displayName: "Enable face detection",
      name: "enableFaceDetection",
      description:
        "This option will apply the AI-based face detection capability during the online exam to ensure that only one human face is present throughout the online exam. Even no-human-face is taken as a violation if this option is enabled. However, this option will work in the online mode of assessment and a very good internet connectivity is expected for this option to work with good accuracy.",
    },
    {
      displayName: "Enable face recognition",
      name: "enableFaceRecognition",
      description:
        "This option will apply the AI-based face recognition capability during the online exam to ensure that the same human face (matching the image of the person whose image was taken at the beginning of the online exam) is present throughout the online exam. This is effective in curbing the impersonation attempts during the online exams. However, this option will work in the online mode of assessment and a very good internet connectivity is expected for this option to work with good accuracy.",
    },
    {
      displayName: "Enable object detection",
      name: "enableObjectDetection",
      description:
        "This option will apply the AI-based object detection capability during the online exam to ensure that the use of a few objects (ex. laptop, ear phone, headphone, book or mobile phone) is restricted throughout the online exam. However, this option will work in the online mode of assessment and a very good internet connectivity is expected for this option to work with good accuracy.",
    },
    {
      displayName: "Enable head pose tracking",
      name: "enableHeadPoseTracking",
      description:
        "This option will apply the AI-based head-pose tracking capability during the online exam to curb malpractices in the form of taking support or hint from the second person or different source, throughout the online exam. However, this option will work in the online mode of assessment and a very good internet connectivity is expected for this option to work with good accuracy.",
    },
    {
      displayName: "Asynchronous integrity check",
      name: "asynchronousIntegrityCheck",
      description:
        "The AI-based capabilities (ex. face detection, face recognition, etc.) require a good, dedicated internet bandwidth at the candidate’s end, throughout the exam’s duration. Alternatively, this option can also be used wherein the candidate’s video is captured and stored locally for the entire duration of the online exam and the recorded video file is uploaded at the end of the assessment for an offline, asynchronized evaluation of the video file. The integrity of the assessment can be evaluated based on this recorded video, by applying AI-based checks in a post-assessment scenario.",
    },
    {
      displayName: "Generate flags in real time",
      name: "generateFlagsInRealTime",
      description:
        "This option will let the invigilators and other stakeholders be notified whenever any violation is encountered during the entire duration of the online exam. The stakeholders will be notified by email, SMS and even through the in-app notifications, if this option is enabled. This is kept as disabled by default as it leads to too many notifications for the stakeholders.",
    },
    {
      displayName: "Enable remote invigilation",
      name: "enableRemoteInvigilation",
      description:
        "This option should be used only when a good internet connectivity can be ensured during the entire duration of the online exam. This allows an invigilator to be located remotely and yet perform certain corrective measure(s) during the online invigilation.",
    },
    {
      displayName: "Generate attendance sheet",
      name: "generateAttendanceSheet",
      description:
        "This option will allow for the automated generation of an attendance sheet at the end of the assessment based on the candidates' presence during the assessments. In the case of a paper-pen mode of assessment, a template for the attendance sheet will be generated automatically and will be available for download.",
    },
    {
      displayName: "Generate response sheet",
      name: "generateResponseSheet",
      description:
        "This option will allow for the automated generation of the response sheet for each of the candidates at the end of the assessment. In the case of a paper-pen mode of assessment, a template for the response sheet will be generated automatically and will be available for download.",
    },
    {
      displayName: "Additional evidence",
      name: "additionalEvidence",
      description:
        "This option in the case where any additional piece of evidence is needed as part of the assessment guidelines/compliance. For attaching the evidence, the linkage type (candidate or assessment) and the label for the attached evidence must be provided. The addition of the evidence can be configured as optional or compulsory.",
    },
    {
      displayName: "Remote Blocking",
      name: "remoteBlocking",
      description:
        "This option allows the invigilator to forcefully block a candidate from attempting an online exam, in the case where any malpractice is observed.",
    },
    {
      displayName: "Forced screen sharing & recording",
      name: "forcedScreenSharingAndRecording",
      description:
        "This option allows the invigilator to forcefully initiate the screen sharing for a candidate and even record the part of the screen sharing, as and when needed. This is useful while capturing an evidence for the instance(s) where any malpractice is observed.",
    },
    {
      displayName: "Video streaming",
      name: "videoStreaming",
      description:
        "This option lets the invigilator get the real-time feed of the video being streamed from each of the candidates' screen.",
    },
  ];
  const types = {
    booleanType: "boolean",
    stringType: "string",
  };
  const tagTypes = {
    allowed: "Allowed",
    notAllowed: "Not Allowed",
  };
  const dataSource = rulesData.map((rule, index) => {
    let value = assessmentDetailsResponse?.rules[rule.name];
    if (typeof value === types?.booleanType) {
      value = value ? tagTypes?.allowed : tagTypes?.notAllowed;
    }
    return {
      key: index + 1,
      rules: rule.displayName,
      additionalComments: rule.description,
      value: value,
    };
  });
  const isValidTag = (value: any) => {
    if (typeof value === types?.stringType) {
      return value.trim() !== "";
    }
    if (Array.isArray(value)) {
      return value.length > 0 && value.some((item) => item.trim() !== "");
    }
    return false;
  };
  const columns = [
    {
      title: "Rules",
      dataIndex: "rules",
      key: "rules",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      render: (text) => {
        let backgroundColor = "";
        let textColor = "black";

        if (text === tagTypes?.allowed) {
          backgroundColor = "#F2FCF5";
          textColor = "#27CA5F";
        } else if (text === tagTypes?.notAllowed) {
          backgroundColor = "#FDF2F2";
          textColor = "#D32F2F";
        } else {
          backgroundColor = "#F1F8FD";
          textColor = "#1976D2";
        }

        return (
          <>
            {isValidTag(text) && (
              <Tag
                style={{
                  backgroundColor: backgroundColor,
                  color: textColor,
                  borderRadius: "100px",
                  padding: "5px 10px",
                  border: "none",
                  fontSize: "12px",
                }}>
                {text}
              </Tag>
            )}
          </>
        );
      },
    },
    {
      title: "Additional comments",
      dataIndex: "additionalComments",
      key: "additionalComments",
    },
  ];
  return (
    <div className="flex-1 flex overflow-y-auto">
      <div className="mx-[40px] my-[20px] table-wrapper ">
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          scroll={{ y: 460 }}
          className="custom-table fix-Header"
        />
      </div>
    </div>
  );
};

export default AssessmentRules;
