const MarksObtained = ({ currentQuestion, correctOption, content }) => {
  let marks: any;
  if (correctOption) {
    marks = currentQuestion?.marks;
  } else {
    marks = currentQuestion == null ? "" : 0;
  }
  return (
    <div className="pt-[10px]">
      <span className="font-[500]">{content}: </span>
      <span className="font-bold pl-[2px]">{marks}</span>
    </div>
  );
};

export default MarksObtained;
