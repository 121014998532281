import { specialKeys, messageEnum } from "@/constants/constants";
import { handleMessage, getIntlErrorMessage } from "@/utils/functions";

const ESCAPE = "Escape";
const F1 = 112;
const F12 = 123;
const CONTEXT_MENU = "contextmenu";
const KEYDOWN = "keydown";
const KEYUP = "keyup";
const RESIZE = "resize";

export const DisableSpecialKeys = () => {
  const disableKeys = (event: any) => {
    const { keyCode, ctrlKey, metaKey, altKey, shiftKey } = event;

    if (event.metaKey && event.shiftKey) {
      event.preventDefault();
      const errorMsg = getIntlErrorMessage("VIOLATIONS", "SPECIAL_KEY_ALERT");
      handleMessage(messageEnum.error, errorMsg);
    }

    if (
      (ctrlKey || metaKey) && // Check if Ctrl or Meta key is pressed
      keyCode >= 65 &&
      keyCode <= 90 // Check if the key is A-Z (key codes 65-90)
    ) {
      event.preventDefault();
    }

    // Check for specific keys to prevent their default actions
    if (
      [...specialKeys.ARROW_KEYS, specialKeys.ESCAPE].includes(keyCode) ||
      (ctrlKey && shiftKey && keyCode === specialKeys.CTRL_SHIFT_I) ||
      (keyCode === specialKeys.TAB && (altKey || ctrlKey || shiftKey)) ||
      keyCode === specialKeys.TAB ||
      (keyCode >= F1 && keyCode <= F12) // Check if the key is f1-f12
    ) {
      event.preventDefault();
    }
  };

  const disableEsc = (event: any) => {
    if (event.key === ESCAPE || event.altKey || event.metaKey) {
      event.preventDefault();
      const errorMsg = getIntlErrorMessage("VIOLATIONS", "SPECIAL_KEY_ALERT");
      handleMessage(messageEnum.error, errorMsg);
    }
  };

  let lastWidth = window.innerWidth;
  let lastHeight = window.innerHeight;

  const screenshotDetector = () => {
    if (window.innerWidth < lastWidth || window.innerHeight < lastHeight) {
      const errorMsg = getIntlErrorMessage("VIOLATIONS", "SPECIAL_KEY_ALERT");
      handleMessage(messageEnum.error, errorMsg);
    }

    lastWidth = window.innerWidth;
    lastHeight = window.innerHeight;
  };

  const handleContextMenu = (event) => {
    // Prevent the default right-click context menu
    event.preventDefault();
  };

  document.addEventListener(CONTEXT_MENU, handleContextMenu);
  document.addEventListener(KEYUP, disableEsc);
  document.addEventListener(KEYDOWN, disableKeys);
  document.addEventListener(RESIZE, screenshotDetector);

  // return () => {
  //   document.removeEventListener(CONTEXT_MENU, handleContextMenu);
  //   document.removeEventListener(KEYUP, disableKeys);
  //   document.removeEventListener(RESIZE, screenshotDetector);
  // };
};

export default DisableSpecialKeys;
