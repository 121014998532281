/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useState, useEffect } from "react";
import {
  contentDivText,
  firebaseMethodKeys,
  headings,
  placeHolders,
  stakeholderLogValues,
  localStorageKeys,
  buttonTitle,
  messageEnum,
} from "@/constants/constants";
import { AssessmentIncrementDecrementProps } from "@/types/componentTypes";
import useDivHeight from "@/hooks/useDivHeight";
import classNames from "classnames";
import { ConfigProvider, Radio, Slider, Space } from "antd";
import type { RadioChangeEvent } from "antd";
import ButtonComponent from "./buttonComponent";
import { handleFirebaseOperation } from "../firebase";
import {
  convertTimestampToTime,
  getFirebaseUrl,
  getIntlErrorMessage,
  getIntlSuccessMessage,
  handleMessage,
} from "@/utils/functions";
import { useSocket } from "@/contexts/socketContext";
import useStakeholderEventLogging from "@/hooks/useStakeholderLogs";
import { eventNames } from "process";
import { authenticatedAxios } from "@/utils/axiosInterceptor";
import { URL } from "@/utils/service-urls";
import { CustomAxiosRequestConfig } from "@/types/functionTypes";

const AssessmentIncrementDecrement: FC<AssessmentIncrementDecrementProps> = ({
  candidateDetails,
  assessmentDetailsResponse,
}) => {
  const [radioValue, setRadioValue] = useState(0);
  const [sliderValue, setSliderValue] = useState([0, 0]);
  const { socket }: any = useSocket();
  const [incrementTime, setIncrementTime] = useState(0);
  const [decrementTime, setDecrementTime] = useState(0);
  const assessmentId = localStorage.getItem(localStorageKeys.assessmentId);
  const candidateId = localStorage.getItem(localStorageKeys.candidateId);
  const setId = localStorage.getItem(localStorageKeys.setId);
  const timeLimit =
    assessmentDetailsResponse?.question_paper_details?.[0]?.time_limit || 0;
  const [minTimeValue, setMinTimeValue] = useState(0);
  const containerIdName = "container";
  const divHeight = useDivHeight(containerIdName);
  const [commentMessage, setCommentMessage] = useState("");
  const plainOptions = [
    { key: 1, value: "Increment" },
    { key: 2, value: "Decrement" },
  ];
  const action = {
    increment: "increment",
    decrement: "decrement",
  };
  const { stakeholderLogEvent } = useStakeholderEventLogging();
  const handleOnChangeRadio = (e: RadioChangeEvent) => {
    setRadioValue(e.target.value);
  };

  const onChangeComplete = (value) => {
    console.log(value, "value");
  };

  const fetchData = async () => {
    try {
      const options: any = {
        assessmentId: assessmentId,
        setId: setId,
        candidateId: candidateId,
        endpoint: `start_time`,
      };

      const firebaseUrl = getFirebaseUrl(options);

      const data = await handleFirebaseOperation(
        firebaseUrl,
        firebaseMethodKeys.GET,
      );

      const start_time = convertTimestampToTime(data);

      const currentTime: any = new Date();
      let startTimeDate: any;
      if (start_time) {
        startTimeDate = new Date(start_time);
      } else {
        startTimeDate = currentTime;
      }
      const elapsedTime = Math.floor((currentTime - startTimeDate) / 1000 / 60); // converting timeStamp to min
      setMinTimeValue(elapsedTime);
    } catch (error) {
      console.log("Failed to get start time", error);
    }
  };

  useEffect(() => {
    fetchData();
    setDecrementTime(0);
    setIncrementTime(0);
  }, []);

  useEffect(() => {
    const minValueInMin = minTimeValue;

    if (radioValue === 1) {
      setSliderValue([minValueInMin, timeLimit]);
    } else if (radioValue === 2) {
      setSliderValue([minValueInMin, timeLimit]);
    }
    setCommentMessage("");
  }, [radioValue, minTimeValue]);

  const handleTextAreaChange = (e: any) => {
    const { value } = e.target;
    setCommentMessage(value);
  };

  const onChangeSlider = (value) => {
    const [newMin, newMax] = value;
    const minValueInMin = minTimeValue / 60;

    const clampedMin = Math.max(newMin, minValueInMin);
    const clampedMax = Math.max(newMax, clampedMin);

    setSliderValue([clampedMin, clampedMax]);

    const [oldMin, oldMax] = sliderValue;

    const diff = clampedMax - oldMax;

    if (radioValue === 1) {
      const increment = diff;

      setIncrementTime((prev) => prev + increment);
      const incrementedTime = incrementTime + diff;
      const logEventData = {
        action: stakeholderLogValues?.INCREMENT_TIMER,
        time: incrementedTime,
      };
      stakeholderLogEvent({
        candidateId: candidateId,
        setId: setId,
        eventType: stakeholderLogValues?.INVIGILATE,
        eventName: stakeholderLogValues?.MODIFY_ASSESSMENT_TIMER,
        eventData: { ...logEventData },
      });
    } else if (radioValue === 2) {
      const decrement = diff;
      setDecrementTime((prev) => prev + decrement);
      const decrementedTime = decrementTime + diff;

      const logEventData = {
        action: stakeholderLogValues?.DECREMENT_TIMER,
        time: decrementedTime,
      };
      stakeholderLogEvent({
        candidateId: candidateId,
        setId: setId,
        eventType: stakeholderLogValues?.INVIGILATE,
        eventName: stakeholderLogValues?.MODIFY_ASSESSMENT_TIMER,
        eventData: { ...logEventData },
      });
    }
  };

  const getEventData = (radioValue, timer) => {
    if (radioValue === 1) {
      return { action: "increment", timer: timer };
    } else if (radioValue === 2) {
      return { action: "decrement", timer: timer };
    }
  };

  const stakeholderLogEventData = (
    action,
    time,
    candidateId,
    setId,
    eventType,
    eventName,
  ) => {
    const logEventData = {
      action: action,
      time: time,
    };
    return stakeholderLogEvent({
      candidateId: candidateId,
      setId: setId,
      eventType: eventType,
      eventName: eventName,
      eventData: { ...logEventData },
    });
  };

  const handleSave = () => {
    if (commentMessage.length > 0) {
      let timeChanged;
      if (radioValue == 1) {
        timeChanged = incrementTime;
        stakeholderLogEventData(
          stakeholderLogValues?.INCREMENT_TIMER,
          timeChanged,
          candidateId,
          setId,
          stakeholderLogValues?.INVIGILATE,
          stakeholderLogValues?.MODIFY_ASSESSMENT_TIMER,
        );
      } else if (radioValue == 2) {
        timeChanged = decrementTime;
        stakeholderLogEventData(
          stakeholderLogValues?.DECREMENT_TIMER,
          timeChanged,
          candidateId,
          setId,
          stakeholderLogValues?.INVIGILATE,
          stakeholderLogValues?.MODIFY_ASSESSMENT_TIMER,
        );
      }
      const url =
        URL.ASSESSMENT.ASSESSMENT_CANDIDATE +
        `/${assessmentId}` +
        "/" +
        URL.ASSESSMENT.CANDIDATE +
        `/${candidateId}`;
      const data = {
        requestBody: {
          setId: setId,
          eventType: stakeholderLogValues?.INVIGILATE,
          eventName: stakeholderLogValues?.MODIFY_TIMER,
          adjustedAssessmentTime: timeChanged,
          eventData: getEventData(radioValue, timeChanged),
          comment: commentMessage,
        },
      };

      authenticatedAxios
        .put(url, data, {
          authenticated: true,
        } as CustomAxiosRequestConfig)
        .then((res) => {
          if (res.status == 200) {
            if (radioValue == 1) {
              timeChanged = incrementTime;
              const payload = {
                candidateId: candidateDetails?.candidate_id,
                action: action?.increment,
                time: timeChanged,
              };
              socket.emit("modify_assessment_time", payload);
            } else if (radioValue == 2) {
              timeChanged = decrementTime;
              const payload = {
                candidateId: candidateDetails?.candidate_id,
                action: action?.decrement,
                time: timeChanged,
              };
              socket.emit("modify_assessment_time", payload);
            }
            setRadioValue(0);
            setCommentMessage("");
            const successMsg = getIntlSuccessMessage(
              "ADJUSTED_ASSESSMENT_TIME_MESSAGE",
              "ADJUSTED_TIME",
            );
            handleMessage(messageEnum.success, successMsg);
          } else {
            const errorMsg = getIntlErrorMessage(
              "ADJUSTED_ASSESSMENT_TIME_MESSAGE",
              "ADJUSTED_TIME",
            );
            handleMessage(messageEnum.error, errorMsg);
          }
        });
    }
  };

  const getMaxSliderValue = (actionType: number) => {
    const timeLimit =
      assessmentDetailsResponse?.question_paper_details?.[0]?.time_limit || 0;
    if (actionType === 1) {
      return timeLimit * 2;
    } else if (actionType === 2) {
      return timeLimit;
    }
  };

  return (
    <div
      className="w-[85%] h-[91%] bg-[#DEE1E6] px-[10px] py-[20px] "
      id={containerIdName}>
      <div className="pb-[10px] ">
        {contentDivText?.candidateId}
        <span>{candidateDetails?.candidate_id}</span>
      </div>
      <div
        className={classNames("bg-[white]  h-[100%] overflow-y-auto")}
        style={{ height: divHeight - 60 }}>
        <div className="pt-[25px] pl-[15px]">
          <div>Please select the action that you want to undertake.</div>
          <Radio.Group
            onChange={handleOnChangeRadio}
            value={radioValue}
            className="mt-[5px] ml-[10px]">
            <Space direction="vertical">
              {plainOptions.map((item) => {
                return (
                  <Radio className="mt-[5px]" key={item?.key} value={item?.key}>
                    {item?.value}
                  </Radio>
                );
              })}
            </Space>
          </Radio.Group>
          {radioValue > 0 && (
            <div className="ml-[10px] pt-[20px] w-[75%]">
              <ConfigProvider
                theme={{
                  components: {
                    Slider: {
                      dotActiveBorderColor: "#27CA5F",
                      handleActiveColor: "#27CA5F",
                      handleColor: "#27CA5F",
                      trackHoverBg: "#27CA5F",
                      trackBg: "#27CA5F",
                      railSize: 6,
                    },
                  },
                  token: {
                    colorPrimaryBorderHover: "#27CA5F",
                  },
                }}>
                <Slider
                  range
                  step={10}
                  value={sliderValue}
                  onChange={onChangeSlider}
                  onChangeComplete={onChangeComplete}
                  max={getMaxSliderValue(radioValue)}
                />
              </ConfigProvider>
              <div className="font-semibold pt-3">{headings?.comment}</div>
              <textarea
                rows={5}
                className="w-[100%] border-[1px] text-[14px] border-solid border-gray text-primaryText p-[10px] mt-[10px] rounded-md mb-[20px]"
                placeholder={placeHolders?.comment}
                style={{ resize: "none" }}
                value={commentMessage}
                onChange={handleTextAreaChange}
                required
              />
              <div>
                <ButtonComponent
                  text={buttonTitle?.save}
                  onClick={handleSave}
                  className={classNames(
                    "px-[16px] py-[8px] border-none rounded-md font-semibold mb-[10px]",
                    commentMessage.length > 0
                      ? "bg-primary  text-[white]"
                      : "bg-[lightGray] text-primaryText",
                  )}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AssessmentIncrementDecrement;
