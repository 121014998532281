/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect } from "react";
import InstructionsScreen from "@/components/instructionsScreen";
import Questions from "@/components/questions";
import AssessmentResponse from "@/components/assessmentResponse";
import {
  localStorageKeys,
  firebaseMethodKeys,
  assessmentStatus,
} from "@/constants/constants";
import { ItemStateContext } from "@/contexts/questionContext";
import VerficationScreen from "@/components/verficationScreen";
import { useNavigate } from "react-router-dom";
import { NAVIGATION } from "@/utils/internalNavigations";
import { FIREBASE_URL } from "@/utils/service-urls";
import { getFirebaseUrl } from "@/utils/functions";
import { RootState } from "@/store";
import { useSelector } from "react-redux";
import { handleFirebaseOperation } from "@/firebase";
// import SystemRequirementCheck from "@/components/systemRequirementCheck";

const Assessment = () => {
  const { steps, setSteps }: any = useContext(ItemStateContext);
  const assessmentDetails: any = JSON.parse(
    localStorage.getItem("assessment_details") ?? "{}",
  );
  const assessmentId = useSelector((state: RootState) => state.assessmentId);
  const candidateId = useSelector((state: RootState) => state.candidateId);
  const setId = useSelector((state: RootState) => state.setId);
  const url = window?.location?.href;
  const pathnameParts = url?.split("/");
  const id = pathnameParts[pathnameParts.length - 2];
  const state_id = pathnameParts[pathnameParts.length - 1];
  localStorage.setItem(localStorageKeys.id, id);
  const response: any = JSON.parse(
    localStorage.getItem("question_paper_theory") ?? "{}",
  );
  const navigate = useNavigate();
  localStorage.setItem(localStorageKeys.stateId, state_id);
  useEffect(() => {
    const fromLogin = localStorage.getItem(localStorageKeys?.loggedIn);
    if (fromLogin !== null && JSON.parse(fromLogin)) {
      setSteps(0);
    }
  }, []);

  const assessmentScreens = [
    <InstructionsScreen key="instructions" />,
    // <SystemRequirementCheck key="systemCheck" />,
    <Questions key="questions" response={response} />,
    //<AssessmentResponse key="response" />,
  ];
  if (
    assessmentDetails?.rules?.captureCandidatesImage ||
    assessmentDetails?.rules?.IDCardVerification
  ) {
    assessmentScreens.splice(
      1,
      0,
      <VerficationScreen
        key="verfication"
        idVerification={assessmentDetails?.rules?.IDCardVerification}
        faceVerification={assessmentDetails?.rules?.captureCandidatesImage}
      />,
    );
  }
  if (
    assessmentDetails?.rules?.showResponsesToCandidates ||
    assessmentDetails?.rules?.showResultsToCandidates
  ) {
    assessmentScreens.splice(
      assessmentScreens.length,
      0,
      <AssessmentResponse key="response" />,
    );
  } else if (steps == assessmentScreens.length) {
    const options = {
      assessmentId: assessmentId,
      setId: setId,
      candidateId: candidateId,
      endpoint: `${FIREBASE_URL?.ENDPOINT?.EXAM_STATUS}`,
    };
    const firebaseUrl = getFirebaseUrl(options);
    handleFirebaseOperation(
      firebaseUrl,
      firebaseMethodKeys.PUT,
      assessmentStatus.COMPLETED,
    );
    navigate(NAVIGATION.DASHBOARD);
  }
  return <div>{assessmentScreens[steps]}</div>;
};

export default Assessment;
