/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { MdOutlineSettings, MdMonitor, MdPublishedWithChanges } from "react-icons/md";
import { MdOutlineIntegrationInstructions } from "react-icons/md";
import { LuPencilRuler, LuFileVideo2 } from "react-icons/lu";
import { LuNewspaper } from "react-icons/lu";
import { GrStakeholder } from "react-icons/gr";
import { PiUsersBold } from "react-icons/pi";
import { Button, ConfigProvider, Menu, Result, Spin } from "antd";
import Details from "@/components/details";
import classNames from "classnames";
import AssessmentInstructions from "@/components/assessmentInstructions";
import FooterLogoComponent from "@/components/footerLogoComponent";
import AssessmentDetailsHeader from "@/components/assessmentDetailsHeader";
import AssessmentRules from "@/components/assessmentRules";
import DashboardHeader from "@/components/dashboardHeader";
import Candidates from "@/components/candidates";
import ConductViva from "@/components/assessmentConductViva";
import QuestionPaper from "@/components/questionPaper";
import StakeHolders from "@/components/stakeHolders";
import { ItemStateContext } from "@/contexts/questionContext";
import { authenticatedAxios } from "@/utils/axiosInterceptor";
import { CustomAxiosRequestConfig } from "@/types/functionTypes";
import { getIntlErrorMessage, handleMessage } from "@/utils/functions";
import { messageEnum, localStorageKeys } from "@/constants/constants";
import { URL } from "@/utils/service-urls";
import { BsPersonWorkspace } from "react-icons/bs";
import Invigilation from "@/components/assessmentInvigilation";
import { useAuth } from "@/contexts/authContext";
import { useChatContext } from "@/contexts/chatContext";
import Results from "@/components/assessmentResults";
import { VscGraphLine } from "react-icons/vsc";
import { assessmentStatuses } from "@/constants/constants";
import { BsClipboard2Pulse } from "react-icons/bs";
import Evaluate from "@/components/assessmentEvaluate";
import UploadResponses from "@/components/uploadResponses";
import EvaluateDashboard from "@/components/assessmentEvaluateDashboard";
import FeedbackModalComponent from "@/components/invigilatorFeedbackModalComponent";
import PublishResults from "@/components/publishResults";
import CandidateResults from "@/components/CandidateResults";

type ItemType = {
  key: string;
  label: string;
  icon?: JSX.Element;
};

const AssessmentDetails = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [isConductViva, setIsConductViva] = useState(false);
  const [currentUserType, setCurrentUserType] = useState({
    isCandidate: false,
    isInvigilator: false,
  });
  const [assessmentType, setAssessmentType] = useState("");
  const { setAssessmentIdCxt, setLoggedIn } = useAuth();
  const { initializeZIM } = useChatContext();
  const { selectedOption, setSelectedOption }: any =
    useContext(ItemStateContext);
  const [assessmentDetailsResponse, setAssessmentDetailsResponse] =
    useState<any>({});
  const [candidatesDetails, setCandidatesDetails] = useState({});
  const assessmentStatus: any = localStorage.getItem(
    localStorageKeys?.assessmentStatus,
  );
  const stakeholderDetails = JSON.parse(
    localStorage.getItem(localStorageKeys.stakeholderDetails) ?? "{}",
  );

  useEffect(() => {
    setSelectedOption("Details");
  }, []);

  useEffect(() => {
    setAssessmentType(assessmentStatus);
    if (
      assessmentDetailsResponse?.question_paper_details?.[0]?.category?.viva
    ) {
      setIsConductViva(false);
    }
  }, [assessmentDetailsResponse]);

  useEffect(() => {
    setCurrentUserType((prev) => ({
      ...prev,
      isInvigilator: stakeholderDetails.is_stakeholder,
      isCandidate: !stakeholderDetails.is_stakeholder,
    }));
  }, []);

  const additionalItems = [
    {
      key: "1",
      icon: <MdOutlineIntegrationInstructions />,
      label: "Instructions",
    },
    { key: "2", icon: <LuPencilRuler />, label: "Rules" },
    { key: "3", icon: <LuNewspaper />, label: "Question Paper" },
    { key: "4", icon: <GrStakeholder />, label: "Stakeholders" },
    { key: "5", icon: <PiUsersBold />, label: "Candidates" },
  ];

  const items = [
    { key: "0", icon: <MdOutlineSettings />, label: "Details" },
    ...(currentUserType?.isInvigilator ? additionalItems : []),
  ];

  // if (isConductViva) {
  if (stakeholderDetails.is_stakeholder) {
    items.push({ key: "6", icon: <LuFileVideo2 />, label: "Conduct Viva" });
  }

  if (!stakeholderDetails.is_stakeholder) {
    items.push({ key: "8", icon: <VscGraphLine />, label: "Results" });
  }

  if (currentUserType?.isInvigilator) {
    items.push({
      key: "7",
      icon: <BsPersonWorkspace />,
      label: "Invigilate",
    });
    items.push({
      key: "10",
      icon: <MdMonitor />,
      label: "Monitor",
    });
    items.push({ key: "9", icon: <BsClipboard2Pulse />, label: "Evaluate" });
    items.push({ key: "11", icon: <BsClipboard2Pulse />, label: "Submit Feedback" });
    items.push({ key: "12", icon: <MdPublishedWithChanges />, label: "Publish Results" });
    if (!stakeholderDetails.is_stakeholder) {
      items.push({ key: "13", icon: <VscGraphLine />, label: "Candidate Results" });
    }
  }

  if (
    currentUserType?.isCandidate &&
    assessmentType === assessmentStatuses.completed
  ) {
    items.push({
      key: "8",
      icon: <VscGraphLine />,
      label: "Results",
    });
  }

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const handleMenuClick = (label) => {
    setSelectedOption(label);
  };

  const detailsScreens = {
    Details: (
      <Details
        key={"details"}
        assessmentDetailsResponse={assessmentDetailsResponse}
        assessmentType={assessmentType}
        isStakeholder={stakeholderDetails.is_stakeholder}
      />
    ),
    Instructions: (
      <AssessmentInstructions
        key={"assInstructions"}
        assessmentDetailsResponse={assessmentDetailsResponse}
      />
    ),
    Rules: (
      <AssessmentRules
        key={"rules"}
        assessmentDetailsResponse={assessmentDetailsResponse}
      />
    ),
    "Question Paper": (
      <QuestionPaper
        key={"qPaper"}
        assessmentDetailsResponse={assessmentDetailsResponse}
      />
    ),
    Stakeholders: <StakeHolders key={"stakeHolders"} />,
    Candidates: (
      <Candidates key={"candidates"} candidatesDetails={candidatesDetails} />
    ),
    "Submit Feedback": (
      <FeedbackModalComponent 
        key={"feedback"}
        assessmentDetailsResponse={assessmentDetailsResponse}
      />
    ),
    "Conduct Viva": (
      <ConductViva
        key={"conductViva"}
        assessmentDetailsResponse={assessmentDetailsResponse}
        candidatesDetails={candidatesDetails}
      />
    ),
    Invigilate: (
      <Invigilation
        key={"invigilation"}
        candidatesDetails={candidatesDetails}
        assessmentDetailsResponse={assessmentDetailsResponse}
        isMonitorTab={false}
      />
    ),
    Monitor: (
      <Invigilation
        key={"invigilation"}
        candidatesDetails={candidatesDetails}
        assessmentDetailsResponse={assessmentDetailsResponse}
        isMonitorTab={false}
      />
    ),
    Results: <CandidateResults key={"results"} />,
    Evaluate: (
      <Evaluate key={"evaluate"} candidatesDetails={candidatesDetails} />
    ),
    "Publish Results": (
      <PublishResults key={"publishResults"} />
    ),
  };

  const assessmentHeaderNames = {
    title: "Assessment Title",
    subText: "Home/Assessment",
  };

  useEffect(() => {
    setSelectedOption("Details");
    const assessmentId = localStorage.getItem(localStorageKeys?.assessmentId);
    if (stakeholderDetails.is_stakeholder) {
      setLoggedIn();
      setAssessmentIdCxt(assessmentId);
    }
    const url = `${URL.ASSESSMENT.CANDIDATE_ASSESSMENT}/${assessmentId}`;
    try {
      authenticatedAxios
        .get(url, { authenticated: true } as CustomAxiosRequestConfig)
        .then((response) => {
          if (response?.status == 200) {
            setAssessmentDetailsResponse(response?.data?.responseBody);
            localStorage.setItem(
              localStorageKeys?.assessmentDetails,
              JSON.stringify(response?.data?.responseBody),
            );
          } else {
            const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
            handleMessage(messageEnum.error, errorMsg);
          }
        })
        .catch(() => {
          const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
          handleMessage(messageEnum.error, errorMsg);
        });
    } catch (error) {
      const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
      handleMessage(messageEnum.error, errorMsg);
    }
  }, []);

  useEffect(() => {
    const assessmentId = localStorage.getItem(localStorageKeys?.assessmentId);
    if (stakeholderDetails.is_stakeholder) {
      initializeZIM();
    }
    const url = `${URL.ASSESSMENT.CANDIDATES_ASSESSMENT}/${assessmentId}/${URL.ASSESSMENT.CANDIDATES}`;
    try {
      authenticatedAxios
        .get(url, { authenticated: true } as CustomAxiosRequestConfig)
        .then((response) => {
          if (response?.status == 200) {
            
            setCandidatesDetails(response?.data?.responseBody);
          } else {
            const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
            handleMessage(messageEnum.error, errorMsg);
          }
        })
        .catch(() => {
          const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
          handleMessage(messageEnum.error, errorMsg);
        });
    } catch (error) {
      const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
      handleMessage(messageEnum.error, errorMsg);
    }
  }, []);

  return (
    <div className="flex flex-col pb-[50px] box-border h-full">
      <DashboardHeader />
      <div className="h-[80%] flex flex-1 justify-center">
        {Object?.keys(assessmentDetailsResponse)?.length > 0 ? (
          <div className="mx-[50px] flex flex-col flex-1">
            <div className="my-[10px] items-center  flex justify-between">
              <div className="font-semibold text-[20px]">
                {assessmentDetailsResponse?.title ?? "Assessment"}
              </div>
              <div className="text-[gray] text-[14px]">
                {assessmentHeaderNames?.subText}
              </div>
            </div>
            <div className="border-gray border-[1px] border-solid"></div>
            <div className="flex mt-[10px] flex-1 overflow-hidden">
              <div
                className={classNames(
                  " border-gray border-[2px] border-solid p-[5px]",
                  collapsed ? "w-[7%]" : "w-[20%]",
                )}>
                <div>
                  <ConfigProvider
                    theme={{
                      components: {
                        Menu: {
                          itemSelectedBg: "#27CA5F",
                          darkItemColor: "black",
                          itemHoverBg: "#21c17a",
                          itemColor: "black",
                          itemHeight: 40,
                          itemBorderRadius: 8,
                          darkItemBg: "white",
                          darkItemSelectedBg: "#27CA5F",
                          darkItemSelectedColor: "white",
                          darkItemHoverBg: "#27CA5F",
                          iconSize: 25,
                          collapsedIconSize: 25,
                          groupTitleFontSize: 30,
                          collapsedWidth: 80
                        },
                      },
                    }}>
                    <Menu
                      defaultSelectedKeys={["0"]}
                      mode="inline"
                      theme="dark"
                      inlineCollapsed={collapsed}>
                      {items.map((item) => (
                        <Menu.Item
                          key={item.key}
                          icon={item.icon}
                          onClick={() => handleMenuClick(item.label)}
                          className="border-gray border-[2px] border-solid flex justify-center items-center">
                          {item.label}
                        </Menu.Item>
                      ))}
                    </Menu>
                  </ConfigProvider>
                </div>
              </div>
              <Button
                type="primary"
                onClick={toggleCollapsed}
                className="text-[black] menuBtn">
                {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              </Button>
              <div
                className={classNames(
                  "border-gray border-[2px] border-solid flex flex-col flex-1",
                  collapsed ? "w-[93%]" : "w-[90%]",
                )}>
                <AssessmentDetailsHeader
                  questionPaperDetails={
                    assessmentDetailsResponse?.question_paper_details
                  }
                  assessment_details={assessmentDetailsResponse}
                  candidate_details={candidatesDetails}
                />
                <div className="flex flex-col overflow-hidden flex-1">
                  {detailsScreens[selectedOption]}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex justify-center items-center h-[80%] py-[20px]">
            <Spin size={"large"} />
          </div>
        )}
      </div>
      <div className="flex justify-end fixed bottom-0 items-center bg-primary w-full  py-[10px]">
        <FooterLogoComponent />
      </div>
    </div>
  );
};

export default AssessmentDetails;
