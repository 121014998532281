import { DashboardCardProps } from "@/types/componentTypes";
import React from "react";
import { TbArrowsDoubleSwNe } from "react-icons/tb";
import { contentDivText } from "@/constants/constants";

const DashboardCard: React.FC<DashboardCardProps> = ({
  title,
  date,
  time,
  id,
  onClick,
  totalCandidates,
  assessmentStatus,
  candidateStatus = "",
}) => {
  return (
    <div className=" pt-4 pb-2 px-4 shadow-customCardDashboard rounded-md text-[#9095A0] text-[16px]">
      <div className="leading-22 p-1">{title}</div>
      <div className="leading-22 p-1">{date}</div>
      <div className="leading-22 p-1">{time}</div>
      <div className="flex items-center justify-between space-x-7 p-2">
        <div className="leading-22 py-5">
          {!totalCandidates
            ? contentDivText?.status
            : contentDivText?.noOfCandidates}
          <span className="text-primary font-medium pl-1">
            {!totalCandidates
              ? candidateStatus?.toUpperCase()
              : totalCandidates}
          </span>
        </div>
        <div
          className="p-2 rounded-full bg-primary cursor-pointer"
          onClick={() => onClick(id, assessmentStatus, candidateStatus)}>
          <TbArrowsDoubleSwNe size={22} color="white" />
        </div>
      </div>
    </div>
  );
};

export default DashboardCard;
