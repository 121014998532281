import React, { FC } from "react";
import DashboardCard from "./dashboardCard";
import moment from "moment";
import { AssessmentCollapseComponentProps } from "@/types/componentTypes";
import DashboardEmptyContent from "./dashboardEmptyContent";
import {
  localStorageKeys,
  DATE_FORMAT,
  TEST_TIMING_FORMAT,
  segmentValues,
} from "@/constants/constants";
import { useNavigate } from "react-router-dom";
import { NAVIGATION } from "@/utils/internalNavigations";

const AssessmentCollapseComponent: FC<AssessmentCollapseComponentProps> = ({
  assessment,
  emptyContent,
  assessmentStatus,
}) => {
  const navigate = useNavigate();
  const formatDate = (date: string) => {
    const today = moment().format(DATE_FORMAT);
    if (moment(date).format(DATE_FORMAT) === today) {
      return segmentValues?.today;
    }
    return moment(date).format("Do MMMM YYYY");
  };

  const onClick = (assessmentId, status, candidateStatus) => {
    if (candidateStatus === "blocked") return;
    const assessmentStatus = status?.split(" ")?.[0];
    localStorage.setItem(localStorageKeys?.assessmentId, assessmentId);
    localStorage.setItem(localStorageKeys?.assessmentStatus, assessmentStatus);
    navigate(`${NAVIGATION.STAKEHOLDERS_ASSESSMENT}/${assessmentId}`);
  };
  return (
    <div>
      {assessment?.length > 0 ? (
        <div className="grid grid-cols-5 gap-5">
          {assessment?.map((item: any, index: number) => {
            const testTimings = `${moment(item?.start_datetime).format(TEST_TIMING_FORMAT)} - ${moment(item?.end_datetime).format(TEST_TIMING_FORMAT)}`;
            return (
              <div key={index}>
                <DashboardCard
                  title={item?.title}
                  date={formatDate(item?.start_datetime)}
                  id={item?.id}
                  time={testTimings}
                  onClick={onClick}
                  totalCandidates={item?.candidates}
                  assessmentStatus={assessmentStatus}
                  candidateStatus={item?.status}
                />
              </div>
            );
          })}
        </div>
      ) : (
        <DashboardEmptyContent content={emptyContent} />
      )}
    </div>
  );
};

export default AssessmentCollapseComponent;
