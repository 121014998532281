import React from "react";
import { EvaluateDashboardProps } from "@/types/componentTypes";

const EvaluateDashboard: React.FC<EvaluateDashboardProps> = ({
  items,
  onDescriptionClick,
}) => {
  return (
    <div className="container mx-auto p-4 py-2.5">
      <div
        className="grid gap-4"
        style={{ gridTemplateColumns: `repeat(auto-fit, minmax(200px, 1fr))` }}>
        {items?.map((item, index) => (
          <div
            key={index}
            className="p-3 bg-[#DEE1E6] rounded-md flex flex-col justify-center items-center py-5  gap-2 ">
            <p className="font-normal text-[16px]">{item.name}</p>
            <p
              className="underline text-[#379AE6] text-[24px] cursor-pointer"
              onClick={() => onDescriptionClick(item)}>
              {item?.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EvaluateDashboard;
