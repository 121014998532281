import { RootState } from "@/types/componentTypes";

const initialState: RootState = {
  candidateId: null,
  assessmentId: null,
  setId: null,
  assessmentDetails: {},
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CANDIDATE_ID":
      return {
        ...state,
        candidateId: action.payload,
      };
    case "SET_ASSESSMENT_ID":
      return {
        ...state,
        assessmentId: action.payload,
      };
    case "SET_ASSESSMENT_SET_ID":
      return {
        ...state,
        setId: action.payload,
      };
    case "SET_ASSESSMENT_DETAILS":
      return {
        ...state,
        assessmentDetails: action.payload,
      };
    default:
      return state;
  }
};

export default rootReducer;
