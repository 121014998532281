import React from "react";
import { ButtonComponentProps } from "@/types/componentTypes";

const ButtonComponent: React.FC<ButtonComponentProps> = ({
  className,
  onClick,
  disabled,
  text,
}) => {
  return (
    <button className={className} onClick={onClick} disabled={disabled}>
      {text}
    </button>
  );
};

export default ButtonComponent;
