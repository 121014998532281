import { useState, useEffect } from "react";
import { handleMessage, getIntlErrorMessage } from "@/utils/functions";
import { messageEnum } from "@/constants/constants";

export const useWebcamStatus = () => {
  const [webcamEnabled, setWebcamEnabled] = useState<boolean | null>(null);

  useEffect(() => {
    const checkWebcamStatus = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        setWebcamEnabled(true);
        stream.getTracks().forEach((track) => track.stop());
      } catch (error) {
        setWebcamEnabled(false);
        const errorMsg = getIntlErrorMessage(
          "PERMISSIONS",
          "FAILED_ACCESS_WEBCAM",
        );
        handleMessage(messageEnum.error, errorMsg);
      }
    };

    checkWebcamStatus();
  }, []);

  return webcamEnabled;
};
