import React, { useState } from "react";
import {
  contentDivText,
  placeHolders,
  buttonTitle,
  headings,
  stakeholderLogValues,
  localStorageKeys,
  messageEnum,
  socketMessages,
} from "@/constants/constants";
import useDivHeight from "@/hooks/useDivHeight";
import classNames from "classnames";
import ButtonComponent from "@/components/buttonComponent";
import { useSocket } from "@/contexts/socketContext";
import { FinishTestProps } from "@/types/componentTypes";
import { URL } from "@/utils/service-urls";
import { authenticatedAxios } from "@/utils/axiosInterceptor";
import { CustomAxiosRequestConfig } from "@/types/functionTypes";
import {
  getIntlErrorMessage,
  getIntlSuccessMessage,
  handleMessage,
} from "@/utils/functions";

const FinishTest: React.FC<FinishTestProps> = ({ candidateDetails }) => {
  const containerIdName = "container";
  const divHeight = useDivHeight(containerIdName);
  const { socket }: any = useSocket();
  const [BlockingMessage, setBlockingMessage] = useState("");
  const candidateId = localStorage.getItem(localStorageKeys.candidateId);
  const setId = localStorage.getItem(localStorageKeys.setId);
  const assessmentId = localStorage.getItem(localStorageKeys.assessmentId);
  const handleTextAreaChange = (e: any) => {
    const { value } = e.target;
    setBlockingMessage(value);
  };
  const handleFinishTest = async () => {
    socket.emit(
      socketMessages?.blockingCandidate,
      candidateDetails?.candidate_id,
    );
    const body = {
      requestBody: {
        comment: "",
        eventData: { remarks: BlockingMessage },
        eventType: stakeholderLogValues?.INVIGILATE,
        eventName: stakeholderLogValues?.BLOCK_CANDIDATE,
        setId: setId,
        status: headings?.blocked,
      },
    };
    await postViolationsToDb(body);
    setBlockingMessage("");
  };
  const postViolationsToDb = async (body) => {
    const url = `${URL.ASSESSMENT.CANDIDATES_ASSESSMENT}/${assessmentId}/${URL.ASSESSMENT.CANDIDATE}/${candidateId}`;
    try {
      authenticatedAxios
        .put(url, body, { authenticated: true } as CustomAxiosRequestConfig)
        .then((response) => {
          if (response?.status == 200) {
            const successMsg = getIntlSuccessMessage(
              " BLOCKING_THE_CANDIDATE",
              "BLOCKING_MESSAGE",
            );
            handleMessage(messageEnum.success, successMsg);
          } else {
            const errorMsg = getIntlErrorMessage(
              "VIOLATION_MESSAGE",
              "VIOLATION",
            );
            handleMessage(messageEnum.error, errorMsg);
          }
        })
        .catch(() => {
          const errorMsg = getIntlErrorMessage(
            "VIOLATION_MESSAGE",
            "VIOLATION",
          );
          handleMessage(messageEnum.error, errorMsg);
        });
    } catch (error) {
      const errorMsg = getIntlErrorMessage("VIOLATION_MESSAGE", "VIOLATION");
      handleMessage(messageEnum.error, errorMsg);
    }
  };
  const handleCancelClicked = () => {
    setBlockingMessage("");
  };

  const buttonContent = [
    {
      text: buttonTitle?.yes,
      onclick: handleFinishTest,
      buttonClassName:
        BlockingMessage.length > 0
          ? "bg-primary  text-[white] px-[10px] py-[5px] text-[14px] border-none rounded-md font-semibold"
          : "bg-[lightGray] text-primaryText px-[10px] py-[5px] text-[14px] border-none rounded-md font-semibold",
    },
    {
      text: buttonTitle?.cancel,
      onclick: handleCancelClicked,
      buttonClassName:
        "px-[10px] py-[5px] text-[14px] border-green border-[2px] border-solid rounded-md font-semibold text-primary",
    },
  ];
  return (
    <div
      className="w-[85%] h-[91%] bg-[#DEE1E6] px-[10px] py-[20px] "
      id={containerIdName}>
      <div className="pb-[10px] ">
        {contentDivText?.candidateId}
        <span>{candidateDetails?.candidate_id}</span>
      </div>

      <div
        className={classNames(
          "bg-[white] pl-[20px] pr-[40px] pt-[20px] flex flex-1 flex-col overflow-y-auto h-[100%]",
        )}
        style={{ height: divHeight - 60 }}>
        <div className="text-[16px] pb-[10px]">{headings?.blockText}</div>
        <div className="font-medium text-[14px]">
          {headings?.reasonForBlocking}
        </div>
        <textarea
          rows={5}
          className="w-[100%] border-[1px] text-[14px] border-solid border-gray text-primaryText p-[10px] mt-[10px] rounded-md"
          placeholder={placeHolders.blockingText}
          style={{ resize: "none" }}
          value={BlockingMessage}
          onChange={handleTextAreaChange}></textarea>
        <div className="py-[10px] text-[14px]">
          {headings?.confirmationText}
        </div>
        <div className="flex space-x-[10px] justify-start">
          {buttonContent?.map((item) => {
            return (
              <ButtonComponent
                text={item.text}
                onClick={item.onclick}
                key={item.text}
                className={item?.buttonClassName}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FinishTest;
