import mammoth from "mammoth";
import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import * as XLSX from "xlsx";

pdfjs.GlobalWorkerOptions.workerSrc =
  "//unpkg.com/pdfjs-dist@4.3.136/build/pdf.worker.min.mjs";

interface FilePreviewProps {
  fileKey: any;
  fileUrl: string;
}

const FilePreview: React.FC<FilePreviewProps> = ({ fileKey, fileUrl }) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [docContent, setDocContent] = useState<string | null>(null);
  const [excelContent, setExcelContent] = useState<string | null>(null);

  const getFileTypeFromKey = (key: string) => {
    const parts = key.split(".");
    if (parts.length > 1) {
      const typePart = parts[parts.length - 1];
      const typeSegments = typePart.split("+");
      if (typeSegments.length > 1) {
        return typeSegments[0].toLowerCase();
      } else {
        return typePart.toLowerCase();
      }
    }
    return undefined;
  };

  useEffect(() => {
    const fileType = getFileTypeFromKey(fileKey);

    if (
      fileType === "doc" ||
      fileType === "docx" ||
      fileType === "msword" ||
      fileType === "document"
    ) {
      fetch(fileUrl)
        .then((response) => response.arrayBuffer())
        .then((arrayBuffer) => mammoth.extractRawText({ arrayBuffer }))
        .then((result) => setDocContent(result.value))
        .catch((error) => console.error("Error reading Word document:", error));
    }

    if (
      fileType === "xls" ||
      fileType === "xlsx" ||
      fileType === "ms-excel" ||
      fileType === "sheet"
    ) {
      fetch(fileUrl)
        .then((response) => response.arrayBuffer())
        .then((arrayBuffer) => {
          const workbook = XLSX.read(arrayBuffer, { type: "array" });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          const excelData = XLSX.utils.sheet_to_html(worksheet);
          setExcelContent(excelData);
        })
        .catch((error) =>
          console.error("Error reading Excel document:", error),
        );
    }
  }, [fileKey, fileUrl]);
  const renderPreview = () => {
    const fileType = getFileTypeFromKey(fileKey);
    if (fileType) {
      switch (fileType) {
        case "png":
        case "jpg":
        case "jpeg":
        case "svg":
          return (
            <img src={fileUrl} alt="Image Preview" style={{ width: "100%" }} />
          );
        case "pdf":
          return (
            <div className="w-full h-[500px] overflow-scroll">
              <Document
                file={fileUrl}
                onLoadSuccess={({ numPages }) => setNumPages(numPages)}>
                {Array.from(new Array(numPages), (el, index) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ))}
              </Document>
            </div>
          );
        case "mp4":
        case "mov":
        case "mpeg":
        case "quicktime":
          return <video controls src={fileUrl} style={{ width: "100%" }} />;
        case "mp3":
          return <audio controls src={fileUrl} />;
        case "doc":
        case "docx":
        case "msword":
        case "document":
          return docContent ? (
            <div dangerouslySetInnerHTML={{ __html: docContent }} />
          ) : (
            <div>Preview Unavailable</div>
          );
        case "xls":
        case "xlsx":
        case "sheet":
        case "ms-excel":
          return excelContent ? (
            <div
              className="flex flex-wrap"
              dangerouslySetInnerHTML={{ __html: excelContent }}
            />
          ) : (
            <div>Preview Unavailable</div>
          );
        default:
          return <div>Unsupported file type</div>;
      }
    } else {
      return <div>Unsupported file type</div>;
    }
  };

  return (
    <div className="file-preview flex p-5 pt-10 w-full">{renderPreview()}</div>
  );
};

export default FilePreview;
