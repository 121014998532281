import { Table } from "antd";
import React, { useEffect, useState } from "react";
import ButtonComponent from "./buttonComponent";
import {
  capitalizeFirstLetter,
  getIntlErrorMessage,
  handleMessage,
} from "@/utils/functions";
import { CustomAxiosRequestConfig } from "@/types/functionTypes";
import { adminAxios, authenticatedAxios } from "@/utils/axiosInterceptor";
import { messageEnum, localStorageKeys } from "@/constants/constants";
import { URL } from "@/utils/service-urls";
import classNames from "classnames";
interface MarksRowData {
  key: string;
  text: string;
  total_marks: number;
  total_marks_obtained: number | null;
}
const SubmitScore = ({ answers }: any) => {
  const [data, setData] = useState<any>([]);
  const assessmentId = localStorage.getItem(localStorageKeys.assessmentId);
  const candidateId = localStorage.getItem(localStorageKeys.candidateId);
  const assessmentDetails: any = JSON.parse(
    localStorage.getItem(localStorageKeys.assessmentDetails) ?? "{}",
  );
  const theory_total: any = localStorage.getItem("theory_total");
  const practical_total: any = localStorage.getItem("practical_total");
  const viva_total: any = localStorage.getItem("viva_total");
  const total_scores = { theory_total, practical_total, viva_total };
  const enableSubmitButton = answers?.every(
    (item) => item.status == "Completed",
  );
  const theoryQuestionsData: any = JSON.parse(
    localStorage.getItem("question_paper_theory") ?? "{}",
  );
  const practicalQuestions: any = JSON.parse(
    localStorage.getItem("question_paper_practicals") ?? "{}",
  );
  const vivaQuestions: any = JSON.parse(
    localStorage.getItem("question_paper_viva") ?? "{}",
  );
  const questionIds = {
    theory: theoryQuestionsData.En.map((item) => item.id),
    practical: practicalQuestions.activity.map((item) => item.id),
    viva: vivaQuestions.viva1.map((item) => item.id),
  };
  const getInitialData = () => {
    const keys = Object.keys(
      assessmentDetails?.question_paper_details?.category,
    );
    const calculateSumByCategory = (ids) => {
      return answers
        .filter((item) => ids.includes(item.question_id))
        ?.reduce((sum, item) => sum + parseInt(item?.marksObtained || 0), 0);
    };
    const formattedData: any = [];
    keys.forEach((key) => {
      formattedData.push({
        key: formattedData?.length + 1,
        text: capitalizeFirstLetter(key),
        total_marks: total_scores[`${key}_total`],
        total_marks_obtained: calculateSumByCategory(questionIds[key]),
      });
    });
    setData(formattedData);
  };
  useEffect(() => {
    getInitialData();
  }, []);
  const calculateTotalMarksObtained = () => {
    return data.reduce(
      (acc: any, item: any) => acc + (item.total_marks_obtained ?? 0),
      0,
    );
  };
  const calculateGrade = async (totalMarksObtained: number) => {
    const url = URL.QUESTIONPAPERDETAILS.EVALUTION_CRITERIA;
    const orgId = assessmentDetails?.organization_id;
    const questionPaperEvaluationId = assessmentDetails?.question_paper_details?.qpv_id;
    const totalMarks = assessmentDetails?.question_paper_details?.total_marks;

    if (!orgId || !questionPaperEvaluationId || !totalMarks) {
      console.error("Missing necessary data for grade calculation");
      return "F";
    }

    const body = {
      requestBody: {
        orgId: orgId,
        questionPaperEvaluationId: questionPaperEvaluationId
      }
    };

    try {
      const res = await adminAxios.post(url, body, { authenticated: true } as CustomAxiosRequestConfig);
      
      if (res.status === 200) {
        const gradingCriteria = res.data?.responseBody[0]?.grading_criteria;
        
        if (!gradingCriteria) {
          console.error("Grading criteria not found");
          return "F";
        }

        // Calculate average as percentage
        const averagePercentage = (totalMarksObtained / totalMarks) * 100;
        console.log('average', averagePercentage)
        // Determine grade dynamically based on criteria
        for (const grade in gradingCriteria) {
          const { min, max } = gradingCriteria[grade];
          if (averagePercentage >= Number(min) && averagePercentage <= Number(max)) {
            console.log('grade', grade);
            return grade; // Return the corresponding grade
          }
        }

        return "F"; // Default to "F" if no grade matches
      } else {
        const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
        handleMessage(messageEnum.error, errorMsg);
        return "F";
      }
    } catch (error) {
      console.error("Error fetching grading criteria:", error);
      return "F";
    }
  };
  const handleUploadToDb = async (body: any) => {
    const url = `${URL?.ASSESSMENT?.CANDIDATE_ASSESSMENT}/${assessmentId}/${URL?.ASSESSMENT?.CANDIDATES}/${candidateId}/${URL?.ASSESSMENT?.FINAL_EVALUATION}`;
    authenticatedAxios
      .put(url, body, { authenticated: true } as CustomAxiosRequestConfig)
      .then((res) => {
        if (res.status == 200) {
          return res;
        } else {
          const errorMsg = getIntlErrorMessage(
            "SUBMIT_SCORES",
            "SUBMIT_SCORES_MESSAGE",
          );
          handleMessage(messageEnum.error, errorMsg);
        }
      });
  };
  const submitScoreClick = async () => {
    if (enableSubmitButton) {
      const totalMarksObtained = await calculateTotalMarksObtained();
      const gradeObtained = await calculateGrade(totalMarksObtained);
      const requestBody = data.reduce(
        (acc: any, item: any) => {
          const key = item.text.toLowerCase();
          acc[key] = item.total_marks_obtained ?? null;
          return acc;
        },
        {
          theory: 0,
          practical: 0,
          viva: 0,
          total: totalMarksObtained,
          grade: gradeObtained,
          is_attempted: true,
          status: "Completed",
        },
      );
      const body = { requestBody: requestBody };
      await handleUploadToDb(body);
    }
  };
  const columns = [
    {
      title: "Text",
      dataIndex: "text",
      key: "text",
    },
    {
      title: "Total Marks",
      dataIndex: "total_marks",
      key: "total_marks",
    },
    {
      title: "Total marks obtained",
      dataIndex: "total_marks_obtained",
      key: "total_marks_obtained",
      render: (text: string, record: MarksRowData) => {
        return <div>{record.total_marks_obtained}</div>;
      },
    },
  ];
  const totalMarksObtained = calculateTotalMarksObtained();
  const totalRow = {
    key: "total",
    text: "Total",
    total_marks: assessmentDetails?.question_paper_details?.total_marks,
    total_marks_obtained: totalMarksObtained,
  };
  return (
    <div className="mt-4 mr-10 ">
      <div>
        <Table
          columns={columns}
          dataSource={[...data, totalRow]}
          bordered
          pagination={false}
          rowClassName={(record) =>
            record.text === "Total" ? "font-bold bg-custom-bg" : ""
          }
          scroll={{ y: 190 }}
        />
      </div>
      <div className="mt-auto px-2 py-2 flex justify-end">
        <ButtonComponent
          text="Submit scores"
          className={classNames(
            " px-[20px] mt-5 py-[10px] border-none rounded-md font-semibold",
            enableSubmitButton
              ? "bg-primary  text-[white]"
              : "bg-[lightGray] text-primaryText",
          )}
          onClick={submitScoreClick}
          disabled={!enableSubmitButton}
        />
      </div>
    </div>
  );
};
export default SubmitScore