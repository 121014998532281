import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { URL } from "@/utils/service-urls";
import { unauthenticatedAxios } from "./utils/axiosInterceptor";
import { localStorageKeys } from "./constants/constants";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import StakeholderLogout from "./components/stakeholderLogout";
import { getClientBrowser } from "./utils/functions";
import { useAuth } from "./contexts/authContext";

function ProtectedRouteStakeholder({ component: Component, ...rest }) {
  const { id } = useParams();
  const token = sessionStorage.getItem(localStorageKeys.jwt);
  const [isLoggedIn, setIsLoggedIn] = useState(!!token);
  const [loading, setLoading] = useState(true);
  const { login } = useAuth();

  const getToken = async () => {
    const data = {
      requestBody: {
        tokenId: id,
        browser: getClientBrowser(),
        ipaddress: "x.x.x.x",
        latitude: "0",
        longitude: "0",
      },
    };
    const response = await unauthenticatedAxios.post(
      `${URL.ACCOUNT.AUTHENTICATE_STAKEHOLDERS}`,
      data,
    );
    console.log(response, "=======");
    if (response.data?.responseBody) {
      sessionStorage.setItem(
        localStorageKeys.jwt,
        response.data.responseBody.jwtToken,
      );
      const data = response.data?.responseBody || {};
      delete data.jwtToken;
      const stakeHolderDetails = JSON.stringify({
        ...data,
      });
      localStorage.setItem(
        localStorageKeys.stakeholderDetails,
        stakeHolderDetails,
      );
      localStorage.setItem(localStorageKeys.orgId, data.orgaization.id);
      localStorage.setItem("user_id", data.id);
      login()?.then((data) => {
        sessionStorage.setItem(
          localStorageKeys.firebaseAuthCreds,
          JSON.stringify(data.user),
        );
      });
      setIsLoggedIn(true);
      setLoading(false);
    } else {
      setIsLoggedIn(false);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (id && !token) {
      getToken();
    } else {
      setLoading(false);
    }
  }, [id]);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}>
          <Spin
            indicator={
              <LoadingOutlined
                style={{ fontSize: 24, backgroundColor: "green" }}
                spin
              />
            }
          />
        </div>
      ) : isLoggedIn ? (
        <Component {...rest} />
      ) : (
        <StakeholderLogout />
      )}
    </>
  );
}

export default ProtectedRouteStakeholder;
