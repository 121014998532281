import React, { useContext, useEffect, useState } from "react";
import Dragger from "@/components/matchingsDragger";
import FillInTheBlanksQuestion from "@/components/fillInTheBlanks";
import HtmlEditor from "@/components/htmlEditor";
import { ItemStateContext } from "@/contexts/questionContext";
import LeftComponentForSingleQuestion from "@/components/leftComponentForSingleQuestion";
import ButtonComponent from "@/components/buttonComponent";
import {
  buttonTitle,
  placeHolders,
  questionType,
  analyticKeys,
  eventType,
  responseText,
  firebaseMethodKeys,
  assessmentStatus,
  localStorageKeys,
} from "@/constants/constants";
import { useNavigate } from "react-router-dom";
import { NAVIGATION } from "@/utils/internalNavigations";
import useEventLogging from "@/hooks/useLogs";
import { LeftComponentForAllQuestionsProps } from "@/types/componentTypes";
import { getFirebaseUrl } from "@/utils/functions";
import { RootState } from "@/store";
import { useSelector } from "react-redux";
import { FIREBASE_URL } from "@/utils/service-urls";
import { handleFirebaseOperation } from "@/firebase";
import SequenceRenderer from "@/components/matchingSequenceRender";
import MatchingResponse from "@/components/matchingResponseComponent";
import MarksObtained from "@/components/marksObtained";
import ResponseItem from "@/components/responseItem";

const LeftComponentForAllQuestions: React.FC<
  LeftComponentForAllQuestionsProps
> = ({
  handleInputBoxChange,
  selectedLanguage,
  handleTextAreaChange,
  ourResponse,
  isResponsivePage = true,
  handleRadioChange,
  handleCheckboxChange,
}) => {
  const { questions, responses, scenarioTypeRes }: any =
    useContext(ItemStateContext);
  const navigate = useNavigate();
  const { logEvent } = useEventLogging();
  const assessmentId = useSelector((state: RootState) => state.assessmentId);
  const candidateId = useSelector((state: RootState) => state.candidateId);
  const setId = useSelector((state: RootState) => state.setId);
  const [firebaseData, setFirebaseData] = useState<any>();
  const { matchingResponse }: any = useContext(ItemStateContext);
  const fromCandidateDashboard = localStorage.getItem(
    localStorageKeys?.fromCandidateDashboard,
  );

  const handleGetFromFirebase = async () => {
    try {
      const endPoint = `${FIREBASE_URL?.ITEM_URL?.ASSESSMENT}/${assessmentId}/answers`;

      const data = await handleFirebaseOperation(
        endPoint,
        firebaseMethodKeys.GET,
      );
      let parsedData: any;
      try {
        parsedData = JSON.parse(JSON.stringify(data));
      } catch (parseError) {
        console.log("Error parsing JSON data:", parseError);
        return;
      }

      setFirebaseData(JSON.parse(parsedData));
    } catch (error) {
      console.log("Error fetching data from Firebase:", error);
    }
  };

  const correctOptions = (
    firebaseData: any,
    currentQuestion: any,
    option: any,
  ) => {
    if (firebaseData) {
      return firebaseData[selectedLanguage]?.[
        currentQuestion.id
      ]?.options?.find((opt: any) => opt?.id === option?.id)?.is_correct_option;
    }
  };
  useEffect(() => {
    handleGetFromFirebase();
  }, []);

  const isMatchingCorrect = (
    responses,
    firebaseData,
    currentQuestion,
    selectedLanguage,
    index,
  ) => {
    const responseItems = Object.values(
      responses?.[selectedLanguage]?.[index]?.matchingResponse || {},
    );
    const correctItems =
      firebaseData?.[selectedLanguage]?.[currentQuestion.id]?.options || [];

    if (responseItems?.length == 0 || correctItems?.length == 0) return false;

    return responseItems.every((responseItem, indexI) => {
      const correctSequence = correctItems[indexI + 1]?.list_correct_sequence;
      if (!correctSequence) return false;

      const responseValues = Object.values(responseItem || {});
      const correctValues = Object.values(correctSequence || {});

      if (responseValues.length !== correctValues.length) return false;

      return responseValues.every((itemValue, indexJ) => {
        return correctValues[indexJ] == itemValue;
      });
    });
  };
  return (
    <div>
      <div className="flex font-bold text-[24px] text-primaryText justify-center pb-[20px] border-b-[1px] border-gray mb-[20px]">
        {responseText?.yourResponses}
      </div>
      {questions.map((currentQuestion: any, index: any) => {
        const isLastQuestion = index === questions.length - 1;
        const correctAnswers = firebaseData?.[selectedLanguage]?.[
          currentQuestion?.id
        ]?.options?.filter((item: any) => item?.list_order_id !== 1);
        const matchingsResponse =
          responses?.[selectedLanguage]?.[index]?.matchingResponse || {};

        return (
          <div key={currentQuestion?.id}>
            <div className="flex gap-1 ">
              <div className="py-0 h-fit">{index + 1}.</div>
              <HtmlEditor
                initialValue={currentQuestion?.question_text}
                allQuestions={true}
              />
            </div>

            {/* {currentQuestion?.question_type === questionType.trueOrfalseType ||
            currentQuestion?.question_type === questionType.multiSelectType ||
            currentQuestion?.question_type ===
              questionType.multipleChoiceType ? (
              <div className="px-[32px] pb-[10px] pt-[5px]">
                {currentQuestion?.options.map(
                  (option: any, optionIndex: any) => {
                    const correctOption = correctOptions(
                      firebaseData,
                      currentQuestion,
                      option,
                    );
                    let selectedOption: any;
                    if (
                      currentQuestion?.question_type ===
                        questionType.trueOrfalseType ||
                      currentQuestion?.question_type ===
                        questionType.multiSelectType
                    ) {
                      selectedOption =
                        responses?.[selectedLanguage]?.[index]
                          ?.radioResponse === option?.option_text ||
                        responses?.[selectedLanguage]?.[index]?.scenarioResponse
                          ?.radioResponse === option?.option_text;
                    } else {
                      selectedOption =
                        responses?.[selectedLanguage]?.[
                          index
                        ]?.checkboxResponse.includes(option?.option_text) ||
                        (
                          responses?.[selectedLanguage]?.[index]
                            ?.checkboxResponse || []
                        ).includes(option?.option_text);
                    }
                    const isSelectedOptionPresent =
                      currentQuestion?.question_type ===
                      questionType.multipleChoiceType
                        ? responses?.[selectedLanguage]?.[index]
                            ?.checkboxResponse
                        : responses?.[selectedLanguage]?.[index]?.radioResponse;

                    return (
                      <ResponseItem
                        key={option}
                        optionIndex={optionIndex}
                        optionText={option.option_text}
                        ourResponse={ourResponse}
                        selectedOption={selectedOption}
                        correctOption={correctOption}
                        isSelectedOptionPresent={isSelectedOptionPresent}
                      />
                    );
                  },
                )}
                {!ourResponse && (
                  <MarksObtained
                    currentQuestion={currentQuestion}
                    content={contentDivText?.marksObtained}
                    correctOption={currentQuestion?.options.some((opt: any) =>
                      firebaseData?.[selectedLanguage]?.[
                        currentQuestion.id
                      ]?.options?.find((fOpt: any) =>
                        fOpt?.id === opt?.id &&
                        fOpt?.is_correct_option &&
                        currentQuestion?.question_type ===
                          questionType.multipleChoiceType
                          ? responses?.[selectedLanguage]?.[
                              index
                            ]?.checkboxResponse.includes(opt?.option_text)
                          : responses?.[selectedLanguage]?.[index]
                              ?.radioResponse === opt?.option_text,
                      ),
                    )}
                  />
                )}
              </div>
            ) : null} */}
            {currentQuestion?.question_type === questionType.trueOrfalseType ||
            currentQuestion?.question_type === questionType.multiSelectType ? (
              <div className="px-[32px] pb-[10px] pt-[5px]">
                {currentQuestion?.options.map(
                  (option: any, optionIndex: any) => {
                    const correctOption = correctOptions(
                      firebaseData,
                      currentQuestion,
                      option,
                    );
                    const selectedOption =
                      responses?.[selectedLanguage]?.[index]?.radioResponse ===
                        option?.id ||
                      responses?.[selectedLanguage]?.[index]?.scenarioResponse
                        ?.radioResponse === option?.option_text;

                    const isSelectedOptionPresent =
                      responses?.[selectedLanguage]?.[index]?.radioResponse;

                    return (
                      <ResponseItem
                        key={option}
                        optionIndex={optionIndex}
                        optionText={option.option_text}
                        ourResponse={ourResponse}
                        selectedOption={selectedOption}
                        correctOption={correctOption}
                        isSelectedOptionPresent={isSelectedOptionPresent}
                      />
                    );
                  },
                )}
                {!ourResponse && (
                  <MarksObtained
                    currentQuestion={currentQuestion}
                    content={responseText?.marksObtained}
                    correctOption={
                      !!firebaseData?.[selectedLanguage]?.[
                        currentQuestion.id
                      ]?.options?.find((fOpt: any) => {
                        return (
                          fOpt?.id ==
                            responses?.[selectedLanguage]?.[index]
                              ?.radioResponse && fOpt?.is_correct_option
                        );
                      })
                    }
                  />
                )}
              </div>
            ) : null}

            {currentQuestion?.question_type ===
              questionType.multipleChoiceType && (
              <div className="px-[32px] pb-[10px] pt-[5px]">
                {currentQuestion?.options.map(
                  (option: any, optionIndex: any) => {
                    const correctOption = correctOptions(
                      firebaseData,
                      currentQuestion,
                      option,
                    );
                    const selectedOption =
                      responses?.[selectedLanguage]?.[
                        index
                      ]?.checkboxResponse.includes(option?.id) ||
                      (
                        responses?.[selectedLanguage]?.[index]?.scenarioResponse
                          ?.checkboxResponse || []
                      ).includes(option?.id);

                    const isSelectedOptionPresent =
                      responses?.[selectedLanguage]?.[index]?.checkboxResponse;

                    return (
                      <ResponseItem
                        key={option.option_text}
                        optionIndex={optionIndex}
                        optionText={option.option_text}
                        ourResponse={ourResponse}
                        selectedOption={selectedOption}
                        correctOption={correctOption}
                        isSelectedOptionPresent={isSelectedOptionPresent}
                      />
                    );
                  },
                )}
                {!ourResponse && (
                  <MarksObtained
                    currentQuestion={currentQuestion}
                    content={responseText?.marksObtained}
                    correctOption={Object.values(
                      responses?.[selectedLanguage]?.[index]?.checkboxResponse,
                    )?.every((item: any) => {
                      const result = firebaseData?.[selectedLanguage]?.[
                        currentQuestion.id
                      ]?.options?.find((fOpt: any) => {
                        return fOpt?.id === item;
                      });
                      return !!result?.is_correct_option;
                    })}
                  />
                )}
              </div>
            )}

            {currentQuestion?.question_type === questionType.subJectiveType && (
              <div className="px-[32px] pb-[10px] pt-[5px]">
                <textarea
                  rows={5}
                  className="rounded-md w-full border-[2px] border-[gray] p-[4px]"
                  readOnly={true}
                  value={
                    responses?.[selectedLanguage]?.[index]?.textAreaResponse
                  }
                  placeholder={placeHolders.yourResponses}
                  style={{ resize: "none" }}
                  onChange={(e) => {
                    if (handleTextAreaChange) {
                      handleTextAreaChange(index, e.target.value);
                    }
                  }}
                />
                {!ourResponse && (
                  <MarksObtained
                    currentQuestion={null}
                    correctOption={null}
                    content={responseText?.subjectiveMarks}
                  />
                )}
              </div>
            )}

            {currentQuestion?.question_type === questionType.filltype && (
              <div key={currentQuestion?.id}>
                <FillInTheBlanksQuestion
                  question={currentQuestion}
                  fillInTheBlanksResponse={
                    responses?.[selectedLanguage]?.[index]
                      ?.fillInTheBlanksResponse || {}
                  }
                  selectedLanguage={selectedLanguage}
                  index={index}
                  ourResponse={true}
                  isResponsivePage={isResponsivePage}
                  optionsData={
                    firebaseData?.[selectedLanguage]?.[currentQuestion.id]
                      ?.options
                  }
                />

                {!ourResponse && (
                  <div className="ml-[32px]">
                    <MarksObtained
                      currentQuestion={currentQuestion}
                      content={responseText?.marksObtained}
                      correctOption={Object?.values(
                        responses?.[selectedLanguage]?.[index]
                          ?.fillInTheBlanksResponse,
                      )?.some(
                        (item, indexI) =>
                          // eslint-disable-next-line prettier/prettier
                          firebaseData?.[selectedLanguage]?.[currentQuestion.id]
                            ?.options[0]?.blank_text_ans_key_data[
                            indexI + 1
                          ] === item,
                      )}
                    />
                  </div>
                )}
              </div>
            )}

            {currentQuestion?.question_type === questionType.scenarioType && (
              <div>
                {currentQuestion?.options.map(
                  (optionData: any, optionIndex: any) => {
                    return (
                      <LeftComponentForSingleQuestion
                        key={optionData}
                        handleInputBoxChange={handleInputBoxChange}
                        selectedLanguage={selectedLanguage}
                        currentQuestion={optionData}
                        handleCheckboxChange={handleCheckboxChange}
                        handleRadioChange={handleRadioChange}
                        handleTextAreaChange={handleTextAreaChange}
                        sIndex={index}
                        index={optionIndex}
                        ourResponse={ourResponse}
                        isResponsivePage={isResponsivePage}
                        firebaseData={firebaseData}
                      />
                    );
                  },
                )}

                {!ourResponse && (
                  <div className="ml-[32px]">
                    <MarksObtained
                      currentQuestion={currentQuestion}
                      content={responseText?.marksObtained}
                      correctOption={
                        scenarioTypeRes?.trueOrFalse &&
                        scenarioTypeRes?.multiChoice
                      }
                    />
                  </div>
                )}
              </div>
            )}

            <div>
              {currentQuestion?.question_type === questionType.matchingType && (
                <div className="pt-[5px] pb-[10px]">
                  <Dragger
                    listNumber={currentQuestion.list_order_id}
                    initialItems={currentQuestion?.options}
                    selectedLanguage={selectedLanguage}
                    matchingIndex={index}
                    allQuestions={true}
                    ourResponse={true}
                  />

                  {!ourResponse ? (
                    <div>
                      <MatchingResponse
                        matchingResponse={matchingsResponse}
                        ourResponse={ourResponse}
                        optionsArray={
                          firebaseData?.[selectedLanguage]?.[
                            currentQuestion?.id
                          ]?.options
                        }
                      />
                      {correctAnswers && (
                        <div className=" flex flex-col relative mx-[32px]  my-[5px] border-green bg-lightGreen p-[10px] mt-[10px] border-[2px] rounded-md">
                          <SequenceRenderer data={correctAnswers} />
                          {
                            <div className="absolute text-[12px] text-[white] rounded-tl-sm rounded-br-sm bg-primary right-0 bottom-[0px] px-[8px] py-[1px] ">
                              {responseText?.correctAnswer}
                            </div>
                          }
                        </div>
                      )}

                      <div className="ml-[32px]">
                        <MarksObtained
                          currentQuestion={currentQuestion}
                          content={responseText?.marksObtained}
                          correctOption={isMatchingCorrect(
                            responses,
                            firebaseData,
                            currentQuestion,
                            selectedLanguage,
                            index,
                          )}
                        />
                      </div>
                    </div>
                  ) : (
                    <div>
                      <MatchingResponse
                        matchingResponse={matchingResponse}
                        ourResponse={ourResponse}
                        optionsArray={currentQuestion?.options}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>

            {isLastQuestion ? null : (
              <div
                className={"w-full border-b-[1px] border-gray my-[20px]"}></div>
            )}
          </div>
        );
      })}
      <div className="flex justify-end">
        <ButtonComponent
          text={buttonTitle.finishTest}
          onClick={async () => {
            logEvent({
              eventType: eventType.POST_ASSESSMENT_ACTION,
              eventName: analyticKeys.FINISH_ASSESSMENT,
            });
            const options = {
              assessmentId: assessmentId,
              setId: setId,
              candidateId: candidateId,
              endpoint: `${FIREBASE_URL?.ENDPOINT?.EXAM_STATUS}`,
            };
            const firebaseUrl = getFirebaseUrl(options);
            await handleFirebaseOperation(
              firebaseUrl,
              firebaseMethodKeys.PUT,
              assessmentStatus.COMPLETED,
            );
            if (fromCandidateDashboard) {
              navigate(NAVIGATION.DASHBOARD);
            } else {
              window.close();
            }
          }}
          className="rounded-md px-[16px] py-[6px] bg-primary border-none text-[white]"
        />
      </div>
    </div>
  );
};

export default LeftComponentForAllQuestions;
