import React, { useState } from "react";
import { buttonTitle } from "@/constants/constants";
import ModalComponent from "./customModal";
import { Col, Empty, Row } from "antd";
import SearchInputComponent from "./searchComponent";
import { CandidatesProps } from "@/types/componentTypes";
import { useSocket } from "@/contexts/socketContext";

const Candidates: React.FC<CandidatesProps> = ({ candidatesDetails }) => {
  console.log(candidatesDetails, "=====cnd===");
  const [userData, setUserData] = useState<any>({});
  const { candidates }: any = useSocket();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const fields = [
    { key: "candidate_id", label: "Candidate ID" },
    { key: "set_id", label: "Assigned Set ID" },
    { key: "full_name", label: "Full Name" },
    { key: "username", label: "Username" },
    { key: "email", label: "Email" },
    { key: "custom_attrs", label: "Custom Attributes" },
    { key: "district", label: "District" },
    { key: "pincode", label: "Pincode" },
    { key: "state", label: "State" },
  ];
  const customAttrs =
    candidatesDetails?.custom_attrs?.map((attr: any) => ({
      key: attr.label,
      value: attr.value,
    })) || [];

  const finalDataSource = [...fields, ...customAttrs];
  const handleUserData = (data: any) => {
    setUserData(data);
    setModalOpen(true);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const filteredCandidates =
    (Array.isArray(candidatesDetails)
      ? candidatesDetails?.filter((candidate: any) =>
          candidate?.full_name?.toLowerCase()?.includes(searchQuery),
        )
      : []) || [];
  return (
    <div className="my-[20px] mx-[40px] h-full overflow-hidden">
      <div className="pb-[20px] sticky top-0 bg-white z-10">
        <SearchInputComponent
          handleSearchChange={handleSearchChange}
          searchQuery={searchQuery}
          className="h-[40px]"
        />
      </div>
      <div className="h-[80%] overflow-auto">
        {filteredCandidates?.length > 0 ? (
          <div className="flex gap-5  flex-wrap  flex-1 ">
            {filteredCandidates.map((item: any, index: any) => (
              <div
                key={index}
                onClick={() => handleUserData(item)}
                className="flex cursor-pointer text-primaryText text-[16px] rounded-sm items-center border-[1px] justify-between border-[#BCC1CA] border-solid p-[20px] w-[230px]">
                <div>{item.full_name}</div>
                {(candidates[item.candidate_id] == "online" ||
                  candidates[item.candidate_id] == "waiting") && (
                  <div className="rounded-full p-1 bg-primary w-1 h-1"></div>
                )}
              </div>
            ))}
          </div>
        ) : (
          <Empty />
        )}
      </div>

      {userData && modalOpen && (
        <ModalComponent
          title={buttonTitle?.candidatesDetails}
          buttonName={buttonTitle?.close}
          titleClass="bg-[#DEE1E6] py-3 px-6 rounded-t-[8px] font-semibold text-[18px]"
          detailsClassName={true}
          className="p-0 h-[200px] overflow-y-auto"
          buttonClass="flex justify-end mx-[20px]"
          isModalOpen={modalOpen}
          width={600}
          contentClass="pt-4 px-8"
          modalButtonClick={() => setModalOpen(false)}
          content={
            <div className="font-medium text-[18px] flex flex-col space-y-[5px] h-[300px] overflow-y-auto">
              {finalDataSource?.map((field: any) => {
                return (
                  <Row justify="start" key={field}>
                    <Col span={6}>{field?.label}</Col>
                    <Col span={1}>:</Col>
                    <Col span={8}>
                      {userData[field.key]?.toString() || "N/A"}
                    </Col>
                  </Row>
                );
              })}
            </div>
          }
        />
      )}
    </div>
  );
};

export default Candidates;
