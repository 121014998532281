import React, { useState } from "react";
import {
  contentDivText,
  placeHolders,
  buttonTitle,
  headings,
  stakeholderLogValues,
  localStorageKeys,
  messageEnum,
  socketMessages,
} from "@/constants/constants";
import useDivHeight from "@/hooks/useDivHeight";
import classNames from "classnames";
import ButtonComponent from "@/components/buttonComponent";
import useStakeholderEventLogging from "@/hooks/useStakeholderLogs";
import { FlagViolationProps } from "@/types/componentTypes";
import { URL } from "@/utils/service-urls";
import { authenticatedAxios } from "@/utils/axiosInterceptor";
import { CustomAxiosRequestConfig } from "@/types/functionTypes";
import { getIntlErrorMessage, handleMessage } from "@/utils/functions";

const FlagViolation: React.FC<FlagViolationProps> = ({ candidateDetails }) => {
  const [violationMessage, setViolationMessage] = useState("");
  const containerIdName = "container";
  const divHeight = useDivHeight(containerIdName);
  const { stakeholderLogEvent } = useStakeholderEventLogging();
  const candidateId = localStorage.getItem(localStorageKeys.candidateId);
  const setId = localStorage.getItem(localStorageKeys.setId);
  const assessmentId = localStorage.getItem(localStorageKeys.assessmentId);
  const handleTextAreaChange = (e: any) => {
    const { value } = e.target;
    setViolationMessage(value);
  };
  const handleSaveData = async () => {
    const logEventData = {
      message: violationMessage,
    };
    stakeholderLogEvent({
      candidateId: candidateId || 2,
      setId: setId || 2,
      eventType: stakeholderLogValues?.MONITOR,
      eventName: stakeholderLogValues?.FLAG_VIOLATION,
      eventData: { ...logEventData },
    });
    const body = {
      requestBody: {
        set_id: setId,
        type: socketMessages?.flaggingViolation,
        description: violationMessage,
      },
    };
    await postViolationsToDb(body);
    setViolationMessage("");
  };
  const postViolationsToDb = async (body) => {
    const url = `${URL.ASSESSMENT.CANDIDATES_ASSESSMENT}/${assessmentId}/${URL.ASSESSMENT.CANDIDATE}/${candidateId}/${URL.ASSESSMENT.VIOLATIONS}`;
    try {
      authenticatedAxios
        .post(url, body, { authenticated: true } as CustomAxiosRequestConfig)
        .then((response) => {
          if (response?.status == 200) {
            console.log(response, "res");
          } else {
            const errorMsg = getIntlErrorMessage(
              "VIOLATION_MESSAGE",
              "VIOLATION",
            );
            handleMessage(messageEnum.error, errorMsg);
          }
        })
        .catch(() => {
          const errorMsg = getIntlErrorMessage(
            "VIOLATION_MESSAGE",
            "VIOLATION",
          );
          handleMessage(messageEnum.error, errorMsg);
        });
    } catch (error) {
      const errorMsg = getIntlErrorMessage("VIOLATION_MESSAGE", "VIOLATION");
      handleMessage(messageEnum.error, errorMsg);
    }
  };
  return (
    <div
      className="w-[85%] h-[91%] bg-[#DEE1E6] px-[10px] py-[20px] "
      id={containerIdName}>
      <div className="pb-[10px] ">
        {contentDivText?.candidateId}
        <span>{candidateDetails?.candidate_id}</span>
      </div>
      <div
        className={classNames("bg-[white] pl-[20px] pr-[40px] pt-[20px]")}
        style={{ height: divHeight - 60 }}>
        <div className="text-[16px] pb-[10px]">{headings?.flagViolation}</div>
        <div className="font-medium text-[14px]">{headings?.reason}</div>
        <textarea
          rows={5}
          className="w-[100%] border-[1px] text-[14px] border-solid border-gray text-primaryText p-[10px] mt-[10px] rounded-md mb-[20px]"
          placeholder={placeHolders.blockingText}
          style={{ resize: "none" }}
          value={violationMessage}
          onChange={handleTextAreaChange}></textarea>
        <div className="flex justify-end">
          <ButtonComponent
            text={buttonTitle?.save}
            className={classNames(
              "px-[10px] py-[5px] text-[14px] border-none rounded-md font-semibold",
              violationMessage.length > 0
                ? "bg-primary  text-[white]"
                : "bg-[lightGray] text-primaryText",
            )}
            onClick={handleSaveData}
          />
        </div>
      </div>
    </div>
  );
};

export default FlagViolation;
