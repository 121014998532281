import { Descriptions, Table, Tag } from "antd";
import * as XLSX from "xlsx";
import { useContext, useEffect, useState } from "react";
import { IoArrowBack } from "react-icons/io5";
import {
  contentDivText,
  backButtonStack,
  evaluationMenuTabs,
  evaluateTitles,
  messageEnum,
  localStorageKeys,
  buttonTitle,
} from "@/constants/constants";
import classNames from "classnames";
import EvaluateResponses from "./assessmentEvaluateResponses";
import { ItemStateContext } from "@/contexts/questionContext";
import {
  getIntlErrorMessage,
  handleMessage,
  questionPaperMapping,
} from "@/utils/functions";
import { authenticatedAxios } from "@/utils/axiosInterceptor";
import { CustomAxiosRequestConfig } from "@/types/functionTypes";
import { URL } from "@/utils/service-urls";
import { EvaluateProps } from "@/types/componentTypes";
import EvaluateDashboard from "./assessmentEvaluateDashboard";
import { CiEdit } from "react-icons/ci";
import RemarkModal from "./addRemarksModal";
import SubmitScore from "./assessmentSubmitResponse";
import VivaResponses from "./vivaResponses";
import ButtonComponent from "./buttonComponent";
import UploadTestFiles from "./uploadTestFiles";
import UploadResponses from "./uploadResponses";
import HorizontalBarChart from "./HorizontalBarChart";
import CandidateFeedback from "./candidateFeedback";
import AssesmentCandidatePerformanceDashboard from "./assessmentCandidatePerformanceDashboard.tsx";

const Evaluate: React.FC<EvaluateProps> = ({ candidatesDetails }) => {
  
  const [candidateAssesmentData,SetcandidateAssessmentData]=useState<any>([]);
  const [candidateSelected, setCandidateSelected] = useState(false);
  const assessmentId = localStorage.getItem(localStorageKeys?.assessmentId);
  const [selectedId, setSelectedCandiadateId] = useState<any>();
  const [answers, setAnswers] = useState<any>([]);
  const [selectedMenu, setSelectedMenu] = useState<string>(
    evaluateTitles?.theoryResponses,
  );
  const [setId, setSetId] = useState<number>();
  const candidateId = localStorage.getItem(localStorageKeys.candidateId);
  const [isClicked, setIsClicked] = useState({
    review: false,
    evaluate: false,
  });
  const [currentCandidateId, setCurrentCandidateId] = useState<string | null>(
    null,
  );
  const {
    isBackClick,
    setIsbackClick,
    selectedUploadType,
    setSelectedUploadType,
    uploadClicked,
    setUploadClicked,
  }: any = useContext(ItemStateContext);
  const [candidatesList, setCandidatesList] = useState([]);
  const [examStatus, setExamStatus] = useState<any>();
  const statusTypes = {
    completed: "Completed",
    pending: "Pending",
    evaluated: "Evaluated",
    blocked: "Blocked",
    attended: "Attended",
    notAttended: "Not Attended",
    markAsAbsent: "Marked as absent",
  };
  const [isDashboardItemClicked, setIsDashboardItemClicked] = useState(false);
  const [individualScores,SetindividualScores]=useState<any>({});
  const [isBackClicked, setIsBackClicked] = useState(false);
  const [remarks, setRemarks] = useState("");
  const assessmentDetails: any = JSON.parse(
    localStorage.getItem(localStorageKeys.assessmentDetails) ?? "{}",
  );
  const assessmentCategories = Object?.keys(
    assessmentDetails?.question_paper_details?.category || {},
  );
  const [evaluationStatus, setEvaluationStatus] = useState("");
  const [remarksSubmitted, setRemarksSubmitted] = useState(false);
  const evaluateMenuTabs = [
    assessmentCategories.includes("theory") && {
      key: 1,
      title: evaluateTitles?.theoryResponses,
    },
    assessmentCategories.includes("practical") && {
      key: 2,
      title: evaluateTitles?.practicalResponses,
    },
    assessmentCategories.includes("viva") && {
      key: 3,
      title: evaluateTitles?.vivaResponses,
    },
    { key: 4, title: evaluateTitles?.submitScore },
    { key: 5, title: evaluateTitles?.uploads },
    {key:6 , title: evaluateTitles?.assessmentCandidatePerformanceDashboard},
    { key: 7, title: evaluateTitles?.candidateFeedback }
  ].filter(Boolean);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModal = (candidateId: string) => {
    setRemarksSubmitted(false);
    setCurrentCandidateId(candidateId);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    // setCurrentCandidateId(null);
  };
  const handleSubmitRemarks = (remarks: string) => {
    if (remarks) {
      const body = {
        requestBody: {
          is_attempted: false,
          remarks: remarks,
        },
      };
      const url = `${URL?.ASSESSMENT?.CANDIDATE_ASSESSMENT}/${assessmentId}/${URL?.ASSESSMENT?.CANDIDATES}/${candidateId}/${URL?.ASSESSMENT?.FINAL_EVALUATION}`;
      authenticatedAxios
        .put(url, body, { authenticated: true } as CustomAxiosRequestConfig)
        .then((res) => {
          if (res.status == 200) {
            setRemarksSubmitted(true);
            return res;
          } else {
            const errorMsg = getIntlErrorMessage(
              "SUBMIT_SCORES",
              "SUBMIT_SCORES_MESSAGE",
            );
            handleMessage(messageEnum.error, errorMsg);
          }
        });
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    setIsbackClick([]);
    setIsClicked({ review: false, evaluate: false });
  }, [selectedMenu]);

  useEffect(() => {
    if (remarksSubmitted === true) {
      getAllCandidates(evaluationStatus);
    }
  }, [remarksSubmitted]);
  const getAllCandidates = (status) => {
    setExamStatus(status);
    const url = `${URL.ASSESSMENT.CANDIDATES_ASSESSMENT}/${assessmentId}/${URL.ASSESSMENT.CANDIDATES}/?status=${status}`;
    try {
      authenticatedAxios
        .get(url, { authenticated: true } as CustomAxiosRequestConfig)
        .then((response) => {
          if (response?.status == 200) {
            setCandidatesList(response?.data?.responseBody);
            setIsDashboardItemClicked(true);
            setIsBackClicked(false);
          } else {
            const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
            handleMessage(messageEnum.error, errorMsg);
          }
        })
        .catch(() => {
          const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
          handleMessage(messageEnum.error, errorMsg);
        });
    } catch (error) {
      const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
      handleMessage(messageEnum.error, errorMsg);
    }
  };

  // const attenenceItemClickCallback = (record: any) => {
  //   console.log("attenenceItemClickCallback clicked", record);
  // };

  const baseClasses =
    "px-2 py-3 bg-[white] text-primary cursor-pointer border-b-[1px] border-[#DEE1E6] border-solid";
  const activeClasses = "font-semibold";
  const inactiveClasses = "font-normal";

  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      render: (_: any, __: any, index: number) => index + 1,
    },
    {
      title: "Candidate Id",
      dataIndex: "candidate_id",
      render: (id: string, record: any) => {
        return (
          <div
            className="underline text-[#1976D2] cursor-pointer"
            onClick={() => handleCandidateIdClick(id, record?.set_id)}>
            {id}
          </div>
        );
      },
    },
    {
      title: "Candidate Name",
      dataIndex: "full_name",
    },
    {
      title: "Attendance",
      dataIndex: "status",
      render: (text: string, record: any) => {
        let backgroundColor = "";
        let textColor = "black";
        let tagName = "";
        if (
          text === statusTypes?.completed ||
          text === statusTypes?.evaluated
        ) {
          backgroundColor = "#F2FCF5";
          textColor = "#27CA5F";
          tagName = statusTypes?.attended;
        } else if (text === statusTypes?.pending) {
          if (record?.is_attempted === false) {
            backgroundColor = "#FDF2F2";
            textColor = "#D32F2F";
            tagName = statusTypes?.markAsAbsent;
          } else {
            backgroundColor = "#FDF2F2";
            textColor = "#D32F2F";
            tagName = statusTypes?.notAttended;
          }
        } else if (text === statusTypes?.blocked) {
          backgroundColor = "#FDF2F2";
          textColor = "#D32F2F";
          tagName = statusTypes?.blocked;
        } else {
          return null;
        }

        return (
          <div className="flex gap-3 items-center justify-center">
            <Tag
              style={{
                backgroundColor: backgroundColor,
                color: textColor,
                borderRadius: "100px",
                padding: "5px 10px",
                border: "none",
                fontSize: "12px",
              }}>
              {tagName}
            </Tag>
            {record?.status === statusTypes.completed &&
              record?.is_attempted !== false && (
                <div>
                  <CiEdit
                    size={24}
                    className="cursor-pointer"
                    onClick={() => handleOpenModal(record?.candidate_id)}
                  />
                </div>
              )}
            <RemarkModal
              isOpen={isModalOpen}
              onRequestClose={handleCloseModal}
              onSubmit={handleSubmitRemarks}
              remarks={remarks}
              setRemarks={setRemarks}
            />
          </div>
        );
      },
    },
    {
      title: "Evaluation Status",
      dataIndex: "evaluation_status",
      render: (text: string) => {
        let backgroundColor = "";
        let textColor = "black";
        let tagName = "";
        if (text === statusTypes?.evaluated) {
          backgroundColor = "#F2FCF5";
          textColor = "#27CA5F";
          tagName = statusTypes?.completed;
        } else if (
          text === statusTypes?.pending ||
          text == statusTypes?.completed
        ) {
          backgroundColor = "#F1F8FD";
          textColor = "#1976D2";
          tagName = statusTypes?.pending;
        } else if (text === statusTypes?.blocked) {
          backgroundColor = "#FDF2F2";
          textColor = "#D32F2F";
          tagName = statusTypes?.blocked;
        } else {
          return null;
        }

        return (
          <Tag
            style={{
              backgroundColor: backgroundColor,
              color: textColor,
              borderRadius: "100px",
              padding: "5px 10px",
              border: "none",
              fontSize: "12px",
            }}>
            {tagName}
          </Tag>
        );
      },
    },
    {
      title: "Score",
      dataIndex: "total",
    },
    {
      title: "Grade",
      dataIndex: "grade",
    },
  ];

  // const candidates_list = [
  //   {
  //     candidate_id: 34567,
  //     full_name: "Nagajyothi",
  //     status: "pending",
  //     set_id: 1,
  //   },
  //   {
  //     candidate_id: 76543,
  //     full_name: "Prasad",
  //     status: "completed",
  //     set_id: 2,
  //   },
  //   {
  //     candidate_id: 45678,
  //     full_name: "Aruna",
  //     status: "evaluated",
  //     set_id: 1,
  //   },
  // ];
  const evalueteScreens: { [key: string]: JSX.Element } = {};
  if (assessmentCategories.includes("theory")) {
    evalueteScreens["Theory Responses"] = (
      <EvaluateResponses
        response={evaluationMenuTabs?.theoryResponses}
        isClicked={isClicked}
        setIsClicked={setIsClicked}
        selectedSetId={setId}
        answers={answers}
        examStatus={examStatus}
      />
    );
  }

  if (assessmentCategories.includes("practical")) {
    evalueteScreens["Practical Responses"] = (
      <EvaluateResponses
        isClicked={isClicked}
        response={evaluationMenuTabs?.practicalResponse}
        setIsClicked={setIsClicked}
        selectedSetId={setId}
        answers={answers}
        examStatus={examStatus}
      />
    );
  }

  if (assessmentCategories.includes("viva")) {
    evalueteScreens["Viva Responses"] = (
      <VivaResponses candidateDetails={candidatesDetails} />
    );
  }
   


  evalueteScreens["Submit Score"] = (
    <SubmitScore key={"details"} answers={answers} />
  );
  evalueteScreens["Uploads"] = <UploadResponses key={"upload"} />;

  evalueteScreens["Candidate Feedback"] = <CandidateFeedback assessmentId={assessmentId} candidateId={selectedId}/>

  const handleCandidateIdClick = (id: any, set_id: number) => {
    setCandidateSelected(true);
    setSelectedCandiadateId(id);
    setSelectedMenu(evaluateTitles?.theoryResponses);
    setSetId(set_id);
    setIsbackClick((prev: any) => [
      ...prev,
      backButtonStack?.candidateSelectedScreen,
    ]);
    localStorage.setItem(localStorageKeys?.candidateId, id);
    const assessmentId = localStorage.getItem(localStorageKeys?.assessmentId);
    const url = `${URL.ASSESSMENT.CANDIDATE_ASSESSMENT}/${assessmentId}/${URL.ASSESSMENT.CANDIDATE_SET}/${set_id}`;
    const answersUrl = `${URL.ASSESSMENT.CANDIDATE_ASSESSMENT}/${assessmentId}/${URL.ASSESSMENT.CANDIDATE_SET}/${set_id}?answer=true`;
    const evaluationsUrl = `${URL.ASSESSMENT.CANDIDATE_ASSESSMENT}/${assessmentId}/${URL.ASSESSMENT.CANDIDATES}/${candidateId}/evaluations`;
    try {
      authenticatedAxios
        .get(url, { authenticated: true } as CustomAxiosRequestConfig)
        .then((response) => {
          if (response?.status == 200) {
            questionPaperMapping(JSON?.stringify(response?.data?.responseBody));
          } else {
            const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
            handleMessage(messageEnum.error, errorMsg);
          }
        })
        .catch((error) => {
          const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
          handleMessage(messageEnum.error, errorMsg);
        });

      authenticatedAxios
        .get(answersUrl, { authenticated: true } as CustomAxiosRequestConfig)
        .then((response) => {
          if (response?.status == 200) {
            localStorage.setItem("answerKey", response?.data?.responseBody);
          } else {
            const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
            handleMessage(messageEnum.error, errorMsg);
          }
        })
        .catch(() => {
          const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
          handleMessage(messageEnum.error, errorMsg);
        });

      authenticatedAxios
        .get(evaluationsUrl, {
          authenticated: true,
        } as CustomAxiosRequestConfig)
        .then((response) => {
          if (response?.status == 200) {
            localStorage.setItem(
              "answers",
              JSON.stringify(response?.data?.responseBody),
            );
            setAnswers(response?.data?.responseBody);
          } else {
            localStorage.setItem("answers", "");
            const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
            handleMessage(messageEnum.error, errorMsg);
          }
        })
        .catch(() => {
          localStorage.setItem("answers", "");
          const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
          handleMessage(messageEnum.error, errorMsg);
        });
    } catch (error) {
      const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
      handleMessage(messageEnum.error, errorMsg);
    }
  };

  const handleBack = () => {
    const popedItem = isBackClick.pop();
    if (popedItem == backButtonStack?.reviewEvaluate) {
      setIsClicked({ review: false, evaluate: false });
    } else {
      setCandidateSelected(false);
    }
  };

  const handleInvigilationMenuClick = (title: string) => {
    
    setSelectedMenu(title);
  };
  const handleUploadScores = () => {
    setSelectedUploadType(3);
    setUploadClicked(true);
  };
  const [dashboardData, setDashboardData] = useState([
    { name: "Planned", description: "0", key: "Pending", status: "status" },
    { name: "Executed", description: "0", key: "Completed", status: "status" },
    {
      name: "Evaluated",
      description: "0",
      key: "Completed",
      status: "evaluation_status",
    },
    {
      name: "Published",
      description: "0",
      key: "Completed",
      status: "evaluation_status",
    },
  ]);
  const [presentScreenStatus, setPresentScreenStatus] = useState("Pending");

  const handleDescriptionClick = (item: any) => {
    setPresentScreenStatus(item.key);
    setEvaluationStatus(item.key);
    getAllCandidates(item.key);
  };
  const handleBackIconClicked = () => {
    setIsBackClicked(true);
  };

  const getCandidatesCount = () => {
    const url = `${URL.ASSESSMENT.CANDIDATES_ASSESSMENT}/${assessmentId}/${URL.ASSESSMENT.CANDIDATES}/${URL.ASSESSMENT.STATUS}`;
    try {
      authenticatedAxios
        .get(url, { authenticated: true } as CustomAxiosRequestConfig)
        .then((response) => {
          if (response?.status == 200) {
            if (response?.data?.responseBody?.length) {
              response?.data?.responseBody?.forEach((item) => {
                const dashboardItemIndex = dashboardData.findIndex(
                  (dataItem) =>
                    item.status === dataItem.key &&
                    item.type === dataItem.status,
                );
                const newData = [...dashboardData];
                
                newData[dashboardItemIndex].description = item.count;
                setDashboardData(newData);
              });
            }
          } else {
            const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
            handleMessage(messageEnum.error, errorMsg);
          }
        })
        .catch(() => {
          return;
          const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
          handleMessage(messageEnum.error, errorMsg);
        });
    } catch (error) {
      const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
      handleMessage(messageEnum.error, errorMsg);
    }
  };

  useEffect(() => {
    getCandidatesCount();
  }, []);

  const [averages, setAverages] = useState([
    { name: "Average Score", description: 0 },
    { name: "Theory Average", description: 0 },
    { name: "Practicals Average", description: 0 },
    { name: "Viva Average", description: 0 },
  ]);
  
 
  const getAssessmentResults = async () => {
    const url = `${URL.ASSESSMENT.ASSESSMENT_RESULTS}/${assessmentId}`;

    try {
      const response = await authenticatedAxios.get(url, {
        authenticated: true,
      } as CustomAxiosRequestConfig);

      if (response?.data?.responseBody?.length) {
        const results = response.data.responseBody; // Calculate averages
        const totalResults = results.length;
        let totalScore = 0,
          totalTheory = 0,
          totalPracticals = 0,
          totalViva = 0;
        results.forEach((student) => {
          totalScore += student.total || 0;
          totalTheory += student.theory || 0;
          totalPracticals += student.practical || 0;
          totalViva += student.viva || 0;
        });
        
       
        // Update the averages state
        setAverages([
          { name: "Average Score", description: parseFloat((totalScore / totalResults).toFixed(2)) },
          { name: "Theory Average", description: parseFloat((totalTheory / totalResults).toFixed(2)) },
          { name: "Practicals Average", description: parseFloat((totalPracticals / totalResults).toFixed(2)) },
          { name: "Viva Average", description: parseFloat((totalViva / totalResults).toFixed(2)) },
        ]);
       SetcandidateAssessmentData(results);
      } else {
        const errorMsg = getIntlErrorMessage("GENERAL", "NO_DATA");
        handleMessage(messageEnum.error, errorMsg);
      }
    } catch (error) {
      const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
      handleMessage(messageEnum.error, errorMsg);
    }
  };
  evalueteScreens["Reports and Downloads"]=(< AssesmentCandidatePerformanceDashboard key={"performanceDashboard"} averages={averages} individualScores={individualScores}/>)
  // backend call to retrive the scores of the candidates
  useEffect(()=>
{
  if(selectedId)
  {
    
    const data=candidateAssesmentData.filter((result)=>(result.candidate_id=== selectedId));
    SetindividualScores(data);
    console.log(individualScores,"individualScores");
  }
},[selectedId])
  useEffect(()=>
  {
    getAssessmentResults();

  },[])

const handleDownloadResult = () => {
  

  // Extract the required fields and include headings
  const data = [
    ["category", "score"], // Add headings as the first row
    ...dashboardData.map(({ name, description }) => [name, description]), // Include the fields from dashboardData
  ];


  // Create a worksheet with headings
  const worksheet = XLSX.utils.aoa_to_sheet(data); // Converts array of arrays to a worksheet

  // Ensure "!ref" is defined before applying styles
  if (worksheet["!ref"]) {
    const headingRange = XLSX.utils.decode_range(worksheet["!ref"]); // Safe access

    // Apply bold styling to the first row (headings)
    for (let C = headingRange.s.c; C <= headingRange.e.c; C++) {
      const cellAddress = XLSX.utils.encode_cell({ r: 0, c: C }); // First row (r: 0)
      if (worksheet[cellAddress]) {
        worksheet[cellAddress].s = {
          font: { bold: true }, // Apply bold styling
          
        };
      }
    }
  }

  // Create a workbook and append the worksheet
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Dashboard Data");

  // Trigger the download
  XLSX.writeFile(workbook, "candidate_results.xlsx");
};
//  excel download
  

  
  return(
    <div>
      {!isDashboardItemClicked || isBackClicked ? (
        <div  className="flex flex-col overflow-y-auto h-screen">
          <EvaluateDashboard
            key={"evaluate"}
            items={dashboardData}
            onDescriptionClick={handleDescriptionClick}
          />
          <div className="flex justify-end items-center mr-[16px] mb-[8px]">
            <button className="bg-primary px-[12px] py-[5px] rounded-md font-semibold text-[white]" onClick={handleDownloadResult}>Download Results</button>
          </div>
          <div className="overflow-auto h-full px-4 mb-12">
            <div className="flex-1 flex mb-12">
              {/* Left Section: Charts */}
              <div className="w-[70%] flex flex-col overflow-y-auto h-[calc(100vh-100px)]">
                <div className="my-[10px]">
                  <HorizontalBarChart id={"Chart1"} dashboardData={dashboardData}value={'Candidates'}/>
                </div>
                <div className="my-[10px]">
                  <HorizontalBarChart id={"Chart2"} dashboardData={averages} value={'Score'}/>
                </div>
              </div>

              {/* Right Section: Averages */}
              <div className="w-[30%] flex flex-col overflow-y-auto pb-5">
                <div className="flex flex-col space-y-4 overflow-auto">
                  {(averages)&&averages.map((average, index) => (
                    <div
                      key={index}
                      className="flex flex-col bg-[#DEE1E6] space-y-4 items-center justify-center rounded-lg py-4"
                    >
                      <div>{average.name}</div>
                      <div>{average.description}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          {!candidateSelected && !isBackClicked ? (
            <div className="flex-1 flex overflow-y-auto flex-col">
              <div
                className="cursor-pointer flex items-center text-[18px] gap-1 font-normal pt-[16px] text-primaryText ml-4 w-fit"
                onClick={handleBackIconClicked}>
                <IoArrowBack size={22} />
                <div>{contentDivText.back}</div>
              </div>
              <div className="mx-[16px] table-wrapper ">
                <div className="flex flex-col">
                  {(presentScreenStatus == "Completed" ||
                    presentScreenStatus == "Evaluated") &&
                  candidatesList?.length ? (
                    <div className="flex justify-end mb-[20px]">
                      <ButtonComponent
                        text={buttonTitle?.uploadScores}
                        className={
                          "px-[10px] py-[5px] text-[14px] border-none rounded-md bg-primary  text-[white]"
                        }
                        onClick={handleUploadScores}
                      />
                    </div>
                  ) : null}
                  <div className="table-wrapper">
                    <Table
                      dataSource={candidatesList}
                      columns={columns}
                      pagination={false}
                      scroll={{ y: 400 }}
                      className="custom-table fix-Header"
                    />
                  </div>
                </div>
                {uploadClicked && (
                  <UploadTestFiles
                    uploadClicked={uploadClicked}
                    setUploadClicked={setUploadClicked}
                    selectedOption={selectedUploadType}
                    setSelectedUploadType={setSelectedUploadType}
                  />
                )}
              </div>
            </div>
          ) : (
            <div className="pl-[16px]">
              <div
                className="cursor-pointer flex items-center text-[18px] gap-1 font-normal pt-[16px] text-primaryText"
                onClick={handleBack}>
                <IoArrowBack size={22} />
                <div>{contentDivText.back}</div>
              </div>

              <div className="border-gray flex gap-2 border-t-[2px] mt-[16px] pt-2 w-full flex-1 h-[100vh]">
                <div className="w-[83%]">
                  <div>
                    <div className="pb-1">
                      {contentDivText?.candidateId}
                      <span>{selectedId}</span>
                    </div>
                  </div>
                  {evalueteScreens[selectedMenu]}
                </div>
                <div className="w-[17%] bg-[#DEE1E6] p-1 flex flex-col flex-1 overflow-y-auto">
                  {evaluateMenuTabs.map((item: any) => (
                    <div
                      key={item.key}
                      className={classNames(
                        baseClasses,
                        selectedMenu === item.title
                          ? activeClasses
                          : inactiveClasses,
                      )}
                      onClick={() => handleInvigilationMenuClick(item.title)}>
                      {item.title}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Evaluate;
