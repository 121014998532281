import React, { useContext } from "react";
import Webcam from "react-webcam";
import { useWebcamStatus } from "@/hooks/useWebcamStatus";
import ButtonComponent from "@/components/buttonComponent";
import ImageWithBasePath from "@/components/imageComponent";
import ModalComponent from "@/components/customModal";
import {
  buttonTitle,
  contentDivText,
  modalContent,
} from "@/constants/constants";
import { ItemStateContext } from "@/contexts/questionContext";
import { WebcamComponentProps } from "@/types/componentTypes";

const WebcamComponent: React.FC<WebcamComponentProps> = ({
  title,
  content,
  capturedImage,
  handleCapture,
  handleVerify,
  handleRetake,
  isModalOpen,
  webcamRef,
  isFromQuestions,
}) => {
  const webcamEnabled = useWebcamStatus();
  const { setIsModalOpen, steps, setSteps }: any = useContext(ItemStateContext);
  return (
    <div className="flex flex-col justify-around  h-[100%]">
      <div className="px-[48px] pt-[48px]">
        <div className="text-primaryText font-bold text-[20px] pb-2">
          {title}
        </div>
        <div className="text-[15px] text-primaryText">{content}</div>
      </div>
      <div className="flex flex-col items-center justify-between h-[70%]">
        {capturedImage ? (
          <ImageWithBasePath
            isUrl={true}
            src={capturedImage}
            width={400}
            height={300}
            className="rounded-xl m-[20px]"
          />
        ) : (
          <div className="flex flex-col justify-center items-center ">
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              width={400}
              height={300}
              className="rounded-xl m-[20px] bg-[#DDDDDD] w-[400px] h-[300px]"
            />
            {!webcamEnabled && webcamEnabled !== null && (
              <div className="text-deniedText">
                {contentDivText.camDisableContent}
              </div>
            )}
          </div>
        )}

        <div className=" flex justify-center p-[20px] ">
          {!capturedImage ? (
            <ButtonComponent
              text={buttonTitle.capture}
              onClick={handleCapture}
              className="px-14 py-2 rounded-lg bg-primary border-[1px] border-[white] text-[white] font-medium"
            />
          ) : (
            <div className="space-x-3">
              <ButtonComponent
                text={buttonTitle.retake}
                onClick={handleRetake}
                className="px-14 py-2 rounded-lg border-[1px] font-medium bg-ButtonGreen border-[white] text-ButtonsecondaryText"
              />
              <ButtonComponent
                text={buttonTitle.upload}
                onClick={handleVerify}
                className="px-14 py-2 rounded-lg bg-primary border-[1px] border-[white] text-[white] font-medium"
              />
            </div>
          )}
        </div>
        {capturedImage && isModalOpen && !isFromQuestions && (
          <ModalComponent
            title={modalContent.begunAssessment}
            titleClass={
              "text-primaryText text-[20px] font-semibold pb-[20px] text-center"
            }
            buttonName={buttonTitle.readyStartNow}
            buttonClass={"flex justify-center items-center text-[white]"}
            width={520}
            modalButtonClick={() => {
              setSteps(steps + 1);
            }}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
          />
        )}
      </div>
    </div>
  );
};

export default WebcamComponent;
