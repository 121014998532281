import React, { useState } from "react";
import ModalComponent from "./customModal";
import {
  Button,
  Progress,
  Radio,
  Space,
  UploadFile,
  RadioChangeEvent,
} from "antd";
import Dragger from "antd/es/upload/Dragger";
import {
  buttonTitle,
  modalContent,
  messageEnum,
  folderTypes,
  localStorageKeys,
} from "@/constants/constants";
import { BsCloudUpload } from "react-icons/bs";
import {
  getBase64,
  getIntlErrorMessage,
  getIntlSuccessMessage,
  getIntlWarningMessage,
  handleMessage,
} from "@/utils/functions";
import { DeleteOutlined } from "@ant-design/icons";
import {
  adminAxios,
  authenticatedAxios,
  axios,
} from "@/utils/axiosInterceptor";
import { CustomAxiosRequestConfig } from "@/types/functionTypes";
//import { FileType } from "@/types/contextTypes";
import { URL } from "@/utils/service-urls";
import { url } from "inspector";
import { FileType } from "@/types/contextTypes";

const UploadTestFiles = ({
  uploadClicked,
  setUploadClicked,
  selectedOption,
  setSelectedUploadType,
}) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [progressList, setProgressList] = useState<Record<string, number>>({});
  const [uploadCompletedList, setUploadCompletedList] = useState<
    Record<string, boolean>
  >({});
  const stakeholderDetails = JSON.parse(
    localStorage.getItem(localStorageKeys?.stakeholderDetails) ?? "{}",
  );
  const assessmentId = localStorage.getItem(localStorageKeys?.assessmentId);
  const candidateId = localStorage.getItem(localStorageKeys?.candidateId);

  const [uploading, setUploading] = useState<boolean>(false);
  const [nextClicked, setNextClicked] = useState<boolean>(false);
  const [evidenceType, setEvidenceType] = useState(0);
  const allowedResponsesFiles = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];
  const allowedEvidencesVideoTypes = [
    "video/mp4",
    "video/quicktime",
    "audio/mpeg",
    "video/mpeg",
  ];
  const allowedImageTypes = ["image/png", "image/jpeg"];
  const uploadNames = {
    uploadingResponses: "uploading Responses",
    uploadedResponses: "uploaded Responses",
    uploadingEvidences: "uploading Evidences",
    uploadedEvidences: "uploaded Evidences",
    uploadResponses: "Upload Responses",
    uploadEvidences: "Uplaod Evidences",
    uploadScores: "Upload Scores",
    uploadingFiles: "Uploading Files",
    uploadedFiles: "Uploaded Files",
    images: "Upload Images",
    videos: "Upload Videos",
    candidateId: "Upload Candidate id proof",
    idProof: "Upload Id proof",
  };
  const evidenceTypes = [
    { key: 1, value: "image" },
    { key: 2, value: "video" },
    { key: 3, value: "candidate id proof" },
    { key: 4, value: "id proof" },
  ];
  const sampleFile = {
    excel: "candidate_answers_sample.xlsx",
    image: "sampleImage.png",
    video: "sampleVideo.mp4",
  };

  const bulkUpload = async (formData) => {
    try {
      const table =
        selectedOption == 3 ? `assessment_evaluations` : `candidate_answers`;
      const url = `${process.env.REACT_APP_ADMIN_URL}/bulk-upload?tableName=${table}`;
      const token = sessionStorage.getItem(localStorageKeys.jwt);
      const config = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      };
      console.log(config);
      // @ts-ignore
      const res = await axios.post(url, formData, {
        headers: config,
      });
      console.log(res);
      if (res?.status === 200) {
        setUploadClicked(false);
        setSelectedUploadType(0);
        const successMsg = getIntlSuccessMessage(
          "FILE_POSTED_MESSAGE",
          "POSTED_SUCCESS",
        );
        handleMessage(messageEnum.success, successMsg);
        setUploading(false);
      } else {
        const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
        handleMessage(messageEnum.error, errorMsg);
        setUploading(false);
      }
    } catch (error) {
      const errorMsg = getIntlErrorMessage(
        "FILE_POSTED_MESSAGE",
        "POSTED_FAILED_MESSAGE",
      );

      handleMessage(messageEnum.error, errorMsg);
      setUploading(false);
      console.error("Error:", error);
    }
  };

  const uploadEvidences = (uploadedData: any, endpoint: any) => {
    try {
      const url = URL.SERVICES.UPLOAD;
      authenticatedAxios
        .post(url, uploadedData, {
          authenticated: true,
        } as CustomAxiosRequestConfig)
        .then(async (res) => {
          if (res.status == 200) {
            const url = `${URL.ASSESSMENT.CANDIDATE_ASSESSMENT}/${assessmentId}/candidate/${candidateId}/evidences/?type=${endpoint}`;
            const data = {
              requestBody: {
                key: res.data.responseBody.key,
                stakeholderId: Number(stakeholderDetails?.id),
              },
            };
            const response = await authenticatedAxios.post(url, data, {
              authenticated: true,
            } as CustomAxiosRequestConfig);
            if (response?.status === 200) {
              setUploadClicked(false);
              setSelectedUploadType(0);
              const successMsg = getIntlSuccessMessage(
                "FILE_POSTED_MESSAGE",
                "POSTED_SUCCESS",
              );
              handleMessage(messageEnum.success, successMsg);
              setUploading(false);
            } else {
              const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
              handleMessage(messageEnum.error, errorMsg);
              setUploading(false);
            }
          } else {
            const errorMsg = getIntlErrorMessage(
              "FILE_POSTED_MESSAGE",
              "POSTED_FAILED_MESSAGE",
            );
            handleMessage(messageEnum.error, errorMsg);
            setUploading(false);
          }
        });
    } catch (error) {
      const errorMsg = getIntlErrorMessage(
        "FILE_POSTED_MESSAGE",
        "POSTED_FAILED_MESSAGE",
      );
      handleMessage(messageEnum.error, errorMsg);
      setUploading(false);
    }
  };

  const handleUploadToDB = () => {
    const files = [...fileList];
    if (files?.length <= 0) {
      // setUploading(false);
      const warningMsg = getIntlWarningMessage(
        "EMPTY_FILE_UPLOAD",
        "EMPTY_UPLOADING",
      );
      handleMessage(messageEnum.warning, warningMsg);
      return;
    }
    if (hasUploadingFiles) {
      return;
    }
    setUploading(true);
    //files.forEach(async (file: any) => {
    const formData = new FormData();
    // @ts-ignore
    formData.append("file", files[0]);

    if (selectedOption !== 2 || selectedOption == 3) {
      bulkUpload(formData);
    } else {
      files.forEach(async (file: any) => {
        const fileUploaded = await getBase64(file as FileType);

        const uploadingImages = {
          requestBody: {
            folder:
              evidenceType === 2
                ? folderTypes?.videoType
                : folderTypes?.imageType,
            file: fileUploaded,
          },
        };

        const getEndPoint = (evidenceType) => {
          let endpoint: any;
          if (evidenceType == 1) {
            endpoint = "image";
          } else if (evidenceType == 2) {
            endpoint = "video";
          } else if (evidenceType == 3) {
            endpoint = "candidate_proof";
          } else if (evidenceType == 4) {
            endpoint = "id_proof";
          }
          return endpoint;
        };

        const endPoint = getEndPoint(evidenceType);

        await uploadEvidences(uploadingImages, endPoint);
      });
    }
  };

  const handleFileChange = (file: UploadFile) => {
    const maxSize = 2 * 1024 * 1024;
    let allowedTypes: string[] = [];

    if (selectedOption === 1) {
      allowedTypes = allowedResponsesFiles;
    } else if (selectedOption === 2) {
      if (evidenceType === 1 || evidenceType === 3 || evidenceType === 4) {
        allowedTypes = allowedImageTypes;
      } else if (evidenceType === 2) {
        allowedTypes = allowedEvidencesVideoTypes;
      }
    } else if (selectedOption === 3) {
      allowedTypes = allowedResponsesFiles;
    }

    const isValidType = allowedTypes.includes(file?.type ?? "");
    const isValidSize = (file?.size ?? 0) <= maxSize;
    const isDuplicate = fileList.some(
      (existingFile) => existingFile.name === file.name,
    );

    if (isDuplicate) {
      const errorMsg = getIntlErrorMessage(
        "UPLOAD_FILE_VIDEO_MESSAGES",
        "DUPLICATE_FILE_MESSAGE",
      );
      handleMessage(messageEnum.error, errorMsg);
      return false;
    }
    if (isValidType && isValidSize && !isDuplicate) {
      setFileList((prev) => [...prev, file]);
      setProgressList((prev) => ({ ...prev, [file.uid]: 0 }));
      setUploadCompletedList((prev) => ({ ...prev, [file.uid]: false }));
      simulateProgress(file);
    } else {
      if (!isValidType && file) {
        const errorMsg = getIntlErrorMessage(
          "UPLOAD_FILE_VIDEO_MESSAGES",
          "FILE_TYPE_MESSAGES",
        );
        const errorMsgNew = getIntlErrorMessage(
          "UPLOAD_FILE_VIDEO_MESSAGES",
          "FILE_TYPE_NOT_SUPPORT",
        );
        handleMessage(
          messageEnum.error,
          `${errorMsg} ${file.type} ${errorMsgNew}`,
        );
      }
      if (!isValidSize) {
        const errorMsg = getIntlErrorMessage(
          "UPLOAD_FILE_VIDEO_MESSAGES",
          "FILE_SIZE_LIMIT_MESSAGE",
        );
        handleMessage(messageEnum.error, errorMsg);
      }
    }
    return isValidType && isValidSize;
  };

  const simulateProgress = (file: UploadFile) => {
    let progressValue = 0;
    const interval = setInterval(() => {
      progressValue += 10;
      setProgressList((prev) => ({ ...prev, [file.uid]: progressValue }));

      if (progressValue >= 100) {
        clearInterval(interval);
        setUploadCompletedList((prev) => ({ ...prev, [file.uid]: true }));
      }
    }, 200);
  };
  const props = {
    name: "file",
    multiple: true,
    fileList,
    beforeUpload: (file: UploadFile) => {
      handleFileChange(file);
      return false;
    },
    onRemove: (file: UploadFile) => {
      setFileList((prevFileList) =>
        prevFileList.filter((item) => item.uid !== file.uid),
      );
      setProgressList((prev) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [file.uid]: _, ...rest } = prev;
        return rest;
      });
      setUploadCompletedList((prev) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [file.uid]: _, ...rest } = prev;
        return rest;
      });
    },
  };
  const hasUploadingFiles = fileList.some(
    (file) => !uploadCompletedList[file.uid],
  );

  const hasUploadedFiles = fileList.some(
    (file) => uploadCompletedList[file.uid],
  );
  const handleDownload = async () => {
    try {
      const table =
        selectedOption == 3 ? `assessment_evaluations` : `candidate_answers`;
      const response: any = await adminAxios.get(
        `bulk-upload/sampleFile?sampleOf=${table}`,
        {
          responseType: "blob",
          authenticated: true,
        } as CustomAxiosRequestConfig,
      );

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.setAttribute("download", sampleFile?.excel);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
      handleMessage(messageEnum?.error, errorMsg);
    }
  };
  const modalTitle = () => {
    if (selectedOption === 1) {
      return uploadNames?.uploadResponses;
    } else if (selectedOption === 3) {
      return uploadNames?.uploadScores;
    } else if (selectedOption === 2) {
      if (evidenceType === 1) {
        return uploadNames?.images;
      } else if (evidenceType === 2) {
        return uploadNames?.videos;
      } else if (evidenceType === 3) {
        return uploadNames?.candidateId;
      } else if (evidenceType === 4) {
        return uploadNames?.idProof;
      }
    } else {
      return;
    }
  };
  const supportedFileDetails = () => {
    if (selectedOption === 1) {
      return modalContent?.supportedResponses;
    } else if (selectedOption === 2) {
      if (evidenceType === 1 || evidenceType === 3 || evidenceType === 4) {
        return modalContent?.supportedEvidencesImages;
      } else if (evidenceType === 2) {
        return modalContent?.supportedEvidencesVideos;
      }
    } else if (selectedOption === 3) {
      return modalContent?.supportedResponses;
    } else {
      return;
    }
  };
  const handleOnChangeRadio = (e: RadioChangeEvent) => {
    setEvidenceType(e.target.value);
  };
  const handleNext = () => {
    if (evidenceType > 0) {
      setNextClicked(true);
    }
  };
  return (
    <div>
      {selectedOption === 2 && !nextClicked ? (
        <ModalComponent
          title={uploadNames?.uploadEvidences}
          width={650}
          className="uploadModal"
          titleClass="text-primaryText text-[20px] font-semibold"
          closeIcon={true}
          isModalOpen={uploadClicked}
          isAssessment={false}
          questionModal={true}
          uploadButtonName1={buttonTitle.cancel}
          uploadButtonName2={buttonTitle?.next}
          handleOnCancel={() => {
            setUploadClicked(false);
          }}
          modalButtonClick={handleNext}
          content={
            <div>
              <div className="py-[10px] font-medium">
                {modalContent?.evidenceType}
              </div>
              <Radio.Group
                onChange={handleOnChangeRadio}
                value={evidenceType}
                className="mt-[5px] ml-[10px]">
                <Space direction="vertical">
                  {evidenceTypes?.map((item) => {
                    return (
                      <Radio
                        className="mt-[5px]"
                        key={item?.key}
                        value={item?.key}>
                        {item?.value}
                      </Radio>
                    );
                  })}
                </Space>
              </Radio.Group>
            </div>
          }
        />
      ) : (
        <ModalComponent
          title={modalTitle()}
          titleEndText={selectedOption === 2 ? false : true}
          titleEndTextButtonClick={handleDownload}
          width={650}
          className="uploadModal"
          titleClass="text-primaryText text-[20px] font-semibold"
          closeIcon={true}
          isModalOpen={uploadClicked}
          isAssessment={false}
          questionModal={true}
          uploadButtonName1={buttonTitle.cancel}
          uploadButtonName2={
            !uploading ? buttonTitle.upload : buttonTitle.uploading
          }
          handleOnCancel={() => {
            setFileList([]);
            setUploadCompletedList({});
            if (selectedOption === 1) {
              setUploadClicked(false);
            } else if (selectedOption === 2) {
              setNextClicked(false);
            } else if (selectedOption === 3) {
              setUploadClicked(false);
              setSelectedUploadType(0);
            }
          }}
          modalButtonClick={handleUploadToDB}
          content={
            <div className="pt-[20px]">
              <Dragger {...props}>
                <p className="flex justify-center">
                  <BsCloudUpload fontSize={50} color="gray" />
                </p>
                <p className="font-semibold text-[20px] text-primaryText pt-2">
                  {modalContent.dropFiles}
                </p>
                <p className="pt-2 text-[13px] text-primaryText">
                  {supportedFileDetails()}
                </p>
                <div className="pt-2 text-primaryText">{modalContent.or}</div>
                <div className="pt-2 font-semibold text-primary">
                  {modalContent.browseFiles}
                </div>
              </Dragger>
              {hasUploadingFiles && (
                <div>
                  <div className="font-semibold text-[16px]">
                    {uploadNames?.uploadingFiles}
                  </div>
                  <div className="max-h-[100px] overflow-y-auto">
                    {fileList.map((file) =>
                      !uploadCompletedList[file.uid] ? (
                        <div key={file.uid} style={{ marginTop: 16 }}>
                          <Progress percent={progressList[file.uid]} />
                          <div className="ml-[2px]">{file.name}</div>
                        </div>
                      ) : null,
                    )}
                  </div>
                </div>
              )}

              {hasUploadedFiles && (
                <div className="font-semibold text-[16px] mt-[16px]">
                  {uploadNames?.uploadedFiles}
                </div>
              )}
              <div className="max-h-[100px] overflow-y-auto">
                {fileList.map((file) =>
                  uploadCompletedList[file.uid] ? (
                    <div
                      key={file.uid}
                      className="flex flex-col mt-[10px] ml-[10px]">
                      <div className="flex justify-between  ml-[2px]">
                        <span>{file.name}</span>
                        <Button
                          type="text"
                          icon={<DeleteOutlined />}
                          onClick={() => {
                            setFileList((prevFileList) =>
                              prevFileList.filter(
                                (item) => item.uid !== file.uid,
                              ),
                            );
                            setProgressList((prev) => {
                              // eslint-disable-next-line @typescript-eslint/no-unused-vars
                              const { [file.uid]: _, ...rest } = prev;
                              return rest;
                            });
                            setUploadCompletedList((prev) => {
                              // eslint-disable-next-line @typescript-eslint/no-unused-vars
                              const { [file.uid]: _, ...rest } = prev;
                              return rest;
                            });
                          }}
                        />
                      </div>
                    </div>
                  ) : null,
                )}
              </div>
            </div>
          }
        />
      )}
    </div>
  );
};

export default UploadTestFiles;
