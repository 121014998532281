import {
  ItemStateContextValues,
  PermissionsState,
  VerificationImage,
} from "@/types/contextTypes";
import { totalParts } from "@/constants/constants";
import moment from "moment";
import React, {
  useState,
  createContext,
  useRef,
  ReactNode,
  useMemo,
} from "react";
import { convertMinutesToHHMMSS, parseTime } from "@/utils/functions";
export const ItemStateContext = createContext<
  ItemStateContextValues | undefined
>(undefined);

interface ItemStateProviderProps {
  children: ReactNode;
}

export const ItemStateProvider: React.FC<ItemStateProviderProps> = ({
  children,
}) => {
  const assessmentDetails: any = JSON.parse(
    localStorage.getItem("assessment_details") ?? "{}",
  );
  const [items, setItems] = useState({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [currentSection, setCurrentSection] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState("En");
  const [sectionNumber, setSectionNumber] = useState(1);
  const [responses, setResponses] = useState({});
  const [showAllQuestions, setShowAllQuestions] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const [isStarted, setIsStarted] = useState(false);
  const [questions, setQuestions] = useState<any[]>([]);
  const [verificationImage, setVerificationImage] = useState<VerificationImage>(
    {
      face: null,
      id: null,
    },
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBackClick, setIsbackClick] = useState([]);
  const [allPermissions, setAllPermissions] = useState<PermissionsState>({
    webcamEnabled: null,
    locationEnabled: null,
    microphoneEnabled: null,
    browserCheck: null,
    isOnline: window.navigator.onLine,
    networkSpeed: null,
    screenSharingAllowed: null,
    browserName: "",
    osName: "",
    vpnDisabled: null,
  });
  const [loggedEvents, setLoggedEvents] = useState<any[]>([]);
  const [fromSampleAssessment, setFromSampleAssessment] = useState(false);
  const [steps, setSteps] = useState(0);
  const [singleQuestionType, setSingleQuestionType] = useState("");
  const streamRef = useRef();
  const [currentLocation, setCurrentLocation] = useState({
    latitude: "",
    longitude: "",
    address: "",
  });
  const [violationChecks, setViolationChecks] = useState({
    ip: "",
    city: "",
    region: "",
    vpn: "",
  });
  const [selectedPart, setSelectedPart] = useState(totalParts[0]);
  const [uploadModalOpen, setUploadModalOpen] = useState({
    fileModal: false,
    videoModal: false,
  });
  const [vivaResponse, setVivaResponse] = useState([]);
  const [activityResponse, setActivityResponse] = useState([]);
  const [timer, setTimer] = useState(10);
  const [isVivaStarted, setVivaStarted] = useState<any>(null);
  const [recordVideo, setRecordVideo] = useState(false);
  const [inVivaQuestion, setVivaQuestion] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<any>("");
  const [selectedName, setSelectedName] = useState<any>({});
  const [sentMessages, setSentMessages] = useState<any>([]);
  const [receivedMessages, setReceivedMessages] = useState<any>([]);
  const [selectedOption, setSelectedOption] = useState("");

  const [zimInstance, setZimInstance] = useState<any>(null);
  const [zegoToken, setZegoToken] = useState<string | null>(null);
  const [countdown, setCountdown] = useState(
    parseTime(
      convertMinutesToHHMMSS(
        assessmentDetails?.question_paper_details?.time_limit,
      ),
    ),
  );
  const [isConnected, setIsConnected] = useState(false);
  const [ischatRoomOpen, setIsChatRoomOpen] = useState<boolean>(false);
  const [feedBackModal, setFeedBackModal] = useState<boolean>(false);
  const [submitClicked, setSubmitClicked] = useState<boolean>(false);
  const [selectedUploadType, setSelectedUploadType] = useState(0);
  const [uploadClicked, setUploadClicked] = useState(false);
  function getTimeString() {
    return moment().format("HH:mm:ss");
  }
  const previousTimeRef = useRef(getTimeString());
  const [allQuestionsInPage, setAllQuestionsInPage] = useState(false);

  const contextValues = useMemo(() => {
    return {
      items,
      setItems,
      currentQuestionIndex,
      setCurrentQuestionIndex,
      selectedLanguage,
      setSelectedLanguage,
      responses,
      setResponses,
      showAllQuestions,
      setShowAllQuestions,
      isStarted,
      setIsStarted,
      questions,
      setQuestions,
      fullScreen,
      setFullScreen,
      isModalOpen,
      setIsModalOpen,
      allPermissions,
      setAllPermissions,
      verificationImage,
      setVerificationImage,
      fromSampleAssessment,
      setFromSampleAssessment,
      steps,
      setSteps,
      loggedEvents,
      setLoggedEvents,
      sectionNumber,
      setSectionNumber,
      singleQuestionType,
      setSingleQuestionType,
      previousTimeRef,
      streamRef,
      currentLocation,
      setCurrentLocation,
      violationChecks,
      setViolationChecks,
      selectedPart,
      setSelectedPart,
      uploadModalOpen,
      setUploadModalOpen,
      currentQuestion,
      setCurrentQuestion,
      currentSection,
      setCurrentSection,
      vivaResponse,
      setVivaResponse,
      timer,
      setTimer,
      isVivaStarted,
      setVivaStarted,
      recordVideo,
      setRecordVideo,
      activityResponse,
      setActivityResponse,
      inVivaQuestion,
      setVivaQuestion,
      selectedCategory,
      setSelectedCategory,
      selectedName,
      setSelectedName,
      sentMessages,
      setSentMessages,
      receivedMessages,
      setReceivedMessages,
      selectedOption,
      setSelectedOption,
      zimInstance,
      setZimInstance,
      zegoToken,
      setZegoToken,
      countdown,
      setCountdown,
      isConnected,
      setIsConnected,
      ischatRoomOpen,
      setIsChatRoomOpen,
      isBackClick,
      setIsbackClick,
      feedBackModal,
      setFeedBackModal,
      submitClicked,
      setSubmitClicked,
      selectedUploadType,
      setSelectedUploadType,
      uploadClicked,
      setUploadClicked,
      allQuestionsInPage,
      setAllQuestionsInPage,
    };
  }, [
    items,
    setItems,
    currentQuestionIndex,
    setCurrentQuestionIndex,
    selectedLanguage,
    setSelectedLanguage,
    responses,
    setResponses,
    showAllQuestions,
    setShowAllQuestions,
    isStarted,
    setIsStarted,
    questions,
    setQuestions,
    fullScreen,
    setFullScreen,
    isModalOpen,
    setIsModalOpen,
    allPermissions,
    setAllPermissions,
    verificationImage,
    setVerificationImage,
    fromSampleAssessment,
    setFromSampleAssessment,
    steps,
    setSteps,
    loggedEvents,
    setLoggedEvents,
    sectionNumber,
    setSectionNumber,
    singleQuestionType,
    setSingleQuestionType,
    previousTimeRef,
    streamRef,
    currentLocation,
    setCurrentLocation,
    violationChecks,
    setViolationChecks,
    selectedPart,
    setSelectedPart,
    uploadModalOpen,
    setUploadModalOpen,
    currentQuestion,
    setCurrentQuestion,
    currentSection,
    setCurrentSection,
    vivaResponse,
    setVivaResponse,
    timer,
    setTimer,
    isVivaStarted,
    setVivaStarted,
    recordVideo,
    setRecordVideo,
    activityResponse,
    setActivityResponse,
    inVivaQuestion,
    setVivaQuestion,
    selectedCategory,
    setSelectedCategory,
    selectedName,
    setSelectedName,
    sentMessages,
    setSentMessages,
    receivedMessages,
    setReceivedMessages,
    selectedOption,
    setSelectedOption,
    zimInstance,
    setZimInstance,
    zegoToken,
    setZegoToken,
    countdown,
    setCountdown,
    isConnected,
    setIsConnected,
    ischatRoomOpen,
    setIsChatRoomOpen,
    isBackClick,
    setIsbackClick,
    feedBackModal,
    setFeedBackModal,
    submitClicked,
    setSubmitClicked,
    selectedUploadType,
    setSelectedUploadType,
    uploadClicked,
    setUploadClicked,
    allQuestionsInPage,
    setAllQuestionsInPage,
  ]);

  return (
    <ItemStateContext.Provider value={contextValues}>
      {children}
    </ItemStateContext.Provider>
  );
};
