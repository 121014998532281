import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import Card from "@/components/elements/Card";
import { getIntlErrorMessage, handleMessage } from "@/utils/functions.ts";
import Logo from "@/components/logo";
import {
  buttonTitle,
  contentDivText,
  loginPageText,
  localStorageKeys,
  messageEnum,
} from "@/constants/constants.js";
import { URL } from "@/utils/service-urls";
import { useAuth } from "@/contexts/authContext";
import { unauthenticatedAxios } from "@/utils/axiosInterceptor";

const Login = () => {
  const navigate = useNavigate();
  const [errors] = useState("");
  const [loading, setLoading] = useState(false);

  interface LoginValues {
    email: string;
    password: string;
    loginWith: string;
    username: string;
  }

  const initialValues = {
    email: "",
    password: "",
    loginWith: "email",
    username: "",
  };

  const validateForm = (values) => {
    const errors: { email?: string; username?: string; password?: string } = {};
    if (selectedLoginWith === "email") {
      if (!values.email) {
        errors.email = getIntlErrorMessage("LOGIN", "EMAIL_REQUIRED");
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
      ) {
        errors.email = getIntlErrorMessage("LOGIN", "INVALID_EMAIL");
      }
    } else if (selectedLoginWith === "username") {
      if (!values.username) {
        errors.username = getIntlErrorMessage("LOGIN", "USER_NAME_REQUIRED");
      }
    }

    if (!values.password) {
      errors.password = getIntlErrorMessage("LOGIN", "PASSWORD_REQUIRED");
    } else if (values.password.length <= 8) {
      errors.password = getIntlErrorMessage("LOGIN", "PASSWORD_LENGTH");
    }

    return errors;
  };

  const onLogin = async (values: LoginValues) => {
    setLoading(true);
    await authenticateUser(values);
  };

  const [selectedLoginWith, setSelectedLoginWith] = useState("email");
  const { login } = useAuth();

  const handleLoginTypeChange = (event, setFieldValue, setErrors) => {
    const loginType = event.target.value;
    setSelectedLoginWith(loginType);
    setFieldValue("loginWith", loginType);
    setFieldValue("email", "");
    setFieldValue("username", "");
    setFieldValue("password", "");
    setErrors({});
  };
  const authenticateUser = async (values: LoginValues) => {
    login().then(() => {
      let requestBody: {
        [key: string]: string;
        password: string;
        browser: string;
        ipaddress: string;
        latitude: string;
        longitude: string;
      } = {
        password: values.password,
        browser: "chrome",
        ipaddress: "x.xx",
        latitude: "0",
        longitude: "0",
      };

      if (selectedLoginWith === "email") {
        requestBody = {
          ...requestBody,
          loginWith: "email",
          email: values.email,
        };
      } else if (selectedLoginWith === "username") {
        requestBody = {
          ...requestBody,
          username: values.username,
        };
      }

      const data = { requestBody };

      unauthenticatedAxios
        .post(URL.ACCOUNT.PASSWORD_AUTHENTICATE, data)
        .then(async (response) => {
          if (response?.status == 200) {
            console.log("Success");
            setLoading(false);
            sessionStorage.setItem(
              localStorageKeys.jwt,
              response.data.responseBody.jwtToken,
            );
            const stakeholderDetails = JSON.stringify({
              full_name: response.data.responseBody.full_name,
              category: {
                name: "candidate",
              },
              id: response.data.responseBody.candidate_id,
              orgaization: {
                id: response.data.responseBody.organization.id,
                name: response.data.responseBody.organization.name,
                logo_file_name:
                  response.data.responseBody.organization.logo_file_name,
              },
              is_stakeholder: false,
            });
            localStorage.setItem(
              localStorageKeys.orgId,
              response.data.responseBody.organization_id,
            );
            localStorage.setItem(
              localStorageKeys.candidateId,
              response.data.responseBody.candidate_id,
            );
            localStorage.setItem(
              localStorageKeys.stakeholderDetails,
              stakeholderDetails,
            );
            const candidateId = response.data.responseBody.candidate_id;
            navigate(`/candidate/home/${candidateId}`);
          } else if (response?.status == 500) {
            const errorMsg = response.data.responseHead.statusDesc;
            console.log("errorMsg");
            console.log(errorMsg);
            handleMessage(messageEnum.error, errorMsg);

            setLoading(false);
          }
        })
        .catch((error) => {
          const errorMessage = error.message;
          setLoading(false);
          console.log("Error:", errorMessage);
        });
    });
  };

  return (
    <div className="login-page">
      <div className="h-screen mt-[130px]">
        <Card
          className={
            "max-w-lg overflow-hidden shadow-lg mx-auto custom-card elevation"
          }>
          <div className="text-center">
            <Logo heroheader={true} />
            <hr className="my-4" />
          </div>
          <div className="flex flex-col justify-center">
            <div className=" px-10 ">
              {errors && (
                <div className="mt-4 text-xs text-[red]">{errors}</div>
              )}
              <div>
                <Formik
                  initialValues={initialValues}
                  validate={validateForm}
                  onSubmit={(values) => onLogin(values)}>
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    setFieldValue,
                    setErrors,
                  }) => (
                    <Form className="mt-8 space-y-6">
                      <div className=" space-y-6 rounded-md shadow-sm">
                        <div className="flex">
                          <div id="my-radio-group" className="mr-[40px]">
                            {loginPageText.loginWith}
                          </div>
                          <fieldset>
                            <label className="mr-[30px]">
                              <Field
                                type="radio"
                                name="loginWith"
                                value="email"
                                className="mr-[5px] radio-input"
                                checked={selectedLoginWith === "email"}
                                onChange={(event) =>
                                  handleLoginTypeChange(
                                    event,
                                    setFieldValue,
                                    setErrors,
                                  )
                                }
                              />
                              {loginPageText.email}
                            </label>
                            <label>
                              <Field
                                type="radio"
                                name="loginWith"
                                value="username"
                                className="mr-[5px]"
                                checked={selectedLoginWith === "username"}
                                onChange={(event) =>
                                  handleLoginTypeChange(
                                    event,
                                    setFieldValue,
                                    setErrors,
                                  )
                                }
                              />
                              {loginPageText.username}
                            </label>
                          </fieldset>
                        </div>
                        {selectedLoginWith === "email" ? (
                          <div>
                            <label htmlFor="email-address" className="sr-only">
                              {loginPageText.emailAddress}
                            </label>
                            <Field
                              type="email"
                              id="email"
                              name="email"
                              value={values.email}
                              onChange={handleChange}
                              className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                              placeholder={loginPageText.emailAddress}
                            />
                            <p className="text-xs" style={{ color: "red" }}>
                              {errors.email && touched.email && errors.email}
                            </p>
                          </div>
                        ) : (
                          <div>
                            <label htmlFor="username" className="sr-only">
                              {loginPageText.username}
                            </label>
                            <Field
                              type="text"
                              id="username"
                              name="username"
                              value={values.username}
                              onChange={handleChange}
                              className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                              placeholder={loginPageText.username}
                            />
                            <p className="text-xs" style={{ color: "red" }}>
                              {errors.username &&
                                touched.username &&
                                errors.username}
                            </p>
                          </div>
                        )}

                        <div>
                          <label htmlFor="password" className="sr-only">
                            {loginPageText.password}
                          </label>
                          <Field
                            type="password"
                            id="password"
                            name="password"
                            value={values.password}
                            onChange={handleChange}
                            className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            placeholder={loginPageText.password}
                          />

                          <p className="text-xs" style={{ color: "red" }}>
                            {errors.password &&
                              touched.password &&
                              errors.password}
                          </p>
                        </div>
                      </div>

                      <div>
                        <button
                          type="submit"
                          className="group relative flex w-full justify-center rounded-md border border-transparent bg-[#22c95cfa] hover:bg-[#22c95cfa] py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                          <span>
                            {loading
                              ? buttonTitle.signingIn
                              : buttonTitle.signIn}
                          </span>
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>

            <p className="text-sm mt-4 text-black text-center">
              <NavLink to="/signup" className=" text-tertiary">
                {contentDivText.forgetPassword}
              </NavLink>
            </p>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Login;
