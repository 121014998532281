/* eslint-disable @typescript-eslint/no-unused-vars */
import { messages } from "@/utils/error-messages.ts";
import { messages as warningMsg } from "@/utils/warning-messages.ts";
import { FIREBASE_URL, URL } from "@/utils/service-urls.ts";
import { messages as successMsg } from "@/utils/success-messages.ts";
import { message } from "antd";
import { messageEnum, localStorageKeys } from "@/constants/constants";
import {
  CustomAxiosRequestConfig,
  FirebaseUrlOptions,
} from "@/types/functionTypes.ts";
import moment from "moment";
import { authenticatedAxios } from "./axiosInterceptor";
import { FileType } from "@/types/contextTypes";

declare global {
  interface Window {
    opr?: any;
    chrome?: any;
  }
}

export const getIntlErrorMessage = (
  identifier: string,
  statusCode: string,
  statusDesc?: string,
): string => {
  const lang = "en";
  if (!messages[identifier]?.[statusCode]?.[lang]) {
    return statusDesc ?? "";
  }
  return messages[identifier][statusCode][lang];
};

export const getIntlSuccessMessage = (
  identifier: string,
  statusCode: string,
  statusDesc?: string,
): string => {
  const lang = "en";
  if (!successMsg[identifier]?.[statusCode]?.[lang]) {
    return statusDesc ?? "";
  }
  return successMsg[identifier][statusCode][lang];
};

export const getIntlWarningMessage = (
  identifier: string,
  statusCode: string,
  statusDesc?: string,
): string => {
  const lang = "en";
  if (!warningMsg[identifier]?.[statusCode]?.[lang]) {
    return statusDesc ?? "";
  }
  return warningMsg[identifier][statusCode][lang];
};

export const handleMessage = (type: string, messageText: string) => {
  switch (type) {
    case "success":
      message.success(messageText);
      break;
    case "error":
      message.error(messageText, 1);
      break;
    case "warning":
      message.warning(messageText, 1);
      break;
    case "info":
      message.info(messageText, 1);
      break;
    default:
      return messageText;
  }
};

export const debounce = <T extends (...args: any[]) => void>(
  func: T,
  delay: number,
) => {
  let timeoutId: ReturnType<typeof setTimeout> | undefined;
  return function (this: ThisParameterType<T>, ...args: Parameters<T>) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func.apply(this, args), delay);
  };
};

export const multiSelectOptionFormatter = (selectedOptions: any) => {
  const selectedOptionIndexes = selectedOptions
    ?.map((option: any, index: any) => {
      return option ? index + 1 : "";
    })
    .filter((index: any) => index !== "");

  const event =
    selectedOptionIndexes?.length > 0
      ? `${selectedOptionIndexes.join(",")}`
      : "-";

  return event;
};

export const getLocationName = async (latitude: any, longitude: any) => {
  const locationLookupUrl = `${URL.EXTERNAL_URLS.LOCATION_LOOKUP_URL}&lat=${latitude}&lon=${longitude}`;
  try {
    const response = await fetch(locationLookupUrl);
    const data = await response.json();
    const location = `${data.address.state_district}, ${data.address.state}`;
    return location;
  } catch (error: any) {
    handleMessage(messageEnum.error, error.message);
  }
};

export const getClassName = ({
  ourResponse,
  selectedOption,
  correctOption,
  isSelectedOptionPresent,
  defaultClassName,
}) => {
  if (ourResponse && selectedOption) {
    return "border-[gray] relative bg-lightGray p-[10px] mt-[10px] border-[1px] rounded-md";
  }
  if (!ourResponse) {
    if (correctOption && correctOption === selectedOption) {
      return "border-green relative bg-lightGreen p-[10px] mt-[10px] border-[2px] rounded-md";
    }
    if (correctOption) {
      if (isSelectedOptionPresent.length === 0) {
        return "border-green relative bg-lightGreen p-[10px] mt-[10px] border-[2px] rounded-md";
      }
      return "border-green relative bg-lightGreen p-[10px] mt-[10px] border-[2px] rounded-md";
    }
    if (selectedOption) {
      return "border-[red] relative bg-lightRed p-[10px] mt-[10px] border-[2px] rounded-md";
    }
    return defaultClassName;
  }
  return defaultClassName;
};

export const getFirebaseUrl = (options: FirebaseUrlOptions): string | null => {
  const { assessmentId, setId, candidateId, endpoint } = options;
  if (!assessmentId || !setId || !candidateId) {
    return getIntlErrorMessage("FIREBASE_UPDATE_MESSAGE", "MISSING_PARAMS");
  }

  const firebaseURL = `${FIREBASE_URL?.ITEM_URL?.ASSESSMENT}/${assessmentId}/${FIREBASE_URL?.ITEM_URL?.SET}/${setId}/${FIREBASE_URL?.ITEM_URL?.CANDIDATE}/${candidateId}/${endpoint}`;
  return firebaseURL;
};

export const updateReduxStateFromLocalStorage = (dispatch: any) => {
  dispatch({
    type: "SET_CANDIDATE_ID",
    payload: JSON.parse(
      localStorage.getItem(localStorageKeys.candidateId) ?? "{}",
    ),
  });
  dispatch({
    type: "SET_ASSESSMENT_ID",
    payload: JSON.parse(
      localStorage.getItem(localStorageKeys.assessmentId) ?? "{}",
    ),
  });
  dispatch({
    type: "SET_ASSESSMENT_SET_ID",
    payload: JSON.parse(localStorage.getItem(localStorageKeys.setId) ?? "{}"),
  });
  dispatch({
    type: "SET_ASSESSMENT_DETAILS",
    payload: JSON.parse(
      localStorage.getItem(localStorageKeys.assessmentDetails) ?? "{}",
    ),
  });
};

export const getMarginTopClass = (width: any) => {
  if (width <= 1024) {
    return "mt-[460px]";
  } else if (width <= 1034) {
    return "mt-[440px]";
  } else if (width <= 1054) {
    return "mt-[400px]";
  } else if (width <= 1100) {
    return "mt-[375px]";
  } else if (width <= 1150) {
    return "mt-[320px]";
  } else if (width <= 1200) {
    return "mt-[260px]";
  } else {
    return "";
  }
};

export const vpnCheck = async () => {
  const url = URL.EXTERNAL_URLS.VPN_DETECTION_URL;
  try {
    const response = await fetch(url);
    if (!response.ok) {
      const statusMsg: any = response.status;
      const textMsg = await response.text();
      const errorMessage = getIntlErrorMessage(
        "VPN_FETCH_MESSAGE",
        "VPN_HTTP_ERROR",
      )
        .replace("$statusMsg", statusMsg)
        .replace("$textMsg", textMsg);
      handleMessage(messageEnum.error, errorMessage);

      throw new Error(errorMessage);
    }
    const data = await response.json();

    return data;
  } catch (error) {
    return error;
  }
};

export const convertMinutesToHHMMSS = (minutes) => {
  const duration = moment.duration(minutes, "minutes");
  const hours = Math.floor(duration.asHours());
  const mins = Math.floor(duration.minutes());
  const secs = Math.floor(duration.seconds());
  return `${String(hours).padStart(2, "0")}:${String(mins).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
};

export const parseTime = (timeString) => {
  const parts = timeString.split(":");
  const hours = parseInt(parts[0], 10);
  const minutes = parseInt(parts[1], 10);
  const seconds = parseInt(parts[2], 10);
  return hours * 3600 + minutes * 60 + seconds;
};

export const convertTimestampToTime = (timestamp) => {
  if (!timestamp || isNaN(timestamp)) {
    return "Invalid timestamp";
  }

  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const questionPaperMapping = (questionPaper) => {
  localStorage.setItem("question_paper", questionPaper);
  const parsed = JSON.parse(JSON.parse(questionPaper));
  console.log(parsed);
  const obj = {};
  Object.keys(parsed).forEach((language) => {
    if (!obj?.[language]) {
      obj[language] = [];
    }

    const filteredArray = parsed[language]
      ?.filter((item) => {
        const key = Object.keys(item)[0];

        return item[key].question_category === "theory";
      })
      .map((item) => {
        const key = Object.keys(item)[0];
        return { ...item[key] };
      });
    const arr = parsed[language]?.filter((item) => {
      const key = Object.keys(item)[0];

      return item[key].question_category === "theory";
    });
    const totalMarks = arr.reduce((total, question) => {
      const key = Object.keys(question)[0];
      return total + question[key].marks;
    }, 0);
    obj[language] = filteredArray;
    localStorage.setItem("theory_total", totalMarks);
  });
  localStorage.setItem("question_paper_theory", JSON.stringify(obj));

  const activityTypeQuestions = { activity: [] };
  Object.keys(parsed).forEach((language) => {
    const filteredArray = parsed[language]
      .filter((item) => {
        const key = Object.keys(item)[0];
        return item[key].question_category === "practical";
      })
      .map((item) => {
        const key = Object.keys(item)[0];
        return { ...item[key] };
      });
    activityTypeQuestions["activity"] = filteredArray;
    localStorage.setItem(
      "has_practicals",
      JSON.stringify(!!filteredArray?.length),
    );
    const arr = parsed[language]?.filter((item) => {
      const key = Object.keys(item)[0];

      return item[key].question_category === "practical";
    });
    const totalMarks = arr.reduce((total, question) => {
      const key = Object.keys(question)[0];
      return total + question[key].marks;
    }, 0);
    localStorage.setItem("practical_total", totalMarks);
  });
  localStorage.setItem(
    "question_paper_practicals",
    JSON.stringify(activityTypeQuestions),
  );

  const vivaQuestions = { viva1: [] };
  Object.keys(parsed).forEach((language) => {
    const filteredArray = parsed[language]
      .filter((item) => {
        const key = Object.keys(item)[0];
        return item[key].question_category === "viva";
      })
      .map((item) => {
        const key = Object.keys(item)[0];
        return { ...item[key] };
      });
    vivaQuestions["viva1"] = filteredArray;
    localStorage.setItem("has_viva", JSON.stringify(!!filteredArray?.length));
    const arr = parsed[language]?.filter((item) => {
      const key = Object.keys(item)[0];

      return item[key].question_category === "viva";
    });
    const totalMarks = arr.reduce((total, question) => {
      const key = Object.keys(question)[0];
      return total + question[key].marks;
    }, 0);
    localStorage.setItem("viva_total", totalMarks);
  });
  localStorage.setItem("question_paper_viva", JSON.stringify(vivaQuestions));
};

export const getBlankIndices = (statement) => {
  const blank_indices_start_end_data: any = [];
  const placeholders = statement.match(/\[\w+\]/g);
  placeholders.forEach((placeholder, index) => {
    const start_index = statement.indexOf(placeholder);
    const end_index = start_index + placeholder.length - 1;

    blank_indices_start_end_data.push({
      bit_index: index + 1,
      start_index: start_index,
      end_index: end_index,
      blank_text: placeholder,
    });
  });

  return blank_indices_start_end_data;
};

export const getClientBrowser = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  const browser =
    userAgent.indexOf("edge") > -1
      ? "edge"
      : userAgent.indexOf("edg") > -1
        ? "chromium based edge"
        : userAgent.indexOf("opr") > -1 && !!window?.opr
          ? "opera"
          : userAgent.indexOf("chrome") > -1 && !!window?.chrome
            ? "chrome"
            : userAgent.indexOf("trident") > -1
              ? "ie"
              : userAgent.indexOf("firefox") > -1
                ? "firefox"
                : userAgent.indexOf("safari") > -1
                  ? "safari"
                  : "unknown browser";

  return browser;
};

export const extractImages = async (
  src: any,
  setImageData: React.Dispatch<React.SetStateAction<any>>,
  setLoading: any,
) => {
  const imageData = {
    requestBody: {
      key: src,
    },
  };
  setLoading(true);

  try {
    const response = await authenticatedAxios.post(
      URL.SERVICES.DOWNLOAD,
      imageData,
      {
        authenticated: true,
      } as CustomAxiosRequestConfig,
    );
    if (response?.status == 200) {
      setLoading(false);
      setImageData((prevState) => ({
        ...prevState,
        [src]: response?.data?.responseBody?.url,
      }));
    } else {
      setLoading(false);
      const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
      //handleMessage(messageEnum.error, errorMsg);
    }
  } catch (error) {
    const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
    // handleMessage(messageEnum.error, errorMsg);
  }
};

export const getBase64 = (file: FileType): Promise<string> => {
  return new Promise((resolve, reject) => {
    if (!(file instanceof Blob)) {
      reject(new Error("Parameter is not of type 'Blob'"));
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};

export const capitalizeFirstLetter = (string) => {
  if (string.length === 0) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const removeHtmlTags = (text) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = text;
  return tempDiv.textContent || tempDiv.innerText || "";
};
