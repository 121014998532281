import { convertMinutesToHHMMSS } from "@/utils/functions";

function InstructionsBodyComponent({ assessmentDetails }: any) {
  return (
    <div className="border-b-[2px] flex justify-between border-gray items-center pl-[50px] pr-[50px] py-[16px]">
      <div className="font-bold text-[24px] text-primaryText">
        {assessmentDetails.assessment_name}
      </div>
      <div className="flex items-center space-x-[60px]">
        <div className="flex items-center gap-3">
          <svg
            width="23"
            height="28"
            viewBox="0 0 23 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M14.65 1H3.6C2.91044 1 2.24912 1.27393 1.76152 1.76152C1.27393 2.24912 1 2.91044 1 3.6V24.4C1 25.0896 1.27393 25.7509 1.76152 26.2385C2.24912 26.7261 2.91044 27 3.6 27H19.2C19.8896 27 20.5509 26.7261 21.0385 26.2385C21.5261 25.7509 21.8 25.0896 21.8 24.4V8.15L14.65 1Z"
              stroke="#22c95cfa"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14 1V8.8H21.8"
              stroke="#22c95cfa"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12.7021 16.6006H6.20215"
              stroke="#22c95cfa"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.6021 21.7991H6.20215"
              stroke="#22c95cfa"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7.11713 9.55225C7.00422 9.82816 6.94859 10.1539 6.94859 10.5283C6.94859 10.7146 7.10294 10.8608 7.28664 10.8608H7.47725C7.77113 10.8608 8.00173 10.6254 8.02996 10.3386C8.04364 10.1997 8.06236 10.09 8.08558 10.0084C8.13162 9.84664 8.2022 9.70418 8.29704 9.58048C8.39227 9.45629 8.599 9.25892 8.92172 8.98668C9.40289 8.58118 9.73077 8.23318 9.90012 7.94307C10.0696 7.65274 10.1543 7.33536 10.1543 6.99191C10.1543 6.39733 9.91259 5.89045 9.43352 5.4737C8.95313 5.0558 8.31191 4.84935 7.51529 4.84935C6.76274 4.84935 6.15126 5.04179 5.68574 5.43122L5.68559 5.43135C5.34215 5.72101 5.10132 6.10497 4.96227 6.581C4.8705 6.89516 5.11012 7.18584 5.42376 7.22134C5.72775 7.25574 5.99053 7.04253 6.0866 6.76344C6.18636 6.47368 6.3279 6.24933 6.50963 6.08761C6.78157 5.84561 7.12109 5.7236 7.53178 5.7236C7.95612 5.7236 8.312 5.85891 8.60262 6.12875L8.60285 6.12896C8.89432 6.3962 9.03738 6.70417 9.03738 7.05468C9.03738 7.24804 8.98847 7.42571 8.89061 7.58871C8.79266 7.75184 8.57491 7.97745 8.23072 8.26669C7.88974 8.55282 7.65085 8.77286 7.51623 8.92588L7.5161 8.92603C7.33379 9.13495 7.20031 9.34366 7.11713 9.55225ZM7.11713 9.55225C7.11716 9.55218 7.11719 9.55211 7.11722 9.55204L7.16349 9.57098L7.11705 9.55247C7.11708 9.5524 7.1171 9.55233 7.11713 9.55225ZM6.89362 12.1631C6.89362 12.4869 7.15608 12.7494 7.47984 12.7494H7.53425C7.85801 12.7494 8.12047 12.4869 8.12047 12.1631C8.12047 11.8394 7.85801 11.5769 7.53425 11.5769H7.47984C7.15608 11.5769 6.89362 11.8394 6.89362 12.1631Z"
              fill="#22c95cfa"
              stroke="#22c95cfa"
              strokeWidth="0.1"
            />
          </svg>
          <div>
            <div className="text-[12px]">Total Questions</div>
            <div>
              {assessmentDetails.question_paper_details.count_of_questions}
            </div>
          </div>
        </div>
        <div className="flex items-center gap-3">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z"
              stroke="#22c95cfa"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 6.5V12L14.75 14.75"
              stroke="#22c95cfa"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3.75 20.25L1 23"
              stroke="#22c95cfa"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M20.25 20.25L23 23"
              stroke="#22c95cfa"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <div>
            <div className="text-[12px]">Test Time</div>
            <div>
              {convertMinutesToHHMMSS(
                assessmentDetails.question_paper_details.time_limit,
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InstructionsBodyComponent;
