import React, { createContext, useContext, useEffect, useState } from "react";
import socketIOClient from "socket.io-client";
import { localStorageKeys } from "@/constants/constants";
import { useAuth } from "./authContext";

interface SocketContextType {
  socket: any;
  candidates: any;
  violations: any;
  timerCountdown: any;
  shareScreenCandidatesList: any;
}

const SocketContext = createContext<SocketContextType | undefined>(undefined);

export const useSocket = () => {
  return useContext(SocketContext);
};

export const SocketProvider = ({ children }) => {
  const { isLoggedIn, assessmentId } = useAuth();
  const [socket, setSocket] = useState<any>(null);
  const [candidates, setCandidates] = useState({});
  const [violations, setViolations] = useState({});
  const [timerCountdown, setTimerCountdown] = useState({});
  const [shareScreenCandidatesList, setShareScreenCandidatesList] = useState(
    {},
  );
  const assessmentIdCandidate = localStorage.getItem(
    localStorageKeys.assessmentId,
  );
  const userId = localStorage.getItem("user_id");
  const stakeHolderDetails = JSON.parse(
    localStorage.getItem(localStorageKeys.stakeholderDetails) ?? "{}",
  );

  useEffect(() => {
    const isStakeholder = !!stakeHolderDetails?.is_stakeholder;
    console.log(isStakeholder, "isStakeholder");
    console.log(userId, "is_user_id");
    console.log(
      isLoggedIn,
      assessmentIdCandidate,
      assessmentId,
      "isStakeholder",
    );
    if (isLoggedIn && (assessmentIdCandidate || assessmentId)) {
      const newSocket = socketIOClient(
        `${process.env.REACT_APP_CANDIDATE_BASE_URL?.replace("/api/v3", "")}/assessment-${assessmentIdCandidate ?? assessmentId}`,
        {
          query: {
            user: userId,
          },
          reconnection: true,
        },
      );
      console.log(newSocket, "newSocket");
      setSocket(newSocket);
      newSocket.on("connect_error", (err: any) => {
        console.log(`connect_error due to ${err.message}`);
      });
      newSocket.on("connect", () => {
        console.log("======connect=====");
        newSocket.emit("join");
        newSocket.emit(
          "login",
          isStakeholder ? `stakeholder-${userId}` : userId,
        );
      });

      newSocket.on("updateCandidateStatus", (onlineCandidates) => {
        console.log(onlineCandidates, "======updateCandidateStatus=====");
        setCandidates(onlineCandidates);
      });
      newSocket.on("updateCandidateViolations", (candidateViolations) => {
        setViolations(candidateViolations);
      });

      newSocket.on("modifiedAssessmentTime", (updatedTimer) => {
        setTimerCountdown(updatedTimer);
      });

      newSocket.on("forceCandidateToShareScreen", (candidatesList) => {
        setShareScreenCandidatesList(candidatesList);
      });

      return () => {
        newSocket.disconnect();
      };
    }
  }, [isLoggedIn, assessmentId, assessmentIdCandidate]);

  return (
    <SocketContext.Provider
      value={{
        socket,
        candidates,
        violations,
        timerCountdown,
        shareScreenCandidatesList,
      }}>
      {children}
    </SocketContext.Provider>
  );
};
