/* eslint-disable @typescript-eslint/no-unused-vars */
import { DetailsProps } from "@/types/componentTypes";
import { Table, Tag } from "antd";
import moment from "moment";
import React from "react";
import ButtonComponent from "./buttonComponent";
import {
  assessmentStatuses,
  localStorageKeys,
  messageEnum,
  firebaseMethodKeys,
} from "@/constants/constants";
import {
  getFirebaseUrl,
  getIntlErrorMessage,
  handleMessage,
  questionPaperMapping,
} from "@/utils/functions";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useAuth } from "@/contexts/authContext";
import { authenticatedAxios } from "@/utils/axiosInterceptor";
import { FIREBASE_URL, URL } from "@/utils/service-urls.ts";
import { handleFirebaseOperation } from "@/firebase";
import { CustomAxiosRequestConfig } from "@/types/functionTypes";
import HtmlEditor from "./htmlEditor";

const Details: React.FC<DetailsProps> = ({
  assessmentDetailsResponse,
  assessmentType,
  isStakeholder,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { login, setLoggedIn, setAssessmentIdCxt } = useAuth();
  const detailsKeys = isStakeholder
    ? [
        {
          displayName: "Title",
          name: "title",
        },
        {
          displayName: "Description",
          name: "description",
        },
        {
          displayName: "Start time",
          name: "start_datetime",
          type: "date",
        },
        {
          displayName: "End time",
          name: "end_datetime",
          type: "date",
        },
        {
          displayName: "Tags",
          name: "tags",
        },
        {
          displayName: "Language",
          name: "languages",
        },
      ]
    : [
        {
          displayName: "Title",
          name: "title",
        },
        {
          displayName: "Course",
          name: "course",
          type: "course",
        },
        {
          displayName: "Start time",
          name: "start_datetime",
          type: "date",
        },
        {
          displayName: "End time",
          name: "end_datetime",
          type: "date",
        },
        {
          displayName: "Language",
          name: "languages",
          type: "language",
        },
        {
          displayName: "Duration",
          name: "duration",
          type: "duration",
        },
        {
          displayName: "Questions",
          name: "questions",
          type: "questions",
        },
        {
          displayName: "Marks",
          name: "marks",
          type: "marks",
        },
      ];
  const keyNames = {
    tags: "tags",
    languages: "languages",
    Tags: "Tags",
    description: "description",
  };

  const dataSource = detailsKeys?.map((rule) => {
    let value = assessmentDetailsResponse[rule?.name];
    if (rule?.name === keyNames?.tags && Array?.isArray(value)) {
      value = value?.map((tag) => `${tag?.type}: ${tag[tag?.key]}`);
    } else if (rule.name === keyNames?.languages) {
      value =
        assessmentDetailsResponse?.rules["languagesSpecifier"] == "Fixed"
          ? assessmentDetailsResponse?.rules[rule.name]?.join("")
          : assessmentDetailsResponse?.rules["languagesSpecifier"];
    } else if (rule.name === keyNames?.description) {
      value = <HtmlEditor initialValue={value} />;
    } else if (rule.type === "date") {
      value = moment(value).format("DD-MMM-YYYY HH:mm");
    } else if (rule.type == "course") {
      value = `Course:  ${assessmentDetailsResponse?.question_paper_details?.course_version_name}`;
    } else if (rule.type == "duration") {
      value = `${assessmentDetailsResponse?.question_paper_details?.time_limit} mins`;
    } else if (rule.type == "questions") {
      value =
        assessmentDetailsResponse?.question_paper_details?.count_of_questions;
    } else if (rule.type == "marks") {
      value = assessmentDetailsResponse?.question_paper_details?.total_marks;
    }
    return {
      key: rule.displayName,
      value: value,
    };
  });
  const customAttrs = Array.isArray(assessmentDetailsResponse?.custom_attrs)
    ? assessmentDetailsResponse?.custom_attrs?.map((attr: any) => ({
        key: attr.label,
        value: attr.value,
      })) || []
    : [];

  const finalDataSource = [...dataSource];
  const columns = [
    {
      dataIndex: "key",
      key: "key",
    },
    {
      dataIndex: "value",
      key: "value",
      render: (text: any, record: any) => {
        if (record.key === keyNames?.Tags) {
          return (
            <span>
              {text.map((tag: any) => (
                <Tag
                  color="#F3F4F6"
                  style={{
                    color: "black",
                    borderRadius: "100px",
                    padding: "5px 10px",
                    display: "inline-block",
                    marginBottom: "10px",
                  }}
                  key={tag}>
                  {tag}
                </Tag>
              ))}
            </span>
          );
        }
        return text;
      },
    },
  ];

  const generateId = () => {
    return `id-${Math.random().toString(36).substr(2, 9)}-${Date.now()}`;
  };

  const startAssessment = () => {
    localStorage.setItem(localStorageKeys?.fromCandidateDashboard, "true");
    const assessmentId = localStorage.getItem(localStorageKeys.assessmentId);
    const candidateId = localStorage.getItem(localStorageKeys.candidateId);
    login()
      .then((data) => {
        sessionStorage.setItem(
          localStorageKeys.firebaseAuthCreds,
          JSON.stringify(data.user),
        );

        authenticatedAxios
          .get(`${URL.ASSESSMENT.CANDIDATE_ASSESSMENT}/${assessmentId}`, {
            authenticated: true,
          } as CustomAxiosRequestConfig)
          .then(async (response) => {
            if (response?.status == 200) {
              localStorage.setItem(localStorageKeys.loggedIn, "true");
              localStorage.setItem(
                localStorageKeys.setId,
                response.data.responseBody.set_id,
              );
              localStorage.setItem(
                localStorageKeys.orgId,
                response.data.responseBody.organization_id,
              );
              localStorage.setItem(
                localStorageKeys.link,
                response.data.responseBody.link,
              );
              localStorage.setItem(
                localStorageKeys?.adjustTime,
                response.data.responseBody.adjust_time_by,
              );
              const assessmentDetails = JSON.stringify({
                set_id: response.data.responseBody.set_id,
                question_paper_details:
                  response.data.responseBody.question_paper_details,
                assessment_name: response.data.responseBody.assessment_name,
                rules: response.data.responseBody.rules,
                full_name: response.data.responseBody.full_name,
                username: response.data.responseBody.username,
                email: response.data.responseBody.email,
                instructions: response.data.responseBody.instructions,
              });
              localStorage.setItem(
                localStorageKeys.assessmentDetails,
                assessmentDetails,
              );
              localStorage.setItem("user_id", String(candidateId));
              dispatch({
                type: "SET_CANDIDATE_ID",
                payload: candidateId,
              });
              dispatch({
                type: "SET_ASSESSMENT_ID",
                payload: assessmentId,
              });
              dispatch({
                type: "SET_ASSESSMENT_SET_ID",
                payload: response.data.responseBody.set_id,
              });
              dispatch({
                type: "SET_ASSESSMENT_DETAILS",
                payload: JSON.parse(assessmentDetails),
              });

              // eslint-disable-next-line @typescript-eslint/no-unused-vars

              try {
                const questionPaper = await handleFirebaseOperation(
                  `assessments/${assessmentId}/sets/${response.data.responseBody.set_id}/question_paper`,
                  firebaseMethodKeys.GET,
                );
                console.log(questionPaper);
                questionPaperMapping(questionPaper);
                const options = {
                  assessmentId: Number(assessmentId),
                  setId: response.data.responseBody.set_id,
                  candidateId: response.data.responseBody.id,
                  endpoint: `${FIREBASE_URL?.ENDPOINT?.EVIDENCES}/${FIREBASE_URL?.ENDPOINT?.ORG_ID}`,
                };
                const firebaseUrl = getFirebaseUrl(options);
                handleFirebaseOperation(
                  firebaseUrl,
                  firebaseMethodKeys.PUT,
                  response.data.responseBody.organization_id,
                );
                setLoggedIn();
                setAssessmentIdCxt(assessmentId);
                const id = generateId();
                navigate(`/assessment/${response.data.responseBody.id}/${id}`);
              } catch (error) {
                console.log(error, "error");
              }
            } else if (response.status == 500) {
              const errorMsg = getIntlErrorMessage(
                "LOGIN",
                response.data.responseHead.statusCode ?? "GENERAL",
              );
              handleMessage(messageEnum.error, errorMsg);
            }
          })
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          .catch((error: any) => {
            const errorMsg = getIntlErrorMessage(
              "LOGIN",
              // error.responseHead.statusCode ?? "GENERAL",
              "GENERAL",
            );
            handleMessage(messageEnum.error, errorMsg);
          });
      })
      .catch((error) => {
        const errorMessage = error.message;
        console.log("Error:", errorMessage);
      });
  };

  return (
    <div className="flex flex-col h-[100%] overflow-y-auto">
      <div className="mx-[40px] my-[20px] table-wrapper">
        <Table
          dataSource={finalDataSource}
          columns={columns}
          pagination={false}
          style={{ width: "50%" }}
          className="details custom-table"
        />
      </div>
      {assessmentType === assessmentStatuses.ongoing && !isStakeholder && (
        <div className="mt-auto px-2 py-2 flex justify-end">
          <ButtonComponent
            text="Start Assessment"
            className={
              "bg-primary px-[20px] py-[10px] border-none rounded-md font-semibold text-[white]"
            }
            onClick={startAssessment}
          />
        </div>
      )}
    </div>
  );
};

export default Details;
