import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import { HtmlEditorProps } from "@/types/componentTypes";

const HtmlEditor: React.FC<HtmlEditorProps> = ({
  initialValue,
  allQuestions,
}) => {
  return (
    <div className={allQuestions ? "" : "pb-0"}>
      <Editor
        inline={true}
        disabled={true}
        tagName="section"
        initialValue={initialValue}
        apiKey={process.env.REACT_APP_HTML_EDITOR_API_kEY}
        init={{
          padding: "0px",
          menubar: false,
          height: "200px",
          branding: false,
          toolbar: "",
          statusbar: false,
        }}
      />
    </div>
  );
};

export default HtmlEditor;
