import React from "react";
import CustomNumberInput from "./customInputNumber";
import { Table } from "antd";
import { VivaResponsesProps } from "@/types/componentTypes";
const VivaResponses: React.FC<VivaResponsesProps> = ({ candidateDetails }) => {
  // const candidateId = localStorage.getItem(localStorageKeys?.candidateId);
  // const candidateData = candidateDetails?.filter((data) => {
  //   const candidateIdStr = String(candidateId);
  //   const dataCandidateIdStr = String(data?.candidate_id);
  //   return dataCandidateIdStr === candidateIdStr;
  // });
  const kpiData = [
    {
      kpi_id: 6,
      kpi_name: "KPI1",
      kpi_score: 20,
      observation_score: 10,
      viva_score: 10,
    },
    {
      kpi_id: 10,
      kpi_name: "KPI2",
      kpi_score: 25,
      observation_score: 10,
      viva_score: 10,
    },
    {
      kpi_id: 12,
      kpi_name: "KPI3",
      kpi_score: 15,
      observation_score: 10,
      viva_score: 10,
    },
    {
      kpi_id: 16,
      kpi_name: "KPI4",
      kpi_score: 10,
      observation_score: 10,
      viva_score: 10,
    },
  ];
  const KPIColumns = [
    {
      title: "KPI Name",
      dataIndex: "name",
      render: (text: string, record: any) => record.kpi_name,
    },
    {
      title: "KPI Score",
      dataIndex: "kpi_score",
      render: (text: string, record: any) => {
        // const value = kpis[selectedPerson]?.[record.kpi_name];
        return (
          <div className="flex gap-2 items-center">
            <CustomNumberInput
              key={record.kpi_id}
              min={0}
              max={10}
              value={"10"}
              onChange={(value: number) => console.log(value)}
              readOnly={true}
            />
            <div>/ {record.kpi_score}</div>
          </div>
        );
      },
    },
    {
      title: "Observation Score",
      dataIndex: "observation_score",
      render: (text: string, record: any) => {
        // const value = observations[selectedPerson]?.[record.kpi_name];
        return (
          <div className="flex gap-2 items-center">
            <CustomNumberInput
              key={record.kpi_id}
              min={0}
              max={10}
              value={"10"}
              onChange={(value: number) => console.log(value)}
              readOnly={true}
            />
            <div>/ {record.observation_score}</div>
          </div>
        );
      },
    },
    {
      title: "Viva Score",
      dataIndex: "viva_score",
      render: (text: string, record: any) => {
        // const value = vivaScores[selectedPerson]?.[record.kpi_name];
        return (
          <div className="flex gap-2 items-center">
            <CustomNumberInput
              key={record.kpi_id}
              min={0}
              max={10}
              value={"10"}
              onChange={(value: number) =>
                // handleVivaScoreChange(selectedPerson, record.kpi_name, value)
                console.log(value)
              }
              readOnly={true}
            />
            <div>/ {record.viva_score}</div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="flex flex-col overflow-y-auto space-y-[10px]">
      {/* <CandidateVideo
        className="w-[100%]"
        candidateDetails={candidateData[0]}
        viewContent={true}
        isMonitorTab={true}
        // remoteStream={
        //   remoteStreams?.[
        //     `candidate-invigilate-${candidatesDetails.details.candidate_id}`
        //   ]
        // }
      /> */}
      <div className="flex flex-col overflow-y-auto pb-[20px]">
        <Table
          columns={KPIColumns}
          className="remarks-table pb-[20px]"
          dataSource={kpiData}
          bordered
          pagination={false}
          scroll={{ y: 60 }}
        />
      </div>
    </div>
  );
};

export default VivaResponses;
