/* eslint-disable @typescript-eslint/no-unused-vars */
import classNames from "classnames";
import React, { useEffect, useState } from "react";

import {
  contentDivText,
  localStorageKeys,
  assessmentStatuses,
  firebaseMethodKeys,
  messageEnum,
  candidateProofsTypes,
} from "@/constants/constants";
import { CandidateProofsProps } from "@/types/componentTypes";
import { useSocket } from "@/contexts/socketContext";
import { FIREBASE_URL } from "@/utils/service-urls";
import { handleFirebaseOperation } from "@/firebase";
import {
  extractImages,
  getIntlErrorMessage,
  handleMessage,
} from "@/utils/functions";
import ImageWithBasePath from "./imageComponent";
import ButtonComponent from "./buttonComponent";
import { authenticatedAxios } from "@/utils/axiosInterceptor";
import { CustomAxiosRequestConfig } from "@/types/functionTypes";
import { Empty, Spin } from "antd";

const CandidateProofs: React.FC<CandidateProofsProps> = ({
  candidateDetails,
  type,
  isMonitorTab,
}) => {
  const { candidates }: any = useSocket();
  const [src, setSrc] = useState<any>("");
  const [idProofImage, setIdProofImage] = useState<any>();
  const [candidateProofImage, setCandidateProofImage] = useState<any>();
  const [videoProofImage, setVideoProofImage] = useState<any>();
  const assessment_id = candidateDetails?.assessment_id;
  const candidate_id = candidateDetails.candidate_id;
  const set_id = candidateDetails?.set_id;
  const [loading, setLoading] = useState(true);

  const getEndPoints = (type: string, isfromFirebase: boolean) => {
    if (type == candidateProofsTypes?.idProof) {
      if (isfromFirebase) {
        return `id_proof`;
      } else {
        return `?type=id_proof`;
      }
    } else if (type == candidateProofsTypes?.candidateProof) {
      if (isfromFirebase) {
        return `candidate_proof`;
      } else {
        return `?type=candidate_proof`;
      }
    } else if (type == "recording") {
      if (isfromFirebase) {
        return `video`;
      } else {
        return `?type=video`;
      }
    }
  };

  const getDataFromFirebase = async (assessmentId, candidate_id, setId) => {
    try {
      const endpoint = `${FIREBASE_URL?.ENDPOINT?.EVIDENCES}/${getEndPoints(type, true)}`;
      const url = `${FIREBASE_URL?.ITEM_URL?.ASSESSMENT}/${assessmentId}/${FIREBASE_URL?.ITEM_URL?.SET}/${setId}/${FIREBASE_URL?.ITEM_URL?.CANDIDATE}/${candidate_id}/${endpoint}`;
      const data = await handleFirebaseOperation(url, firebaseMethodKeys.GET);
      if (data) {
        setSrc(data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log("Error fetching data from Firebase:", error);
    }
  };

  const getLogsFromDB = async (assessmentId: any, candidateId: any) => {
    const endpoint = `${FIREBASE_URL?.ENDPOINT?.EVIDENCES}/${getEndPoints(type, false)}`;

    authenticatedAxios
      .get(
        `/candidates/assessment/${assessmentId}/candidate/${candidateId}/${endpoint}`,
        {
          authenticated: true,
        } as CustomAxiosRequestConfig,
      )
      .then((response) => {
        if (response?.status == 200) {
          const data = response?.data?.responseBody;
          setSrc(data);
        } else {
          const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
          handleMessage(messageEnum.error, errorMsg);
        }
      })
      .catch(() => {
        const errorMsg = getIntlErrorMessage("GENERAL", "API_ERROR");
        handleMessage(messageEnum.error, errorMsg);
      });
  };

  const getData = async () => {
    const assessmentStatus = localStorage.getItem(
      localStorageKeys?.assessmentStatus,
    );
    const candidateOnlineStatus = candidates[candidate_id];
    if (
      assessmentStatus === assessmentStatuses?.ongoing &&
      candidateOnlineStatus === assessmentStatuses?.online
    ) {
      await getDataFromFirebase(assessment_id, candidate_id, set_id);
    } else if (assessmentStatus === assessmentStatuses?.completed) {
      getLogsFromDB(assessment_id, candidate_id);
    } else {
      setLoading(false);
      return;
    }
  };

  useEffect(() => {
    getData();
  }, [type]);

  useEffect(() => {
    if (src && src?.length && type == candidateProofsTypes?.idProof) {
      extractImages(src, setIdProofImage, setLoading);
    } else if (
      type == candidateProofsTypes?.candidateProof &&
      Object.keys(src)?.length
    ) {
      const candidateProofs = Object.keys(src).map((item: any) => {
        return src[item];
      });
      candidateProofs.map((item: any) =>
        extractImages(item?.key, setCandidateProofImage, setLoading),
      );
    }
  }, [type, src?.length]);

  return (
    <div className={classNames("px-[10px] py-[20px] w-[85%] h-[100%]")}>
      <div className="pb-[10px] ">
        {contentDivText?.candidateId}
        <span>{candidateDetails?.candidate_id}</span>
      </div>
      <div
        className={classNames(
          "",
          "h-full",
          loading || !idProofImage || !candidateProofImage
            ? "flex justify-center items-center"
            : "pt-[30px]",
        )}>
        {loading ? (
          <Spin />
        ) : (
          <div>
            {type === candidateProofsTypes?.idProof && (
              <div className="h-full">
                {idProofImage && idProofImage[src] ? (
                  <div className="flex flex-col items-center">
                    <ImageWithBasePath
                      isUrl={true}
                      src={idProofImage[src]}
                      width={350}
                      height={100}
                      className="flex items-center"
                    />
                  </div>
                ) : (
                  <Empty description={"No Id proof found"} />
                )}
              </div>
            )}
            {type == candidateProofsTypes?.candidateProof && (
              <div>
                {candidateProofImage ? (
                  <div className="flex justify-evenly">
                    {Object?.entries(candidateProofImage).map(
                      ([key, url], index) => {
                        return (
                          <div key={key}>
                            <ImageWithBasePath
                              isUrl={true}
                              src={url}
                              width={350}
                              height={100}
                              className="flex items-center"
                            />
                            <div className="font-medium text-primaryText flex justify-center pt-[16px]">
                              {index === 0
                                ? "Before Assessment"
                                : "After Assessment"}
                            </div>
                          </div>
                        );
                      },
                    )}
                  </div>
                ) : (
                  <Empty description={"No candidate images found"} />
                )}
              </div>
            )}
            {type === candidateProofsTypes?.videoRecordings && (
              <div>
                <div className="flex flex-col items-center">
                  {videoProofImage && videoProofImage[src] ? (
                    <video
                      src={videoProofImage[src]}
                      controls
                      width={350}
                      height={100}
                      className="flex items-center"
                    />
                  ) : (
                    <Empty description={"No video recordings found"} />
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      {(idProofImage || candidateProofImage) &&
        !isMonitorTab &&
        (type == candidateProofsTypes?.idProof ||
          type == candidateProofsTypes?.candidateProof) && (
          <div className={classNames("flex justify-end mr-[30px] mt-[20px]")}>
            <ButtonComponent
              text="Mark as Verified"
              className="bg-primary px-[20px] py-[10px] border-none rounded-md font-semibold text-[white]"
            />
          </div>
        )}
    </div>
  );
};

export default CandidateProofs;
