import React, { useEffect, useState } from "react";
import EvaluateDashboard from "@/components/assessmentEvaluateDashboard";
import { authenticatedAxios } from "@/utils/axiosInterceptor";
import { CustomAxiosRequestConfig } from "@/types/functionTypes";
import { URL } from "@/utils/service-urls";
import { getIntlErrorMessage, getIntlSuccessMessage, handleMessage } from "@/utils/functions";
import { messageEnum, localStorageKeys } from "@/constants/constants";
import { MdOutlineFileDownload } from "react-icons/md";
function publishResults() {
  const assessmentId = localStorage.getItem(localStorageKeys?.assessmentId);
  const [dashboardData, setDashboardData] = useState([
    { name: "Planned", description: "0", key: "Pending", status: "status" },
    { name: "Executed", description: "0", key: "Completed", status: "status" },
    { name: "Evaluated", description: "0", key: "Completed", status: "evaluation_status" },
    { name: "Published", description: "0", key: "Completed", status: "evaluation_status" },
  ]);
  useEffect(() => {
    const fetchCandidatesCount = async () => {
      const url = `${URL.ASSESSMENT.CANDIDATES_ASSESSMENT}/${assessmentId}/${URL.ASSESSMENT.CANDIDATES}/${URL.ASSESSMENT.STATUS}`;
      try {
        const response = await authenticatedAxios.get(url, { authenticated: true } as CustomAxiosRequestConfig);
        if (response.status === 200 && response?.data?.responseBody?.length) {
          const updatedData = [...dashboardData];
          response.data.responseBody.forEach((item) => {
            const index = updatedData.findIndex((dataItem) => item.status === dataItem.key && item.type === dataItem.status);
            if (index !== -1) {
              updatedData[index].description = item.count;
            }
          });
          setDashboardData(updatedData);
        } else {
          handleMessage(messageEnum.error, getIntlErrorMessage("GENERAL", "API_ERROR"));
        }
      } catch (error) {
        handleMessage(messageEnum.error, getIntlErrorMessage("GENERAL", "API_ERROR"));
      }
    };
    fetchCandidatesCount();
  }, []);
  const handlePublishClick = async() => {
    const url = `${URL.ASSESSMENT.CANDIDATES_ASSESSMENT}/${assessmentId}/publishResults`;
    try {
      const response = await authenticatedAxios.get(url, { authenticated: true } as CustomAxiosRequestConfig);
      console.log('respo', response.data.responseBody?.message);
      const successMsg = getIntlSuccessMessage(
        "RESULTS_PUBLISHED_MESSAGE",
        "SUBMIT_SUCCESS",
      );
    handleMessage(messageEnum.success, successMsg);
    } catch (error) {
      handleMessage(messageEnum.error, getIntlErrorMessage("GENERAL", "API_ERROR"));
    }
  };
  return (
    <div>
      <EvaluateDashboard
        key="evaluate"
        items={dashboardData}
        onDescriptionClick={() => {}}
      />
    <p className="mb-4" style={{margin :"5px"}}>
          This action will publish the results of the candidates whose assessment status is <strong>Evaluated</strong>. Are you sure that you want to proceed further?
        </p>
        <button
          className="flex items-center bg-green-500 text-black py-2 px-4 rounded hover:bg-green-600"
          style={{ backgroundColor: "rgba(34, 201, 92, 0.98)", marginLeft  :"10px" }}
          onClick={handlePublishClick}
        >
          <span className="mr-2">Publish Results</span>
        </button>
    </div>
  );
}
export default publishResults;