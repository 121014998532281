import React from "react";
import { ConfigProvider, Input } from "antd";
import ModalComponent from "./customModal";
import { RemarkModalProps } from "@/types/componentTypes";
import ButtonComponent from "./buttonComponent";
import classNames from "classnames";
import { buttonTitle, modalContent } from "@/constants/constants";

const { TextArea } = Input;

const RemarkModal: React.FC<RemarkModalProps> = ({
  isOpen,
  onRequestClose,
  onSubmit,
  remarks,
  setRemarks,
}) => {
  const handleSubmit = () => {
    onSubmit(remarks);
    setRemarks("");
  };
  return (
    <ModalComponent
      isModalOpen={isOpen}
      handleOnCancel={onRequestClose}
      width={700}
      title={
        <span className="font-normal text-[24px] text-[#171A1F] ">
          {modalContent?.markAsAbsent}
        </span>
      }
      content={
        <div>
          <p className="font-bold text-[14px] text-[#424955] my-[10px] leading-22">
            {modalContent?.remarks}
          </p>
          <ConfigProvider
            theme={{
              components: {
                Input: {
                  hoverBorderColor: "#27CA5F",
                  activeBorderColor: "#27CA5F",
                },
              },
            }}>
            <TextArea
              className="custom-textarea mb-[20px]"
              rows={4}
              placeholder={modalContent?.addRemarks}
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
            />
          </ConfigProvider>
          <div className="flex justify-end items-center">
            <ButtonComponent
              text={buttonTitle?.submit}
              onClick={handleSubmit}
              disabled={remarks.trim() === ""}
              className={classNames(
                remarks.trim() === ""
                  ? "bg-[lightGray] text-primaryText"
                  : "bg-primary text-[white]",
                " px-[12px] py-[5px] rounded-md font-semibold ",
              )}
            />
          </div>
        </div>
      }
    />
  );
};

export default RemarkModal;
