import { QuestionCategoryAndSetsProps } from "@/types/componentTypes";
import { Table } from "antd";
import React from "react";

const QuestionCategoryAndSets: React.FC<QuestionCategoryAndSetsProps> = ({
  sets,
  handleSetClicked,
  assessmentDetailsResponse,
}) => {
  const questionPaperData = [
    { displayName: "Question Category", name: "category" },
    { displayName: "Grouping Criteria", name: "question_grouping_logic" },
  ];
  const keyNames = {
    category: "category",
    questionPaper: "Question Papers",
  };

  const dataSource = questionPaperData?.flatMap((rule) => {
    const value =
      assessmentDetailsResponse?.question_paper_details?.[0]?.[rule?.name];

    if (!value) return [];

    if (rule?.name === keyNames?.category) {
      return Object.entries<any>(value).flatMap(([categoryName, levels]) => {
        if (!levels) return [];
        const groupingCriteria =
          assessmentDetailsResponse?.question_paper_details[0]
            ?.question_grouping_logic[categoryName] || "";
        return Object.entries<any>(levels).map(([level, marks]) => ({
          category: categoryName,
          marks: marks,
          level: level,
          grouping: groupingCriteria,
        }));
      });
    }

    return [];
  });

  const columns = [
    {
      title: "Question Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Difficulty Level",
      dataIndex: "level",
      key: "level",
    },
    {
      title: "Marks",
      dataIndex: "marks",
      key: "marks",
    },
    {
      title: "Grouping Criteria",
      dataIndex: "grouping",
      key: "grouping",
    },
  ];

  return (
    <div className="mt-[20px] ">
      <div className="table-wrapper mb-[20px] ">
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          scroll={{ y: 180 }}
        />
      </div>
      <div className="text-[16px]">{keyNames?.questionPaper}</div>
      <div className="flex flex-col space-y-[5px]">
        {sets?.map((set: any, index: any) => {
          return (
            <div key={index}>
              <div
                className="text-[#67B9F6] text-[14px] cursor-pointer"
                style={{ textDecoration: "underLine" }}
                onClick={() => handleSetClicked(set.setId)}>
                {set?.text}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default QuestionCategoryAndSets;
