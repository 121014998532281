import React, { useContext, useEffect } from "react";
import { ItemStateContext } from "@/contexts/questionContext";
import { localStorageKeys } from "@/constants/constants";
import { ScreenSharingCheckProps } from "@/types/componentTypes";

const endTracks = (
  tracks: any[],
  setAllPermissions: (arg0: (prev: any) => any) => void,
): any => {
  tracks.forEach((track) => {
    track.onended = () => {
      setAllPermissions((prev) => ({
        ...prev,
        screenSharingAllowed: false,
      }));
    };
  });
};

const ScreenSharingCheck: React.FC<ScreenSharingCheckProps> = ({ onAllow }) => {
  const { setAllPermissions, streamRef }: any = useContext(ItemStateContext);

  const checkScreenSharingPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getDisplayMedia();
      const tracks = stream.getTracks();
      const isScreenSharing = tracks.some((track) => track.kind === "video");

      if (isScreenSharing) {
        const isEntireScreen = tracks.some((track) =>
          track.label?.includes("screen:"),
        );

        setAllPermissions((prev) => ({
          ...prev,
          screenSharingAllowed: isEntireScreen,
        }));

        localStorage.setItem(
          localStorageKeys.storedScreenShareStatus,
          JSON.stringify(isEntireScreen),
        );

        endTracks(tracks, setAllPermissions);

        streamRef.current = stream;
        onAllow();
      } else {
        setAllPermissions((prev) => ({
          ...prev,
          screenSharingAllowed: false,
        }));

        localStorage.setItem(
          localStorageKeys.storedScreenShareStatus,
          JSON.stringify(false),
        );

        onAllow();
      }
    } catch (error) {
      console.error("Error checking screen sharing permission:", error);

      setAllPermissions((prev) => ({
        ...prev,
        screenSharingAllowed: false,
      }));

      localStorage.setItem(
        localStorageKeys.storedScreenShareStatus,
        JSON.stringify(false),
      );

      onAllow();
    }
  };

  useEffect(() => {
    checkScreenSharingPermission();
  }, []); // Run once on component mount

  return <div></div>; // You can render additional UI elements here if needed
};

export default ScreenSharingCheck;
