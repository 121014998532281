import React, { useEffect } from "react";
import Home from "./pages/Home";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "./App.css";
import ProtectedRoute from "./ProtectedRoute";
import ProtectedRouteStakeholder from "./ProtectedRouteStakeholder";
import LoginWithAccessCode from "./pages/LoginWithAccessCode";
import Dashboard from "./pages/Dashboard";
import Assessment from "./pages/Assessment";
import Login from "./pages/Login";
import AssessmentDetails from "./pages/AssessmentDetails";
import { AuthProvider } from "./contexts/authContext";
import { ChatProvider } from "./contexts/chatContext";
import { ItemStateProvider } from "./contexts/questionContext";
import { SocketProvider } from "./contexts/socketContext";
import { FIREBASE_URL } from "@/utils/service-urls";
import { getFirebaseUrl } from "@/utils/functions";
import { firebaseMethodKeys, assessmentStatus } from "@/constants/constants";
import { handleFirebaseOperation } from "@/firebase";
import { localStorageKeys } from "./constants/constants";
import CandidateBlockedScreen from "./components/candidateBlockedScreen";
function App() {
  useEffect(() => {
    const onUnload = (e) => {
      e.preventDefault();
      e.returnValue = "";
    };

    const handleUnload = async () => {
      const assessmentId = localStorage.getItem(localStorageKeys.assessmentId);
      const setId = localStorage.getItem(localStorageKeys.setId);
      const candidateId = localStorage.getItem(localStorageKeys.candidateId);
      try {
        if (candidateId && assessmentId && setId) {
          const options = {
            assessmentId: assessmentId,
            setId: setId,
            candidateId: candidateId,
            endpoint: `${FIREBASE_URL?.ENDPOINT?.EXAM_STATUS}`,
          };
          const firebaseUrl = getFirebaseUrl(options);
          handleFirebaseOperation(
            firebaseUrl,
            firebaseMethodKeys.PUT,
            assessmentStatus.COMPLETED,
          );
        }
      } catch (error) {
        console.error("Error making API call:", error);
      }
    };

    window.addEventListener("beforeunload", onUnload);
    window.addEventListener("unload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", onUnload);
      window.removeEventListener("unload", handleUnload);
    };
  }, []);

  return (
    <AuthProvider>
      <ItemStateProvider>
        <SocketProvider>
          <ChatProvider>
            <Router>
              <Routes>
                <Route path="/login" element={<LoginWithAccessCode />} />
                <Route path="/" element={<ProtectedRoute component={Home} />} />
                <Route
                  path="/assessment/:id/:stateId"
                  element={<ProtectedRoute component={Assessment} />}
                />
                <Route
                  path="/stakeholder/home/:id"
                  element={<ProtectedRouteStakeholder component={Dashboard} />}
                />

                <Route
                  path="/stakeholder/assessment/:id"
                  element={
                    <ProtectedRouteStakeholder component={AssessmentDetails} />
                  }
                />
                <Route path="/candidate/login" element={<Login />} />
                <Route path="/candidate/home/:id" element={<Dashboard />} />
                <Route path="*" element={<Navigate to="/" />} />
                {/* <Route path="/block" component={CandidateBlockedScreen} /> */}
                <Route
                  path="/assessment/block"
                  element={<CandidateBlockedScreen />}
                />
              </Routes>
            </Router>
          </ChatProvider>
        </SocketProvider>
      </ItemStateProvider>
    </AuthProvider>
  );
}

export default App;
